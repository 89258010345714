import React from 'react'
import { connect } from 'react-redux'

import moment from 'moment'
import { length, isEmpty } from 'ramda'
import { Icon, Table, Button, Loader, Pagination } from 'semantic-ui-react'

import {
  create,
  update,
  removeById,
  findFormsByCustomerId,
} from '~/store/modules/forms/actions'

import EmptyList from '~/components/EmptyList'
import FormsHeader from '~/components/FormsHeader'
import FormsDetailModal from '~/components/Modal/FormsDetail'
import Main from '../Main'

class Forms extends React.Component {
  state = {
    errors: [],
    search: '',
    formName: '',

    formIdEdit: '',
    formNameEdit: '',

    limit: 10,
    offset: 0,

    activePage: 0,
    siblingRange: 1,
    boundaryRange: 0,
    openModal: false,
    showEllipsis: true,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,
  }

  componentDidMount() {
    this.onRefresh()
  }

  onRefresh = () => {
    this.setState({ errors: [] })
    this.props.findFormsByCustomerId({
      ...this.state,
      id: this.props.user.profile.uid,
    })
  }

  handlerInput = ({ target }) =>
    this.setState({
      formName: target.value,
    })

  handlerSearch = ({ target }) => {
    this.setState({ search: target.value })

    this.props.findFormsByCustomerId({
      ...this.state,
      search: target.value,
      id: this.props.user.profile.uid,
    })
  }

  handlePaginationChange = (e, { activePage }) => {
    this.props.findFormsByCustomerId({
      ...this.state,
      id: this.props.user.profile.uid,
      offset: activePage * this.state.limit - this.state.limit,
    })
    this.setState({ activePage })
  }

  componentDidUpdate(nextProps) {
    if (nextProps.formsCreate !== this.props.formsCreate) {
      this.refreshProps(this.props.formsCreate)
      this.errorProps(this.props.formsCreate)
    }
    if (nextProps.formsUpdate !== this.props.formsUpdate) {
      this.refreshProps(this.props.formsUpdate)
      this.errorProps(this.props.formsUpdate)
    }
    if (nextProps.formsRemove !== this.props.formsRemove) {
      this.refreshProps(this.props.formsRemove)
      this.errorProps(this.props.formsRemove)
    }
  }

  refreshProps = props => props.success && this.onRefresh()

  errorProps = props =>
    props.error &&
    this.setState({
      errors: [].concat('error'),
    })

  renderItem = (item, index) => (
    <Table.Row key={`key-${index}`}>
      <Table.Cell>#{item.id}</Table.Cell>
      <Table.Cell>{item.name}</Table.Cell>
      <Table.Cell>
        {moment(item.created_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Button
          icon
          onClick={() => {
            this.setState({
              openModal: true,
              formNameEdit: item.name,
              formIdEdit: item.uid,
            })
          }}
        >
          <Icon name="pencil" />
        </Button>
        <Button
          icon
          primary
          onClick={() => this.props.history.push(`/forms/${item.uid}`)}
        >
          <Icon name="ordered list" />
        </Button>
        <Button negative icon onClick={() => this.removeItem(item.uid)}>
          <Icon name="trash" />
        </Button>
      </Table.Cell>
    </Table.Row>
  )

  submitForm = () => {
    const arr = []

    if (!this.state.formName) {
      this.setState({
        errors: arr.concat('Nome da formulário não pode estar vazio!'),
      })
      return
    }

    this.setState({ errors: [] })

    this.props.create({
      name: this.state.formName,
      customer_id: this.props.user.profile.uid,
    })
  }

  submitUpdate = () => {
    const arr = []

    if (isEmpty(this.state.formNameEdit)) {
      this.setState({
        errors: arr.concat('Nome da formulário não pode estar vazio!'),
      })
      return
    }

    this.setState({
      errors: [],
      openModal: false,
    })

    this.props.update({
      uid: this.state.formIdEdit,
      name: this.state.formNameEdit,
      customerId: this.props.user.profile.uid,
    })
  }

  handleChangeForm = ({ target }) =>
    this.setState({
      [target.id]: target.value,
    })

  removeItem = id => this.props.removeById(id, this.props.user.profile.uid)

  render() {
    return (
      <Main {...this.props}>
        <FormsHeader
          {...this.state}
          handlerInput={this.handlerInput}
          handlerSearch={this.handlerSearch}
          submitForm={() => this.submitForm()}
        />
        {this.props.formsList.isFetching ? (
          <div>
            <Loader active inline="centered" />
          </div>
        ) : length(this.props.formsList.data) ? (
          <div style={{ marginTop: 20 }}>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell>Criado Em</Table.HeaderCell>
                  <Table.HeaderCell>Ações</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {length(this.props.formsList.data) > 0 &&
                  this.props.formsList.data.map((item, index) => {
                    return this.renderItem(item, index)
                  })}
              </Table.Body>
              {length(this.props.formsList.data) >= 10 && (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="8">
                      <Pagination
                        totalPages={100}
                        activePage={this.state.activePage}
                        siblingRange={this.state.siblingRange}
                        boundaryRange={this.state.boundaryRange}
                        onPageChange={this.handlePaginationChange}
                        ellipsisItem={
                          this.state.showEllipsis ? undefined : null
                        }
                        firstItem={
                          this.state.showFirstAndLastNav ? undefined : null
                        }
                        lastItem={
                          this.state.showFirstAndLastNav ? undefined : null
                        }
                        prevItem={
                          this.state.showPreviousAndNextNav ? undefined : null
                        }
                        nextItem={
                          this.state.showPreviousAndNextNav ? undefined : null
                        }
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          </div>
        ) : (
          <EmptyList
            icon="wpforms"
            text="Nenhum formulário foi adicionado..."
          />
        )}
        <FormsDetailModal
          {...this.state}
          handleChangeForm={this.handleChangeForm}
          submitUpdate={() => this.submitUpdate()}
          close={() => this.setState({ openModal: false })}
        />
      </Main>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    formsList: state.formsList,
    formsCreate: state.formsCreate,
    formsRemove: state.formsRemove,
    formsUpdate: state.formsUpdate,
  }
}

export default connect(mapStateToProps, {
  create,
  update,
  removeById,
  findFormsByCustomerId,
})(Forms)
