import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  useHistory,
  useParams,
} from 'react-router-dom'

import { Menu } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import Main from '~/pages/Main'
import { subCategoriesAll } from '~/store/modules/categories/actions'
import {
  findCategoriesById,
  updateSubCategories,
  updatePayment,
  findById,
  update as updateCustomer,
} from '~/store/modules/customers/actions'
import message from '~/utils/messages'

import Details from './components/Info'
import Payment from './components/Payment'
import Subcategories from './components/Subcategories'

export default function CustomerDetail(props) {
  const { customerId } = useParams()

  const [activePage, setActivePage] = useState(0)
  const [customer, setCustomer] = useState({})
  const [customerPaymentConfig, setCustomerPaymentConfig] = useState({})
  const [subCategories, setSubCategories] = useState([])
  const [isLoadingCategories, setIsLoadingCategories] = useState(true)

  const history = useHistory()
  const dispatch = useDispatch()

  const customerData = useSelector((store) => store.customersDetail)
  const customerUpdateData = useSelector((store) => store.customersUpdate)
  const customerPaymentUpdateData = useSelector(
    (store) => store.customersPaymentUpdate
  )
  const customerCategoriesData = useSelector(
    (store) => store.customerCategories
  )
  const subCategoriesData = useSelector((store) => store.subCategoriesAll)
  const customersUpdateSubcategorieData = useSelector(
    (store) => store.customersUpdateSubcategorie
  )

  const handleTabs = (page) => {
    setActivePage(page)
  }

  const getCustomer = useCallback(() => {
    dispatch(findById(customerId))
  }, [customerId, dispatch])

  useEffect(() => {
    getCustomer()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (customerData.success) {
      setCustomer(customerData.data)
      setCustomerPaymentConfig({
        customerPaymentType: customerData.data.customer_payment_type,
        limit: customerData.data.limit,
      })
      customerData.success = false
    }
  }, [customerData])

  const submitUpdateCustomer = useCallback(
    (customerForm) => {
      return dispatch(updateCustomer({ ...customerForm }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (customerUpdateData.success) {
      getCustomer()
      message().success('Cliente atualizado com sucesso!')
      customerUpdateData.success = false
    } else if (customerUpdateData.error) {
      message().error(customerUpdateData.message)
    }
  }, [customerUpdateData, getCustomer])

  const submitUpdateConfig = useCallback(
    (configForm) => {
      return dispatch(
        updatePayment({
          ...configForm,
          uid: customer.customer_config_id,
        })
      )
    },
    [customer.customer_config_id, dispatch]
  )

  useEffect(() => {
    if (customerPaymentUpdateData.success) {
      getCustomer()
      message().success('Configuração de pagamento atualizada com sucesso!')
      customerPaymentUpdateData.success = false
    } else if (customerPaymentUpdateData.error) {
      message().error(customerPaymentUpdateData.message)
    }
  }, [customerPaymentUpdateData, getCustomer])

  useEffect(() => {
    dispatch(subCategoriesAll())
  }, []) //eslint-disable-line

  const submitUpdateSubCategories = useCallback(
    (updateData) => {
      dispatch(
        updateSubCategories(
          {
            data: updateData,
          },
          customerId
        )
      )
    },
    [customerId, dispatch]
  )

  const getCategories = useCallback(() => {
    dispatch(findCategoriesById(customerId))
  }, [customerId, dispatch])

  useEffect(() => {
    if (customersUpdateSubcategorieData.success) {
      message().success('Subcategorias salvas com sucesso!')
      customersUpdateSubcategorieData.success = false
    } else if (customersUpdateSubcategorieData.error) {
      message().error(customersUpdateSubcategorieData.message)
      customersUpdateSubcategorieData.error = false
    }
    getCategories()
  }, [customersUpdateSubcategorieData, customerId, getCategories])

  useEffect(() => {
    if (subCategoriesData.success) {
      getCategories()

      setSubCategories(
        subCategoriesData.data.map((i) => Object.assign(i, { deleted: false }))
      )
      subCategoriesData.success = false
    }
  }, [getCategories, customerId, subCategoriesData])

  useEffect(() => {
    if (customerCategoriesData.success) {
      customerCategoriesData.data.forEach((value) => {
        const index = subCategories.findIndex(
          (i) => value.mission_main_id === i.uid
        )

        if (subCategories[index] !== undefined) {
          const newSubCategories = [...subCategories]
          newSubCategories[index].deleted = true
          setSubCategories(newSubCategories)
        }
      })

      setIsLoadingCategories(false)
      customerCategoriesData.success = false
    }
  }, [customerCategoriesData, subCategories])

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      link: '/customers',
      name: 'Clientes',
    },
    {
      active: true,
      hasLink: false,
      link: `/customers/${customerId}`,
      name: customer.name,
    },
  ]

  return (
    <Main {...props}>
      <Router>
        <Breadcrumbs items={breadcrumbsList} history={history} />
        <Menu secondary pointing color="blue">
          <Menu.Item
            content="Detalhes"
            name="details"
            active={activePage === 0}
            onClick={() => handleTabs(0)}
          />
          <Menu.Item
            content="Pagamento"
            name="payment"
            active={activePage === 1}
            onClick={() => handleTabs(1)}
          />
          <Menu.Item
            content="Sub-categorias"
            name="subcategories"
            active={activePage === 2}
            onClick={() => handleTabs(2)}
          />
        </Menu>

        {activePage === 0 && (
          <Details
            customer={customer}
            submitUpdateCustomer={submitUpdateCustomer}
          />
        )}
        {activePage === 1 && (
          <Payment
            paymentConfig={customerPaymentConfig}
            submitUpdateConfig={submitUpdateConfig}
          />
        )}
        {activePage === 2 && (
          <Subcategories
            subCategories={subCategories}
            updateSubCategories={submitUpdateSubCategories}
            isLoadingCategories={isLoadingCategories}
          />
        )}
      </Router>
    </Main>
  )
}
