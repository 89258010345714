import React, { memo } from 'react'
import DatePicker from 'react-datepicker'

import PropTypes from 'prop-types'
import { Icon, Select, Button, Card, Form } from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'

function BudgetFilter({
  filters,
  isLoading,
  clearFilters,
  onChangeFilter,
  submitExport,
}) {
  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group>
            <Form.Field>
              <div className="ui input">
                <DatePicker
                  id="initial"
                  selectsStart
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Inicio"
                  selected={filters.initial}
                  startDate={filters.initial}
                  endDate={filters.final}
                  onChange={(value) => onChangeFilter('initial', value)}
                />
              </div>
            </Form.Field>

            <Form.Field>
              <div className="ui input">
                <DatePicker
                  id="final"
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Fim"
                  selected={filters.final}
                  startDate={filters.initial}
                  endDate={filters.final}
                  onChange={(value) => onChangeFilter('final', value)}
                />
              </div>
            </Form.Field>

            <Form.Field
              control={Select}
              placeholder="Limite"
              selection
              value={filters.limit}
              options={[
                { key: 1, value: 15, text: 15 },
                { key: 2, value: 50, text: 50 },
                { key: 3, value: 100, text: 100 },
              ]}
              onChange={(e, { value }) => onChangeFilter('limit', value)}
            />
          </Form.Group>
        </Form>
      </Card.Content>

      <Card.Content>
        <Button icon color="grey" labelPosition="left" onClick={clearFilters}>
          <Icon name="undo" />
          Limpar Filtros
        </Button>

        <Button
          icon
          color="orange"
          labelPosition="left"
          onClick={submitExport}
          loading={isLoading}
          disabled={isLoading}
        >
          <Icon name="download" />
          Gerar Relatório
        </Button>
      </Card.Content>
    </Card>
  )
}

BudgetFilter.propTypes = {
  filters: PropTypes.object,
  isLoading: PropTypes.bool,
  clearFilters: PropTypes.func,
  onChangeFilter: PropTypes.func,
  submitExport: PropTypes.func,
}

export default memo(BudgetFilter)
