import { GET_CUSTOMERS_RATES_LIST } from '~/services/api/endpoints'
import { get } from '~/utils/actionsRequests'

function getRates(customerId) {
  return get({
    url: `${GET_CUSTOMERS_RATES_LIST}?customer_id=${customerId}`,
    typeFetch: 'RATES',
    typeError: 'RATES_FAIL',
    typeResponse: 'RATES_OK',
  })
}

export { getRates }
