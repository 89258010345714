import React from 'react'

import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'

import CustomerCategories from '~/components/CustomerCategories/index'
import CustomerSelectedCategories from '~/components/CustomerSelectedCategories/index'

export default function Subcategories(props) {
  const { subCategories, updateSubCategories, isLoadingCategories } = props

  const toggle = ({ target }) => {
    if (!target.value) return false

    const index = subCategories.findIndex((i) => i.id === Number(target.value))

    const newSubCategories = [...subCategories]
    newSubCategories[index].deleted = target.checked

    const updateData = {
      deleted: !target.checked,
      mission_main_id: newSubCategories[index].uid,
    }

    return updateSubCategories([updateData])
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} largeScreen={8}>
          <CustomerSelectedCategories
            subCategories={subCategories}
            isLoadingCategories={isLoadingCategories}
          />
        </Grid.Column>
        <Grid.Column mobile={16} largeScreen={8}>
          <CustomerCategories
            subCategories={subCategories}
            isLoadingCategories={isLoadingCategories}
            toggle={toggle}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
Subcategories.propTypes = {
  updateSubCategories: PropTypes.func,
  isLoadingCategories: PropTypes.bool,
  subCategories: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      category: PropTypes.string,
      created_at: PropTypes.string,
      deleted: PropTypes.bool,
      expiring_time: PropTypes.number,
      id: PropTypes.number,
      status: PropTypes.bool,
      title: PropTypes.string,
      uid: PropTypes.string,
    })
  ).isRequired,
}
