import React from 'react'
import update from 'react-addons-update'
import { connect } from 'react-redux'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import { format } from 'date-fns'
import queryString from 'query-string'
import { isNil, omit } from 'ramda'
import { Icon, Popup, Table, Button } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'

import UsersSearchModal from '~/components/Modal/UsersSearch'
import TableComponent from '~/components/Table'
import { IMPORT_FILE_MISSIONS_OPEN } from '~/services/api/endpoints'
import {
  findUfsMission,
  findCitiesMission,
} from '~/store/modules/campaign/actions'
import {
  categories as findCategories,
  findSubcategories,
} from '~/store/modules/categories/actions'
import { findAll as findCustomers } from '~/store/modules/customers/actions'
import { exportOpenMissions } from '~/store/modules/imports/actions'
import { findOpen } from '~/store/modules/missions/actions'
import {
  findUsersInProgressById,
  findByOpens,
  deliveryNotification,
} from '~/store/modules/users/actions'

import MissionsFilter from './components/Filter'
import UsersInProgress from './components/UsersInProgress'

const directions = [
  { key: 0, text: 'Aberta', value: 'opened' },
  { key: 1, text: 'Iniciadas', value: 'started' },
  { key: 2, text: 'Finalizadas', value: 'finished' },
]

const columns = [
  'ID',
  'Missão',
  'Cidade',
  'UF',
  'Categoria',
  'Participantes',
  'Cliente',
  'Expira Em',
  '',
]

const orderBy = {
  opened: 'de Missões Abertas',
  started: 'de Missões Iniciadas',
  finished: 'de Missões Finalizadas',
}

const startQuery = {
  uf: [],
  city: [],
  limit: 15,
  offset: 0,
  directions,
  search: 'limit=15&offset=0&status=3',
  final: null,
  initial: null,
  ufsList: [],
  customers: [],
  activePage: 1,
  kind: 'opened',
  limitUsers: 20,
  citiesList: [],
  categories: [],
  customer_id: '',
  subCategories: [],
  missions_main_id: '',
}

class MissionsOpen extends React.Component {
  state = {
    users: [],
    usersList: [],
    lat: null,
    lng: null,
    open: false,
    modalId: '',
    message: '',
    identifier: '',
    missionName: '',
    isLoading: true,
    selectAll: false,
    missionsOpenData: [],
    isLoadingUsers: false,
    isLoadingButton: false,
    isLoadingSubmit: false,
    openUsersProgress: false,
    isLoadingUsersProgress: false,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,

    ...startQuery,
  }

  componentDidMount() {
    this.startQuery()
    this.onRefresh()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.missionsOpen !== this.props.missionsOpen) {
      if (nextProps.missionsOpen.success) {
        this.calculatePagination(nextProps.missionsOpen)
      }
    }

    if (nextProps.delivered !== this.props.delivered) {
      if (nextProps.delivered.success) {
        this.showToast('Mensagens enviadas com sucesso!', true)
        this.setState({ isLoadingSubmit: false, open: false })
        nextProps.delivered.success = false
      }
    }

    if (nextProps.usersSearch !== this.props.usersSearch) {
      this.handlerUsersList('users', nextProps.usersSearch)
    }

    if (nextProps.usersInProgress !== this.props.usersInProgress) {
      this.handlerUsersList('usersList', nextProps.usersInProgress)
    }

    if (nextProps.customers !== this.props.customers) {
      this.handlerList(nextProps.customers, 'customers')
    }

    if (nextProps.ufs !== this.props.ufs) {
      this.handlerList(nextProps.ufs, 'ufsList')
    }

    if (nextProps.cities !== this.props.cities) {
      this.handlerList(nextProps.cities, 'citiesList')
    }

    if (nextProps.categories !== this.props.categories) {
      this.handlerList(nextProps.categories, 'categories')
    }

    if (nextProps.subCategoriesAll !== this.props.subCategoriesAll) {
      this.handlerList(nextProps.subCategoriesAll, 'subCategories')
    }

    if (nextProps.exportedMissionsOpen !== this.props.exportedMissionsOpen) {
      if (nextProps.exportedMissionsOpen.success) {
        window.open(nextProps.exportedMissionsOpen.data, 'self')
      }
    }
  }

  sortedRequest = (order, direction) => {
    const parsedQuery = queryString.parse(this.state.search)
    const search = {
      ...parsedQuery,
      direction,
      type: order,
      limit: Number(this.state.limit),
    }

    if (!isNil(this.state.initial) && !isNil(this.state.final)) {
      Object.assign(search, {
        initial: Number(this.state.initial),
        final: Number(this.state.final),
      })
    }

    const query = queryString.stringify(search)

    this.setState({ ...search, search: query })
    this.props.history.replace({ search: query })
    this.props.findOpen(query)
  }

  handleSort = (order, direction) => {
    let column = order
    switch (column) {
      case 'Cliente':
        column = 'customername'
        this.sortedRequest(column, direction)
        break
      case 'Categoria':
        column = 'category'
        this.sortedRequest(column, direction)
        break
      case 'Missão':
        column = 'name'
        this.sortedRequest(column, direction)
        break
      case 'Cidade':
        column = 'place'
        this.sortedRequest(column, direction)
        break
      case 'Expira Em':
        column = 'expired_at'
        this.sortedRequest(column, direction)
        break
      case 'UF':
        column = 'uf'
        this.sortedRequest(column, direction)
        break
      case 'Participantes':
        column = 'participants'
        this.sortedRequest(column, direction)
        break
      default:
        break
    }
  }

  startQuery = () => {
    const { initial, final } = queryString.parse(this.props.location.search)

    const query = {
      ...queryString.parse(this.props.location.search || 'offset=0&limit=15'),
      direction: 'asc',
      type: 'm.expired_at',
    }

    if (!isNil(initial) && !isNil(final)) {
      Object.assign(query, {
        initial: Number(initial),
        final: Number(final),
      })
    }

    this.setState({
      ...query,
      limit: Number(query.limit),
      search: this.props.location.search,
    })

    this.props.history.replace({ search: queryString.stringify(query) })
    this.props.findOpen(queryString.stringify(query))
  }

  exportMissions = () => {
    const query = this.props.location.search
    const parse = queryString.parse(query)
    const newQuery = omit(['limit', 'offset'], parse)
    window.open(
      `${IMPORT_FILE_MISSIONS_OPEN}?${queryString.stringify(newQuery)}`,
      'self'
    )
  }

  onRefresh = () =>
    Promise.all([
      this.props.findUfsMission(),
      this.props.findCategories(),
      this.props.findCustomers({ search: '', limit: 1000, offset: 0 }),
    ])

  resetFilter = () => {
    this.setState({ ...startQuery })
    this.startQuery()
    this.onRefresh()
  }

  openWhats = (item) =>
    window.open(`https://api.whatsapp.com/send?phone=${item.phone}`)

  handlerChange = (value, key) => {
    if (key === 'category_id') {
      this.props.findSubcategories(value)
    }

    if (key === 'uf') {
      const ufs = value.join(', ')
      this.props.findCitiesMission(`ufs=${ufs}`)
      this.handlerQuery(key, ufs)
      this.setState({ ...this.state, [key]: ufs })
      return false
    }

    if (key === 'initial' || key === 'final') {
      this.handlerQuery(key, new Date(value).getTime())
      this.setState({ ...this.state, [key]: value })
      return false
    }

    this.handlerQuery(key, value)
    this.setState({ ...this.state, [key]: value })
  }

  handlerQuery = (target, value) => {
    const queries = queryString.parse(this.props.location.search)
    const handlerQueries = { ...queries, [target]: value }
    const search = queryString.stringify(handlerQueries)
    this.props.history.replace({
      search,
      pahtname: this.props.location.pathname,
    })
  }

  handlePaginationChange = (e, { activePage }) => {
    const newOffset = (activePage - 1) * this.state.limit
    const offset = parseInt(newOffset, 0).toFixed(0)
    const queries = queryString.parse(this.props.location.search)
    const search = queryString.stringify({
      ...queries,
      offset: newOffset,
      limit: this.state.limit,
    })
    this.setState({ activePage, offset })

    this.handlerQuery('offset', offset)
    this.props.findOpen(search)
  }

  showToast = (description, type) =>
    toast(
      {
        description,
        title: 'Alerta',
        type: type ? 'success' : 'warning',
        icon: type ? 'check' : 'remove',
      },
      () => console.log('toast closed')
    )

  handlerState = (key, value) => this.setState({ [key]: value })

  handlerList = (props, key) => {
    if (props.success) {
      const arr = props.data.map((item, index) => ({
        key: index,
        value: item.uid || item.uf || item,
        text: item.name || item.title || item,
      }))
      this.setState({ [key]: arr })
    }
  }

  handlerUsersList = (key, props) => {
    if (props.success) {
      const arr = props.data.map((item) => ({ ...item, checked: false }))
      this.setState({
        [key]: arr,
        isLoadingUsers: false,
        [`isLoadingButton${this.state.modalId}`]: false,
        [`isLoadingUsersProgress${this.state.modalId}`]: false,
      })
    } else if (props.error) {
      this.showToast(props.message)
      this.setState({
        isLoadingUsers: false,
        [`isLoadingButton${this.state.modalId}`]: false,
        [`isLoadingUsersProgress${this.state.modalId}`]: false,
      })
      return false
    }
  }

  calculatePagination = (props) => {
    if (props.success) {
      const newCount = Number(props.count)
      const value = newCount / this.state.limit
      const currentCount =
        this.state.limit < newCount
          ? props.data.length + this.state.limit * (this.state.activePage - 1)
          : newCount

      this.setState({
        isLoading: false,
        missions: props.data,
        totalCount: newCount,
        actualCount: currentCount,
        count: Math.ceil(value),
      })
    }
  }

  loadingMore = (lat, lng) => {
    const limitUsers = this.state.limitUsers + 20
    this.setState({ limitUsers, isLoadingUsers: true })
    setTimeout(() => {
      this.props.findByOpens({ limit: limitUsers, lat, lng })
    }, 2000)
  }

  openModal = (item) => {
    this.setState({
      open: true,
      lat: item.lat,
      lng: item.lng,
      modalId: item.uid,
      isLoadingModal: true,
      missionName: item.name,
      identifier: item.identifier,
      [`isLoadingButton${item.uid}`]: true,
    })
    this.props.findByOpens({ ...item, limit: this.state.limitUsers })
  }

  openModalUsers = (item) => {
    this.setState({
      modalId: item.uid,
      missionName: item.name,
      openUsersProgress: true,
      identifier: item.identifier,
      [`isLoadingUsersProgress${item.uid}`]: true,
    })
    this.props.findUsersInProgressById(item.uid)
  }

  handlerToggle = (key, value) => {
    const item = this.state[key].find((i) => i.uid === value)
    const index = this.state[key].findIndex((i) => i.uid === value)
    this.setState({
      [key]: update(this.state[key], {
        [index]: { checked: { $set: !item.checked } },
      }),
    })
  }

  selectAllSubmit = (type) => {
    if (!this.state.selectAll) {
      this.state[type].map((i) => (i.checked = true))
      this.setState({ [type]: this.state[type], selectAll: true })
    } else {
      this.state[type].map((i) => (i.checked = false))
      this.setState({ [type]: this.state[type], selectAll: false })
    }
  }

  submitFilterRegister = () => {
    const queries = {
      ...queryString.parse(this.props.location.search),
      limit: Number(this.state.limit),
    }

    const { initial, final } = queries

    if (!isNil(initial) && !isNil(final)) {
      Object.assign(queries, { initial: Number(initial), final: Number(final) })
    }

    const search = queryString.stringify({
      ...queries,
      offset: 0,
      type: 'expired_at',
      direction: 'asc',
    })

    this.props.findOpen(search)
    this.props.history.replace({ search })
  }

  submitNotification = (type) => {
    const arr = this.state[type].filter((i) => i.checked)

    if (arr.length === 0) {
      this.showToast('Selecione pelo menos um usuário!')
      return false
    }

    if (!this.state.message) {
      this.showToast('Mensagem não pode estar vazia!')
      return false
    }

    this.setState({ isLoadingSubmit: true })

    arr.forEach(({ uid, name, phone }) =>
      this.props.deliveryNotification({
        uid,
        name,
        phone,
        message: this.state.message,
      })
    )
  }

  renderItem = (item) => (
    <Table.Row key={uuidv4()} negative={!item.status}>
      <Table.Cell>{item.identifier}</Table.Cell>
      <Table.Cell>{item.name}</Table.Cell>
      <Table.Cell>{item.city}</Table.Cell>
      <Table.Cell>{item.uf}</Table.Cell>
      <Table.Cell>{item.category_name}</Table.Cell>
      <Table.Cell>{item.participants}</Table.Cell>
      <Table.Cell>{item.customername}</Table.Cell>
      <Table.Cell>
        {format(new Date(item.expired_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Button.Group basic size="small">
          <Popup
            trigger={
              <Button
                icon
                onClick={() => this.openModalUsers(item)}
                loading={this.state[`isLoadingUsersProgress${item.uid}`]}
                disabled={this.state[`isLoadingUsersProgress${item.uid}`]}
              >
                <Icon name="users" />
              </Button>
            }
            inverted
            content="Usuários na misssão"
            position="top center"
          />
          {!isNil(item.lat) && !isNil(item.lng) && (
            <Popup
              trigger={
                <Button
                  icon
                  onClick={() => this.openModal(item)}
                  loading={this.state[`isLoadingButton${item.uid}`]}
                  disabled={this.state[`isLoadingButton${item.uid}`]}
                >
                  <Icon name="bell" />
                </Button>
              }
              inverted
              position="top center"
              content="Usuários próximos"
            />
          )}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  )

  render() {
    return (
      <>
        <MissionsFilter
          {...this}
          {...this.state}
          exportMissions={this.exportMissions}
        />

        <TableComponent
          {...this}
          {...this.state}
          columns={columns}
          data={this.state.missions}
          renderItem={this.renderItem}
          isLoading={this.state.isLoading}
          hasText={orderBy[this.state.kind]}
          handlePaginationChange={this.handlePaginationChange}
          emptyText={{
            icon: 'world',
            text: 'Nenhuma Missão foi encontrada...',
          }}
          handleColumnSort={this.handleSort}
        />
        <UsersSearchModal
          {...this}
          {...this.state}
          close={() => this.setState({ open: false })}
        />
        <UsersInProgress
          {...this}
          {...this.state}
          closeUsers={() => this.setState({ openUsersProgress: false })}
        />
        <SemanticToastContainer position="top-right" />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  ufs: state.ufsMissions,
  customers: state.customers,
  delivered: state.delivered,
  categories: state.categories,
  cities: state.citiesMissions,
  usersSearch: state.usersSearch,
  missionsOpen: state.missionsOpen,
  usersInProgress: state.usersInProgress,
  subCategoriesAll: state.getSubCategories,
  exportedMissionsOpen: state.exportedMissionsOpen,
})

export default connect(mapStateToProps, {
  findOpen,
  findByOpens,
  findCustomers,
  findCategories,
  findUfsMission,
  findCitiesMission,
  findSubcategories,
  exportOpenMissions,
  deliveryNotification,
  findUsersInProgressById,
})(MissionsOpen)
