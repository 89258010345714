import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import {
  List,
  Image,
  Form,
  Grid,
  Modal,
  Icon,
  Button,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'

import ImageAvatar from '~/assets/avatar.png'

const UsersSearch = ({
  open,
  close,
  users,
  message,
  openWhats,
  loadingMore,
  handlerState,
  identifier,
  lat,
  lng,
  missionName,
  selectAll,
  handlerToggle,
  isLoadingUsers,
  isLoadingSubmit,
  selectAllSubmit,
  submitNotification,
}) => (
  <Modal size="large" open={open} onClose={close}>
    <Modal.Header>
      <Grid>
        <Grid.Column width={13}>
          Usuários próximos de {missionName} <br />
          {identifier ? `Identificador: ${identifier}` : ''}
        </Grid.Column>
        <Grid.Column width={3}>
          <Button
            size="mini"
            color={selectAll ? 'grey' : 'blue'}
            onClick={() => selectAllSubmit('users')}
          >
            {selectAll ? 'Deselecionar' : 'Selecionar'} tudo ({users.length})
          </Button>
        </Grid.Column>
      </Grid>
    </Modal.Header>
    <Modal.Content scrolling style={{ maxHeight: 300 }}>
      <List divided verticalAlign="middle" relaxed="very">
        {users.map((item, index) => (
          <List.Item key={`key-${index}`}>
            <List.Content floated="right">
              <Button
                as="a"
                size="mini"
                color="green"
                content="Abrir Whatsapp"
                onClick={() => openWhats(item)}
              />
              <CopyToClipboard
                text={item.phone}
                onCopy={() => console.log('copiado')}
              >
                <Button
                  as="a"
                  size="mini"
                  color="blue"
                  content="Copiar Telefone"
                />
              </CopyToClipboard>
              <Checkbox
                value={item.uid}
                id={`item-${index}`}
                checked={item.checked}
                onChange={({ target }) => handlerToggle('users', target.value)}
              />
            </List.Content>
            <Image
              avatar
              src={item.avatar === null ? ImageAvatar : item.avatar}
            />
            <List.Content>
              <List.Header as="a">{item.name}</List.Header>
              <List.Description>
                <b>{item.distance}km</b>, já realizou{' '}
                <b>{item.total_finished}</b> missões,{' '}
                <b>{item.total_approved}</b> foram aprovadas e seu balanço atual
                é de <b>R$ {item.balance}</b>
              </List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
      <Grid centered columns={1}>
        <Grid.Column>
          <Button
            compact
            attached="bottom"
            content="Carregar mais..."
            loading={isLoadingUsers}
            disabled={isLoadingUsers}
            onClick={() => loadingMore(lat, lng)}
          />
        </Grid.Column>
      </Grid>
    </Modal.Content>
    <Modal.Actions>
      <Form>
        <TextArea
          rows={2}
          value={message || ''}
          placeholder="Ex: Têm missão por perto, vamos fazer?"
          onChange={(e, { value }) => handlerState('message', value)}
        />
      </Form>
      <Button
        primary
        icon
        labelPosition="right"
        loading={isLoadingSubmit}
        style={{ marginTop: 10 }}
        onClick={() => submitNotification('users')}
      >
        Encaminhar Notificação <Icon name="bell" />
      </Button>
    </Modal.Actions>
  </Modal>
)

export default UsersSearch
