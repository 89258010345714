import React from 'react'
import { connect } from 'react-redux'

import { format } from 'date-fns'
import { Message } from 'semantic-ui-react'

import { findAll } from '~/store/modules/logs/actions'

import EmptyList from '~/components/EmptyList'
import Main from '../Main'

class Logs extends React.Component {
  state = {
    logs: [],
    limit: 10,
    offset: 0,
  }

  componentDidMount() {
    this.onRefresh()
  }

  onRefresh = () => {
    this.props.findAll({ ...this.state })
  }

  handlePaginationChange = (e, { activePage }) => {
    this.props.findAll({
      ...this.state,
      offset: activePage * this.state.limit - this.state.limit,
    })
    this.setState({ activePage })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.logs !== this.props.logs) {
      console.log(nextProps.logs)
    }
  }

  renderItem = (item, index) => (
    <Message key={`key-${index}`}>
      <Message.Header>
        {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm:ss')}
      </Message.Header>
      <p>{item.message}</p>
    </Message>
  )

  render() {
    return (
      <Main {...this.props}>
        {this.props.user.profile.role_name === 'Basic' ||
        this.props.user.profile.role_name === 'Leader' ? (
          <EmptyList icon="lock" text="Acesso restrito" />
        ) : (
          !this.props.logs.isFetching &&
          this.props.logs.data.map(this.renderItem)
        )}
      </Main>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    logs: state.logs,
  }
}

export default connect(mapStateToProps, {
  findAll,
})(Logs)
