import {
  BADGET_GET,
  BADGET_FETCH,
  BADGET_CREATE,
  BADGET_UPDATE,
  BADGET_REMOVE,
} from '~/services/api/endpoints'
import { get, remove, putFormData, postFormData } from '~/utils/actionsRequests'

const findAll = () =>
  get({
    url: BADGET_FETCH,
    typeFetch: 'BADGET_FETCH',
    typeError: 'BADGET_FETCH_FAIL',
    typeResponse: 'BADGET_FETCH_OK',
  })

const findById = (id) =>
  get({
    url: BADGET_GET(id),
    typeFetch: 'BADGET_GET',
    typeError: 'BADGET_GET_FAIL',
    typeResponse: 'BADGET_GET_OK',
  })

const removeById = (id) =>
  remove({
    url: BADGET_REMOVE(id),
    typeFetch: 'BADGET_REMOVE',
    typeError: 'BADGET_REMOVE_FAIL',
    typeResponse: 'BADGET_REMOVE_OK',
  })

const create = (data) =>
  postFormData({
    data,
    url: BADGET_CREATE,
    typeFetch: 'BADGET_CREATE',
    typeError: 'BADGET_CREATE_FAIL',
    typeResponse: 'BADGET_CREATE_OK',
  })

const update = (data, id) =>
  putFormData({
    data,
    url: BADGET_UPDATE(id),
    typeFetch: 'BADGET_UPDATE',
    typeError: 'BADGET_UPDATE_FAIL',
    typeResponse: 'BADGET_UPDATE_OK',
  })

export { create, update, findAll, findById, removeById }
