import React, { memo, useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'

import { CNPJ } from 'cpf_cnpj'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Card, Form, Input, Grid, Button, Select } from 'semantic-ui-react'

import message from '~/utils/messages'

const configOptions = [
  { key: 1, text: 'Pós-pago Normal', value: 1 },
  { key: 2, text: 'Pós-pago Desonerado', value: 2 },
  { key: 3, text: 'Pré-pago Normal', value: 3 },
  { key: 4, text: 'Pré-pago Desonerado', value: 4 },
]
const situationOptions = [
  { key: 1, text: 'Ativo', value: 'Ativo' },
  { key: 2, text: 'On Hold', value: 'On Hold' },
  { key: 3, text: 'Inativo', value: 'Inativo' },
]

function CreateCustomer({
  customerData,
  show,
  onSubmit,
  isLoading,
  isUpdating,
}) {
  const [customer, setCustomer] = useState({
    payment_type: 1,
    expiring_day: 10,
    contract_start: new Date(),
  })

  useEffect(() => {
    if (customerData) {
      setCustomer((oldCustomer) => ({ ...oldCustomer, ...customerData }))
    }
  }, [customerData])

  function handlerInput(_, { id, value }) {
    if (isUpdating && id === 'contract_start') {
      setCustomer((oldCustomer) => ({ ...oldCustomer, [id]: moment(value) }))
      return
    }

    setCustomer((oldCustomer) => ({ ...oldCustomer, [id]: value }))
  }

  function handleDate(value) {
    setCustomer((oldCustomer) => ({ ...oldCustomer, contract_start: value }))
  }

  const isFormValid = () => {
    if (!customer.responsible) {
      message().error('O responsável não pode estar vazio!')
      return false
    }

    if (!customer.name) {
      message().error('O nome não pode estar vazio!')
      return false
    }

    if (!customer.email) {
      message().error('O email não pode estar vazio!')
      return false
    }

    if (!CNPJ.isValid(customer.cnpj)) {
      message().error('CNPJ inválido!')
      return false
    }
    if (!/^\d+$/.test(customer.cnpj)) {
      message().error('O CNPJ deve conter apenas números!')
      return false
    }
    if (customer.cnpj.length < 14) {
      message().error('O CNPJ deve contar no minimo 14 números')
      return false
    }

    if (!customer.phone) {
      message().error('O Telefone não pode estar vazio!')
      return false
    }
    if (
      customer.phone.length !== 11 &&
      customer.phone.length !== 10 &&
      customer.phone.length !== 9 &&
      customer.phone.length !== 8
    ) {
      message().error('Telefone inválido!')
      return false
    }

    if (
      !isUpdating &&
      (customer.customer_payment_type === 1 ||
        customer.customer_payment_type === 2)
    ) {
      if (!customer.limit) {
        message().error('O limite não pode estar vazio!')
        return false
      }
      if (Number(customer.limit) === 0) {
        message().error('O limite deve ser maior que zero!')
        return false
      }
    }

    return true
  }

  const submitForm = () => {
    if (isFormValid()) {
      onSubmit(customer)
    }
  }

  return (
    <Card centered fluid style={{ display: show ? 'block' : 'none' }}>
      <Card.Content>
        <Card.Header>Dados do Cliente</Card.Header>
      </Card.Content>
      <Card.Content>
        <Form>
          <Grid relaxed>
            <Grid.Column>
              <Form.Field
                control={Input}
                id="responsible"
                type="text"
                value={customer.responsible || ''}
                onChange={handlerInput}
                label="Responsável (Obrigatório)"
                placeholder="Ex: João Silva"
              />
              <Form.Field
                control={Input}
                id="name"
                type="text"
                value={customer.name || ''}
                onChange={handlerInput}
                label="Nome (Obrigatório)"
                placeholder="Ex: Mission Brasil"
              />
              <Form.Field
                control={Input}
                id="email"
                type="email"
                value={customer.email || ''}
                onChange={handlerInput}
                label="Email (Obrigatório)"
                placeholder="Ex: cliente@cliente.com"
              />
              <Form.Field
                control={Input}
                id="cnpj"
                type="number"
                value={customer.cnpj || ''}
                onChange={handlerInput}
                label="CNPJ (Obrigatório)"
                placeholder="Ex: CNPJ Válido"
              />
              <Form.Field>
                <label>Inicio de Contrato</label>
                <DatePicker
                  id="contract_start"
                  minDate={moment()}
                  dateFormat="dd/MM/yyyy"
                  onChange={handleDate}
                  value={
                    moment(customer.contract_start).format('DD/MM/YYYY') ||
                    new Date()
                  }
                />
              </Form.Field>
              <Form.Field
                control={Input}
                id="phone"
                type="number"
                value={customer.phone || ''}
                onChange={handlerInput}
                label="Telefone (Obrigatório)"
                placeholder="Ex: 48987654321"
              />
              <Form.Input
                id="billing_estimated"
                type="number"
                onChange={handlerInput}
                label="Potencial de Faturamento"
                placeholder="Potencial de Faturamento (Ex: 1000)"
                value={customer.billing_estimated || ''}
                pattern="[0-9]*"
              />
              <Form.Field
                id="situation"
                control={Select}
                options={situationOptions}
                onChange={handlerInput}
                value={customer.situation || ''}
                label="Situação"
                placeholder="Ativo, On Hold, Inativo"
              />
              {!isUpdating && (
                <>
                  <Form.Field
                    id="customer_payment_type"
                    control={Select}
                    options={configOptions}
                    onChange={handlerInput}
                    value={customer.customer_payment_type || ''}
                    label="Tipo de Pagamento"
                    placeholder="Tipo de Pagamento"
                  />
                  {(customer.customer_payment_type === 1 ||
                    customer.customer_payment_type === 2) && (
                    <Form.Field
                      control={Input}
                      id="limit"
                      type="number"
                      value={customer.limit || ''}
                      onChange={handlerInput}
                      label="Limite (Obrigatório)"
                      placeholder="Ex: 100"
                    />
                  )}
                </>
              )}
            </Grid.Column>
          </Grid>
        </Form>
      </Card.Content>
      <Card.Content extra textAlign="right">
        <Button
          positive
          icon="check"
          onClick={() => submitForm()}
          loading={isLoading}
          disabled={isLoading}
          labelPosition="left"
          content="Salvar"
        />
      </Card.Content>
    </Card>
  )
}

CreateCustomer.propTypes = {
  customerData: PropTypes.exact({
    uid: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    cnpj: PropTypes.string,
    phone: PropTypes.string,
    avatar: PropTypes.string,
    status: PropTypes.bool,
    responsible: PropTypes.string,
    billing_estimated: PropTypes.number,
    url_integration_import: PropTypes.string,
    url_integration_export: PropTypes.string,
    customer_config_id: PropTypes.string,
    customer_payment_type: PropTypes.number,
    expiring_day: PropTypes.number,
    limit: PropTypes.string,
    payment_type: PropTypes.number,
    contract_start: PropTypes.string,
    situation: PropTypes.string,
    total_spend: PropTypes.number,
  }),
  onSubmit: PropTypes.func,
  show: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUpdating: PropTypes.bool,
}

export default memo(CreateCustomer)
