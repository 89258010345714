import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { SemanticToastContainer } from 'react-semantic-toasts'

import * as EmailValidator from 'email-validator'
import { Grid, Loader, Message, Tab } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'

import Breadcrumbs from '~/components/Breadcrumbs'
import api from '~/services/api'
import {
  USER_DATA,
  USER_DATA_DETAIL,
  USER_UPDATE,
} from '~/services/api/endpoints'
import history from '~/services/history'
import message from '~/utils/messages'

import RecentActivities from './components/Tabs/RecentActivities'
import RecentPayments from './components/Tabs/RecentPayments'
import RecentRegisters from './components/Tabs/RecentRegisters'
import UserGuests from './components/Tabs/UserGuests'
import UserInfo from './components/UserInfo'
import UserInfoEditModal from './components/UserInfo/UserInfoEditModal'

const errorItem = new Map([
  ['city', 'cidade'],
  ['neighborhood', 'bairro'],
  ['phone', 'telefone'],
  ['number', 'número do endereço'],
  ['postal_code', 'CEP'],
  ['uf', 'Estado'],
  ['verified_email', 'verefique o email'],
  ['email', 'email'],
])

export default function UserDetail() {
  const { id: userId } = useParams()

  const [userDetail, setUserDetail] = useState(null)
  const [userExtraData, setUserExtraData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
  const [errors, setErrors] = useState([])
  const [modalVisible, setModalVisible] = useState(false)

  function openModal() {
    setModalVisible(true)
  }

  const errorResponse = useCallback((msg) => {
    setIsLoading(false)
    setErrors((e) => e.concat(msg))
  }, [])

  const loadAll = useCallback(async () => {
    try {
      setIsLoading(true)
      const [uDetail, uExtraData] = await Promise.all([
        api.get(`${USER_DATA_DETAIL}${userId}`),
        api.get(`${USER_DATA}${userId}`),
      ])

      setUserDetail(uDetail.data.data)
      setUserExtraData(uExtraData.data.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      message().error(error.response.data.data || 'Houve um erro no servidor')
    }
  }, [userId])

  async function updateProfile(profile) {
    setIsLoadingUpdate(true)

    const { name, phone, email, newPass, confirmPass } = profile

    if (!name) {
      errorResponse('Nome não pode estar vazio!')
      return false
    }

    if (!email) {
      errorResponse('Email não pode estar vazio!')
      return false
    }

    if (!EmailValidator.validate(email)) {
      errorResponse('Email inválido!')
      return false
    }

    if (!phone && phone.length < 14) {
      errorResponse('Número de telefone incorreto!')
      return false
    }

    if (newPass) {
      if (newPass.length > 0 && newPass.length < 6) {
        errorResponse('Senha deve conter no minimo 6 caracteres!')
        return false
      }

      if (newPass !== confirmPass) {
        errorResponse('Senhas devem ser iguais!')
        return false
      }
    }

    try {
      await api.put(USER_UPDATE(userId), profile)
      setUserDetail((ud) => ({ ...ud, ...profile }))
      setIsLoadingUpdate(false)
      setModalVisible(false)
      return message().success('Usuário atualizado com sucesso!')
    } catch (error) {
      console.log(error)
      setIsLoadingUpdate(false)
      errorResponse(error.response.data.data || 'Houve um erro no servidor')
      return false
    }
  }

  useEffect(() => {
    loadAll()
  }, [loadAll])

  const warningMessage = (warning) => (
    <Message warning>
      <Message.Header>Informações faltando</Message.Header>
      <ul>
        {warning.map((item) => (
          <li key={uuidv4()}>{errorItem.get(item)}</li>
        ))}
      </ul>
    </Message>
  )

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      name: 'Usuários',
      link: '/users',
    },
    {
      active: true,
      hasLink: false,
      name: userDetail?.name,
    },
  ]

  const warning =
    !isLoading &&
    Object.keys(userDetail).filter(
      (attribute) =>
        userDetail[attribute] === null && errorItem.get(attribute) !== undefined
    )

  const panes = [
    {
      menuItem: 'Pagamentos Recentes',
      render: () => (
        <Tab.Pane attached={false}>
          <RecentPayments userId={userDetail.uid} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Registros Recentes',
      render: () => (
        <Tab.Pane attached={false}>
          <RecentRegisters userId={userId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Atividades Recentes',
      render: () => (
        <Tab.Pane attached={false}>
          <RecentActivities userDetailData={userDetail} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Usuários Convidados',
      render: () => (
        <Tab.Pane attached={false}>
          <UserGuests userId={userId} />
        </Tab.Pane>
      ),
    },
  ]

  return (
    <>
      <Breadcrumbs items={breadcrumbsList} history={history} />

      {isLoading ? (
        <>
          <Loader active inline="centered" />
        </>
      ) : (
        <>
          {warning.length > 0 ? warningMessage(warning) : null}

          <Grid>
            <Grid.Column width={4}>
              <UserInfo
                userDetail={userDetail}
                userExtraData={userExtraData}
                openModal={openModal}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
            </Grid.Column>
          </Grid>

          <UserInfoEditModal
            userProfile={userDetail}
            open={modalVisible}
            close={() => setModalVisible(false)}
            errors={errors}
            submit={updateProfile}
            isLoading={isLoadingUpdate}
          />
        </>
      )}

      <SemanticToastContainer position="top-right" />
    </>
  )
}
