import React from 'react'
import { Editor } from 'react-draft-wysiwyg'

import { isEmpty } from 'ramda'
import {
  Card,
  Form,
  Grid,
  Icon,
  Image,
  Button,
  Checkbox,
} from 'semantic-ui-react'

const CreateCategories = ({
  show,
  title,
  myRef,
  onClick,
  avatar,
  create,
  isLoading,
  isConsultant,
  isExclusive,
  hasFormData,
  uploadImage,
  onChange,
  urlVideo,
  editorState,
  handlerInput,
  requireApproval,
  onEditorStateChange,
}) => (
  <Card
    centered
    fluid
    style={{ marginTop: 10, display: show ? 'block' : 'none' }}
  >
    <Card.Content>
      <Grid columns={2} relaxed>
        <Grid.Column width={3}>
          <Card>
            <Image
              size="medium"
              verticalAlign="middle"
              src={
                isEmpty(avatar)
                  ? 'https://react.semantic-ui.com/images/avatar/large/matthew.png'
                  : avatar.src
              }
            />
            <Card.Content>
              <input
                type="file"
                id="uploadImage"
                accept="image/*"
                style={{ display: 'none' }}
                ref={myRef}
                onChange={uploadImage}
              />
              <Button
                icon="upload"
                content="Upload"
                labelPosition="right"
                onClick={onClick}
              />
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column width={13}>
          <Form>
            <Form.Input
              id="title"
              type="text"
              value={title || ''}
              onChange={handlerInput}
              label="Título (Obrigatório)"
              placeholder="Ex: Advogado"
            />
            <Form.Field inline>
              <Checkbox
                id="exclusive"
                label="Exclusivo"
                checked={isExclusive}
                onChange={() => onChange('isExclusive')}
              />
            </Form.Field>
            <Form.Field inline>
              <Checkbox
                id="isConsultant"
                checked={isConsultant}
                label="Consultor"
                onChange={() => onChange('isConsultant')}
              />
            </Form.Field>
            {isConsultant && (
              <Form.Field inline>
                <Checkbox
                  id="requireApproval"
                  checked={requireApproval}
                  label="Exige aprovação de um líder?"
                  onChange={() => onChange('requireApproval')}
                />
              </Form.Field>
            )}
            <Form.Input
              id="urlVideo"
              type="text"
              value={urlVideo || ''}
              disabled={isExclusive}
              onChange={handlerInput}
              label="URL Video (Obrigatório)"
              placeholder="Ex: https://www.youtube.com/embed/{HASH}"
            />
            <div className="field">
              <label>Descrição (Obrigatório)</label>
              <div className="editor">
                <Editor
                  editorState={editorState}
                  editorClassName="editorClassName"
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  onEditorStateChange={onEditorStateChange}
                  style={{ borderWidth: 1, borderColor: 'gray' }}
                />
              </div>
            </div>
          </Form>
        </Grid.Column>
      </Grid>
    </Card.Content>
    <Card.Content extra textAlign="right">
      <Button
        icon
        positive
        onClick={create}
        loading={isLoading}
        disabled={isLoading}
        labelPosition="left"
      >
        <Icon name="check" /> Salvar
      </Button>
    </Card.Content>
  </Card>
)

export default CreateCategories
