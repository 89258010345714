import React from 'react'

import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'

import SideBarLeft from '~/components/SideBar'

import * as S from './styles'

export default function Default({ children }) {
  console.log('render default')
  return (
    <S.Container>
      <SideBarLeft />
      <Grid className="grid-size">
        <Grid.Row>
          <Grid.Column width={16} className="content-main">
            {React.cloneElement(children)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </S.Container>
  )
}

Default.propTypes = {
  children: PropTypes.element.isRequired,
}
