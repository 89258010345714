import React, { useState, useEffect, useCallback } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SemanticToastContainer } from 'react-semantic-toasts'

import { omit } from 'ramda'

import Breadcrumbs from '~/components/Breadcrumbs'
import history from '~/services/history'
import {
  findById,
  createItem,
  removeItem,
  updateItem,
  reorder as storeReorder,
} from '~/store/modules/formsUsersItems/actions'
import messages from '~/utils/messages'

import CreateItem from './components/CreateItem'
import Item from './components/Item'

const grid = 2

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? 'lightgreen' : '#f1f2f3',
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  width: '100%',
  padding: grid,
  background: isDraggingOver ? 'lightblue' : '#f1f2f3',
})

const itemInitialState = {
  order: null,
  question: '',
  forms_users_items_type_id: '',
}

export default function FormsUsersDetail() {
  const { id: formUsersId } = useParams()

  const [items, setItems] = useState([])
  const [values, setValues] = useState({})
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState(itemInitialState)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [multiples, setMultiples] = useState([])
  const [questionMultiples, setQuestionMultiples] = useState('')
  const [questionMultiplesItem, setQuestionMultiplesItem] = useState({})

  const formsUsersItems = useSelector((store) => store.formsUsersItems)
  const formsUsersCreateItems = useSelector(
    (store) => store.formsUsersCreateItems
  )
  const formsUsersDeleteItems = useSelector(
    (store) => store.formsUsersDeleteItems
  )
  const formsUsersUpdateItems = useSelector(
    (store) => store.formsUsersUpdateItems
  )
  const formsUsersReorderItems = useSelector(
    (store) => store.formsUsersReorderItems
  )

  const dispatch = useDispatch()

  const resetForm = useCallback(() => {
    setItem({ ...item, ...itemInitialState })
    setMultiples([])
  }, [item])

  useEffect(() => {
    dispatch(findById(formUsersId))
  }, [dispatch, formUsersId])

  useEffect(() => {
    if (formsUsersItems.success) {
      setItems(formsUsersItems.data)
      formsUsersItems.success = false
    }

    if (formsUsersCreateItems.success) {
      messages().success('Item criado com sucesso!')
      setItems([...items, formsUsersCreateItems.data])
      setLoading(false)
      resetForm()
      formsUsersCreateItems.success = false
    } else if (formsUsersCreateItems.error) {
      setLoading(false)
      messages().error(formsUsersCreateItems.message)
      formsUsersCreateItems.error = false
    }

    if (formsUsersDeleteItems.success) {
      setLoadingUpdate(false)
      messages().success('Item removido com sucesso!')
      formsUsersDeleteItems.success = false
    }

    if (formsUsersUpdateItems.success) {
      setLoadingUpdate(false)
      messages().success('Item atualizado com sucesso!')
      formsUsersUpdateItems.success = false
    }

    if (formsUsersReorderItems.success) {
      console.log(formsUsersReorderItems.data)
    }
  }, [
    formsUsersItems,
    formsUsersCreateItems,
    formsUsersDeleteItems,
    formsUsersUpdateItems,
    formsUsersReorderItems,
    items,
    resetForm,
  ])

  function createItems(e) {
    if (e.key === 'Enter') {
      setMultiples([
        ...multiples,
        {
          option: questionMultiples,
          order: multiples.length + 1,
        },
      ])
      setQuestionMultiples('')
    }
  }

  function createItemsUpdate(key, itemParam) {
    const indexItem = items.find((i) => i === itemParam)

    if (key === 'Enter') {
      itemParam.options.items = [
        ...itemParam.options.items,
        {
          option: questionMultiplesItem[itemParam.uid],
          order: itemParam.options.items.length + 1,
        },
      ]
    }

    items[indexItem] = item
    setValues({ ...values, item })
    setItems(items)
  }

  function handleInput(value, key) {
    setItem({ ...item, [key]: value })
  }

  function handleMultiple(target) {
    setQuestionMultiples(target.value)
  }

  function handleMultipleItem(itemParam, value, opt) {
    const index = itemParam.options.items.findIndex((i) => i === opt)
    itemParam.options.items[index].option = value

    const indexItem = items.findIndex((i) => i === itemParam)
    items[indexItem] = itemParam

    setValues({ ...values, item: itemParam })
    setItems(items)
  }

  function handleUpdate(itemParam, value, id) {
    const index = items.findIndex((i) => i === itemParam)
    items[index][id] = value
    setValues({ ...value, [id]: value })
    setItems(items)
  }

  function removeItemForm(itemParam) {
    setLoadingUpdate(true)
    const newItems = items.filter((i) => i.uid !== itemParam.uid)
    setItems(newItems)
    dispatch(removeItem(itemParam.uid))
  }

  function removeItemMultiple(itemParam, opt) {
    const newItem = itemParam.options.items.filter((i) => i !== opt)
    const indexItem = items.findIndex((i) => i === itemParam)
    items[indexItem].options.items = newItem

    setValues({ ...values, item: itemParam })
    setItems(items)
  }

  function submit() {
    if (!item.question || !item.question.trim()) {
      messages().error('Pergunta não pode estar vazia!')
      return false
    }

    if (!item.forms_users_items_type_id) {
      messages().error('Tipo não pode estar vazio!')
      return false
    }

    const formItem = {
      ...item,
      order: items.length + 1,
      forms_users_id: formUsersId,
    }

    if (
      multiples.length > 0 &&
      (item.forms_users_items_type_id === 6 ||
        item.forms_users_items_type_id === 7)
    ) {
      Object.assign(formItem, {
        options: {
          items: [...multiples],
        },
      })
    }

    setLoading(true)
    return dispatch(createItem(formItem))
  }

  function submitUpdate(itemParam) {
    setLoadingUpdate(true)
    const newItem = itemParam || values
    const omitList = ['description', 'title']
    dispatch(updateItem(omit(omitList, newItem), itemParam.uid))
  }

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    const questions = result.map((i, index) => ({ ...i, order: index + 1 }))
    dispatch(storeReorder({ questions }, formUsersId))
    setItems(questions)
    return questions
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    )

    setItems(newItems)
  }

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      name: 'Formulários Extras',
      link: '/forms-users-extra',
    },
    {
      active: true,
      hasLink: false,
      name: 'Itens',
    },
  ]

  return (
    <>
      <Breadcrumbs items={breadcrumbsList} history={history} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(providedDroppable, snapshotDroppable) => (
            <div
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
              style={getListStyle(snapshotDroppable.isDraggingOver)}
            >
              {items.map((itemParam, index) => (
                <Draggable
                  index={index}
                  key={itemParam.uid}
                  draggableId={itemParam.uid}
                >
                  {(providedDraggable, snapshotDraggable) => (
                    <div
                      ref={providedDraggable.innerRef}
                      {...providedDraggable.draggableProps}
                      {...providedDraggable.dragHandleProps}
                      style={getItemStyle(
                        snapshotDraggable.isDragging,
                        providedDraggable.draggableProps.style
                      )}
                    >
                      <Item
                        {...itemParam}
                        item={itemParam}
                        index={index}
                        key={itemParam.uid}
                        loading={loadingUpdate}
                        handleUpdate={handleUpdate}
                        submitUpdate={submitUpdate}
                        removeItemForm={removeItemForm}
                        handleMultiple={handleMultipleItem}
                        createItemsUpdate={createItemsUpdate}
                        removeItemMultiple={removeItemMultiple}
                        questionMultiplesItem={questionMultiplesItem}
                        onChangeMultiple={(e, { value }) =>
                          setQuestionMultiplesItem({ [itemParam.uid]: value })
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {providedDroppable.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <CreateItem
        {...item}
        loading={loading}
        multiples={multiples}
        submit={() => submit()}
        reset={() => resetForm()}
        createItems={createItems}
        handleInput={handleInput}
        removeItem={removeItemForm}
        handleMultiple={handleMultiple}
      />
      <SemanticToastContainer position="top-right" />
    </>
  )
}
