import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { SemanticToastContainer } from 'react-semantic-toasts'

import { Card, Dropdown } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import EmptyList from '~/components/EmptyList'
import { findAll } from '~/store/modules/badges/actions'
import { findByUserId } from '~/store/modules/formsUsersData/actions'
import { findByIdUserDetail } from '~/store/modules/users/actions'
import {
  fetch,
  create,
  update,
  removeById,
} from '~/store/modules/usersConsultants/actions'
import messages from '~/utils/messages'

import FormsUsersData from './components/FormsUsersData'
import Item from './components/Item'

function Consultants() {
  const dispatch = useDispatch()
  const history = useHistory()

  const findBadges = useSelector((state) => state.findBadges)
  const usersDetail = useSelector((state) => state.usersDetail)
  const formsUsersData = useSelector((state) => state.formsUsersData)
  const usersLeaderFetch = useSelector((state) => state.usersLeaderFetch)
  const usersLeaderCreated = useSelector((state) => state.usersLeaderCreated)
  const usersLeaderUpdated = useSelector((state) => state.usersLeaderUpdated)
  const usersLeaderDeleted = useSelector((state) => state.usersLeaderDeleted)

  const userId = useParams().id
  const userName = usersDetail.data.name
  const defaultQuery = `?leader_id=${userId}`

  const [items, setItems] = useState([])
  const [badges, setBadges] = useState([])
  const [badgeId, setBadgeId] = useState('')
  const [loading, setLoading] = useState(false)
  const [percentualValue, setPercentual] = useState({})
  const [open, setOpen] = useState(false)
  const [formsData, setFormsData] = useState([])

  useEffect(() => {
    dispatch(findByIdUserDetail(userId))
  }, [userId, dispatch])

  useEffect(() => {
    dispatch(findAll())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetch(defaultQuery))
  }, [dispatch, defaultQuery])

  useEffect(() => {
    if (usersLeaderFetch.success) {
      setItems(usersLeaderFetch.data)
    }

    if (usersLeaderCreated.success) {
      handleSuccess(usersLeaderCreated, 'Consultor adicionado com sucesso!')
    } else if (usersLeaderCreated.error) {
      handleError(usersLeaderCreated)
    }

    if (usersLeaderUpdated.success) {
      handleSuccess(usersLeaderUpdated, 'Consultor atualizado com sucesso!')
    } else if (usersLeaderUpdated.error) {
      handleError(usersLeaderUpdated)
    }

    if (usersLeaderDeleted.success) {
      handleSuccess(usersLeaderDeleted, 'Consultor removido com sucesso!')
    } else if (usersLeaderDeleted.error) {
      handleError(usersLeaderDeleted)
    }

    if (findBadges.success) {
      setBadges(
        findBadges.data.map((i) => ({
          key: i.uid,
          value: i.uid,
          text: i.title,
        }))
      )
      findBadges.success = false
    }

    if (formsUsersData.success) {
      const formsDataItems = formsUsersData.data[0].answers.items
      setFormsData(formsDataItems)
    }
  }, [
    formsData,
    findBadges,
    usersLeaderFetch,
    usersLeaderCreated,
    usersLeaderDeleted,
    usersLeaderUpdated,
    formsUsersData.data,
    formsUsersData.success,
  ])

  function handleSuccess(prop, message) {
    setLoading(false)
    messages().success(message)
    prop.success = false
  }

  function handleError(prop) {
    setLoading(false)
    messages().error(prop.message)
    prop.error = false
  }

  function handleInput(value) {
    setBadgeId(value)
    fetch(`${defaultQuery}&badge_id=${value}`)
  }

  function addUser(item) {
    if (!percentualValue[item.uid]) {
      messages().error('Adicionar percentual')
      return false
    }

    if (!badgeId) {
      messages().error('Escolha um badge!')
      return false
    }

    setLoading(true)
    const index = items.findIndex((i) => i === item)
    items[index].status = true

    const params = {
      status: true,
      is_leader: false,
      badge_id: badgeId,
      leader_id: userId,
      user_id: item.user_id,
      percentual: percentualValue[item.uid],
    }

    setItems(items)
    return item.status ? update(params, item.uid) : create(params)
  }

  function remove(item) {
    if (!badgeId) {
      messages().error('Escolha o badge do consultor!')
      return
    }

    const index = items.findIndex((i) => i === item)
    items[index].status = false

    setItems(items)
    removeById(item.uid, badgeId)
  }

  function handleChange(value, id) {
    setPercentual({ [id]: value })
  }

  function getConsultantFormsUsers(user) {
    findByUserId(user.user_id)
    setOpen(true)
  }

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      name: 'Usuários',
      link: '/users',
    },
    {
      active: false,
      hasLink: false,
      name: userName,
    },
    {
      active: true,
      hasLink: false,
      name: 'Consultores',
    },
  ]
  return (
    <>
      <Breadcrumbs history={history} items={breadcrumbsList} hasButton>
        <Dropdown
          button
          labeled
          floating
          text="Badges"
          icon="filter"
          className="icon"
        >
          <Dropdown.Menu>
            {badges.length > 0 &&
              badges.map((i) => (
                <Dropdown.Item
                  key={i.value}
                  onClick={() => handleInput(i.value)}
                >
                  {i.text}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </Breadcrumbs>
      {items.length > 0 ? (
        <Card.Group>
          {items.map((item, index) => (
            <Item
              item={item}
              index={index}
              remove={remove}
              addUser={addUser}
              key={item.uid}
              loading={loading}
              handleChange={handleChange}
              percentualValue={percentualValue}
              getConsultantFormsUsers={getConsultantFormsUsers}
            />
          ))}
        </Card.Group>
      ) : (
        <EmptyList text="Nenhum consultor até o momento..." icon="users" />
      )}
      <FormsUsersData
        open={open}
        items={formsData}
        close={() => setOpen(false)}
      />
      <SemanticToastContainer position="bottom-right" />
    </>
  )
}

export default Consultants
