import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'

import { Input, Card, Form, Button, Select, Dropdown } from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'

import useUfsAndCitiesFilters from '~/hooks/useUfsAndCitiesFilters'
import api from '~/services/api'
import {
  MISSIONS_MAIN_BY_CATEGORY,
  CATEGORY_MISSIONS_MAIN,
  CUSTOMERS,
} from '~/services/api/endpoints'

const orders = [
  { key: 0, value: 'm.created_at', text: 'Criado Em' },
  { key: 1, value: 'm.expired_at', text: 'Expira Em' },
]

const directions = [
  { key: 0, value: 'asc', text: 'Crescente' },
  { key: 1, value: 'desc', text: 'Descrescente' },
]

export default function MissionFilter({
  filters,
  isLoading,
  onChangeFilters,
  clearFilters,
}) {
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([])
  const [categoriesOptions, setCategoriesOptions] = useState([])
  const [customersOptions, setCustomersOptions] = useState([])

  const {
    ufsOptions,
    citiesOptions,
    onChangeUFs,
    onChangeCities,
  } = useUfsAndCitiesFilters(filters, onChangeFilters)

  const getOptionsArray = (arr) => {
    return arr.map((item, index) => ({
      key: index,
      value: item.uid || item.uf || item,
      text: item.name || item.title || item,
    }))
  }

  useEffect(() => {
    ;(async () => {
      const promises = [
        api.get(CATEGORY_MISSIONS_MAIN),
        api.get(CUSTOMERS, { limit: 1000 }),
      ]

      const [categoriesRes, customersRes] = await Promise.allSettled(promises)

      if (categoriesRes?.value?.data?.data) {
        setCategoriesOptions(getOptionsArray(categoriesRes.value.data.data))
      }
      if (customersRes?.value?.data?.data) {
        setCustomersOptions(getOptionsArray(customersRes.value.data.data))
      }
    })()
  }, [])

  useEffect(() => {
    if (filters.categoryId) {
      ;(async () => {
        try {
          const res = await api.get(
            `${MISSIONS_MAIN_BY_CATEGORY}${filters.categoryId}`
          )
          setSubCategoriesOptions(getOptionsArray(res.data.data))
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }, [filters.categoryId])

  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Buscar..."
              onChange={(e, { value }) => onChangeFilters({ search: value })}
            />
            <Form.Field
              control={Select}
              placeholder="Limite"
              selection
              value={filters.limit}
              options={[
                { key: 0, value: 10, text: 10 },
                { key: 1, value: 15, text: 15 },
                { key: 2, value: 25, text: 25 },
                { key: 3, value: 50, text: 50 },
                { key: 4, value: 100, text: 100 },
              ]}
              onChange={(e, { value }) => onChangeFilters({ limit: value })}
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Clientes"
              options={customersOptions}
              disabled={!customersOptions.length}
              value={filters.customerId}
              onChange={(e, { value }) =>
                onChangeFilters({ customerId: value })
              }
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Categoria"
              options={categoriesOptions}
              disabled={!categoriesOptions.length}
              value={filters.categoryId}
              onChange={(e, { value }) =>
                onChangeFilters({ categoryId: value })
              }
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Sub-Categoria"
              options={subCategoriesOptions}
              disabled={!subCategoriesOptions.length}
              value={filters.missionsMainId}
              onChange={(e, { value }) =>
                onChangeFilters({ missionsMainId: value })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Field fluid disabled={!ufsOptions.length}>
              <Dropdown
                search
                selection
                multiple
                options={ufsOptions}
                placeholder="UFs"
                value={filters.ufs || []}
                onChange={(e, { value }) => onChangeUFs(value)}
              />
            </Form.Field>
            <Form.Field
              fluid
              disabled={!citiesOptions.length || !filters?.ufs?.length}
            >
              <Dropdown
                search
                selection
                multiple
                options={citiesOptions}
                placeholder="Cidades"
                value={filters.cities || []}
                onChange={(e, { value }) => onChangeCities(value)}
              />
            </Form.Field>
            <Form.Field
              control={Select}
              search
              selection
              options={orders}
              placeholder="Coluna"
              value={filters.order}
              onChange={(e, { value }) =>
                onChangeFilters({
                  order: value,
                  iniCreate: null,
                  finCreate: null,
                  iniExpired: null,
                  finExpired: null,
                })
              }
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Ordenar Por"
              options={directions}
              value={filters.direction}
              onChange={(e, { value }) => onChangeFilters({ direction: value })}
            />
            {filters.order === 'm.expired_at' && (
              <>
                <Form.Field
                  selectsStart
                  control={DatePicker}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Inicio"
                  selected={filters.iniExpired}
                  startDate={filters.iniExpired}
                  endDate={filters.finExpired}
                  onChange={(value) =>
                    onChangeFilters({
                      iniExpired: value,
                      iniCreate: null,
                      finCreate: null,
                    })
                  }
                />
                <Form.Field
                  control={DatePicker}
                  id="endDate"
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Fim"
                  selected={filters.finExpired}
                  startDate={filters.iniExpired}
                  endDate={filters.finExpired}
                  onChange={(value) =>
                    onChangeFilters({
                      finExpired: value,
                      iniCreate: null,
                      finCreate: null,
                    })
                  }
                />
              </>
            )}
            {filters.order === 'm.created_at' && (
              <>
                <Form.Field
                  selectsStart
                  control={DatePicker}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Inicio"
                  selected={filters.iniCreate}
                  startDate={filters.iniCreate}
                  endDate={filters.finCreate}
                  onChange={(value) =>
                    onChangeFilters({
                      iniCreate: value,
                      iniExpired: null,
                      finExpired: null,
                    })
                  }
                />
                <Form.Field
                  control={DatePicker}
                  id="endDate"
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Fim"
                  selected={filters.finCreate}
                  startDate={filters.iniCreate}
                  endDate={filters.finCreate}
                  onChange={(value) =>
                    onChangeFilters({
                      finCreate: value,
                      iniExpired: null,
                      finExpired: null,
                    })
                  }
                />
              </>
            )}
          </Form.Group>
        </Form>
      </Card.Content>
      <Card.Content>
        <Button
          icon="undo"
          color="grey"
          content="Limpar Filtros"
          labelPosition="left"
          onClick={clearFilters}
          loading={isLoading}
        />
      </Card.Content>
    </Card>
  )
}
