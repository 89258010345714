import React, { memo } from 'react'

import PropTypes from 'prop-types'
import { Grid, Card, Statistic } from 'semantic-ui-react'

function BudgetHeaderData({
  paymentsNumbersInfo,
  onClickStatus,
  statusToBorderColor,
}) {
  return (
    <Grid columns={3}>
      <Grid.Row>
        <Grid.Column>
          <Card
            fluid
            style={{
              border: statusToBorderColor === 0 ? `3px solid #fbbd08` : '',
              cursor: 'pointer',
            }}
            onClick={() => onClickStatus('status', 0)}
            as="a"
          >
            <Card.Content textAlign="center">
              <Statistic size="small">
                <Statistic.Label
                  style={{ color: '#fbbd08' }}
                  content="Pendente(s)"
                />
                <Statistic.Value
                  content={paymentsNumbersInfo.payments_pendent_count}
                  style={{ color: '#838486' }}
                />
              </Statistic>
            </Card.Content>
          </Card>
        </Grid.Column>

        <Grid.Column>
          <Card
            fluid
            style={{
              border: statusToBorderColor === 1 ? `3px solid #73c78f` : '',
              cursor: 'pointer',
            }}
            onClick={() => onClickStatus('status', 1)}
            as="a"
          >
            <Card.Content textAlign="center">
              <Statistic size="small">
                <Statistic.Label
                  style={{ color: '#73c78f' }}
                  content="Realizado(s)"
                />
                <Statistic.Value
                  content={paymentsNumbersInfo.payment_done_count}
                  style={{ color: '#838486' }}
                />
              </Statistic>
            </Card.Content>
          </Card>
        </Grid.Column>

        <Grid.Column>
          <Card fluid style={{ backgroundColor: '#eee' }}>
            <Card.Content textAlign="center">
              <Statistic size="small">
                <Statistic.Label
                  style={{ color: '#55b9e6' }}
                  content="Total (R$)"
                />
                <Statistic.Value
                  content={paymentsNumbersInfo.payment_count}
                  style={{ color: '#838486' }}
                />
              </Statistic>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

BudgetHeaderData.propTypes = {
  paymentsNumbersInfo: PropTypes.object,
  onClickStatus: PropTypes.func,
  statusToBorderColor: PropTypes.number,
}

export default memo(BudgetHeaderData)
