import React from 'react'
import { connect } from 'react-redux'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { head, isEmpty, isNil } from 'ramda'

import Breadcrumbs from '~/components/Breadcrumbs'
import CreateBadge from '~/components/CreateBadge'
import { update, findById } from '~/store/modules/badges/actions'

import '~/assets/css/react-draft-wysiwyg.css'

class BadgeDetail extends React.Component {
  state = {
    show: true,
    isLoading: false,

    title: '',
    avatar: '',
    urlVideo: '',
    description: '',
    hasFormData: false,
    isExclusive: false,
    isConsultant: false,
    requireApproval: false,
    editorState: EditorState.createEmpty(),
  }

  handlerInput = (e, { id, value }) => this.setState({ [id]: value })

  onChange = (key) => this.setState({ [key]: !this.state[key] })

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    })
  }

  componentDidMount() {
    this.props.findById(this.props.match.params.id)
  }

  uploadImage = (e) => {
    const file = head(e.target.files)
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = (event) => {
      this.setState({
        avatar: {
          src: event.target.result,
          data: file,
        },
      })
    }
  }

  updateBadge = () => {
    const {
      avatar,
      title,
      isExclusive,
      description,
      isConsultant,
      urlVideo,
      requireApproval,
    } = this.state

    if (isEmpty(avatar)) {
      this.showToast('Imagem não pode estar vazia!')
      return false
    }

    if (isEmpty(title)) {
      this.showToast('Título não pode estar vazio!')
      return false
    }

    if (!isExclusive) {
      if (isEmpty(urlVideo)) {
        this.showToast('Vídeo não pode estar vazio!')
        return false
      }

      if (isEmpty(description)) {
        this.showToast('Descrição não pode estar vazia!')
        return false
      }
    }

    this.setState({ isLoading: true })

    const newData = new FormData()
    newData.set('title', title)
    newData.set('exclusive', isExclusive)
    newData.set('is_consultant', isConsultant)
    newData.set('require_approval', requireApproval)
    newData.set('url_video', urlVideo)
    newData.set('description', description)

    if (!isNil(avatar.data)) {
      newData.append('avatar', avatar.data)
    }

    this.props.update(newData, this.props.match.params.id)
  }

  showToast = (description, type) => {
    toast(
      {
        description,
        title: 'Alerta',
        type: type ? 'success' : 'warning',
        icon: type ? 'check' : 'remove',
      },
      () => console.log('toast closed')
    )
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.badgeUpdate !== this.props.badgeUpdate) {
      if (nextProps.badgeUpdate.success) {
        setTimeout(() => {
          this.props.findById(this.props.match.params.id)
          nextProps.badgeUpdate.success = false
          this.setState({ isLoading: false })
          this.showToast('Badge atualizado com sucesso!', true)
        }, 1000)
      } else if (nextProps.badgeUpdate.error) {
        this.setState({ isLoading: false })
        this.showToast(nextProps.badgeUpdate.message)
        nextProps.badgeUpdate.error = false
      }
    }

    if (nextProps.findBadge !== this.props.findBadge) {
      if (nextProps.findBadge.success) {
        const {
          avatar,
          url_video,
          exclusive,
          description,
          is_consultant,
          require_approval,
        } = nextProps.findBadge.data
        const contentBlock = htmlToDraft(description)
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        )
        this.setState({
          ...nextProps.findBadge.data,
          urlVideo: url_video,
          isExclusive: exclusive,
          avatar: { src: avatar },
          isConsultant: is_consultant,
          requireApproval: require_approval,
          editorState: EditorState.createWithContent(contentState),
        })
        nextProps.findBadge.success = false
      }
    }
  }

  handlerCancel = () => this.setState({ open: false })

  render() {
    const breadcrumbsList = [
      {
        active: false,
        hasLink: true,
        link: '/badges',
        name: 'Badges',
      },
      {
        active: true,
        hasLink: false,
        name: this.state.title,
      },
    ]
    return (
      <>
        <Breadcrumbs items={breadcrumbsList} history={this.props.history} />
        <SemanticToastContainer position="bottom-right" />
        <CreateBadge
          {...this.state}
          onChange={this.onChange}
          create={() => this.updateBadge()}
          uploadImage={this.uploadImage}
          handlerInput={this.handlerInput}
          myRef={(ref) => (this.upload = ref)}
          onClick={() => this.upload.click()}
          onEditorStateChange={this.onEditorStateChange}
        />
      </>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    findBadge: store.findBadge,
    badgeUpdate: store.badgeUpdate,
  }
}

export default connect(mapStateToProps, { update, findById })(BadgeDetail)
