import React from 'react'
import { connect } from 'react-redux'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'
import {
  arrayMove,
  SortableElement,
  SortableContainer,
} from 'react-sortable-hoc'
import Sticky from 'react-stickynode'

import _ from 'lodash'
import { head, length, isEmpty, propEq, filter } from 'ramda'
import {
  Grid,
  List,
  Loader,
  Button,
  Header,
  Dimmer,
  Segment,
  Divider,
} from 'semantic-ui-react'

import { findById } from '~/store/modules/badges/actions'
import {
  create,
  createAnswer,
  updateQuestion,
  removeAnswer,
  removeQuestion,
  updateAnswer,
  findById as findForm,
  changeOrderAnswers,
  changeOrderQuestions,
} from '~/store/modules/badgesForms/actions'

import Breadcrumbs from '~/components/Breadcrumbs'
import CreateFormsItems from '~/components/Cards/CreateFormsItems'
import FormsItems from '~/components/FormsItems'
import FormsItemsUpdate from '~/components/FormsItemsUpdate'
import Main from '../Main'

const SortableItem = SortableElement(({ value }) => (
  <List.Item>
    {head(value.items).question_order}. {head(value.items).question}
  </List.Item>
))

const SortableList = SortableContainer(({ items }) => (
  <List celled style={{ fontSize: 12 }}>
    {items.map((value, index) => (
      <SortableItem key={`index-${index}`} index={index} value={value} />
    ))}
  </List>
))

const SortableMultipleList = SortableContainer(FormsItems)

class Detail extends React.Component {
  state = {
    items: [],
    questions: [],
    scrollTop: 0,
    className: '',
    disable: false,
    hasChange: false,
    showConfirm: false,
    hasFocus: false,

    order: 0,
    value: '',
    length: 10,
    question: '',
    answer: '',
    forms_id: '',
    multiples: [],
    questionItem: '',
    questionMultipleItem: '',

    removeId: '',
    title: '',
    questionId: '',
    newMultipleItem: {},

    multiplesItems: '',
    removeMultiplesId: '',
  }

  constructor(props) {
    super(props)
    this.handlerInput = this.handlerInput.bind(this)
    this.removeMultiples = this.removeMultiples.bind(this)
  }

  componentWillMount() {
    this.timer = null
    this.badgeId = this.props.match.params.id
  }

  load = () =>
    Promise.all([
      this.props.findById(this.badgeId),
      this.props.findForm(this.badgeId),
    ])

  componentDidMount() {
    this.load()
  }

  // NEED FIXED
  handleChangeForm = (e, { value }) => {
    const changeData = this.state.multiples[value]
    changeData.is_correct = true

    this.setState({ value, multiples: this.state.multiples })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { questions } = this.state
    const newArr = arrayMove(questions, oldIndex, newIndex)
    this.setState({ questions: newArr })
    this.props.changeOrderQuestions({ items: newArr })
  }

  onSortEndMultiples = (id, { oldIndex, newIndex }) => {
    const newArr = arrayMove(this.state[`items${id}`], oldIndex, newIndex)
    this.setState({ [`items${id}`]: newArr })
    this.props.changeOrderAnswers({ items: this.state[`items${id}`] })
  }

  renderSortableMultipleItems = id => (
    <SortableMultipleList
      {...this.state}
      {...this.props}
      state={this.state}
      items={this.state[`items${id}`]}
      onSortEnd={e => this.onSortEndMultiples(id, e)}
      handlerInput={this.handlerInputMultiple}
      removeMultipleItems={this.removeMultiples}
      handleChangeFormMultiple={this.handleChangeFormMultiple}
    />
  )

  // FIXED
  removeMultiples = ({ question_iq, answer_order, answer_id }) => {
    this.props.removeAnswer(answer_id)
    const currentItems = this.state[`items${question_iq}`]
    const removeItem = currentItems.filter(i => i.answer_order !== answer_order)
    this.setState({ [`items${question_iq}`]: removeItem })
  }

  renderSortableItems = () => (
    <SortableList items={this.state.questions} onSortEnd={this.onSortEnd} />
  )

  handlerInputForm = ({ target }) =>
    this.setState({ [target.id]: target.value })

  handlerInput = ({ target }) => {
    const updateData = {
      active: true,
      question: target.value,
      badge_id: this.badgeId,
    }

    clearTimeout(this.timer)

    this.setState({ [target.id]: target.value })

    this.timer = setTimeout(
      () =>
        this.props.updateQuestion(
          updateData,
          target.id.replace('question', '')
        ),
      1000
    )
  }

  // FIXED
  handleChangeFormMultiple = item => {
    const currentItems = this.state[`items${item.question_iq}`]
    const newData = currentItems.find(i => i.answer_id === item.answer_id)
    const updateData = {
      active: true,
      is_correct: !item.is_correct,
      badge_question_id: item.question_iq,
    }
    newData.is_correct = !newData.is_correct
    this.props.updateAnswer(updateData, item.answer_id)
    this.setState({ [`items${item.question_iq}`]: currentItems })
  }

  handlerTitleItem = ({ target }) =>
    this.setState({ [target.id]: target.value })

  handlerInputMultiple = ({ target }) => {
    clearTimeout(this.timer)

    this.setState({ [target.id]: target.value })

    const splitValue = target.id.replace('answer', '').split('_')
    const answerId = splitValue[0]
    const questionId = splitValue[1]

    this.timer = setTimeout(() => {
      this.props.updateAnswer(
        {
          active: true,
          answer: target.value,
          badge_question_id: questionId,
        },
        answerId
      )
    }, 1000)
  }

  addItem = ({ key }) => {
    const { questionItem } = this.state
    const newData = {
      is_correct: false,
      answer: questionItem,
      badge_question_id: this.badgeId,
      order: this.state.multiples.length + 1,
    }

    key === 'Enter' &&
      this.setState({
        questionItem: '',
        multiples: [...this.state.multiples, newData],
      })
  }

  addMultipeItem = ({ uid }, e) => {
    const currentItems = this.state[`items${uid}`]
    const newData = {
      active: true,
      is_correct: false,
      badge_question_id: uid,
      order: currentItems.length + 1,
      answer: this.state.questionMultipleItem,
    }

    if (e.key === 'Enter') {
      this.props.createAnswer(newData)
      this.setState({ disable: true, [`questionMultipleItem${uid}`]: '' })
    }
  }

  handlerSelect = (e, { value }) => this.setState({ type: value })

  handlerSelectFrom = (e, { value, id }) => this.setState({ [id]: value })

  submitItem = () => {
    const searching = propEq('is_correct', true)

    if (isEmpty(this.state.question)) {
      alert('Pergunta não pode estar vazia!')
      return false
    }

    if (length(this.state.multiples) <= 1) {
      alert('Adicione pelo menos duas respostas!')
      return false
    }

    if (length(filter(searching, this.state.multiples)) === 0) {
      alert('Selecione pelo menos uma resposta correta!')
      return false
    }

    this.setState({ isLoading: true })

    this.props.create({
      active: true,
      badge_id: this.badgeId,
      question: this.state.question,
      answers: this.state.multiples,
      order: this.state.questions.length + 1,
    })
  }

  handlerSubmit = e => e.preventDefault()

  showConfirm = ({ showConfirm, removeId }) => {
    this.setState({ removeId, showConfirm })
  }

  submitRemove = () => {
    const removeItems = this.state.questions.filter(
      i => i.uid !== this.state.removeId
    )

    this.setState({ showConfirm: false, questions: removeItems })
    this.props.removeQuestion(this.state.removeId)
  }

  cancelConfirm = ({ showConfirm }) => this.setState({ showConfirm })

  showToast = (description, type) =>
    toast(
      {
        description,
        title: 'Alerta',
        icon: type ? 'check' : 'remove',
        type: type ? 'success' : 'warning',
      },
      () => console.log('toast closed')
    )

  renderItem = (item, index) => (
    <FormsItemsUpdate
      item={item}
      index={index}
      {...this.state}
      state={this.state}
      key={`items${index}`}
      showConfirm={this.showConfirm}
      submitRemove={this.submitRemove}
      submitUpdate={this.submitUpdate}
      handlerSubmit={this.handlerSubmit}
      cancelConfirm={this.cancelConfirm}
      handlerInput={this.handlerInput}
      handlerTitleItem={this.handlerTitleItem}
      addMultipeItem={this.addMultipeItem}
      handlerInputMultiple={this.handlerInputMultiple}
      handleChangeFormMultiple={this.handleChangeFormMultiple}
      renderSortableMultipleItems={this.renderSortableMultipleItems}
    />
  )

  createAnswerResponse = props => {
    if (props.success) {
      const { uid, order, answer, is_correct, badge_question_id } = props.data
      const currentItems = this.state[`items${badge_question_id}`]
      const newData = {
        answer,
        is_correct,
        answer_id: uid,
        answer_order: order,
        badge_id: this.badgeId,
        question_iq: badge_question_id,
      }

      this.setState({
        disable: false,
        [`items${badge_question_id}`]: [...currentItems, newData],
      })

      props.success = false
    }
  }

  createQuestionResponse = props => {
    if (props.success) {
      this.setState({
        value: '',
        question: '',
        multiples: [],
        questionItem: '',
        isLoading: false,
      })
      this.load()
      props.success = false
    } else if (props.error) {
      this.showToast(props.message)
      props.error = false
      return false
    }
  }

  responsePattern = props => {
    if (props.success) {
      this.load()
      props.success = false
    } else if (props.error) {
      this.showToast(props.message)
      props.error = false
      return false
    }
  }

  responseWithToast = props => {
    if (props.success) {
      this.showToast('Salvando...', true)
      props.success = false
    } else if (props.error) {
      this.showToast(props.message)
      props.error = false
      return false
    }
  }

  responseQuerieBadges = props => {
    if (props.success) {
      const newArr = _.chain(props.data)
        .groupBy(i => i.question)
        .map((value, key) => ({
          items: value,
          question: key,
          uid: value[0].question_iq,
          order: value[0].question_order,
        }))
        .value()

      newArr.forEach((i, idx) => {
        const id = i.items[0].question_iq
        this.setState({
          [`items${id}`]: i.items,
          [`question${id}`]: i.question,
        })
      })

      this.setState({
        isFocus: true,
        isLoading: false,
        questions: newArr,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.findFormBadge !== this.props.findFormBadge) {
      this.responseQuerieBadges(nextProps.findFormBadge)
    }

    if (nextProps.badgeFormCreateAnswer !== this.props.badgeFormCreateAnswer) {
      this.createAnswerResponse(nextProps.badgeFormCreateAnswer)
    }

    if (nextProps.badgeFormCreate !== this.props.badgeFormCreate) {
      this.createQuestionResponse(nextProps.badgeFormCreate)
    }

    if (nextProps.badgeFormUpdateAnswer !== this.props.badgeFormUpdateAnswer) {
      this.responseWithToast(nextProps.badgeFormUpdateAnswer)
    }

    if (
      nextProps.badgeFormUpdateQuestion !== this.props.badgeFormUpdateQuestion
    ) {
      this.responseWithToast(nextProps.badgeFormUpdateQuestion)
    }

    if (
      nextProps.changeOrderResponseQuestion !==
      this.props.changeOrderResponseQuestion
    ) {
      this.responsePattern(nextProps.changeOrderResponseQuestion)
    }
  }

  loading = () => (
    <Segment>
      <Dimmer active>
        <Loader size="medium">Carregando...</Loader>
      </Dimmer>
    </Segment>
  )

  createForm = () => (
    <CreateFormsItems
      {...this.state}
      addItem={this.addItem}
      handlerSelect={this.handlerSelect}
      submitItem={() => this.submitItem()}
      handleChangeForm={this.handleChangeForm}
      handlerInputCreate={this.handlerInputForm}
    />
  )

  render() {
    const breadcrumbsList = [
      {
        active: false,
        hasLink: true,
        link: '/badges',
        name: 'Badges',
      },
      {
        active: false,
        hasLink: false,
        name: this.props.findBadge.data.title,
      },
      {
        active: true,
        hasLink: false,
        name: 'Exame',
      },
    ]
    return (
      <Main {...this.props}>
        <div ref="scrollView">
          <Breadcrumbs items={breadcrumbsList} history={this.props.history} />
          <Grid divided>
            <Grid.Column width={13}>
              {this.props.findFormBadge.isFetching ? (
                this.loading()
              ) : (
                <Segment>
                  {this.state.questions.map((item, index) =>
                    this.renderItem(item, index)
                  )}
                  <Divider />
                  {this.createForm()}
                </Segment>
              )}
            </Grid.Column>
            <Grid.Column width={3}>
              <div ref={this.handleContextRef}>
                <Sticky bottomBoundary="#content" top={20}>
                  <Segment>
                    <Header as="h3">Ordenação</Header>
                    {this.renderSortableItems()}
                    {this.state.hasChange && (
                      <Segment padded>
                        <Button
                          fluid
                          primary
                          content="Aplicar Ordenação"
                          onClick={() => this.load()}
                        />
                      </Segment>
                    )}
                  </Segment>
                </Sticky>
              </div>
            </Grid.Column>
          </Grid>
          <SemanticToastContainer position="top-center" />
        </div>
      </Main>
    )
  }
}

const mapStateToProps = state => ({
  findBadge: state.findBadge,
  findFormBadge: state.findFormBadge,
  findFormBadges: state.findFormBadges,
  badgeFormCreate: state.badgeFormCreate,
  badgeFormUpdate: state.badgeFormUpdate,
  badgeFormRemove: state.badgeFormRemove,
  badgeFormCreateAnswer: state.badgeFormCreateAnswer,
  badgeFormUpdateAnswer: state.badgeFormUpdateAnswer,
  badgeFormRemoveAnswer: state.badgeFormRemoveAnswer,
  badgeFormUpdateQuestion: state.badgeFormUpdateQuestion,
  changeOrderAnswers: state.changeOrderAnswers,
  changeOrderResponseQuestion: state.changeOrderQuestions,
})

export default connect(mapStateToProps, {
  create,
  findById,
  findForm,
  createAnswer,
  removeAnswer,
  updateAnswer,
  updateQuestion,
  removeQuestion,
  changeOrderAnswers,
  changeOrderQuestions,
})(Detail)
