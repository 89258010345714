const initialState = {
  data: {},
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const MISSIONS_CREATE = 'MISSIONS_CREATE'
const MISSIONS_CREATE_OK = 'MISSIONS_CREATE_OK'
const MISSIONS_CREATE_FAIL = 'MISSIONS_CREATE_FAIL'

export default function missionsCreateReducers(state = initialState, action) {
  const { data, message, type } = action

  switch (type) {
    case MISSIONS_CREATE:
      return { isFetching: true }

    case MISSIONS_CREATE_OK:
      return { ...state, auth: data, success: true, error: false }

    case MISSIONS_CREATE_FAIL:
      return { isFetching: false, success: false, error: true, message }

    default:
      return state
  }
}
