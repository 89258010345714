export default {
  1: 'Pós-pago Normal',
  2: 'Pós-pago Desonerado',
  3: 'Pré-pago Normal',
  4: 'Pré-pago Desonerado',
}

// $scope.customerPaymentsType = [
//   { type: 1, description: 'Pós-pago Normal' },
//   { type: 2, description: 'Pós-pago Desonerado' },
//   { type: 3, description: 'Pré-pago Normal' },
//   { type: 4, description: 'Pré-pago Desonerado' },
// ]
