import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { length, isNil } from 'ramda'
import {
  Form,
  Radio,
  Modal,
  Button,
  Loader,
  Dimmer,
  Message,
  Segment,
} from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import {
  formHasMission,
  create as createForms,
  update as updateForms,
} from '~/store/modules/formsMissions/actions'

export default function MissionForms({
  selectedMissionUid,
  isOpenModalForm,
  onCloseModalForm,
}) {
  const [forms, setForms] = useState([])
  const [formName, setFormName] = useState('')
  const [formValue, setFormValue] = useState('')
  const [formsData, setFormsData] = useState({})
  const [errorsModal, setErrorsModal] = useState([])

  const formHasMissionResponse = useSelector((store) => store.formHasMission)
  const formsList = useSelector((store) => store.formsList)
  const formsMissionsUpdate = useSelector((store) => store.formsMissionsUpdate)
  const formsMissionsCreate = useSelector((store) => store.formsMissionsCreate)

  const isLoading = formHasMissionResponse.isFetching

  const dispatch = useDispatch()

  const handlerCreateForms = useCallback(
    (props) => {
      if (props.success) {
        setErrorsModal([])
        dispatch(formHasMission({ id: selectedMissionUid }))
      } else if (props.error) {
        setErrorsModal((em) => em.concat(props.message))
      }
    },
    [dispatch, selectedMissionUid]
  )

  const submitForms = (type) => {
    const formsDataObj = {
      forms_id: formValue,
      mission_id: selectedMissionUid,
    }

    if (type === 'create') {
      return dispatch(createForms(formsDataObj))
    }

    return dispatch(updateForms(formsDataObj))
  }

  const handleChangeForm = (e, { value }) => {
    setFormName(value.name)
    setFormValue(value.uid)
  }

  useEffect(() => {
    dispatch(formHasMission({ id: selectedMissionUid }))
  }, [dispatch, selectedMissionUid])

  useEffect(() => {
    if (formsList && formsList.success) {
      setForms(formsList.data)
      formsList.success = false
    }
  }, [formsList])

  useEffect(() => {
    if (formHasMissionResponse && formHasMissionResponse.success) {
      setFormsData(formHasMissionResponse.data)
      formHasMissionResponse.success = false
    }
  }, [formHasMissionResponse])

  useEffect(() => {
    if (formsMissionsCreate) {
      handlerCreateForms(formsMissionsCreate)
    }
  }, [formsMissionsCreate, handlerCreateForms])

  useEffect(() => {
    if (formsMissionsUpdate) {
      handlerCreateForms(formsMissionsUpdate)
    }
  }, [formsMissionsUpdate, handlerCreateForms])

  return (
    <Modal size="small" open={isOpenModalForm} onClose={onCloseModalForm}>
      <Modal.Header>Formulário(s)</Modal.Header>
      <Modal.Content>
        {length(errorsModal) > 0 && (
          <Message negative header="Alerta!" content={errorsModal} />
        )}

        {isLoading && (
          <Segment>
            <Dimmer active>
              <Loader />
            </Dimmer>
          </Segment>
        )}

        {!isLoading &&
        length(forms) > 0 &&
        !isNil(formsData.missions_found) &&
        parseInt(formsData.missions_already_save, 0) > 0 ? (
          <Message
            warning
            header="Alerta!"
            content={`Já houveram ${formsData.mission_has_started} participantes que responderam o formulário, por isso, você não pode mais altera-lo!`}
          />
        ) : (
          <Form>
            <Form.Field>
              Formulário selecionado: <b>{formName}</b>
            </Form.Field>
            {forms.map((item, index) => {
              const checked =
                formsData.mission_found === item.uid || item.name === formName
              return (
                <Form.Field key={`key-${index}`}>
                  <Radio
                    name="radio"
                    value={item}
                    label={item.name}
                    checked={checked}
                    onChange={handleChangeForm}
                  />
                </Form.Field>
              )
            })}
          </Form>
        )}

        {!isLoading && length(forms) <= 0 && (
          <EmptyList
            marginTop={0}
            icon="wpforms"
            text="Nenhum formulário foi adicionado..."
          />
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={onCloseModalForm}>Cancelar</Button>
        {isNil(formsData.mission_found) ? (
          <Button
            primary
            loading={isLoading}
            disable={isLoading}
            content="Criar"
            onClick={() => submitForms('create')}
          />
        ) : (
          <Button
            primary
            loading={isLoading}
            disable={isLoading}
            onClick={() => submitForms('update')}
            content="Atualizar"
          />
        )}
      </Modal.Actions>
    </Modal>
  )
}
