import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Button, Confirm, Divider } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'

import CreateCustomer from '~/components/CreateCustomer/index'
import EmptyList from '~/components/EmptyList'
import TableComponent from '~/components/Table'
import usePaginationTable from '~/hooks/usePaginationTable'
import useQueryFilters from '~/hooks/useQueryFilters'
import api from '~/services/api'
import { CUSTOMERS } from '~/services/api/endpoints'
import history from '~/services/history'
import { findByCustomer } from '~/store/modules/categories/actions'
import { create, removeById } from '~/store/modules/customers/actions'
import { getRates } from '~/store/modules/rates/actions'
import message from '~/utils/messages'

import CategoriesModal from './Categories'
import CustomerFilter from './CustomerFilter'
import Item from './Item'
import RatesModal from './Rates'

const initialFilters = {
  contract_start: null,
  situation: null,
  name: null,
  phone: null,
  customer_payment_type: null,
  customer_limit: null,
  amount: null,
  billing_estimated: null,
  direction: null,
  order: null,
  limit: 15,
  offset: 0,
}

const columns = [
  'Contrato',
  'Situação',
  'Nome',
  'Telefone',
  'Tipo de Pagamento',
  'Limite',
  'Saldo Atual',
  'Gasto Total',
  'Potencial de Faturamento',
  'Rate',
  'Ações',
]

/* eslint-disable */
const columnsMapForApiSort = {
  Contrato: 'contract_start',
  Situação: 'situation',
  Nome: 'name',
  Telefone: 'phone',
  'Tipo de Pagamento': 'customer_payment_type',
  Limite: 'customer_limit',
  'Saldo Atual': 'amount',
  'Gasto Total': 'total_spend',
  'Potencial de Faturamento': 'billing_estimated',
  Rate: 'rate_avg',
}
/* eslint-enable */

export default function Customers() {
  const [categories, setCategories] = useState([])
  const [openCategoriesModal, setOpenCategoriesModal] = useState(false)
  const [isLoadingCategories, setIsLoadingCategories] = useState(false)

  const [ratesList, setRatesList] = useState([])
  const [openRates, setOpenRates] = useState(false)
  const [isLoadingRates, setIsLoadingRates] = useState(false)

  const [customersList, setCustomersList] = useState([])
  const [showCreateCustomer, setShowCreateCustomer] = useState(false)
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(true)
  const [customerId, setCustomerId] = useState('')
  const [customerName, setCustomerName] = useState('')

  const [isConfirmRemoveModalOpen, setIsConfirmRemoveModalOpen] = useState(
    false
  )

  const user = useSelector((store) => store.user)
  const ratesData = useSelector((store) => store.ratesReducers)
  const customerCreatedData = useSelector((store) => store.createCustomer)
  const customerRemovedData = useSelector((store) => store.customerRemove)
  const categoriesByCustomerData = useSelector(
    (store) => store.categoriesByCustomer
  )

  const dispatch = useDispatch()

  const parseQueryParamsBeforeSetFilters = useCallback((queryObj) => {
    const parsedParams = {}

    if (queryObj.limit) {
      parsedParams.limit = parseInt(queryObj.limit, 0)
    }
    if (queryObj.offset) {
      parsedParams.offset = parseInt(queryObj.offset, 0)
    }
    if (queryObj.contract_start) {
      parsedParams.contract_start = new Date(
        parseInt(queryObj.contract_start, 0)
      )
    }
    if (queryObj.customer_payment_type) {
      parsedParams.customer_payment_type = parseInt(
        queryObj.customer_payment_type,
        0
      )
    }

    return parsedParams
  }, [])

  const {
    filters,
    debouncedFilters,
    setFilters,
    onChangeFilter,
    clearFilters,
  } = useQueryFilters(initialFilters, parseQueryParamsBeforeSetFilters)

  const {
    count,
    totalCount,
    activePage,
    calculatePagination,
    handleSort,
    onPageChange,
    emptyText,
  } = usePaginationTable(debouncedFilters, setFilters, columnsMapForApiSort)

  const getCustomers = useCallback(async () => {
    try {
      const validParams = { ...debouncedFilters }

      if (validParams.contract_start) {
        validParams.contract_start = new Date(
          validParams.contract_start
        ).getTime()
      }

      setIsLoadingCustomers(true)
      const res = await api.get(CUSTOMERS, { params: validParams })
      setCustomersList(res.data.data)
      calculatePagination(res.data.count)
      setIsLoadingCustomers(false)
    } catch (error) {
      console.log(error)
      setIsLoadingCustomers(false)
      message().error(error.response.data.data || 'Houve um erro no servidor')
    }
  }, [debouncedFilters, calculatePagination])

  const showConfirmRemove = useCallback((item) => {
    setIsConfirmRemoveModalOpen(true)
    setCustomerId(item.uid)
  }, [])

  const removeByCustomerId = useCallback(() => {
    setIsConfirmRemoveModalOpen(false)
    dispatch(removeById(customerId))
  }, [dispatch, customerId])

  const submitCreateCustomer = useCallback(
    (customer) => {
      const contractStart = new Date(customer.contract_start).getTime()

      setIsLoadingCustomers(true)

      return dispatch(
        create({
          ...customer,
          contract_start: contractStart,
          config: {
            limit: customer.limit,
            payment_type: customer.payment_type,
            expiring_day: customer.expiring_day,
            customer_payment_type: customer.customer_payment_type,
          },
        })
      )
    },
    [dispatch]
  )

  useEffect(() => {
    if (debouncedFilters !== null) {
      getCustomers()
    }
  }, [debouncedFilters, getCustomers])

  useEffect(() => {
    if (ratesData.success) {
      setRatesList(ratesData.data)
      setIsLoadingRates(false)
    }
  }, [ratesData])

  useEffect(() => {
    if (customerCreatedData.success) {
      const newCustomer = { ...customerCreatedData.data }
      setCustomersList((list) => [newCustomer, ...list])
      message().success('Cliente criado com sucesso!')
      setIsLoadingCustomers(false)
      customerCreatedData.success = false
    } else if (customerCreatedData.error) {
      message().error(customerCreatedData.message)
      setIsLoadingCustomers(false)
    }
  }, [dispatch, filters, customerCreatedData])

  useEffect(() => {
    if (customerRemovedData.success) {
      const removedCustomer = customerRemovedData.data[0]
      setCustomersList((list) =>
        list.filter((c) => c.uid !== removedCustomer.uid)
      )
      message().success('Cliente removido com sucesso!')
      customerRemovedData.success = false
    }
  }, [customerRemovedData])

  useEffect(() => {
    if (categoriesByCustomerData.success) {
      setCategories(categoriesByCustomerData.data)
      setIsLoadingCategories(false)
      categoriesByCustomerData.success = false
    }
  }, [categoriesByCustomerData])

  const getCustomersCategories = useCallback(
    (item) => {
      setCategories([])
      setOpenCategoriesModal(true)
      setIsLoadingCategories(true)
      setCustomerName(item.name)
      dispatch(findByCustomer(item.uid))
    },
    [dispatch]
  )

  const handlerRates = useCallback(
    (item) => {
      dispatch(getRates(item.uid))
      setOpenRates(true)
      setIsLoadingRates(true)
      setCustomerName(item.name)
    },
    [dispatch]
  )

  const renderItem = useCallback(
    (item, index) => {
      return (
        <Item
          key={uuidv4()}
          item={item}
          index={index}
          history={history}
          handlerRates={handlerRates}
          showConfirmRemove={showConfirmRemove}
          getCustomersCategories={getCustomersCategories}
        />
      )
    },
    [getCustomersCategories, handlerRates, showConfirmRemove]
  )

  if (
    user.profile.role_name === 'Basic' ||
    user.profile.role_name === 'Leader'
  ) {
    return <EmptyList icon="lock" text="Acesso restrito" />
  }

  return (
    <>
      {filters && (
        <>
          <CustomerFilter
            filters={filters}
            isLoading={isLoadingCustomers}
            clearFilters={clearFilters}
            onChangeFilter={onChangeFilter}
          />

          <Divider inverted />
        </>
      )}

      <CreateCustomer
        show={showCreateCustomer}
        onSubmit={submitCreateCustomer}
        isLoading={isLoadingCustomers}
      />

      <Button
        size="small"
        color={showCreateCustomer ? 'red' : 'blue'}
        onClick={() => setShowCreateCustomer(!showCreateCustomer)}
        content={showCreateCustomer ? 'Fechar' : 'Criar'}
      />

      <Divider />

      <TableComponent
        data={customersList}
        columns={columns}
        renderItem={renderItem}
        isLoading={isLoadingCustomers}
        count={count}
        totalCount={totalCount}
        activePage={activePage}
        offset={filters?.offset}
        handleColumnSort={handleSort}
        handlePaginationChange={onPageChange}
        emptyText={emptyText}
        siblingRange={1}
        boundaryRange={0}
        showEllipsis
        showFirstAndLastNav
        showPreviousAndNextNav
      />

      <Confirm
        open={isConfirmRemoveModalOpen}
        header="Alerta"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onCancel={() => setIsConfirmRemoveModalOpen(false)}
        onConfirm={() => removeByCustomerId()}
        content="Tem certeza deseja remover este cliente?"
      />

      <CategoriesModal
        categories={categories}
        customerName={customerName}
        openCategoriesModal={openCategoriesModal}
        isLoadingCategories={isLoadingCategories}
        close={() => setOpenCategoriesModal(false)}
      />

      <RatesModal
        rates={ratesList}
        openModal={openRates}
        customerName={customerName}
        isLoadingRates={isLoadingRates}
        close={() => setOpenRates(false)}
      />
    </>
  )
}
