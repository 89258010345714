import React, { memo } from 'react'
import DatePicker from 'react-datepicker'

import PropTypes from 'prop-types'
import { Select, Button, Card, Form, Input } from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'

function CustomerFilter({ filters, isLoading, clearFilters, onChangeFilter }) {
  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group>
            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Nome"
              value={filters.name || ''}
              onChange={(e, { value }) => onChangeFilter('name', value)}
            />

            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Telefone"
              value={filters.phone || ''}
              onChange={(e, { value }) => onChangeFilter('phone', value)}
            />

            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Limite"
              value={filters.customer_limit || ''}
              onChange={(e, { value }) =>
                onChangeFilter('customer_limit', value)
              }
            />

            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Saldo Atual"
              value={filters.amount || ''}
              onChange={(e, { value }) => onChangeFilter('amount', value)}
            />

            <Form.Field
              control={Input}
              icon="search"
              className="icon"
              placeholder="Potencial de Faturamento"
              value={filters.billing_estimated || ''}
              onChange={(e, { value }) =>
                onChangeFilter('billing_estimated', value)
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Field>
              <div className="ui input">
                <DatePicker
                  id="contract_start"
                  selectsStart
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Contrato"
                  selected={filters.contract_start || ''}
                  onChange={(value) => onChangeFilter('contract_start', value)}
                />
              </div>
            </Form.Field>

            <Form.Field
              control={Select}
              placeholder="Situação"
              selection
              value={filters.situation || ''}
              options={[
                { key: 0, text: 'Todos', value: undefined },
                { key: 1, text: 'Ativo', value: 'Ativo' },
                { key: 2, text: 'On Hold', value: 'On Hold' },
                { key: 3, text: 'Inativo', value: 'Inativo' },
              ]}
              onChange={(e, { value }) => onChangeFilter('situation', value)}
            />

            <Form.Field
              control={Select}
              placeholder="Tipo de Pagamento"
              selection
              value={filters.customer_payment_type || ''}
              options={[
                { key: 0, text: 'Todos', value: undefined },
                { key: 1, text: 'Pós-pago Normal', value: 1 },
                { key: 2, text: 'Pós-pago Desonerado', value: 2 },
                { key: 3, text: 'Pré-pago Normal', value: 3 },
                { key: 4, text: 'Pré-pago Desonerado', value: 4 },
              ]}
              onChange={(e, { value }) =>
                onChangeFilter('customer_payment_type', value)
              }
            />

            <Form.Field
              control={Select}
              placeholder="Resultados por página"
              selection
              value={filters.limit || ''}
              options={[
                { key: 1, value: 15, text: 15 },
                { key: 2, value: 50, text: 50 },
                { key: 3, value: 100, text: 100 },
              ]}
              onChange={(e, { value }) => onChangeFilter('limit', value)}
            />
          </Form.Group>
        </Form>
      </Card.Content>

      <Card.Content>
        <Button
          icon="undo"
          color="grey"
          content="Limpar Filtros"
          labelPosition="left"
          onClick={clearFilters}
          loading={isLoading}
        />
      </Card.Content>
    </Card>
  )
}

CustomerFilter.propTypes = {
  filters: PropTypes.object,
  isLoading: PropTypes.bool,
  clearFilters: PropTypes.func,
  onChangeFilter: PropTypes.func,
}

export default memo(CustomerFilter)
