import { responseData } from '~/utils/reducer'

const CREDIT_REQUEST = 'CREDIT_REQUEST'
const UPDATE_CREDIT_REQUESTS = 'UPDATE_CREDIT_REQUESTS'

const creditRequest = responseData(CREDIT_REQUEST, 'array')
const updateCreditRequest = responseData(UPDATE_CREDIT_REQUESTS, 'object')

export default {
  creditRequest,
  updateCreditRequest,
}
