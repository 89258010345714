import React, { useEffect, useState } from 'react'

import t from 'prop-types'
import { Modal, Button, Form, Dropdown } from 'semantic-ui-react'

import { USERS_REPORT } from '~/services/api/endpoints'
import message from '~/utils/messages'
import { objectToQueryString } from '~/utils/queryStringHelper'

export default function ModalExport({ filters, getValidParams, open, close }) {
  const [columnsOptions, setColumnsOptions] = useState([])
  const [columnsSelected, setColumnsSelected] = useState([])

  const submitExport = () => {
    if (columnsSelected.length === 0) {
      message().error('Selecione ao menos uma coluna!')
      return false
    }

    const validParams = getValidParams()
    validParams.export_columns = columnsSelected
    const searchString = objectToQueryString(validParams)

    return window.open(`${USERS_REPORT}?${searchString}`)
  }

  const clear = () => {
    setColumnsSelected([])
  }

  useEffect(() => {
    if (!filters) return

    const columns = []
    const ignore = ['limit', 'offset', 'order', 'direction', 'badges']

    Object.keys(filters).forEach((key) => {
      if (!ignore.includes(key)) {
        columns.push({ key, value: key, text: key })
      }
    })

    setColumnsOptions(columns)
  }, [filters])

  return (
    <Modal size="small" open={open} centered={false}>
      <Modal.Content>
        <h2>Selecione as colunas que serão exportadas:</h2>
        <Form>
          <Form.Field>
            <Dropdown
              search
              selection
              multiple
              options={columnsOptions}
              placeholder="Colunas"
              value={columnsSelected || []}
              onChange={(e, { value }) => setColumnsSelected(value)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Content>
        <Button color="red" icon="close" onClick={close} content="Cancelar" />

        <Button
          color="green"
          floated="right"
          icon="download"
          onClick={submitExport}
          content="Exportar"
        />
        <Button
          color="grey"
          floated="right"
          icon="undo"
          onClick={clear}
          content="Limpar"
        />
      </Modal.Content>
    </Modal>
  )
}

ModalExport.propTypes = {
  filters: t.objectOf(t.any),
  getValidParams: t.func,
  open: t.bool,
  close: t.func,
}
