import React from 'react'
import { SemanticToastContainer } from 'react-semantic-toasts'

import 'react-semantic-toasts/styles/react-semantic-alert.css'

import PropTypes from 'prop-types'

import * as S from './styles'

export default function Auth({ children }) {
  console.log('render default')
  return (
    <S.Container>
      {children}
      <SemanticToastContainer position="top-center" />
    </S.Container>
  )
}

Auth.propTypes = {
  children: PropTypes.element.isRequired,
}
