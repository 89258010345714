import {
  FORMS_HAS_MISSIONS,
  FORMS_MISSIONS_CREATE,
  FORMS_MISSIONS_UPDATE,
} from '~/services/api/endpoints'
import { get, post, put } from '~/utils/actionsRequests'

const formHasMission = ({ id }) =>
  get({
    url: `${FORMS_HAS_MISSIONS}${id}`,
    typeFetch: 'FORMS_HAS_MISSIONS',
    typeError: 'FORMS_HAS_MISSIONS_FAIL',
    typeResponse: 'FORMS_HAS_MISSIONS_OK',
  })

const create = (data) =>
  post({
    data,
    url: `${FORMS_MISSIONS_CREATE}`,
    typeFetch: 'FORMS_MISSIONS_CREATE',
    typeError: 'FORMS_MISSIONS_CREATE_FAIL',
    typeResponse: 'FORMS_MISSIONS_CREATE_OK',
  })

const update = (data) =>
  put({
    data,
    url: `${FORMS_MISSIONS_UPDATE}`,
    typeFetch: 'FORMS_MISSIONS_UPDATE',
    typeError: 'FORMS_MISSIONS_UPDATE_FAIL',
    typeResponse: 'FORMS_MISSIONS_UPDATE_OK',
  })

export { create, update, formHasMission }
