import { INVOICE_FETCH } from '~/services/api/endpoints'
import { get } from '~/utils/actionsRequests'

const getInvoice = (id) =>
  get({
    url: `${INVOICE_FETCH(id)}?status=approved`,
    typeFetch: 'INVOICE_FETCH',
    typeError: 'INVOICE_FETCH_FAIL',
    typeResponse: 'INVOICE_FETCH_OK',
  })

export { getInvoice }
