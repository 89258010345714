import React from 'react'
import { connect } from 'react-redux'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'
import {
  arrayMove,
  SortableElement,
  SortableContainer,
} from 'react-sortable-hoc'
import Sticky from 'react-stickynode'

import { head, add, length, isEmpty, isNil } from 'ramda'
import {
  Grid,
  List,
  Loader,
  Button,
  Header,
  Dimmer,
  Segment,
  Divider,
} from 'semantic-ui-react'

import { findById as findForm } from '~/store/modules/forms/actions'
import {
  create,
  update,
  findById,
  removeById,
  changeOrder,
} from '~/store/modules/formsItems/actions'
import {
  create as createMultiples,
  removeById as removeMultiples,
  changeOrder as changeOrderMultiples,
} from '~/store/modules/formsItemsMultiples/actions'

import Breadcrumbs from '~/components/Breadcrumbs'
import CreateFormsItems from '~/components/Cards/CreateFormsItems'
import FormsItems from '~/components/FormsItems'
import FormsItemsUpdate from '~/components/FormsItemsUpdate'
import Main from '../Main'

const SortableItem = SortableElement(({ value }) => (
  <List.Item>
    {head(value.items).order}. {head(value.items).question}
  </List.Item>
))

const SortableList = SortableContainer(({ items }) => (
  <List celled style={{ fontSize: 12 }}>
    {items.map((value, index) => (
      <SortableItem key={`index-${index}`} index={index} value={value} />
    ))}
  </List>
))

const SortableMultipleList = SortableContainer(FormsItems)

class Detail extends React.Component {
  state = {
    errors: [],

    items: [],
    scrollTop: 0,
    className: '',
    hasChange: false,
    showConfirm: false,

    type: '',
    order: '',
    length: 10,
    section: '',
    question: '',
    forms_id: '',
    multiples: [],
    questionItem3: '',
    questionItem4: '',

    removeId: '',
    title: '',
    questionId: '',
    newMultipleItem: {},

    multiplesItems: '',
    removeMultiplesId: '',
  }

  constructor(props) {
    super(props)

    this.handlerInput = this.handlerInput.bind(this)
    this.removeMultiples = this.removeMultiples.bind(this)
  }

  onLoad = () => {
    this.setState({ hasChange: false })
    const data = {
      id: this.props.match.params.id,
      customerId: this.props.user.profile.uid,
    }
    this.props.findById(data)
    this.props.findForm(data)
  }

  componentDidMount() {
    this.onLoad()
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { items } = this.state
    const newArr = arrayMove(items, oldIndex, newIndex)
    this.setState({ items: newArr })
    this.props.changeOrder({ items: newArr })
  }

  onSortEndMultiples = ({ oldIndex, newIndex }) => {
    const { items } = this.state
    const newArr = arrayMove(items, oldIndex, newIndex)
    this.setState({ items: newArr })
    this.props.changeOrder({ items: newArr })
  }

  onSortEndMultiples = (id, { oldIndex, newIndex }) => {
    const newArr = arrayMove(this.state[`items${id}`], oldIndex, newIndex)
    this.setState({ [`items${id}`]: newArr })
    this.props.changeOrderMultiples({ items: newArr })
  }

  renderSortableMultipleItems = id => (
    <SortableMultipleList
      {...this.state}
      {...this.props}
      state={this.state}
      handlerInput={this.handlerInput}
      removeMultipleItems={this.removeMultiples}
      items={this.state[`items${id}`]}
      onSortEnd={e => this.onSortEndMultiples(id, e)}
    />
  )

  removeMultiples = ({ customerId, value }) => {
    this.setState({
      removeMultiplesId: value,
      multiplesItems: this.state[`items${value.fiId}`],
    })

    this.props.removeMultiples({ customerId, id: value.fiqmId })
  }

  renderSortableItems = () => (
    <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />
  )

  handlerInput = ({ target }) => this.setState({ [target.id]: target.value })

  addItem = e => {
    const { type, questionItem3, questionItem4 } = this.state
    e.key === 'Enter' &&
      this.setState({
        multiples: this.state.multiples.concat({
          forms_items_id: this.props.match.params.id,
          order: add(length(this.state.multiples), 1),
          name: type === 3 ? questionItem3 : questionItem4,
        }),
      })
  }

  addMultipeItem = ({ id, formItemId }, e) => {
    const arr = []
    if (e.key === 'Enter') {
      if (
        (this.state[`type${id}`] === 3 || this.state[`type${id}`] === 4) &&
        isNil(formItemId)
      ) {
        this.setState({
          [`errors${id}`]: arr.concat(
            'Para respostas multiplas você precisa remover essa pergunta e criar uma nova!'
          ),
        })
        return
      }

      this.props.createMultiples({
        forms_items_id: formItemId,
        order: add(length(this.state[`items${id}`]), 1),
        title: this.state.questionMultipleItem,
      })
      this.setState({ questionId: id })
    }
  }

  submitUpdate = (id, type) => {
    this.setState({ [`loading${id}`]: true })

    this.state[`items${id}`].map((i, idx) => {
      return (i.title = this.state[`title${i.fqmId}`])
    })

    setTimeout(() => {
      this.props.update({
        formItemId: id,
        type: this.state[`type${id}`],
        length: this.state[`length${id}`],
        section: this.state[`section${id}`],
        question: this.state[`question${id}`],
        multiples: this.state[`items${id}`],
      })
    }, 200)
  }

  handlerSelect = (e, { value }) => this.setState({ type: value })

  handlerSelectFrom = (e, { value, id }) => this.setState({ [id]: value })

  showConfirm = () => this.setState({ showConfirm: true })

  submitRemove = () => {
    this.setState({ showConfirm: false })
    this.props.removeById({
      id: this.state.removeId,
      customerId: this.props.user.profile.uid,
    })
  }

  submitItem = () => {
    const arr = []
    if (isEmpty(this.state.section)) {
      this.setState({
        errors: arr.concat('Seção não pode estar vazia'),
      })
      return
    }
    if (isEmpty(this.state.type)) {
      this.setState({
        errors: arr.concat('Escolha o tipo de pergunta'),
      })
      return
    }
    if (isEmpty(this.state.question)) {
      this.setState({
        errors: arr.concat('Pergunta não pode estar vazia'),
      })
      return
    }
    if (
      (this.state.type === 3 || this.state.type === 4) &&
      length(this.state.multiples) === 0
    ) {
      this.setState({
        errors: arr.concat('Você precisa adicionar um item...'),
      })
      return
    }
    if (this.state.type === 7 && length(this.state.length) === 0) {
      this.setState({
        errors: arr.concat('Número de caracteres não pode ser zero!'),
      })
      return
    }

    this.setState({ errors: [] })

    this.props.create({
      type: this.state.type,
      section: this.state.section,
      question: this.state.question,
      forms_id: this.props.match.params.id,
      customer_id: this.props.user.profile.uid,
      order: add(length(this.props.formsItems.data), 1),
      length: isEmpty(this.state.length) ? null : this.state.length,
      items: this.state.multiples.length ? this.state.multiples : null,
    })
  }

  handlerSubmit = e => e.preventDefault()

  showConfirm = ({ showConfirm, removeId }) => {
    this.setState({ showConfirm, removeId })
  }

  cancelConfirm = ({ showConfirm }) => {
    this.setState({ showConfirm })
  }

  renderItem = (item, index) => (
    <FormsItemsUpdate
      item={item}
      index={index}
      {...this.state}
      state={this.state}
      showConfirm={this.showConfirm}
      handlerInput={this.handlerInput}
      submitRemove={this.submitRemove}
      submitUpdate={this.submitUpdate}
      handlerSubmit={this.handlerSubmit}
      cancelConfirm={this.cancelConfirm}
      addMultipeItem={this.addMultipeItem}
      handlerSelectFrom={this.handlerSelectFrom}
      renderSortableMultipleItems={this.renderSortableMultipleItems}
    />
  )

  componentWillReceiveProps(nextProps) {
    const {
      formsItems,
      formsOrder,
      formsItemsCreate,
      formsItemsUpdate,
      formsItemsRemove,
      formsItemsMultiplesCreate,
      formsItemsMultiplesRemove,
    } = this.props

    if (formsItemsUpdate !== nextProps.formsItemsUpdate) {
      if (nextProps.formsItemsUpdate.success) {
        const { id } = nextProps.formsItemsUpdate.data
        this.setState({ [`loading${id}`]: false })
        setTimeout(() => {
          toast(
            {
              title: 'Alerta',
              type: 'success',
              icon: 'check',
              description: 'Atualização realizada com sucesso!',
            },
            () => console.log('toast closed')
          )
        }, 200)
      }

      if (nextProps.formsItemsUpdate.error) {
        toast(
          {
            title: 'Alerta',
            type: 'warning',
            icon: 'remove',
            description: nextProps.formsItemsUpdate.message,
          },
          () => console.log('toast closed')
        )
      }
    }

    if (formsItemsMultiplesCreate !== nextProps.formsItemsMultiplesCreate) {
      if (nextProps.formsItemsMultiplesCreate.success) {
        const {
          uid,
          id,
          title,
          order,
          forms_items_id,
        } = nextProps.formsItemsMultiplesCreate.data

        if (!isEmpty(nextProps.formsItemsMultiplesCreate.data)) {
          this.setState({
            [`items${this.state.questionId}`]: this.state[
              `items${this.state.questionId}`
            ].concat({
              order,
              title,
              fqmId: id,
              fiId: this.state.questionId,
              formItemId: forms_items_id,
              fiqmId: uid,
            }),
            [`title${id}`]: title,
          })
        }
        nextProps.formsItemsMultiplesCreate.data = {}
      }
      if (nextProps.formsItemsMultiplesCreate.error) {
        const arr = []
        this.setState({
          errors: arr.concat(nextProps.formsItemsMultiplesCreate.message),
        })
        return
      }
    }

    if (formsItems !== nextProps.formsItems) {
      if (nextProps.formsItems.success) {
        this.setState({ items: nextProps.formsItems.data })

        return nextProps.formsItems.data.map(i => {
          const id = head(i.items).fiId
          this.setState({
            [`items${id}`]: i.items,
            [`length${id}`]: i.length,
            [`type${id}`]: head(i.items).type,
            [`section${id}`]: head(i.items).section,
            [`question${id}`]: head(i.items).question,
          })
          return i.items.map((im, idx) =>
            this.setState({
              [`title${im.fqmId}`]: im.title,
            })
          )
        })
      }
    }

    if (formsOrder !== nextProps.formsOrder) {
      if (nextProps.formsOrder.success) {
        this.setState({ hasChange: true })
      }
    }

    if (formsItemsCreate !== nextProps.formsItemsCreate) {
      if (nextProps.formsItemsCreate.success) {
        this.setState({ items: nextProps.formsItemsCreate.data })

        setTimeout(() => {
          toast(
            {
              title: 'Alerta',
              type: 'success',
              icon: 'check',
              description: 'Pergunta adicionada com sucesso!',
            },
            () => console.log('toast closed')
          )
        }, 200)

        return nextProps.formsItemsCreate.data.map(i => {
          const id = head(i.items).fiId
          this.setState({
            [`items${id}`]: i.items,
            [`length${id}`]: i.length,
            [`type${id}`]: head(i.items).type,
            [`section${id}`]: head(i.items).section,
            [`question${id}`]: head(i.items).question,
          })

          setTimeout(() => {
            window.scrollTo(0, this.refs.scrollView.scrollHeight)
            this.setState({
              type: '',
              order: '',
              length: 10,
              section: '',
              question: '',
              multiples: [],
            })
          }, 200)

          return i.items.map((im, idx) =>
            this.setState({
              [`title${im.fqmId}`]: im.title,
            })
          )
        })
      }
    }

    if (formsItemsRemove !== nextProps.formsItemsRemove) {
      if (nextProps.formsItemsRemove.success) {
        const array = [...this.state.items]
        const index = this.state.items
          .map(e => parseInt(e.formsItem, 0))
          .indexOf(this.state.removeId)

        array.splice(index, 1)

        index > -1 && this.setState({ items: array })

        setTimeout(() => {
          toast(
            {
              title: 'Alerta',
              type: 'success',
              icon: 'check',
              description: 'Item removido com sucesso!',
            },
            () => console.log('toast closed')
          )
        }, 200)
      } else if (nextProps.formsItemsRemove.error) {
        toast(
          {
            title: 'Alerta',
            type: 'warning',
            icon: 'remove',
            description: nextProps.formsItemsRemove.message,
          },
          () => console.log('toast closed')
        )
      }
    }

    if (formsItemsMultiplesRemove !== nextProps.formsItemsMultiplesRemove) {
      if (nextProps.formsItemsMultiplesRemove.success) {
        const { fiId, fqmId } = this.state.removeMultiplesId
        const array = [...this.state[`items${fiId}`]]
        const index = this.state[`items${fiId}`]
          .map(e => e.fqmId)
          .indexOf(fqmId)

        array.splice(index, 1)
        index > -1 && this.setState({ [`items${fiId}`]: array })

        setTimeout(() => {
          toast(
            {
              title: 'Alerta',
              type: 'success',
              icon: 'check',
              description: 'Item removido com sucesso!',
            },
            () => console.log('toast closed')
          )
        }, 200)
      }
    }
  }

  render() {
    const breadcrumbsList = [
      {
        active: false,
        hasLink: true,
        link: '/forms',
        name: 'Formulários',
      },
      {
        active: true,
        hasLink: false,
        name: this.props.formDetail.data.name,
      },
    ]
    return (
      <Main {...this.props}>
        <div ref="scrollView">
          <Breadcrumbs items={breadcrumbsList} history={this.props.history} />
          <Grid divided>
            <Grid.Column width={13}>
              {this.props.formsItems.isFetching ? (
                <Segment>
                  <Dimmer active>
                    <Loader size="medium">Carregando...</Loader>
                  </Dimmer>
                </Segment>
              ) : (
                <div>
                  {length(this.state.items) > 0 &&
                    this.state.items.map((item, index) => {
                      return this.renderItem(item, index)
                    })}
                  <Divider />
                  <CreateFormsItems
                    {...this.state}
                    addItem={this.addItem}
                    handlerSelect={this.handlerSelect}
                    submitItem={() => this.submitItem()}
                    changeQuestion={this.changeQuestion}
                    handlerInputCreate={this.handlerInput}
                  />
                </div>
              )}
            </Grid.Column>
            <Grid.Column width={3}>
              <div ref={this.handleContextRef}>
                <Sticky bottomBoundary="#content" top={20}>
                  <Segment>
                    <Header as="h3">Ordenação</Header>
                    {this.renderSortableItems()}
                    {this.state.hasChange && (
                      <Segment padded>
                        <Button
                          fluid
                          primary
                          content="Aplicar Ordenação"
                          onClick={() => this.onLoad()}
                        />
                      </Segment>
                    )}
                  </Segment>
                </Sticky>
              </div>
            </Grid.Column>
          </Grid>
          <SemanticToastContainer position="bottom-right" />
        </div>
      </Main>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    formDetail: store.findForm,
    formsItems: store.formsItems,
    formsOrder: store.formsOrder,
    formsUpdate: store.formsUpdate,
    formsItemsRemove: store.formsItemsRemove,
    formsItemsCreate: store.formsItemsCreate,
    formsItemsUpdate: store.formsItemsUpdate,
    formsItemsMultiplesOrder: store.formsItemsMultiplesOrder,
    formsItemsMultiplesCreate: store.formsItemsMultiplesCreate,
    formsItemsMultiplesRemove: store.formsItemsMultiplesRemove,
  }
}

export default connect(mapStateToProps, {
  create,
  update,
  findForm,
  findById,
  changeOrder,
  removeById,
  createMultiples,
  removeMultiples,
  changeOrderMultiples,
})(Detail)
