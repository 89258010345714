import React from 'react'
import DatePicker from 'react-datepicker'

import t from 'prop-types'
import {
  Modal,
  Form,
  Grid,
  Icon,
  Label,
  Button,
  TextArea,
  Dropdown,
} from 'semantic-ui-react'
import '~/assets/css/react-datepicker.css'

const ListDropdown = ({ placeholder, options, onChange, values }) => (
  <div className="field">
    <Dropdown
      search
      selection
      multiple
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      defaultValue={values || []}
    />
  </div>
)

ListDropdown.propTypes = {
  onChange: t.func,
  placeholder: t.string,
  options: t.arrayOf(t.any),
  values: t.arrayOf(t.any),
}

const FormCampaign = ({
  item,

  states,
  weeks,
  types,
  times,
  cities,
  badges,
  periods,

  loading,
  citiesLoading,
  submit,
  onClose,
  openModal,
  onChange,
  handleState,
}) => (
  <Modal size="large" open={openModal.show} onClose={onClose}>
    <Modal.Header>Configuração da Campanha</Modal.Header>
    <Modal.Content>
      <Form>
        <Grid columns={2} divided="vertically">
          <Grid.Column>
            <Form.Input
              id="name"
              type="text"
              value={item?.name || ''}
              label="Nome (Obrigatório)"
              placeholder="Ex: Campanha X"
              onChange={(e, { value }) => onChange('name', value)}
            />
            {states.length > 0 && (
              <ListDropdown
                options={states}
                placeholder="UF"
                onChange={handleState}
                values={item?.places.states}
              />
            )}
            {!citiesLoading && (cities.length > 0 || item?.places.states) && (
              <ListDropdown
                options={cities}
                placeholder="Cidades"
                values={item?.places.cities || null}
                onChange={(e, { value }) => onChange('cities', value)}
              />
            )}
            <ListDropdown
              options={badges}
              onChange={onChange}
              placeholder="Badges"
            />
            <div className="field">
              <span>Mensagem (Obrigatório)</span>
              <TextArea
                id="message"
                value={item?.message || ''}
                placeholder="Mensagem..."
                onChange={(e, { value }) => onChange('message', value)}
              />
              <div style={{ marginTop: 10 }}>
                <Label as="a" tag>
                  #usuario#
                </Label>
                <Label as="a" tag>
                  #cidade#
                </Label>
                <Label as="a" tag>
                  #uf#
                </Label>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="field">
              <span>Periodo (Obrigatório)</span>
              <div
                className="ui input react-datepicker-manage"
                style={{ marginBottom: 10, width: '100%' }}
              >
                <DatePicker
                  id="initial"
                  selectsStart
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Inicio"
                  selected={item?.initial}
                  startDate={item?.initial}
                  endDate={item?.final}
                  onChange={(value) => onChange('initial', value)}
                />
              </div>
              <div
                style={{ flex: 1 }}
                className="ui input react-datepicker-manage"
              >
                <DatePicker
                  id="final"
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Fim"
                  selected={item?.final}
                  startDate={item?.initial}
                  endDate={item?.final}
                  onChange={(value) => onChange('final', value)}
                />
              </div>
            </div>
            <div className="field">
              <label>Tipo de Campanha</label>
              <Dropdown
                search
                options={types}
                defaultValue={item?.recurrence.type}
                placeholder="Tipo de Campanha"
                onChange={(e, { value }) => onChange('type', value)}
              />
            </div>
            <div className="field">
              <label>Horários (Obrigatório)</label>
              <Dropdown
                search
                selection
                multiple
                options={times}
                placeholder="Horários"
                onChange={(e, { value }) => onChange('hours', value)}
                defaultValue={item?.recurrence?.hours}
              />
            </div>
            {item.type === 'monthly' && (
              <div className="field">
                <label>Dias do Mês (Obrigatório)</label>
                {periods.length > 0 && (
                  <Dropdown
                    search
                    selection
                    multiple
                    options={periods}
                    placeholder="Dias do Mês"
                    defaultValue={item?.recurrence.period}
                    onChange={(e, { value }) => onChange('days', value)}
                  />
                )}
              </div>
            )}
            {item.type === 'weekly' && (
              <div className="field">
                <label>Dias da Semana (Obrigatório)</label>
                {weeks.length > 0 && (
                  <Dropdown
                    search
                    selection
                    multiple
                    options={weeks}
                    placeholder="Dias da semana"
                    defaultValue={item?.recurrence.period}
                    onChange={(e, { value }) => onChange('week', value)}
                  />
                )}
              </div>
            )}
          </Grid.Column>
        </Grid>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button content="Cancelar" onClick={onClose} />
      <Button
        icon
        positive
        onClick={submit}
        loading={loading}
        disabled={loading}
        labelPosition="left"
      >
        <Icon name="check" /> Enviar
      </Button>
    </Modal.Actions>
  </Modal>
)

FormCampaign.propTypes = {
  item: t.objectOf(t.any),

  type: t.string,
  weeks: t.arrayOf(t.any),
  types: t.arrayOf(t.any),
  cities: t.arrayOf(t.any),
  states: t.arrayOf(t.any),
  periods: t.arrayOf(t.any),
  times: t.arrayOf(t.any),
  badges: t.arrayOf(t.any),

  citiesLoading: t.bool,

  loading: t.bool,
  openModal: t.objectOf(t.any),

  submit: t.func,
  onClose: t.func,
  onChange: t.func,
  handleState: t.func,
}

export default FormCampaign
