import { all, put, call, takeLatest } from 'redux-saga/effects'

import api from '~/services/api'
import { ACCOUNTS_AUTH } from '~/services/api/endpoints'
import history from '~/services/history'
import { signInSuccess, signFailure } from '~/store/modules/auth/actions'
import message from '~/utils/messages'

export function* signInRequest({ payload }) {
  try {
    const { username, password } = payload

    const res = yield call(api.post, ACCOUNTS_AUTH, {
      login: username,
      pass: password,
    })

    const user = res.data.data

    yield put(signInSuccess(user))

    history.push('/dashboard')
  } catch (error) {
    console.log(error)
    yield put(signFailure())
    message().error(error.response.data.data || 'Houve um erro no servidor')
  }
}

export function* onRehydrate({ payload }) {
  if (!payload) return

  const { isLoading, signed } = payload.auth

  if (!signed && isLoading) {
    yield put(signFailure())
  }
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signInRequest),
  takeLatest('persist/REHYDRATE', onRehydrate),
])
