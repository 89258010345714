import React from 'react'

import { length } from 'ramda'
import { Menu, Input, Button, Message } from 'semantic-ui-react'

const FormsHeader = ({
  errors,
  formName,
  submitForm,
  handlerInput,
  handlerSearch,
}) => (
  <div>
    {length(errors) > 0 && <Message warning header="Alerta!" list={errors} />}
    <Menu>
      <Menu.Item>
        <Input
          icon="search"
          className="icon"
          onChange={handlerSearch}
          placeholder="Buscar formulários..."
        />
      </Menu.Item>
      <Menu.Item position="right">
        <Input
          label={<Button content="Adicionar" onClick={submitForm} />}
          labelPosition="right"
          value={formName || ''}
          onChange={handlerInput}
          placeholder="Nome do Formulário..."
        />
      </Menu.Item>
    </Menu>
  </div>
)

export default FormsHeader
