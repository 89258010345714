import React from 'react'

import { isNil } from 'ramda'
import { Icon, Grid, Dropdown, Form, Segment, Header } from 'semantic-ui-react'

import columns from '../../helpers/columns'

function removeSpecialCaracteres(text) {
  text = text.replace(/[ÀÁÂÃÄÅ]/g, 'A')
  text = text.replace(/[àáâãäå]/g, 'a')
  text = text.replace(/[ÈÉÊË]/g, 'E')
  text = text.replace(/[éèê]/g, 'e')
  text = text.replace(/[úù]/g, 'u')
  text = text.replace(/[ÚÙ]/g, 'U')
  text = text.replace(/[ÓÒÔ]/g, 'O')
  text = text.replace(/[óòô]/g, 'o')
  text = text.replace(/[íì]/g, 'i')
  text = text.replace(/[ÍÌ]/g, 'I')
  return text.replace(/[^a-z0-9]/gi, '')
}

function findValue(response, onChange, k) {
  const item = columns.find((i) => {
    console.log(
      removeSpecialCaracteres(i.text.toLowerCase()),
      removeSpecialCaracteres(response.toLowerCase())
    )
    return (
      removeSpecialCaracteres(i.text.toLowerCase()) ===
      removeSpecialCaracteres(response.toLowerCase())
    )
  })
  const options = columns.map((i) => ({ ...i, col: k }))

  if (!isNil(item)) {
    onChange(this, { options, value: item.value })
  }

  return isNil(item) ? null : item.value
}

export default function Files({
  rows,
  onChange,
  isDragActive,
  getRootProps,
  getInputProps,
}) {
  return (
    <div style={{ marginTop: 10 }}>
      <div className="field">
        <label>Arquivo .XLSX (Obrigatório)</label>
      </div>

      <Form style={{ marginTop: 10 }}>
        {rows.length > 0 && (
          <Grid columns={3} divided>
            <Grid.Row>
              {rows.map((text, k) => (
                <Grid.Column stretched key={`k-${k}`}>
                  <Form.Group widths="equal">
                    <div className="field">
                      <label>{text}</label>
                      <Dropdown
                        search
                        selection
                        key={`k-${k}`}
                        onChange={onChange}
                        placeholder="Escolha a coluna"
                        defaultValue={findValue(text, onChange, k)}
                        options={columns.map((i) => ({ ...i, col: k }))}
                      />
                    </div>
                  </Form.Group>
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        )}
      </Form>

      {rows.length === 0 && (
        <div
          {...getRootProps()}
          style={{
            padding: 10,
            marignTop: 10,
            borderWidth: 1,
            borderColor: '#b9b9b9',
            borderStyle: 'dashed',
          }}
        >
          <Segment placeholder textAlign="center" className="overflow">
            <Header icon>
              <Icon name="file excel outline" />
              {isDragActive ? (
                <p>Solte o arquivo aqui...</p>
              ) : (
                <p>
                  Arraste e solte os arquivos aqui ou clique para selecioná-los
                </p>
              )}
              <input {...getInputProps()} />
            </Header>
          </Segment>
        </div>
      )}
    </div>
  )
}
