import React from 'react'

import { isNil } from 'ramda'
import { List, Menu, Image, Card, Button, Progress } from 'semantic-ui-react'

import ImagePdf from '~/assets/pdf.png'
import EmptyList from './EmptyList'

const PDFList = ({
  myRef,
  onClick,
  attachs,
  progress,
  isLoading,
  removeId,
  uploadAttachments,
}) => {
  return (
    <div>
      <Menu>
        <Menu.Menu position="left">
          <Menu.Item>
            <input
              multiple
              type="file"
              id="uploadAttachment"
              accept="application/pdf"
              style={{ display: 'none' }}
              ref={myRef}
              onChange={uploadAttachments}
            />
            <Button
              basic
              icon="plus"
              content="Adicionar"
              labelPosition="left"
              loading={isLoading}
              onClick={onClick}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      {!isNil(progress) && (
        <Progress percent={progress} progress active success>
          {progress === 100
            ? 'Arquivo(s) enviado(s) com sucesso!'
            : 'Enviando...'}
        </Progress>
      )}
      <List divided verticalAlign="middle">
        {attachs.length === 0 && (
          <EmptyList text="Nenhum Arquivo" icon="file pdf outline" />
        )}
        {attachs.map((item, index) => {
          return (
            <Card
              key={`item-${index}`}
              style={{
                margin: 8,
                width: '24%',
                display: 'inline-block',
              }}
            >
              <Image
                as="a"
                size="medium"
                src={ImagePdf}
                href={item.file_path}
                target="_blank"
              />

              <Card.Content>
                <Card.Header>{item.key.split('_')[1]}</Card.Header>
              </Card.Content>
              <Card.Content extra>
                <Button.Group floated="right">
                  <Button
                    basic
                    compact
                    icon="linkify"
                    floated="right"
                    onClick={() => window.open(item.file_path, '_blank')}
                  />
                  <Button
                    basic
                    compact
                    icon="trash"
                    floated="right"
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => removeId(item.uid)}
                  />
                </Button.Group>
              </Card.Content>
            </Card>
          )
        })}
      </List>
    </div>
  )
}

export default PDFList
