import {
  ATTACHMENTS,
  ATTACHMENTS_REMOVE,
  ATTACHMENTS_UPLOAD,
} from '~/services/api/endpoints'
import { get, remove, post } from '~/utils/actionsRequests'

const upload = (data, id) =>
  post({
    data,
    url: `${ATTACHMENTS_UPLOAD}${id}`,
    typeFetch: 'ATTACHMENTS_UPLOAD',
    typeError: 'ATTACHMENTS_UPLOAD_FAIL',
    typeResponse: 'ATTACHMENTS_UPLOAD_OK',
  })

const findByMission = (id) =>
  get({
    url: `${ATTACHMENTS}${id}`,
    typeFetch: 'ATTACHMENTS',
    typeError: 'ATTACHMENTS_FAIL',
    typeResponse: 'ATTACHMENTS_OK',
  })

const removeById = (id) =>
  remove({
    url: `${ATTACHMENTS_REMOVE}/${id}`,
    typeFetch: 'ATTACHMENTS_REMOVE',
    typeError: 'ATTACHMENTS_REMOVE_FAIL',
    typeResponse: 'ATTACHMENTS_REMOVE_OK',
  })

export { upload, removeById, findByMission }
