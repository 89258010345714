import React from 'react'
import DatePicker from 'react-datepicker'

import { Input, Card, Form, Button, Select } from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'

const RegisterFilter = ({
  limit,
  customerId,
  categoryId,
  missionsMainId,

  ufsList,
  citiesList,
  customers,
  directions,
  categories,
  subCategories,
  handlerChange,
  initial,
  final,
  exportMissions,
  submitFilterRegister,
  kind,
}) => (
  <Card fluid>
    <Card.Content>
      <Form>
        <Form.Group widths="equal">
          <Form.Field
            control={Input}
            icon="search"
            className="icon"
            placeholder="Buscar..."
            onChange={(e, { value }) => handlerChange(value, 'search')}
          />
          <Form.Field
            control={Select}
            placeholder="Limite"
            selection
            value={limit}
            options={[
              { key: 0, value: 10, text: 10 },
              { key: 1, value: 15, text: 15 },
              { key: 2, value: 25, text: 25 },
              { key: 3, value: 50, text: 50 },
              { key: 4, value: 100, text: 100 },
            ]}
            onChange={(e, { value }) => handlerChange(value, 'limit')}
          />
          <Form.Field
            control={Select}
            search
            selection
            placeholder="Clientes"
            options={customers}
            value={customerId}
            onChange={(e, { value }) => handlerChange(value, 'customer_id')}
          />
          <Form.Field
            control={Select}
            search
            selection
            placeholder="Categoria"
            options={categories}
            value={categoryId}
            onChange={(e, { value }) => handlerChange(value, 'category_id')}
          />
          {subCategories.length > 0 && (
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Sub-Categoria"
              options={subCategories}
              value={missionsMainId}
              onChange={(e, { value }) =>
                handlerChange(value, 'missions_main_id')
              }
            />
          )}
        </Form.Group>
        <Form.Group>
          <Form.Field
            control={Select}
            search
            selection
            multiple
            options={ufsList}
            placeholder="UF"
            onChange={(e, { value }) => handlerChange(value, 'uf')}
          />
          {citiesList.length > 0 && (
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Cidades"
              options={citiesList}
              onChange={(e, { value }) => handlerChange(value, 'city')}
            />
          )}
          <Form.Field
            control={Select}
            search
            selection
            placeholder="Ordenar Por"
            options={directions}
            value={kind}
            onChange={(e, { value }) => handlerChange(value, 'kind')}
          />
          <Form.Field
            selectsStart
            control={DatePicker}
            dateFormat="dd/MM/yyyy"
            placeholderText="Data Inicio"
            selected={initial}
            startDate={initial}
            endDate={final}
            onChange={(value) => handlerChange(value, 'initial')}
          />
          <Form.Field
            control={DatePicker}
            id="endDate"
            selectsEnd
            dateFormat="dd/MM/yyyy"
            placeholderText="Data Fim"
            selected={final}
            startDate={initial}
            endDate={final}
            onChange={(value) => handlerChange(value, 'final')}
          />
        </Form.Group>
      </Form>
    </Card.Content>
    <Card.Content>
      <Button
        icon="check"
        color="green"
        content="Filtrar"
        labelPosition="left"
        onClick={submitFilterRegister}
      />
      <Button
        color="olive"
        labelPosition="left"
        onClick={exportMissions}
        icon="file excel outline"
        content="Exportar (.XLSX)"
      />
    </Card.Content>
  </Card>
)
export default RegisterFilter
