import { LOGS } from '~/services/api/endpoints'
import { get } from '~/utils/actionsRequests'

const findAll = () =>
  get({
    url: LOGS,
    typeFetch: 'LOGS',
    typeError: 'LOGS_FAIL',
    typeResponse: 'LOGS_OK',
  })

export { findAll }
