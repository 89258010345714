import {
  USERS_LEADER_FETCH,
  USERS_LEADER_CREATE,
  USERS_LEADER_UPDATE,
  USERS_LEADER_REMOVE,
} from '~/services/api/endpoints'
import { get, put, post, remove } from '~/utils/actionsRequests'

const create = (data) =>
  post({
    data,
    url: USERS_LEADER_CREATE,
    typeFetch: 'USERS_LEADER_CREATE',
    typeError: 'USERS_LEADER_CREATE_FAIL',
    typeResponse: 'USERS_LEADER_CREATE_OK',
  })

const update = (data, id) =>
  put({
    data,
    url: USERS_LEADER_UPDATE(id),
    typeFetch: 'USERS_LEADER_UPDATE',
    typeError: 'USERS_LEADER_UPDATE_FAIL',
    typeResponse: 'USERS_LEADER_UPDATE_OK',
  })

const fetch = (query) =>
  get({
    url: `${USERS_LEADER_FETCH}${query}`,
    typeFetch: 'USERS_LEADER_FETCH',
    typeError: 'USERS_LEADER_FETCH_FAIL',
    typeResponse: 'USERS_LEADER_FETCH_OK',
  })

const removeById = (id, badgeId) =>
  remove({
    url: USERS_LEADER_REMOVE(id, badgeId),
    typeFetch: 'USERS_LEADER_REMOVE',
    typeError: 'USERS_LEADER_REMOVE_FAIL',
    typeResponse: 'USERS_LEADER_REMOVE_OK',
  })

export { fetch, create, update, removeById }
