import {
  MISSIONS_MAIN_VALUE_CREATE,
  MISSIONS_MAIN_VALUE_FETCH,
} from '~/services/api/endpoints'
import { get, post, remove, put } from '~/utils/actionsRequests'

const missionsMainValues = (id) =>
  get({
    url: `${MISSIONS_MAIN_VALUE_FETCH}${id}/missions_main/fetch`,
    typeFetch: 'MISSIONS_MAIN_VALUE_FETCH',
    typeError: 'MISSIONS_MAIN_VALUE_FETCH_FAIL',
    typeResponse: 'MISSIONS_MAIN_VALUE_FETCH_OK',
  })

const missionsMainValuesCreate = (data) =>
  post({
    data,
    url: MISSIONS_MAIN_VALUE_CREATE,
    typeFetch: 'MISSIONS_MAIN_VALUE_CREATE',
    typeError: 'MISSIONS_MAIN_VALUE_CREATE_FAIL',
    typeResponse: 'MISSIONS_MAIN_VALUE_CREATE_OK',
  })

const missionsMainValuesUpdate = (data) =>
  put({
    data,
    url: `${MISSIONS_MAIN_VALUE_FETCH}${data.uid}/update`,
    typeFetch: 'MISSIONS_MAIN_VALUE_UPDATE',
    typeError: 'MISSIONS_MAIN_VALUE_UPDATE_FAIL',
    typeResponse: 'MISSIONS_MAIN_VALUE_UPDATE_OK',
  })

const missionsMainValuesRemove = (id) =>
  remove({
    url: `${MISSIONS_MAIN_VALUE_FETCH}${id}/remove`,
    typeFetch: 'MISSIONS_MAIN_VALUE_REMOVE',
    typeError: 'MISSIONS_MAIN_VALUE_REMOVE_FAIL',
    typeResponse: 'MISSIONS_MAIN_VALUE_REMOVE_OK',
  })

export {
  missionsMainValues,
  missionsMainValuesUpdate,
  missionsMainValuesRemove,
  missionsMainValuesCreate,
}
