import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import Accounts from '~/pages/Accounts'
import Badges from '~/pages/Badge/Badges'
import BadgeDetail from '~/pages/Badge/Detail'
import BadgeForm from '~/pages/Badge/Form'
import Budget from '~/pages/Budget'
import Campaign from '~/pages/Campaign'
import Categories from '~/pages/Categories'
import CategoriesDetail from '~/pages/Categories/Detail'
import CreditRequest from '~/pages/CreditRequest/CreditRequest'
import Customers from '~/pages/Customers'
import CustomersDetail from '~/pages/CustomersDetail'
import Dashboard from '~/pages/Dashboard'
import FormsItems from '~/pages/Forms/Detail'
import Forms from '~/pages/Forms/Forms'
import FormsUsersExtra from '~/pages/FormsUsersExtra'
import FormsUsersExtraDetail from '~/pages/FormsUsersExtra/Detail'
import Imports from '~/pages/Imports'
import ImportsFiles from '~/pages/Imports/Details'
import ImportsProductsPDV from '~/pages/ImportsProductsPDV'
import LeadersBySubcategory from '~/pages/LeadersBySubcategory'
import Login from '~/pages/Login'
import Logs from '~/pages/Logs/Logs'
import Metrics from '~/pages/Metrics'
import Missions from '~/pages/Missions'
import Expiring from '~/pages/Missions/Expiring'
import MissionsOpen from '~/pages/Missions/Open'
import NotFound from '~/pages/NotFound'
import Promotions from '~/pages/Promotions'
import RegistersDetail from '~/pages/Registers/Detail'
import Photos from '~/pages/Registers/Photos'
import Registers from '~/pages/Registers/Registers'
import SubCategories from '~/pages/Subcategories'
import CustomStatus from '~/pages/Subcategories/CustomStatus'
import SubCategoriesDetail from '~/pages/Subcategories/Detail'
import SubCategoriesValues from '~/pages/Subcategories/SubCategoriesValues'
import Users from '~/pages/Users'
import Consultants from '~/pages/Users/Consultants'
import UsersDetail from '~/pages/Users/Detail'
import UserBadges from '~/pages/UsersBadges'

import Route from './Route'

const Routes = () => (
  <Switch>
    <Route exact name="login" path="/" component={Login} />
    <Route
      isPrivate
      exact
      name="dashboard"
      path="/dashboard"
      component={Dashboard}
    />
    <Route isPrivate exact name="metrics" path="/metrics" component={Metrics} />
    <Route isPrivate exact name="badges" path="/badges" component={Badges} />
    <Route
      isPrivate
      exact
      name="badges"
      path="/badges/:id"
      component={BadgeDetail}
    />
    <Route
      isPrivate
      exact
      name="badges"
      path="/badges/:id/form"
      component={BadgeForm}
    />
    <Route
      isPrivate
      exact
      name="formsUsersExtra"
      path="/forms-users-extra"
      component={FormsUsersExtra}
    />
    <Route
      isPrivate
      exact
      name="formsUsersExtra"
      path="/forms-users-extra/:id"
      component={FormsUsersExtraDetail}
    />
    <Route isPrivate exact name="forms" path="/forms" component={Forms} />
    <Route
      isPrivate
      exact
      name="formsItems"
      path="/forms/:id"
      component={FormsItems}
    />
    <Route isPrivate exact name="budget" path="/budget" component={Budget} />
    <Route
      isPrivate
      exact
      name="missions"
      path="/missions"
      component={Missions}
    />
    <Route
      isPrivate
      exact
      name="missions"
      path="/imports"
      component={Imports}
    />
    <Route
      isPrivate
      exact
      name="missions"
      path="/imports/:importFileId"
      component={ImportsFiles}
    />
    <Route
      isPrivate
      exact
      name="import-products"
      path="/import-products"
      component={ImportsProductsPDV}
    />
    <Route
      isPrivate
      exact
      name="missions"
      path="/missions/expiring"
      component={Expiring}
    />
    <Route
      isPrivate
      exact
      name="registers"
      path="/registers/"
      component={Registers}
    />
    <Route
      isPrivate
      exact
      name="promotions"
      path="/promotions"
      component={Promotions}
    />
    <Route
      isPrivate
      exact
      name="accounts"
      path="/accounts"
      component={Accounts}
    />
    <Route isPrivate exact name="logs" path="/logs" component={Logs} />
    <Route
      isPrivate
      exact
      name="categories"
      path="/categories"
      component={Categories}
    />
    <Route
      isPrivate
      exact
      name="categories"
      component={CategoriesDetail}
      path="/categories/:categoryId"
    />
    <Route
      isPrivate
      exact
      name="categories"
      component={SubCategories}
      path="/categories/:categoryId/sub-categories"
    />
    <Route
      isPrivate
      exact
      name="categories"
      component={SubCategoriesDetail}
      path="/categories/:categoryId/sub-categories/:subcategoryId"
    />
    <Route
      isPrivate
      exact
      name="categories"
      path="/categories/:categoryId/sub-categories/:subcategoryId/custom-status"
      component={CustomStatus}
    />
    <Route
      isPrivate
      exact
      name="categories"
      component={SubCategoriesValues}
      path="/categories/:categoryId/sub-categories-values/:subcategoryId"
    />
    <Route
      isPrivate
      exact
      name="leadersBySubcategories"
      component={LeadersBySubcategory}
      path="/leaders/:subcategoryId"
    />
    <Route
      isPrivate
      exact
      name="requests"
      path="/requests"
      component={CreditRequest}
    />
    <Route
      isPrivate
      exact
      name="customers"
      path="/customers"
      component={Customers}
    />
    <Route
      isPrivate
      exact
      name="customers"
      path="/customers/:customerId"
      component={CustomersDetail}
    />
    <Route isPrivate exact name="users" path="/users" component={Users} />
    <Route
      isPrivate
      exact
      name="users"
      path="/user/:id"
      component={UsersDetail}
    />
    <Route
      isPrivate
      exact
      name="users"
      path="/user/:id/badges"
      component={UserBadges}
    />
    <Route
      isPrivate
      exact
      name="users"
      path="/user/:id/consultants"
      component={Consultants}
    />
    <Route
      isPrivate
      exact
      name="missions"
      path="/missions/open"
      component={MissionsOpen}
    />
    <Route
      isPrivate
      exact
      name="registers"
      path="/register/:id"
      component={RegistersDetail}
    />
    <Route
      isPrivate
      exact
      name="photos"
      path="/register/:id/photos/:missionsUsersId"
      component={Photos}
    />
    <Route
      isPrivate
      exact
      name="campaign"
      path="/campaign"
      component={Campaign}
    />

    <Route exact path="/404" component={NotFound} />
    <Redirect to="/404" />
  </Switch>
)

export default Routes
