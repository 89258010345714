import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'

import CustomerPaymentConfig from '~/components/CustomerPaymentConfig/index'

export default function Payment(props) {
  const { paymentConfig, submitUpdateConfig } = props
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(false)
  }, [paymentConfig])

  const updateConfig = (configForm) => {
    setIsLoading(true)
    submitUpdateConfig(configForm)
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} computer={8}>
          <CustomerPaymentConfig
            customerPaymentConfig={paymentConfig}
            isLoading={isLoading}
            onSubmit={updateConfig}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
Payment.propTypes = {
  paymentConfig: PropTypes.exact({
    customerPaymentType: PropTypes.number,
    limit: PropTypes.string,
  }),
  submitUpdateConfig: PropTypes.func,
}
