import { responseData } from '~/utils/reducer'

const PHOTOS = 'PHOTOS'
const PHOTOS_COUNT = 'PHOTOS_COUNT'
const PHOTOS_REMOVE = 'PHOTOS_REMOVE'
const PHOTOS_ROTATE = 'PHOTOS_ROTATE'
const PHOTOS_CREATE = 'PHOTOS_CREATE'

const photos = responseData(PHOTOS, 'array')
const photosCount = responseData(PHOTOS_COUNT, 'object')
const photosRemove = responseData(PHOTOS_REMOVE, 'object')
const photosCreate = responseData(PHOTOS_CREATE, 'object')
const photosRotate = responseData(PHOTOS_ROTATE, 'boolean')

export default {
  photos,
  photosCount,
  photosCreate,
  photosRemove,
  photosRotate,
}
