import React, { useState, useEffect } from 'react'

import { format } from 'date-fns'
import t from 'prop-types'
import { Card, Image, Button, List, Label, Divider } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'

import ImageAvatar from '~/assets/avatar.png'
import api from '~/services/api'
import { MGM_FIND_HOST } from '~/services/api/endpoints'
import { formatPrice } from '~/utils/format'

export default function UserInfo({ userDetail, userExtraData, openModal }) {
  const [hostUser, setHostUser] = useState(null)

  useEffect(() => {
    if (userDetail.uid) {
      ;(async () => {
        try {
          const res = await api.get(MGM_FIND_HOST(userDetail.uid))
          setHostUser(res.data.data[0])
        } catch (error) {
          console.log(error)
        }
      })()
    }
  }, [userDetail])

  const { balance, ma, paid, pav, ppv, rap, rn, rp } = userExtraData

  const infos = [
    { name: 'Atividades', value: ma, type: 'amount' },
    { name: 'Rg. Pendentes', value: rap, type: 'currency' },
    { name: 'Rg. Aprovados', value: rp, type: 'currency' },
    { name: 'Rg. Reprovados', value: rn, type: 'currency' },
    { name: 'Saldo', value: balance, type: 'currency' },
    { name: 'Arrecadou', value: paid, type: 'currency' },
    { name: 'Pg. Aprovados', value: pav, type: 'currency' },
    { name: 'Pg. Pendentes', value: ppv, type: 'currency' },
  ]

  return (
    <Card fluid>
      <Image src={userDetail.avatar || ImageAvatar} wrapped ui={false} />
      <Card.Content>
        <Card.Header>{userDetail.name}</Card.Header>
        <Card.Meta>
          <span className="date">
            Último acesso{' '}
            {format(new Date(userDetail.last_access), 'dd/MM/yyyy HH:mm')}
          </span>
        </Card.Meta>
        <Card.Description>{userDetail.email}</Card.Description>
        <Card.Description>{userDetail.phone}</Card.Description>
        {hostUser && (
          <>
            <Divider />
            <Card.Description>
              Convidado por:{' '}
              <a href={`/user/${hostUser?.host_id}`} target="blank">
                {hostUser?.name}
              </a>
            </Card.Description>
            <Card.Description>
              Comissão gerada: {formatPrice(hostUser?.total)}
            </Card.Description>
            <Divider />
          </>
        )}
        <Button
          fluid
          compact
          color="blue"
          content="Editar Dados"
          onClick={() => openModal()}
          style={{ marginTop: '10px' }}
        />
      </Card.Content>
      <Card.Content extra>
        <List selection>
          {infos.map((item) => (
            <List.Item key={uuidv4()}>
              <Label color="grey" horizontal style={{ float: 'right' }}>
                {item.type === 'currency'
                  ? formatPrice(item.value)
                  : item.value}
              </Label>
              {item.name}
            </List.Item>
          ))}
        </List>
      </Card.Content>
    </Card>
  )
}

UserInfo.propTypes = {
  userDetail: t.objectOf(t.any),
  userExtraData: t.objectOf(t.any),
  openModal: t.func,
}
