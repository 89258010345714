import React from 'react'

import { Form, Modal, Button } from 'semantic-ui-react'

const FormsDetail = ({
  close,
  openModal,
  formNameEdit,
  submitUpdate,
  handleChangeForm,
}) => (
  <Modal size="small" open={openModal} onClose={openModal}>
    <Modal.Header>{formNameEdit}</Modal.Header>
    <Modal.Content>
      <Form>
        <Form.Input
          type="text"
          id="formNameEdit"
          onChange={handleChangeForm}
          value={formNameEdit || ''}
          label="Nome do Formulário"
          placeholder="Ex: Formulário 1"
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button content="Cancelar" onClick={close} />
      <Button
        positive
        icon="checkmark"
        content="Atualizar"
        labelPosition="right"
        onClick={submitUpdate}
      />
    </Modal.Actions>
  </Modal>
)

export default FormsDetail
