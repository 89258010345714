import React, { useState, memo } from 'react'

import PropTypes from 'prop-types'
import { length, isNil } from 'ramda'
import { Table, Loader, Pagination } from 'semantic-ui-react'

import translate from '~/assets/i18n/ptBr'

import EmptyList from './EmptyList'

const TableComponent = ({
  data,
  columns,
  isLoading,
  count,
  totalCount,
  activePage,
  siblingRange,
  emptyText,
  offset,
  renderItem,
  boundaryRange,
  showEllipsis,
  showFirstAndLastNav,
  showPreviousAndNextNav,
  handlePaginationChange,
  handleColumnSort,
}) => {
  const [filters, setFilters] = useState({ col: null, order: 'ascending' })

  const firstSort = (clickedColumn) => {
    setFilters({ col: clickedColumn, order: 'ascending' })
    handleColumnSort(clickedColumn, 'asc')
  }

  const reverseSort = () => {
    const { col, order } = filters
    const newDirection = order === 'ascending' ? 'descending' : 'ascending'
    const dir = order === 'ascending' ? 'desc' : 'asc'
    setFilters({ ...filters, order: newDirection })
    handleColumnSort(col, dir)
  }

  const sortHandler = (clickedColumn) => {
    if (
      handleColumnSort &&
      clickedColumn.toLowerCase() !== 'identificador' &&
      clickedColumn.toLowerCase() !== 'criador' &&
      clickedColumn.toLowerCase() !== 'id' &&
      clickedColumn.toLowerCase() !== 'ações'
    ) {
      if (clickedColumn !== filters.col) {
        return firstSort(clickedColumn)
      }

      return reverseSort(clickedColumn)
    }
  }

  return (
    <div style={{ marginTop: 10 }}>
      {isLoading ? (
        <div>
          <Loader active inline="centered" />
        </div>
      ) : (
        <div className="container">
          <Table
            celled
            sortable={
              typeof handleColumnSort === 'function' &&
              handleColumnSort !== undefined
            }
          >
            <Table.Header>
              <Table.Row>
                {columns.map((name, index) => (
                  <Table.HeaderCell
                    key={`key-${index}`}
                    onClick={() => sortHandler(name)}
                    content={translate[name] || name}
                    sorted={filters.col === name ? filters.order : null}
                  />
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {length(data) > 0 ? (
                data.map(renderItem)
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={length(columns)}>
                    <EmptyList {...emptyText} />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={length(columns)}>
                  {!isNil(count) && (
                    <>
                      <Pagination
                        totalPages={parseInt(count, 0)}
                        activePage={activePage}
                        siblingRange={siblingRange}
                        boundaryRange={boundaryRange}
                        onPageChange={handlePaginationChange}
                        ellipsisItem={showEllipsis ? undefined : null}
                        firstItem={showFirstAndLastNav ? undefined : null}
                        lastItem={showFirstAndLastNav ? undefined : null}
                        prevItem={showPreviousAndNextNav ? undefined : null}
                        nextItem={showPreviousAndNextNav ? undefined : null}
                      />
                      <div
                        style={{
                          marginLeft: '10px',
                          display: 'inline',
                          fontWeight: 'bold',
                        }}
                      >
                        {Number(offset)} de {Number(totalCount)} total
                      </div>
                    </>
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      )}
    </div>
  )
}

TableComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  columns: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  count: PropTypes.number,
  totalCount: PropTypes.number,
  activePage: PropTypes.number,
  siblingRange: PropTypes.number,
  emptyText: PropTypes.objectOf(PropTypes.any),
  offset: PropTypes.number,
  renderItem: PropTypes.func,
  boundaryRange: PropTypes.number,
  showEllipsis: PropTypes.bool,
  showFirstAndLastNav: PropTypes.bool,
  showPreviousAndNextNav: PropTypes.bool,
  handlePaginationChange: PropTypes.func,
  handleColumnSort: PropTypes.func,
}

export default memo(TableComponent)
