import {
  FORMS_ITEMS_MULTIPLES_CREATE,
  FORMS_ITEMS_MULTIPLES_REMOVE,
  FORMS_ITEMS_MULTIPLES_UPDATE_ORDER,
} from '~/services/api/endpoints'
import { post, put, remove } from '~/utils/actionsRequests'

const changeOrder = (data) =>
  put({
    data,
    url: `${FORMS_ITEMS_MULTIPLES_UPDATE_ORDER}`,
    typeFetch: 'FORMS_ITEMS_MULTIPLES_UPDATE_ORDER',
    typeError: 'FORMS_ITEMS_MULTIPLES_UPDATE_ORDER_FAIL',
    typeResponse: 'FORMS_ITEMS_MULTIPLES_UPDATE_ORDER_OK',
  })

const create = (data) =>
  post({
    data,
    url: `${FORMS_ITEMS_MULTIPLES_CREATE}`,
    typeFetch: 'FORMS_ITEMS_MULTIPLES_CREATE',
    typeError: 'FORMS_ITEMS_MULTIPLES_CREATE_FAIL',
    typeResponse: 'FORMS_ITEMS_MULTIPLES_CREATE_OK',
  })

const removeById = ({ id, customerId }) =>
  remove({
    url: `${FORMS_ITEMS_MULTIPLES_REMOVE}${id}/${customerId}`,
    typeFetch: 'FORMS_ITEMS_MULTIPLES_REMOVE',
    typeError: 'FORMS_ITEMS_MULTIPLES_REMOVE_FAIL',
    typeResponse: 'FORMS_ITEMS_MULTIPLES_REMOVE_OK',
  })

export { create, removeById, changeOrder }
