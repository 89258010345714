import React from 'react'
import DatePicker from 'react-datepicker'

import { Card, Form, Button, Select } from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'

export default function Filter({
  final,
  initial,
  category_id,
  customer_id,
  missions_main_id,

  customers,
  categories,
  subCategories,
  handlerChange,
  submit,
}) {
  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Clientes"
              options={customers}
              value={customer_id}
              onChange={(e, { value }) => handlerChange(value, 'customer_id')}
            />
            <Form.Field
              control={Select}
              search
              selection
              placeholder="Categoria"
              options={categories}
              value={category_id}
              onChange={(e, { value }) => handlerChange(value, 'category_id')}
            />
            {subCategories.length > 0 && (
              <Form.Field
                control={Select}
                search
                selection
                placeholder="Sub-Categoria"
                options={subCategories}
                value={missions_main_id}
                onChange={(e, { value }) =>
                  handlerChange(value, 'missions_main_id')
                }
              />
            )}
            <Form.Field
              selectsStart
              control={DatePicker}
              dateFormat="dd/MM/yyyy"
              placeholderText="Data Inicio"
              selected={initial}
              startDate={initial}
              endDate={final}
              onChange={(value) => handlerChange(value, 'initial')}
            />
            <Form.Field
              control={DatePicker}
              id="endDate"
              selectsEnd
              dateFormat="dd/MM/yyyy"
              placeholderText="Data Fim"
              selected={final}
              startDate={initial}
              endDate={final}
              onChange={(value) => handlerChange(value, 'final')}
            />
          </Form.Group>
        </Form>
      </Card.Content>
      <Card.Content>
        <Button
          icon="check"
          color="green"
          content="Filtrar"
          labelPosition="left"
          onClick={submit}
        />
      </Card.Content>
    </Card>
  )
}
