import React from 'react'

import t from 'prop-types'
import { Table } from 'semantic-ui-react'
import { v4 } from 'uuid'

const Item = ({ item }) => (
  <Table.Row key={v4()}>
    <Table.Cell>{item.father_id}</Table.Cell>
    <Table.Cell>{item.children_father_id}</Table.Cell>
    <Table.Cell>{item.description}</Table.Cell>
  </Table.Row>
)

Item.propTypes = {
  item: t.objectOf(t.any),
}

export default Item
