import React from 'react'

import PropTypes from 'prop-types'
import { Modal, List, Loader, Button } from 'semantic-ui-react'

export default function FormsResponse({
  openFormsModal,
  close,
  isLoading,
  formsItems,
}) {
  return (
    <Modal size="small" open={openFormsModal} onClose={close}>
      <Modal.Header content="Respostas" />
      <Modal.Content scrolling>
        <List>
          {isLoading ? (
            <div>
              <Loader active inline="centered" />
            </div>
          ) : (
            formsItems.map((item, index) => {
              return (
                <List.Item key={`key-${index}`}>
                  <List.Header content={`Seção ${item.section}`} />
                  <List>
                    {item.items.map((i, idx) => {
                      return (
                        <List.Item key={`items-${idx}`}>
                          {i.answer.trim() && (
                            <>
                              <List.Header
                                content={`${i.order}. ${i.question}`}
                              />
                              R: {i.answer}
                            </>
                          )}
                        </List.Item>
                      )
                    })}
                  </List>
                </List.Item>
              )
            })
          )}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close}>Ok</Button>
      </Modal.Actions>
    </Modal>
  )
}

FormsResponse.propTypes = {
  openFormsModal: PropTypes.func,
  close: PropTypes.func,
  isLoading: PropTypes.bool,
  formsItems: PropTypes.arrayOf(PropTypes.object),
}
