import React from 'react'

import { Grid, Card, Statistic } from 'semantic-ui-react'

const RegisterHeaderData = ({ status, data, handlerChange }) => (
  <Grid columns={5} relaxed>
    <Grid.Row>
      <Grid.Column>
        <Card
          fluid
          style={{ border: status === 4 && '3px solid #2185d0' }}
          as="a"
          onClick={e => handlerChange(4, 'status')}
        >
          <Card.Content textAlign="center">
            <Statistic color="blue">
              <Statistic.Value>
                {data.missions_rejected_by_analysis}
              </Statistic.Value>
              <Statistic.Label>Em Correção</Statistic.Label>
            </Statistic>
          </Card.Content>
        </Card>
      </Grid.Column>
      <Grid.Column>
        <Card
          fluid
          style={{ border: status === 3 && '3px solid #f2711c' }}
          as="a"
          onClick={e => handlerChange(3, 'status')}
        >
          <Card.Content textAlign="center">
            <Statistic color="orange">
              <Statistic.Value>{data.registers_analyze_count}</Statistic.Value>
              <Statistic.Label>Em Análise</Statistic.Label>
            </Statistic>
          </Card.Content>
        </Card>
      </Grid.Column>
      <Grid.Column>
        <Card
          fluid
          style={{ border: status === 0 && '3px solid #fbbd08' }}
          as="a"
          onClick={e => handlerChange(0, 'status')}
        >
          <Card.Content textAlign="center">
            <Statistic color="yellow">
              <Statistic.Value>{data.registers_pendent_count}</Statistic.Value>
              <Statistic.Label>Pendentes</Statistic.Label>
            </Statistic>
          </Card.Content>
        </Card>
      </Grid.Column>
      <Grid.Column>
        <Card
          fluid
          style={{ border: status === 1 && '3px solid #21ba45' }}
          as="a"
          onClick={e => handlerChange(1, 'status')}
        >
          <Card.Content textAlign="center">
            <Statistic color="green">
              <Statistic.Value>{data.registers_approved_count}</Statistic.Value>
              <Statistic.Label>Aprovados</Statistic.Label>
            </Statistic>
          </Card.Content>
        </Card>
      </Grid.Column>
      <Grid.Column>
        <Card
          fluid
          style={{ border: status === 2 && '3px solid #db2828' }}
          as="a"
          onClick={e => handlerChange(2, 'status')}
        >
          <Card.Content textAlign="center">
            <Statistic color="red">
              <Statistic.Value>{data.registers_reproved_count}</Statistic.Value>
              <Statistic.Label>Reprovados</Statistic.Label>
            </Statistic>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

export default RegisterHeaderData
