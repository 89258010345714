import React, { useState, memo, useEffect } from 'react'

import PropTypes from 'prop-types'
import { Card, Icon, Form, Grid, Button, Select } from 'semantic-ui-react'

import message from '~/utils/messages'

const configOptions = [
  { key: 1, text: 'Pós-pago Normal', value: 1 },
  { key: 2, text: 'Pós-pago Desonerado', value: 2 },
  { key: 3, text: 'Pré-pago Normal', value: 3 },
  { key: 4, text: 'Pré-pago Desonerado', value: 4 },
]

function CustomerPaymentConfig({ customerPaymentConfig, onSubmit, isLoading }) {
  const [config, setConfig] = useState({})

  useEffect(() => {
    setConfig((oldConfig) => ({ ...oldConfig, ...customerPaymentConfig }))
  }, [customerPaymentConfig])

  function onInputChange(_, { id, value }) {
    setConfig((oldConfig) => ({ ...oldConfig, [id]: value }))
  }

  const isFormValid = () => {
    if (config.customerPaymentType === null) {
      message().error('Escolha um modelo de pagamento!')
      return false
    }

    if (config.customerPaymentType === 1 || config.customerPaymentType === 2) {
      if (!config.limit) {
        message().error('O limite não pode estar vazio!')
        return false
      }
      if (Number(config.limit) === 0) {
        message().error('O limite deve ser maior que zero!')
        return false
      }
    }

    return true
  }

  const submitForm = () => {
    if (isFormValid()) {
      onSubmit({
        limit: config.limit,
        customer_payment_type: config.customerPaymentType,
      })
    }
  }

  return (
    <Card centered fluid>
      <Card.Content>
        <Card.Header>Configuração de Pagamento</Card.Header>
      </Card.Content>
      <Card.Content>
        <Form>
          <Grid relaxed>
            <Grid.Column>
              <Form.Field
                id="customerPaymentType"
                control={Select}
                options={configOptions}
                onChange={onInputChange}
                value={config.customerPaymentType || ''}
                label="Tipo de Pagamento"
                placeholder="Tipo de Pagamento"
              />
              {(config.customerPaymentType === 1 ||
                config.customerPaymentType === 2) && (
                <Form.Input
                  id="limit"
                  type="number"
                  value={config.limit || ''}
                  onChange={onInputChange}
                  label="Limite (Obrigatório)"
                  placeholder="Ex: 100"
                />
              )}
            </Grid.Column>
          </Grid>
        </Form>
      </Card.Content>
      <Card.Content extra textAlign="right">
        <Button
          icon
          color="green"
          onClick={() => submitForm()}
          loading={isLoading}
          disabled={isLoading}
          labelPosition="left"
        >
          <Icon name="check" /> Salvar
        </Button>
      </Card.Content>
    </Card>
  )
}

CustomerPaymentConfig.propTypes = {
  customerPaymentConfig: PropTypes.shape({
    customerPaymentType: PropTypes.number,
    limit: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default memo(CustomerPaymentConfig)
