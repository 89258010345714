import { GOOGLE_API_KEY } from '~/config/env'
import {
  MISSIONS,
  MISSIONS_OPEN,
  MISSIONS_CREATE,
  MISSIONS_PLACES,
  MISSIONS_UPDATE,
  GOOGLE_GEOCODING,
  MISSION_FEEDS_BY_USER,
  MISSIONS_USERS_EXPIRING,
  MISSIONS_USERS_EXPIRING_REMOVE,
  MISSIONS_USERS_EXPIRING_EXTEND,
  MISSIONS_PARTICIPANTS,
  MISSIONS_UPDATE_STATUS,
} from '~/services/api/endpoints'
import { get, put, post, getAdress, remove } from '~/utils/actionsRequests'

const create = (data) =>
  post({
    data,
    url: MISSIONS_CREATE,
    typeFetch: 'MISSIONS_CREATE',
    typeError: 'MISSIONS_CREATE_FAIL',
    typeResponse: 'MISSIONS_CREATE_OK',
  })

const findById = (query) =>
  get({
    url: `${MISSIONS}?${query}`,
    typeFetch: 'MISSIONS',
    typeError: 'MISSIONS_FAIL',
    typeResponse: 'MISSIONS_OK',
  })

const findOpen = (query) =>
  get({
    url: `${MISSIONS_OPEN}?${query}`,
    typeFetch: 'MISSIONS_OPEN',
    typeError: 'MISSIONS_OPEN_FAIL',
    typeResponse: 'MISSIONS_OPEN_OK',
  })

const findParticipantsById = ({ id }) =>
  get({
    url: `${MISSIONS_PARTICIPANTS}${id}`,
    typeFetch: 'MISSIONS_PARTICIPANTS',
    typeError: 'MISSIONS_PARTICIPANTS_FAIL',
    typeResponse: 'MISSIONS_PARTICIPANTS_OK',
  })

const userMissionsFeedsCount = (id) =>
  get({
    url: `${MISSION_FEEDS_BY_USER}?missionsUsersId=${id}`,
    typeFetch: 'FETCH_USER_FEEDS_COUNT',
    typeError: 'FETCH_USER_FEEDS_COUNT_FAIL',
    typeResponse: 'FETCH_USER_FEEDS_COUNT_OK',
  })

const places = ({ id, limit }) =>
  get({
    url: `${MISSIONS_PLACES}?customerId=${id}&limit=${limit}`,
    typeFetch: 'MISSIONS_PLACES',
    typeError: 'MISSIONS_PLACES_FAIL',
    typeResponse: 'MISSIONS_PLACES_OK',
  })

const update = (data, uid) =>
  put({
    data,
    url: `${MISSIONS_UPDATE}${uid}`,
    typeFetch: 'UPDATE_MISSIONS',
    typeError: 'UPDATE_MISSIONS_FAIL',
    typeResponse: 'UPDATE_MISSIONS_OK',
  })

const updateStatus = (data) =>
  put({
    data,
    url: MISSIONS_UPDATE_STATUS,
    typeFetch: 'UPDATE_MISSIONS_STATUS',
    typeError: 'UPDATE_MISSIONS_STATUS_FAIL',
    typeResponse: 'UPDATE_MISSIONS_STATUS_OK',
  })

const searchPlaces = (address) =>
  getAdress({
    url: `${GOOGLE_GEOCODING}?key=${GOOGLE_API_KEY}&address=${address}&language=pt-BR&components=country:BR`,
    typeFetch: 'SEARCH_PLACES',
    typeError: 'SEARCH_PLACES_FAIL',
    typeResponse: 'SEARCH_PLACES_OK',
  })

const missionsUsersExpiring = (query) =>
  get({
    url: `${MISSIONS_USERS_EXPIRING}${query}`,
    typeFetch: 'MISSIONS_USERS_EXPIRING',
    typeError: 'MISSIONS_USERS_EXPIRING_FAIL',
    typeResponse: 'MISSIONS_USERS_EXPIRING_OK',
  })

const removeMissionsUsers = (id) =>
  remove({
    url: MISSIONS_USERS_EXPIRING_REMOVE(id),
    typeFetch: 'MISSIONS_USERS_EXPIRING_REMOVE',
    typeError: 'MISSIONS_USERS_EXPIRING_REMOVE_FAIL',
    typeResponse: 'MISSIONS_USERS_EXPIRING_REMOVE_OK',
  })

const extendMissionsUsers = (item) =>
  put({
    data: { new_expiration: item.new_expiration },
    url: MISSIONS_USERS_EXPIRING_EXTEND(item.id),
    typeFetch: 'MISSIONS_USERS_EXPIRING_EXTEND',
    typeError: 'MISSIONS_USERS_EXPIRING_EXTEND_FAIL',
    typeResponse: 'MISSIONS_USERS_EXPIRING_EXTEND_OK',
  })

export {
  create,
  places,
  update,
  findOpen,
  findById,
  searchPlaces,
  updateStatus,
  removeMissionsUsers,
  extendMissionsUsers,
  missionsUsersExpiring,
  userMissionsFeedsCount,
  findParticipantsById,
}
