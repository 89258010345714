import React, { useEffect, useState } from 'react'

import { Modal, Button, Form } from 'semantic-ui-react'

import api from '~/services/api'
import { BADGET_FETCH } from '~/services/api/endpoints'
import message from '~/utils/messages'

export default ({ fields, handleUpdate, onPress, isLoading, open, close }) => {
  const [badges, setBadges] = useState([])

  useEffect(() => {
    ;(async () => {
      try {
        const res = await api.get(BADGET_FETCH)
        setBadges(
          res.data.data.map((i, key) => ({
            key,
            value: i.uid,
            text: i.title,
          }))
        )
      } catch (error) {
        console.log(error)
        message().error(error.response.data.data || 'Houve um erro no servidor')
      }
    })()
  }, [])

  return (
    <Modal size="small" open={open} centered={false}>
      <Modal.Content>
        <h2>Selecione o limite de pessoas do consultor líder</h2>
        <Form>
          <Form.Input
            type="number"
            placeholder="Ex: 10"
            value={fields.leaderLimit || ''}
            label="Limite de Consultores"
            onChange={({ target }) => handleUpdate(target, 'leaderLimit')}
          />
          <Form.Dropdown
            fluid
            search
            selection
            options={badges || []}
            placeholder="Escolha o badge..."
            onChange={(e, target) => handleUpdate(target, 'badgeId')}
          />
        </Form>
      </Modal.Content>
      <Modal.Content>
        <Button
          color="grey"
          onClick={close}
          content="Cancelar"
          loading={isLoading}
          disabled={isLoading}
        />
        <Button
          color="green"
          onClick={onPress}
          content="Ativar"
          loading={isLoading}
          disabled={isLoading}
        />
      </Modal.Content>
    </Modal>
  )
}
