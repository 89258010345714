import React from 'react'

import { Modal, Button, Form, Message } from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'

const UserBalance = ({
  fields,
  handlerInput,
  isLoading,
  submit,
  errors,
  open,
  close,
}) => (
  <Modal size="small" open={open} onClose={close}>
    <Modal.Header content="Atualizar Missão" />
    <Modal.Content>
      {errors.length > 0 && (
        <Message
          key={errors}
          warning
          fluid
          header="Alerta!"
          style={{ padding: 10 }}
          list={errors}
        />
      )}
      <Form>
        <Form.Input
          type="text"
          id="value"
          value={fields.value}
          disabled
          onChange={handlerInput}
          label="Valor (Obrigatório)"
        />
        <Form.Input
          type="text"
          id="userName"
          onChange={handlerInput}
          value={fields.userName || ''}
          label="Responsável pela Requisição"
          placeholder="Ex: João"
        />

        <Form.Input
          type="text"
          id="obs"
          onChange={handlerInput}
          value={fields.obs || ''}
          label="Observação"
          placeholder="Ex: Motivo do saque adiantado..."
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button negative onClick={close}>
        Cancelar
      </Button>
      <Button
        positive
        onClick={submit}
        icon="checkmark"
        loading={isLoading}
        disabled={isLoading}
        labelPosition="right"
        content="Enviar Requisição"
      />
    </Modal.Actions>
  </Modal>
)

export default UserBalance
