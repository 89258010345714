import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-semantic-toasts'

import { Card, Loader } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import EmptyList from '~/components/EmptyList'
import { findByIdUserDetail } from '~/store/modules/users/actions'
import {
  findBadgeByUser,
  create,
  removeById,
} from '~/store/modules/usersBadges/actions'

import Main from '../Main'
import BadgeItem from './components/BadgeItem'

export default function UserBadges(props) {
  const userId = useParams().id
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)

  const usersDetail = useSelector((store) => store.usersDetail)
  const usersBadges = useSelector((store) => store.usersBadges)

  const dispatch = useDispatch()

  const usersBadgesData = useSelector((store) => store.usersBadges)
  const usersBadgesCreate = useSelector((store) => store.usersBadgesCreate)
  const usersBadgesUpdate = useSelector((store) => store.usersBadgesUpdate)
  const usersBadgesRemove = useSelector((store) => store.usersBadgesRemove)

  const showToast = (description, type) => {
    toast(
      {
        description,
        title: 'Alerta',
        type: type ? 'success' : 'warning',
        icon: type ? 'check' : 'remove',
      },
      () => console.log('toast closed')
    )
  }

  const getUserBadge = useCallback(() => {
    return dispatch(findBadgeByUser(userId))
  }, []) //eslint-disable-line

  const createBadge = useCallback(
    (badgeId) => {
      dispatch(
        create({
          badge_id: badgeId,
          active: true,
          user_id: userId,
        })
      )
    },
    [dispatch, userId]
  )

  const removeBadge = useCallback(
    (usersBadgesId) => {
      dispatch(removeById(usersBadgesId))
    },
    [dispatch]
  )

  useEffect(() => {
    setIsLoading(true)

    dispatch(findByIdUserDetail(userId))
    getUserBadge()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (usersBadgesData.success) {
      setIsLoading(false)
    } else if (usersBadgesData.error) {
      showToast(usersBadgesData.message)
    }
  }, [usersBadgesData, getUserBadge, dispatch])

  useEffect(() => {
    if (usersBadgesCreate.success) {
      getUserBadge()
    } else if (usersBadgesCreate.error) {
      showToast(usersBadgesCreate.message)
    }
  }, [usersBadgesCreate, getUserBadge, dispatch])

  useEffect(() => {
    if (usersBadgesUpdate.success) {
      getUserBadge()
    } else if (usersBadgesUpdate.error) {
      showToast(usersBadgesUpdate.message)
    }
  }, [usersBadgesUpdate, getUserBadge, dispatch])

  useEffect(() => {
    if (usersBadgesRemove.success) {
      getUserBadge()
    } else if (usersBadgesRemove.error) {
      showToast(usersBadgesRemove.message)
    }
  }, [usersBadgesRemove, getUserBadge, dispatch])

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      name: 'Usuários',
      link: '/users',
    },
    {
      active: false,
      hasLink: false,
      name: usersDetail.data.name,
      link: `/user/${userId}`,
    },
    {
      active: true,
      hasLink: false,
      name: 'Badges',
    },
  ]

  return (
    <Main {...props}>
      <Breadcrumbs items={breadcrumbsList} history={history} />
      {isLoading && <Loader active inline="centered" />}
      <Card.Group>
        {!isLoading &&
          usersBadges.data.map((badge) => (
            <BadgeItem
              badge={badge}
              key={badge.badge_id}
              createBadge={createBadge}
              removeBadge={removeBadge}
            />
          ))}
      </Card.Group>
      {!isLoading && usersBadges.data.length === 0 && (
        <EmptyList icon="shield" text="Nenhum Badge..." />
      )}
    </Main>
  )
}
