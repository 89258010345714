import { METRICS } from '~/services/api/endpoints'
import { post } from '~/utils/actionsRequests'

const findMetrics = (data) =>
  post({
    data,
    url: `${METRICS}?xls=${data.xls}`,
    typeFetch: 'METRICS',
    typeError: 'METRICS_FAIL',
    typeResponse: 'METRICS_OK',
  })

export { findMetrics }
