import React, { memo, useState } from 'react'

import PropTypes from 'prop-types'
import { length } from 'ramda'
import { Card, Table, Checkbox, Input, Loader } from 'semantic-ui-react'

import EmptyList from '../EmptyList'

function CustomerCategories({ toggle, subCategories, isLoadingCategories }) {
  const [searchFilter, setSearchFilter] = useState('')

  const onSearch = (e, search) => {
    setSearchFilter(search.value)
  }

  const filteredCategories = subCategories.filter(
    (category) =>
      category.title.toLowerCase().includes(searchFilter) ||
      category.id.toString().toLowerCase().includes(searchFilter)
  )
  return (
    <Card centered fluid style={{ marginBottom: 20, overflowY: 'auto' }}>
      <Card.Content>
        <Card.Header>Selecionar Sub-categorias</Card.Header>
      </Card.Content>
      <Card.Content>
        <Input
          fluid
          name="search"
          style={{ width: '100%' }}
          icon="search"
          onChange={onSearch}
          placeholder="Pesquise por nome ou ID"
        />
      </Card.Content>
      <Table
        unstackable
        singleLine
        collapsing
        style={{ marginTop: 0, borderBottom: 0, borderLeft: 0, borderRight: 0 }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>NOME</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">ID ATIVIDADE</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!isLoadingCategories &&
            filteredCategories.map((item, index) => (
              <Table.Row key={`keys-${index}`}>
                {toggle && (
                  <Table.Cell>
                    <Checkbox
                      label={{ children: item.title }}
                      id={`item-${index}`}
                      value={item.id}
                      onChange={toggle}
                      checked={item.deleted}
                    />
                  </Table.Cell>
                )}
                <Table.Cell textAlign="right">{item.id}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {isLoadingCategories && (
        <Card.Content>
          <Loader active inline="centered" />
        </Card.Content>
      )}
      {!isLoadingCategories && length(subCategories) === 0 && (
        <EmptyList
          marginTop={20}
          icon="tag"
          text="Entre em contato conosco para adicionar as suas categorias."
        />
      )}
    </Card>
  )
}

CustomerCategories.propTypes = {
  toggle: PropTypes.func,
  subCategories: PropTypes.arrayOf(PropTypes.object),
  isLoadingCategories: PropTypes.bool,
}

export default memo(CustomerCategories)
