import React from 'react'

import { Button, List, Form, Modal } from 'semantic-ui-react'

export default function ModalAddress({
  open,
  list,
  item,
  close,
  address,
  changeAddress,
  handlerAddress,
}) {
  return (
    <Modal size="tiny" open={open} onClose={close}>
      <Modal.Header>Alterar Endereço</Modal.Header>
      <Modal.Content>
        <Form.Input
          fluid
          type="text"
          value={address}
          placeholder="Ex: Florianópolis"
          onChange={(e, { value }) => handlerAddress(value)}
        />
        {address.length > 0 && (
          <List selection verticalAlign="middle">
            {list.map((i, index) => {
              const { lat, lng } = i.geometry.location
              const data = {
                location: { lat, lng },
                formattedAddress: i.formatted_address,
              }
              return (
                <List.Item
                  key={`l-${index}`}
                  onClick={() => changeAddress({ data, item })}
                >
                  <List.Icon style={{ color: 'red' }} name="marker" />
                  <List.Content>
                    <List.Header as="a">{i.formatted_address}</List.Header>
                  </List.Content>
                </List.Item>
              )
            })}
          </List>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={close}>
          Cancelar
        </Button>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content="OK"
          onClick={close}
        />
      </Modal.Actions>
    </Modal>
  )
}
