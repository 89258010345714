import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import queryString from 'query-string'
import { head, isEmpty } from 'ramda'
import { Button, Confirm } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import CreateSubCategory from '~/components/CreateSubCategory'
import BadgesList from '~/components/Modal/BadgesList'
import TableComponent from '~/components/Table'
import Item from '~/pages/Subcategories/components/Item'
import { findAll } from '~/store/modules/badges/actions'
import {
  findSubcategories,
  subCategoriesUpdateStatus,
  subCategoriesCreate,
  removeSubCategorieById,
  subCategoriesUpdateBadge,
  subCategoriesUpdate,
} from '~/store/modules/categories/actions'
import { validURL } from '~/utils/helpers'

import '~/assets/css/react-draft-wysiwyg.css'

export default function SubCategories() {
  const history = useHistory()
  const location = useLocation()
  const { categoryId } = useParams()

  const [state, setState] = useState({
    limit: 10,
    offset: 0,
    search: 'offset=0&limit=10',
    activePage: 1,
    subCategories: [],
    siblingRange: 1,
    boundaryRange: 0,
    showEllipsis: true,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,
    categoryName: 'Sub-Categorias',

    open: false,
    openModal: false,
    close: false,
    show: false,
    success: true,
    isLoading: false,
    badgeId: '',
    badges: [],

    errors: [],
    removeId: '',
    subIndex: 0,
    isFilterLoading: false,

    title: '',
    avatar: '',
    image_path: '',
    description: '',
    fix_time: 0,
    user_value: 0,
    mission_commission_percent: 0,
    participants: 0,
    expiring_time: 0,
    customer_value: 0,
    time_to_appreciate: 0,
    can_restart: false,
    has_commission: false,
    show_only_if_badge: false,
    has_check_availability: false,
    has_forms: false,
    has_photo: false,
    no_location: false,
    has_scanner: false,
    has_timeline: false,
    has_attachment: false,
    can_multiple: false,
    has_products: false,
    has_import_image: false,
    has_auto_approval: false,
    has_value_variable: false,
    is_expiring_weekend: false,
    has_value_percentage: false,
    has_import_document: false,
    editorState: EditorState.createEmpty(),
  })
  const findBadges = useSelector((store) => store.findBadges)
  const subCategoriesData = useSelector((store) => store.getSubCategories)
  const removeSubCategory = useSelector((store) => store.removeSubCategory)
  const subCategoriesCreateData = useSelector(
    (store) => store.subCategoriesCreate
  )
  const updateBadgeData = useSelector((store) => store.subCategoriesUpdateBadge)
  const subCategoriesUpdateData = useSelector(
    (store) => store.subCategoriesUpdate
  )

  const dispatch = useDispatch()

  const uploadRef = useRef()
  const uploadBackRef = useRef()

  const changeState = useCallback(
    (prop) => {
      setState({ ...state, ...prop })
    },
    [state]
  )

  const getSubcategories = useCallback(
    (searchString) => {
      dispatch(findSubcategories(categoryId, searchString))
    },
    [dispatch, categoryId]
  )

  function handleInput(e, { id, value }) {
    changeState({ [id]: value })
  }

  function handlerChangeRadio(e, { value }) {
    changeState({ badgeId: value })
  }

  function handleChange(e, { id }) {
    if (id === 'has_value_variable' || id === 'has_value_percentage') {
      return changeState({
        ...state,
        customer_value: 1,
        user_value: 0.3,
        mission_commission_percent: null,
        [id]: !state[id],
      })
    }

    return changeState({ ...state, [id]: !state[id] })
  }

  function onEditorStateChange(editorState) {
    changeState({
      editorState,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    })
  }

  function uploadImage(e) {
    const file = head(e.target.files)
    const reader = new FileReader()
    const stateProp = `${e.target.id}`
    reader.readAsDataURL(file)
    reader.onloadend = (event) => {
      changeState({
        [stateProp]: {
          base64: event.target.result,
          size: file.size,
          name: file.name,
          type: file.type,
        },
      })
    }
  }

  function showToast(description, type) {
    toast(
      {
        description,
        title: 'Alerta',
        type: type ? 'success' : 'warning',
        icon: type ? 'check' : 'remove',
      },
      () => console.log('toast closed')
    )
  }

  function errorData(error) {
    changeState({ isLoading: false })
    showToast(error)
  }

  function create() {
    const {
      title,
      avatar,
      image_path,
      description,
      user_value,
      mission_commission_percent,
      participants,
      expiring_time,
      customer_value,
      time_to_appreciate,
      can_restart,
      has_commission,
      show_only_if_badge,
      has_check_availability,
      has_forms,
      has_photo,
      no_location,
      has_scanner,
      has_products,
      has_timeline,
      has_attachment,
      webview_link,
      has_webview,
      can_multiple,
      has_value_variable,
      has_value_percentage,
      has_import_image,
      fix_time,
      is_expiring_weekend,
      has_auto_approval,
      has_import_document,
      should_inactivate_when_reproved,
    } = state

    if (isEmpty(title)) {
      showToast('Título não pode estar vazio!')
      return false
    }

    if (parseFloat(time_to_appreciate) <= 0) {
      showToast('Tempo de Aprovação deve ser maior que zero!')
      return false
    }

    if (parseFloat(expiring_time) <= 0) {
      showToast('Expiração deve ser maior que zero!')
      return false
    }

    if (parseFloat(fix_time) <= 0) {
      showToast('Tempo de correção deve ser maior que zero!')
      return false
    }

    if (parseFloat(customer_value) <= 0) {
      showToast('Valor do Cliente deve ser maior que zero!')
      return false
    }

    if (parseFloat(user_value) <= 0) {
      showToast('Valor do usuário deve ser maior que zero!')
      return false
    }

    if (parseFloat(customer_value) <= parseFloat(user_value)) {
      showToast('Valor do Cliente deve ser maior que do usuário!')
      return false
    }

    if (parseInt(participants, 0) <= 0) {
      showToast('Participantes deve ser maior que zero!')
      return false
    }

    if (isEmpty(avatar)) {
      showToast('Icone não pode estar vazio!')
      return false
    }

    if (isEmpty(image_path)) {
      showToast('Background não pode estar vazia!')
      return false
    }

    if (isEmpty(description)) {
      showToast('Descrição não pode estar vazia!')
      return false
    }

    if (has_webview) {
      if (!validURL(webview_link)) {
        errorData('URL inválida!')
        return false
      }
    }

    if (has_value_variable && has_value_percentage) {
      showToast('Uma subcategoria só pode ser valor variável ou porcentagem!')
      return false
    }

    const missionCommissionPercent =
      mission_commission_percent === '' ? null : mission_commission_percent

    changeState({ isLoading: true })

    return dispatch(
      subCategoriesCreate({
        title,
        avatar,
        image_path,
        description,
        user_value,
        mission_commission_percent: missionCommissionPercent,
        participants,
        expiring_time,
        customer_value,
        time_to_appreciate,
        can_restart,
        has_commission,
        show_only_if_badge,
        has_check_availability,
        has_forms,
        fix_time,
        has_photo,
        no_location,
        has_scanner,
        has_timeline,
        has_attachment,
        has_import_image,
        has_value_variable,
        is_expiring_weekend,
        has_products,
        can_multiple,
        has_value_percentage,
        has_auto_approval,
        has_import_document,
        should_inactivate_when_reproved,
        category_missions_main_id: categoryId,
      })
    )
  }

  function handlerCancel() {
    changeState({ open: false })
  }

  function openRemoveId(removeId) {
    setTimeout(() => {
      changeState({ removeId, open: true })
    }, 200)
  }

  function removeSubcategory() {
    changeState({ open: false })
    dispatch(removeSubCategorieById(state.removeId))
  }

  function changeStatus(item) {
    dispatch(
      subCategoriesUpdateStatus({
        missionId: item.uid,
        status: !item.status,
      })
    )

    item.status = !item.status
    changeState()
    // update(state.subCategories, { $set: item });
  }

  function openShieldModal(item) {
    dispatch(findAll())
    changeState({
      openModal: true,
      badgeId: item.badge_id,
      missionsMainId: item.uid,
    })
  }

  function updateBadge() {
    dispatch(
      subCategoriesUpdateBadge({
        missionsMainId: state.missionsMainId,
        badgeId: state.badgeId,
      })
    )
  }

  function removeBadge() {
    const subcategory = state.subCategories.find(
      (s) => s.uid === state.missionsMainId
    )
    dispatch(
      subCategoriesUpdate({
        uid: subcategory.uid,
        title: subcategory.title,
        description: subcategory.description,
        customer_value: subcategory.customer_value,
        user_value: subcategory.user_value,
        avatar: subcategory.avatar,
        badge_id: null,
      })
    )
  }

  function handlerQuery(target, value) {
    const queries = queryString.parse(location.search)
    const handlerQueries = { ...queries, [target]: value }
    const search = queryString.stringify(handlerQueries)
    history.replace({
      search,
      pahtname: location.pathname,
    })
  }

  function handlePaginationChange(e, { activePage }) {
    const newOffset = (activePage - 1) * state.limit
    handlerQuery('offset', Number(newOffset))
    const queries = queryString.parse(location.search)
    const search = queryString.stringify({
      ...queries,
      offset: newOffset,
      limit: state.limit,
    })
    changeState({ activePage, offset: Number(newOffset) })
    getSubcategories(search)
  }

  function calculatePagination(prop) {
    if (prop.success) {
      const newCount = Number(prop.count)
      const value = newCount / state.limit
      const currentCount =
        state.limit < newCount
          ? prop.data.length + state.limit * (state.activePage - 1)
          : newCount

      changeState({
        totalCount: newCount,
        subCategories: prop.data,
        count: Math.ceil(value),
        actualCount: currentCount,
      })
    }
  }

  useEffect(() => {
    getSubcategories(state.search)

    if (location.state !== undefined) {
      changeState({ categoryName: location.state.item.name })
    } else {
      changeState({ categoryName: 'Sub-Categorias' })
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (subCategoriesCreateData.success) {
      setTimeout(() => {
        const subCategoriesWithAdded = [
          { ...subCategoriesCreateData.data },
          ...state.subCategories,
        ]
        changeState({
          isLoading: false,
          subCategories: subCategoriesWithAdded,
          totalCount: state.totalCount + 1,
        })
        subCategoriesCreateData.success = false
        showToast('Categoria criada com sucesso!', true)
      }, 1000)
    } else if (subCategoriesCreateData.error) {
      changeState({ isLoading: false })
      showToast(subCategoriesCreateData.message)
      subCategoriesCreateData.error = false
    }
  }, [subCategoriesCreateData]); //eslint-disable-line

  useEffect(() => {
    if (findBadges.success) {
      changeState({ badges: findBadges.data })
      findBadges.success = false
    }
  }, [findBadges.success]); //eslint-disable-line

  useEffect(() => {
    if (updateBadgeData.success) {
      getSubcategories(state.search)
      showToast('Badge atualizado com sucesso!', true)
      changeState({ openModal: false })
      updateBadgeData.success = false
    } else if (updateBadgeData.error) {
      showToast(updateBadgeData.message)
    }
  }, [updateBadgeData]); //eslint-disable-line

  useEffect(() => {
    if (subCategoriesData.success) {
      changeState({ subCategories: subCategoriesData.data })
      calculatePagination(subCategoriesData)
      subCategoriesData.success = false
    }
  }, [subCategoriesData.success]); //eslint-disable-line

  useEffect(() => {
    if (removeSubCategory.success) {
      setTimeout(() => {
        const subCategoriesWithoutRemoved = state.subCategories.filter(
          (sc) => sc.uid !== state.removeId
        )
        changeState({
          isLoading: false,
          subCategories: subCategoriesWithoutRemoved,
          totalCount: state.totalCount - 1,
        })
        removeSubCategory.success = false
        showToast('Sub-Categoria removida com sucesso!', true)
      }, 1000)
    }
  }, [removeSubCategory.success]); //eslint-disable-line

  useEffect(() => {
    if (subCategoriesUpdateData.success) {
      getSubcategories(state.search)
      showToast('Badge atualizado com sucesso!', true)
      changeState({ openModal: false })
      subCategoriesUpdateData.success = false
    } else if (subCategoriesUpdateData.error) {
      showToast(subCategoriesUpdateData.message)
    }
  }, [changeState, getSubcategories, state.search, subCategoriesUpdateData])

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      link: '/categories',
      name: 'Categorias',
    },
    {
      active: true,
      hasLink: false,
      name: state.categoryName,
    },
  ]

  return (
    <>
      <Breadcrumbs items={breadcrumbsList} history={history} />
      <Button
        style={{ marginTop: 10, marginBottom: 10 }}
        color={state.show ? 'red' : 'orange'}
        onClick={() => changeState({ show: !state.show })}
      >
        {state.show ? 'Fechar' : 'Nova Sub-Categoria'}
      </Button>

      <CreateSubCategory
        {...state}
        myRef={uploadRef}
        create={() => create()}
        uploadImage={uploadImage}
        myRefBack={uploadBackRef}
        handleInput={handleInput}
        handleChange={handleChange}
        uploadBackground={uploadImage}
        onEditorStateChange={onEditorStateChange}
        onClick={() => uploadRef.current.click()}
        onClickBack={() => uploadBackRef.current.click()}
      />

      <Confirm
        header="Alerta"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        open={state.open}
        onCancel={handlerCancel}
        onConfirm={removeSubcategory}
        content="Tem certeza deseja remover este item?"
      />
      <SemanticToastContainer position="top-right" />

      <TableComponent
        {...state}
        data={state.subCategories}
        handlePaginationChange={handlePaginationChange}
        renderItem={(item, index) => (
          <Item
            key={item.uid}
            item={item}
            index={index}
            history={history}
            changeStatus={changeStatus}
            openRemoveId={openRemoveId}
            openShieldModal={openShieldModal}
            categoryId={categoryId}
          />
        )}
        emptyText={{
          icon: 'check circle outline',
          text: 'Nenhuma Categorias...',
        }}
        columns={[
          '',
          'Título',
          'Aprovação',
          'Expiração',
          'Participantes',
          'Valor Cliente',
          'Criado Em',
          'Atualizado Em',
          '',
        ]}
      />

      <BadgesList
        {...state}
        onSaveBadge={updateBadge}
        onRemoveBadge={removeBadge}
        handlerChangeRadio={handlerChangeRadio}
        close={() => changeState({ openModal: false })}
      />
    </>
  )
}
