import axios from 'axios'

import { API_BASE_URL } from '~/config/env'

export const baseURL = `${API_BASE_URL}/v1/`

const api = axios.create({
  baseURL,
})

export default api
