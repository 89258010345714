import React from 'react'
import NumberFormat from 'react-number-format'

import { Modal, Button, Form } from 'semantic-ui-react'

export default function SubcategorieValuesModal({
  close,
  update,
  openModal,
  handlerInput,

  user_value,
  description,
  customer_value,
}) {
  return (
    <Modal size="small" open={openModal} onClose={close}>
      <Modal.Header content="Atualizar Missão" />
      <Modal.Content>
        <Form>
          <Form.Input
            type="text"
            value={description || ''}
            label="Descrição (Obrigatório)"
            placeholder="Ex: Cópia de Processo"
            onChange={(e, { value }) => handlerInput('description', value)}
          />

          <NumberFormat
            prefix="%"
            customInput={Form.Input}
            decimalSeparator="."
            label="Valor Cliente"
            fixedDecimalScale={2}
            thousandSeparator
            value={customer_value || ''}
            placeholder="5% (Deve ser maior que o usuário)"
            onValueChange={({ value }) => handlerInput('customer_value', value)}
          />

          <NumberFormat
            prefix="%"
            decimalSeparator="."
            label="Valor Usuário"
            fixedDecimalScale={2}
            thousandSeparator
            customInput={Form.Input}
            placeholder="3% (Deve ser menor que o cliente)"
            value={user_value || ''}
            onValueChange={({ value }) => handlerInput('user_value', value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={close}>
          Cancelar
        </Button>
        <Button
          positive
          icon="checkmark"
          content="Atualizar"
          labelPosition="right"
          onClick={update}
        />
      </Modal.Actions>
    </Modal>
  )
}
