import React from 'react'

import { Card, Menu, Icon, Label } from 'semantic-ui-react'

const RegisterServices = ({
  onClick,
  has_photo,
  has_forms,
  has_attachment,
  photos_count,
  openFormsModal,
  has_import_image,
  has_import_document,
}) => (
  <Card>
    <Card.Content>
      <Card.Header>Serviços</Card.Header>
    </Card.Content>
    <Card.Content>
      <Menu>
        {(has_photo ||
          has_attachment ||
          has_import_image ||
          has_import_document) && (
          <Menu.Item as="a" onClick={onClick}>
            <Icon name="file outline" /> Arquivos
            <Label color="red" floating>
              {photos_count}
            </Label>
          </Menu.Item>
        )}
        {has_forms && (
          <Menu.Item as="a" onClick={openFormsModal}>
            <Icon name="file text" /> Respostas
          </Menu.Item>
        )}
      </Menu>
    </Card.Content>
  </Card>
)

export default RegisterServices
