import {
  ACCOUNTS,
  ACCOUNTS_CREATE,
  ACCOUNTS_REMOVE,
  ACCOUNTS_UPDATE,
  ACCOUNTS_ROLES,
  ACCOUNTS_ROLES_CREATE,
  ACCOUNTS_ROLES_REMOVE,
  ACCOUNTS_ROLES_UPDATE,
} from '~/services/api/endpoints'
import { get, post, put, remove } from '~/utils/actionsRequests'

const fetch = ({ name, limit, login, offset }) =>
  `login=${login}&name=${name}&limit=${limit}&offset=${offset}`

const findAccounts = (params) =>
  get({
    url: `${ACCOUNTS}?${fetch(params)}`,
    typeFetch: 'ACCOUNTS',
    typeError: 'ACCOUNTS_FAIL',
    typeResponse: 'ACCOUNTS_OK',
  })

const removeById = (id) =>
  remove({
    url: ACCOUNTS_REMOVE(id),
    typeFetch: 'ACCOUNTS_REMOVE',
    typeError: 'ACCOUNTS_REMOVE_FAIL',
    typeResponse: 'ACCOUNTS_REMOVE_OK',
  })

const create = (data) =>
  post({
    data,
    url: ACCOUNTS_CREATE,
    typeFetch: 'ACCOUNTS_CREATE',
    typeError: 'ACCOUNTS_CREATE_FAIL',
    typeResponse: 'ACCOUNTS_CREATE_OK',
  })

const update = (data) =>
  put({
    data,
    url: ACCOUNTS_UPDATE,
    typeFetch: 'ACCOUNTS_UPDATE',
    typeError: 'ACCOUNTS_UPDATE_FAIL',
    typeResponse: 'ACCOUNTS_UPDATE_OK',
  })

const findRoles = () =>
  get({
    url: ACCOUNTS_ROLES,
    typeFetch: 'ACCOUNTS_ROLES',
    typeError: 'ACCOUNTS_ROLES_FAIL',
    typeResponse: 'ACCOUNTS_ROLES_OK',
  })

const removeRole = (id) =>
  remove({
    url: ACCOUNTS_ROLES_REMOVE(id),
    typeFetch: 'ACCOUNTS_ROLES_REMOVE',
    typeError: 'ACCOUNTS_ROLES_REMOVE_FAIL',
    typeResponse: 'ACCOUNTS_ROLES_REMOVE_OK',
  })

const createRole = (data) =>
  post({
    data,
    url: ACCOUNTS_ROLES_CREATE,
    typeFetch: 'ACCOUNTS_ROLES_CREATE',
    typeError: 'ACCOUNTS_ROLES_CREATE_FAIL',
    typeResponse: 'ACCOUNTS_ROLES_CREATE_OK',
  })

const updateRole = (data) =>
  put({
    data,
    url: ACCOUNTS_ROLES_UPDATE,
    typeFetch: 'ACCOUNTS_ROLES_UPDATE',
    typeError: 'ACCOUNTS_ROLES_UPDATE_FAIL',
    typeResponse: 'ACCOUNTS_ROLES_UPDATE_OK',
  })

export {
  create,
  update,
  removeById,
  findAccounts,
  createRole,
  updateRole,
  removeRole,
  findRoles,
}
