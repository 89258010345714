import {
  CREDIT_REQUESTS,
  UPDATE_CREDIT_REQUESTS,
} from '~/services/api/endpoints'
import { get, put } from '~/utils/actionsRequests'

const findAll = () =>
  get({
    url: CREDIT_REQUESTS,
    typeFetch: 'CREDIT_REQUEST',
    typeError: 'CREDIT_REQUEST_FAIL',
    typeResponse: 'CREDIT_REQUEST_OK',
  })

const update = (data) =>
  put({
    url: UPDATE_CREDIT_REQUESTS,
    data,
    typeFetch: 'UPDATE_CREDIT_REQUESTS',
    typeError: 'UPDATE_CREDIT_REQUESTS_FAIL',
    typeResponse: 'UPDATE_CREDIT_REQUESTS_OK',
  })

export { findAll, update }
