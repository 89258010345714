import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import {
  List,
  Image,
  Form,
  Grid,
  Modal,
  Icon,
  Button,
  Checkbox,
  TextArea,
} from 'semantic-ui-react'

import ImageAvatar from '~/assets/avatar.png'

const UsersSearch = ({
  openUsersProgress,
  closeUsers,
  usersList,
  message,
  openWhats,
  handlerState,
  missionName,
  selectAll,
  handlerToggle,
  isLoadingSubmit,
  selectAllSubmit,
  submitNotification,
}) => (
  <Modal size="large" open={openUsersProgress} onClose={closeUsers}>
    <Modal.Header>
      <Grid>
        <Grid.Column width={13}>{missionName}</Grid.Column>
        <Grid.Column width={3}>
          <Button
            size="mini"
            color={selectAll ? 'grey' : 'blue'}
            onClick={() => selectAllSubmit('usersList')}
          >
            {selectAll ? 'Deselecionar' : 'Selecionar'} tudo ({usersList.length}
            )
          </Button>
        </Grid.Column>
      </Grid>
    </Modal.Header>
    <Modal.Content scrolling style={{ maxHeight: 300 }}>
      <List divided verticalAlign="middle" relaxed="very">
        {usersList.map((item, index) => (
          <List.Item key={`key-${index}`}>
            <List.Content floated="right">
              <Button
                as="a"
                size="mini"
                color="green"
                content="Abrir Whatsapp"
                onClick={() => openWhats(item)}
              />
              <CopyToClipboard
                text={item.phone}
                onCopy={() => console.log('copiado')}
              >
                <Button
                  as="a"
                  size="mini"
                  color="blue"
                  content="Copiar Telefone"
                />
              </CopyToClipboard>
              <Checkbox
                value={item.uid}
                id={`item-${index}`}
                checked={item.checked}
                onChange={({ target }) =>
                  handlerToggle('usersList', target.value)
                }
              />
            </List.Content>
            <Image
              avatar
              src={item.avatar === null ? ImageAvatar : item.avatar}
            />
            <List.Content>
              <List.Header as="a">
                {item.name} ({item.email})
              </List.Header>
              <List.Description>
                <b>{item.description}</b>
              </List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </Modal.Content>
    <Modal.Actions>
      <Form>
        <TextArea
          rows={2}
          value={message || ''}
          placeholder="Ex: Têm missão por perto, vamos fazer?"
          onChange={(e, { value }) => handlerState('message', value)}
        />
      </Form>
      <Button
        primary
        icon
        labelPosition="right"
        loading={isLoadingSubmit}
        style={{ marginTop: 10 }}
        onClick={() => submitNotification('usersList')}
      >
        Encaminhar Notificação <Icon name="bell" />
      </Button>
    </Modal.Actions>
  </Modal>
)

export default UsersSearch
