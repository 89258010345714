import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { length, head } from 'ramda'
import { List, Icon, Modal, Image, Button, Message } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import {
  findByMission,
  upload,
  removeById,
} from '~/store/modules/attachments/actions'

export default function MissionAttachments({
  selectedMissionUid,
  customerId,
  isOpenModalAttachments,
  onCloseModalAttachments,
}) {
  const [attachments, setAttachments] = useState([])
  const [errorsModalAttach, setErrorsModalAttach] = useState([])

  const attachmentList = useSelector((store) => store.attachments)
  const attachmentDelete = useSelector((store) => store.removeAttachment)
  const attachmentUpload = useSelector((store) => store.uploadAttachments)

  const isLoading = attachmentUpload.isFetching

  const uploadRef = useRef()

  const dispatch = useDispatch()

  const removeAttachment = (id) => dispatch(removeById(id))

  const attachmentResponse = useCallback(
    (props) => {
      if (props.success) {
        const missionId = props.data.mission_id || selectedMissionUid
        dispatch(findByMission(missionId))
        props.success = false
      }
    },
    [dispatch, selectedMissionUid]
  )

  const uploadAttachments = (e) => {
    const file = head(e.target.files)
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = (event) => {
      const { size, name, type } = file
      if (size >= 3000000) {
        setErrorsModalAttach(
          errorsModalAttach.concat('Arquivo deve ser menor ou igual a 3MB!')
        )
        return false
      }

      return dispatch(
        upload(
          {
            file: {
              size,
              name,
              type,
              base64: event.target.result,
            },
            column: 'path',
            table: 'attachments',
            uid: selectedMissionUid,
          },
          customerId
        )
      )
    }
  }

  useEffect(() => {
    if (attachmentUpload) {
      attachmentResponse(attachmentUpload)
    }
  }, [attachmentResponse, attachmentUpload])

  useEffect(() => {
    if (attachmentDelete) {
      attachmentResponse(attachmentDelete)
    }
  }, [attachmentResponse, attachmentDelete])

  useEffect(() => {
    if (attachmentList && attachmentList.success) {
      setAttachments(attachmentList.data)
      attachmentList.success = false
    }
  }, [attachmentList])

  useEffect(() => {
    dispatch(findByMission(selectedMissionUid))
  }, [dispatch, selectedMissionUid])

  return (
    <Modal
      size="small"
      onClose={onCloseModalAttachments}
      open={isOpenModalAttachments}
    >
      <Modal.Header>Arquivos (PDF)</Modal.Header>
      <Modal.Content>
        {attachments.length === 0 && (
          <EmptyList
            marginTop={20}
            icon="file pdf outline"
            text="Nenhum arquivo..."
          />
        )}
        {length(errorsModalAttach) > 0 && (
          <Message
            fluid
            warning
            header="Alerta!"
            key={errorsModalAttach}
            list={errorsModalAttach}
            style={{ padding: 10 }}
          />
        )}
        <List divided verticalAlign="middle">
          {attachments.map((item, index) => {
            return (
              <List.Item key={`key-${index}`}>
                <List.Content floated="right">
                  <Button
                    icon
                    color="blue"
                    onClick={() => window.open(item.path, '_blank')}
                  >
                    <Icon name="external" />
                  </Button>
                  <Button
                    icon
                    color="red"
                    onClick={() => removeAttachment(item.uid)}
                  >
                    <Icon name="trash outline" />
                  </Button>
                </List.Content>
                <Image avatar src={require('~/assets/pdf.png')} />
                <List.Content>
                  {item.name} ({item.size})
                </List.Content>
              </List.Item>
            )
          })}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <input
          type="file"
          id="uploadAttachment"
          accept="application/pdf"
          style={{ display: 'none' }}
          ref={uploadRef}
          onChange={uploadAttachments}
        />
        <Button content="Cancelar" onClick={onCloseModalAttachments} />
        <Button
          positive
          icon="upload"
          content="Upload"
          labelPosition="right"
          loading={isLoading}
          onClick={() => uploadRef.current.click()}
        />
      </Modal.Actions>
    </Modal>
  )
}
