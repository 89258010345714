import React from 'react'
import DatePicker from 'react-datepicker'

import { Menu, Icon, Select, Button, Checkbox } from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'

const CreditRequestGenerator = ({
  hasPos,
  final,
  initial,
  isLoading,
  customers,
  submitFilter,
  handlerChange,
}) => (
  <Menu>
    <Menu.Item>
      <Icon name="filter" />
    </Menu.Item>
    <Menu.Item>
      <div className="ui input">
        <DatePicker
          id="initial"
          selectsStart
          dateFormat="dd/MM/yyyy 00:00:00"
          placeholderText="Data Inicio"
          selected={initial}
          startDate={initial}
          endDate={final}
          onChange={(value) => handlerChange('initial', value)}
        />
      </div>
    </Menu.Item>
    <Menu.Item>
      <div className="ui input">
        <DatePicker
          id="final"
          selectsEnd
          dateFormat="dd/MM/yyyy 23:59:59"
          placeholderText="Data Fim"
          selected={final}
          startDate={initial}
          endDate={final}
          onChange={(value) => handlerChange('final', value)}
        />
      </div>
    </Menu.Item>
    <Menu.Item>
      <Select
        placeholder="Clientes"
        options={customers}
        onChange={(e, { value }) => handlerChange('customerId', value)}
      />
    </Menu.Item>
    <Menu.Item>
      <Checkbox
        label="Pós-Pago"
        value={hasPos}
        onChange={(e, { checked }) => handlerChange('hasPos', checked)}
      />
    </Menu.Item>
    <Menu.Item position="right">
      <Button
        icon
        color="orange"
        loading={isLoading}
        labelPosition="left"
        onClick={submitFilter}
        disabled={isLoading}
      >
        <Icon name="check circle outline" />
        Gerar Requisição
      </Button>
    </Menu.Item>
  </Menu>
)

export default CreditRequestGenerator
