import React from 'react'

import { format } from 'date-fns'
import { Table, Popup, Button } from 'semantic-ui-react'

export default function Item({ item, history, handleUpdate }) {
  return (
    <Table.Row key={`index-${item.uid}`}>
      <Table.Cell>{item.title}</Table.Cell>
      <Table.Cell>{item.description}</Table.Cell>
      <Table.Cell>
        {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        {item.updated_at &&
          format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Popup
          inverted
          content="Itens"
          trigger={
            <Button
              icon="search"
              onClick={() => history.push(`forms-users-extra/${item.uid}`)}
            />
          }
        />
        <Popup
          content={item.status ? 'Inativar' : 'Ativar'}
          inverted
          trigger={
            <Button
              icon={item.status ? 'remove' : 'check'}
              color={item.status ? 'red' : 'green'}
              onClick={() => handleUpdate(item)}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  )
}
