import React from 'react'

import { Form, Card, Icon, Button, Dropdown } from 'semantic-ui-react'

const AccountsCreate = ({
  show,
  name,
  email,
  login,
  role_id,
  roles,
  password,
  isLoading,
  handlerRole,
  handlerInput,
  submitAccount,
}) => (
  <Card
    centered
    fluid
    style={{ marginTop: 10, display: show ? 'block' : 'none' }}
  >
    <Card.Content>
      <Form>
        <Form.Input
          type="text"
          id="name"
          value={name || ''}
          onChange={handlerInput}
          placeholder="Nome"
        />

        <Form.Input
          type="text"
          id="login"
          value={login || ''}
          autoComplete="username"
          onChange={handlerInput}
          placeholder="Username"
        />

        <Form.Input
          type="text"
          id="email"
          value={email || ''}
          autoComplete="username"
          onChange={handlerInput}
          placeholder="Email"
        />

        <Form.Input
          id="password"
          type="password"
          placeholder="Senha"
          value={password || ''}
          onChange={handlerInput}
          autoComplete="new-password"
        />

        <Dropdown
          selection
          options={roles}
          placeholder="Role"
          value={role_id || null}
          onChange={handlerRole}
        />
      </Form>
    </Card.Content>
    <Card.Content extra textAlign="left">
      <Button
        icon
        positive
        onClick={submitAccount}
        loading={isLoading}
        disabled={isLoading}
        labelPosition="left"
      >
        <Icon name="check" /> Criar
      </Button>
    </Card.Content>
  </Card>
)

export default AccountsCreate
