import React from 'react'
import { connect } from 'react-redux'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import { format, getTime } from 'date-fns'
import { length, dissoc } from 'ramda'
import {
  Grid,
  Table,
  Loader,
  Popup,
  Button,
  Message,
  Pagination,
} from 'semantic-ui-react'

import Graphic from '~/components/Charts/Basic'
import CreditRequestGenerator from '~/components/CreditRequestGenerator'
import Empty from '~/components/EmptyList'
import { findAll, update } from '~/store/modules/creditRequest/actions'
import {
  findAll as findCustomers,
  proccessPayment,
} from '~/store/modules/customers/actions'
import {
  dashboard,
  filter as filterDash,
} from '~/store/modules/dashboard/actions'

import Main from '../Main'

const periods = ['day', 'week', 'month', 'year']

class Budget extends React.Component {
  state = {
    limit: 10,
    offset: 0,
    search: '',
    creditRequest: [],
    dashboardFilter: [],

    activePage: 0,
    siblingRange: 1,
    openModal: false,
    boundaryRange: 0,
    showEllipsis: true,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,

    customerId: '',
    initial: null,
    final: null,

    paymentId: '',
    filterErrors: [],

    customers: [],

    hasPos: false,

    status: 1,
    period: 'month',
    where: 'status',
    typeData: 'sum',
    table: 'master_credit_requests',
    label: 'Requisições Aprovadas',
    columnDate: 'updated_at',
    year: format(new Date(), 'yyyy'),
  }

  constructor(props) {
    super(props)
    this.handlerClick = this.handlerClick.bind(this)
  }

  componentDidMount() {
    this.onRefresh()
  }

  async onRefresh() {
    return await Promise.all([
      this.props.findAll(),
      this.props.dashboard(),
      this.props.findCustomers({ search: '', limit: 1000, offset: 0 }),
      this.props.filterDash({ ...this.state }),
    ])
  }

  submitFilter = () => {
    const { customerId, initial, final } = this.state

    if (!initial) {
      this.showToast('A data inicial deve ser selecionado!')
      return false
    }

    if (!final) {
      this.showToast('A data final deve ser selecionado!')
      return false
    }

    if (final <= initial) {
      this.showToast('A data inicial deve ser maior que a inicial!')
      return false
    }

    if (!customerId) {
      this.showToast('O cliente deve ser selecionado!')
      return false
    }

    this.props.proccessPayment(
      {
        customer_id: customerId,
        initial: getTime(initial),
        final: getTime(final),
      },
      this.state.hasPos
    )
  }

  handlerChange = (key, value) => this.setState({ [key]: value })

  handlerClick = (e) => {
    this.setState({ period: e })
    const newObj = dissoc('period', this.state)
    this.props.filterDash({ period: e, ...newObj })
  }

  handlePaginationChange = (e, { activePage }) => {
    this.props.findAll()
    this.setState({ activePage })
  }

  updateRequest = ({ uid, status, email, customer_id }) => {
    this.setState({ [`isConfirmLoading${uid}`]: true })
    setTimeout(
      () => this.props.update({ uid, status, email, customer_id }),
      1000
    )
  }

  showToast = (description, type) =>
    toast(
      {
        description,
        title: 'Alerta',
        type: type ? 'success' : 'warning',
        icon: type ? 'check' : 'remove',
      },
      () => console.log('toast closed')
    )

  renderCells = (item, index) => (
    <Table.Row
      key={`key-${index}`}
      warning={item.status === 0}
      positive={item.status === 1}
      negative={item.status === 2}
    >
      <Table.Cell>{item.customerName}</Table.Cell>
      <Table.Cell>{item.customerEmail}</Table.Cell>
      <Table.Cell>{item.payment_type}</Table.Cell>
      <Table.Cell>
        {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        {item.updated_at &&
          format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>R$ {item.value}</Table.Cell>
      <Table.Cell textAlign="center" style={{ width: 150 }}>
        {item.status === 0 && (
          <div>
            <Popup
              inverted
              trigger={
                <Button
                  positive
                  icon="check"
                  onClick={() =>
                    this.updateRequest({
                      uid: item.uid,
                      status: 1,
                      email: item.customerEmail,
                      customer_id: item.customer_id,
                    })
                  }
                  loading={this.state[`isConfirmLoading${item.uid}`]}
                  disabled={this.state[`isConfirmLoading${item.uid}`]}
                />
              }
              content="Confirmar Pagamento"
            />
            <Popup
              inverted
              trigger={
                <Button
                  negative
                  icon="remove"
                  onClick={() =>
                    this.updateRequest({
                      uid: item.uid,
                      status: 2,
                      email: item.customerEmail,
                      customer_id: item.customer_id,
                    })
                  }
                  loading={this.state[`isConfirmLoading${item.uid}`]}
                  disabled={this.state[`isConfirmLoading${item.uid}`]}
                />
              }
              content="Cancelar"
            />
          </div>
        )}
      </Table.Cell>
    </Table.Row>
  )

  handlerList = (props) => {
    if (props.success) {
      const customers = props.data.map((item, index) => ({
        key: index,
        text: item.name,
        value: item.uid,
      }))
      this.setState({ customers })
    } else if (props.error) {
      this.showToast(props.message)
      return false
    }
  }

  handlerResponse = (props) => {
    if (props.success) {
      this.showToast('Requisição realizada com sucesso!', true)
      this.onRefresh()
      props.success = false
    } else if (props.error) {
      this.showToast(props.message)
      props.error = false
      return false
    }
  }

  componentWillReceiveProps({
    customers,
    creditRequest,
    processRequest,
    updateCreditRequest,
    dashboardFilter,
  }) {
    if (creditRequest !== this.props.creditRequest) {
      if (creditRequest.success) {
        this.setState({ creditRequest: creditRequest.data })
      }
    }

    if (updateCreditRequest !== this.props.updateCreditRequest) {
      this.handlerResponse(updateCreditRequest)
    }

    if (processRequest !== this.props.processRequest) {
      this.handlerResponse(processRequest)
    }

    if (customers !== this.props.customers) {
      this.handlerList(customers)
    }

    if (dashboardFilter !== this.props.dashboardFilter) {
      if (dashboardFilter.success) {
        this.setState({
          dashboardFilter: dashboardFilter.data.map((i) => ({
            name: format(new Date(i.period), 'dd/MM/yy'),
            Valor: Number(i.count),
          })),
        })
        dashboardFilter.success = false
      }
    }
  }

  renderBudgetComponent = () => (
    <div style={{ marginTop: 10 }}>
      {this.props.creditRequest.isFetching && (
        <div>
          <Loader active inline="centered" />
        </div>
      )}
      {length(this.state.creditRequest) > 0 ? (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Cliente</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Tipo de Pagamento</Table.HeaderCell>
              <Table.HeaderCell>Criado em</Table.HeaderCell>
              <Table.HeaderCell>Atualizado em</Table.HeaderCell>
              <Table.HeaderCell>Valor</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.creditRequest.map(this.renderCells)}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="8">
                <Pagination
                  totalPages={100}
                  activePage={this.state.activePage}
                  siblingRange={this.state.siblingRange}
                  boundaryRange={this.state.boundaryRange}
                  onPageChange={this.handlePaginationChange}
                  ellipsisItem={this.state.showEllipsis ? undefined : null}
                  firstItem={this.state.showFirstAndLastNav ? undefined : null}
                  lastItem={this.state.showFirstAndLastNav ? undefined : null}
                  prevItem={
                    this.state.showPreviousAndNextNav ? undefined : null
                  }
                  nextItem={
                    this.state.showPreviousAndNextNav ? undefined : null
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      ) : (
        <Empty icon="money" text="Nenhuma transação até o momento..." />
      )}
    </div>
  )

  renderComponent = () => (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column className="content">
            <Graphic
              periods={periods}
              label={this.state.label}
              active={this.state.period}
              onPress={this.handlerClick}
              data={this.state.dashboardFilter}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {length(this.state.filterErrors) > 0 && (
        <Message negative header="Alerta!" list={this.state.filterErrors} />
      )}
      <CreditRequestGenerator {...this} {...this.state} />
      {this.renderBudgetComponent()}
      <SemanticToastContainer position="top-right" />
    </>
  )

  render() {
    return (
      <Main {...this.props}>
        {this.props.user.profile.role_name === 'Basic' ||
        this.props.user.profile.role_name === 'Leader' ? (
          <Empty icon="lock" text="Acesso restrito" />
        ) : (
          this.renderComponent()
        )}
      </Main>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.user,
    customers: store.customers,
    dataCustomer: store.dashboard,
    creditRequest: store.creditRequest,
    processRequest: store.processRequest,
    dashboardFilter: store.dashboardFilter,
    updateCreditRequest: store.updateCreditRequest,
  }
}

export default connect(mapStateToProps, {
  update,
  findAll,
  dashboard,
  filterDash,
  findCustomers,
  proccessPayment,
})(Budget)
