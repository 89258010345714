import { useState, useEffect, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import { useDebounce } from 'use-lodash-debounce'

import {
  queryStringToObject,
  objectToQueryString,
} from '~/utils/queryStringHelper'

export default function useQueryFilters(
  initialFilters,
  parseQueryParamsBeforeSetFilters,
  parseFiltersBeforeSetQueryParams
) {
  const history = useHistory()
  const { search } = useLocation()

  const [filters, setFilters] = useState(null)
  const debouncedFilters = useDebounce(filters, 300)

  const clearFilters = useCallback(() => {
    setFilters({ ...initialFilters })
  }, [initialFilters])

  const onChangeFilter = useCallback((key, value) => {
    return setFilters((f) => ({ ...f, offset: 0, [key]: value }))
  }, [])

  const onChangeFilters = useCallback((newFilters) => {
    return setFilters((f) => ({ ...f, offset: 0, ...newFilters }))
  }, [])

  useEffect(() => {
    if (debouncedFilters !== null) {
      let parsedFilters
      if (parseFiltersBeforeSetQueryParams) {
        parsedFilters = parseFiltersBeforeSetQueryParams(debouncedFilters)
      }

      const validFilters = { ...debouncedFilters, ...parsedFilters }
      const searchString = objectToQueryString(validFilters, history)
      history.push({ search: searchString })
    }
  }, [debouncedFilters, parseFiltersBeforeSetQueryParams, history])

  useEffect(() => {
    if (debouncedFilters === null) {
      let queryObj = queryStringToObject(search)

      if (parseQueryParamsBeforeSetFilters) {
        const parsedParams = parseQueryParamsBeforeSetFilters(queryObj)
        queryObj = { ...queryObj, ...parsedParams }
      }

      const initialQuery = { ...initialFilters, ...queryObj }

      setFilters(initialQuery)
    }
  }, [
    debouncedFilters,
    search,
    initialFilters,
    parseQueryParamsBeforeSetFilters,
  ])

  return {
    filters,
    debouncedFilters,
    setFilters,
    onChangeFilter,
    onChangeFilters,
    clearFilters,
  }
}
