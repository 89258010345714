import React from 'react'
import { Popup } from 'react-map-gl'

export default function PopupItem({ anchor, lat, lng, title }) {
  return (
    <Popup
      anchor={anchor || 'top'}
      tipSize={10}
      offsetTop={40}
      offsetLeft={18}
      latitude={lat}
      longitude={lng}
      closeButton={false}
    >
      <div style={{ fontSize: 11 }}>
        {title}
        <p>
          <a
            rel="noopener noreferrer"
            href={`https://www.google.com/maps/?q=${lat}, ${lng}`}
            target="_blank"
          >
            {`(${lat}, ${lng})`}
          </a>
        </p>
      </div>
    </Popup>
  )
}
