import { responseData } from '~/utils/reducer'

const USERS_DATA = 'USERS_DATA'
const USERS_BANNED = 'USERS_BANNED'
const USER_UPDATE = 'USER_UPDATE'
const USER_DATA_FEEDS = 'USER_DATA_FEEDS'
const USER_DATA_DETAIL = 'USER_DATA_DETAIL'
const DELIVERY_MESSAGES = 'DELIVERY_MESSAGES'
const USER_DATA_PAYMENTS = 'USER_DATA_PAYMENTS'
const USER_DATA_REGISTERS = 'USER_DATA_REGISTERS'
const USER_NEARBY_BY_MISSION = 'USER_NEARBY_BY_MISSION'
const USERS_IN_PROGRESS = 'USERS_IN_PROGRESS'
const SEARCH_LEADERS = 'SEARCH_LEADERS'

const usersData = responseData(USERS_DATA, 'object')
const userUpdate = responseData(USER_UPDATE, 'object')
const delivered = responseData(DELIVERY_MESSAGES, 'object')
const usersSearch = responseData(USER_NEARBY_BY_MISSION, 'array')
const usersFeeds = responseData(USER_DATA_FEEDS, 'array')
const bannedResponse = responseData(USERS_BANNED, 'object')
const usersDetail = responseData(USER_DATA_DETAIL, 'object')
const usersPayments = responseData(USER_DATA_PAYMENTS, 'array')
const usersRegisters = responseData(USER_DATA_REGISTERS, 'array')
const usersInProgress = responseData(USERS_IN_PROGRESS, 'array')
const leadersSearched = responseData(SEARCH_LEADERS, 'array')

export default {
  delivered,
  usersData,
  userUpdate,
  usersFeeds,
  usersSearch,
  usersDetail,
  usersPayments,
  usersRegisters,
  bannedResponse,
  leadersSearched,
  usersInProgress,
}
