import { responseData } from '~/utils/reducer'

const AVERAGE = 'AVERAGE'
const CATEGORIES = 'CATEGORIES'
const CATEGORY_DETAIL = 'CATEGORY_DETAIL'
const CATEGORY_UPDATE = 'CATEGORY_UPDATE'
const SUB_CATEGORIES = 'SUB_CATEGORIES'
const GET_SUB_CATEGORIES = 'GET_SUB_CATEGORIES'
const CAT_BY_CUSTOMER = 'CAT_BY_CUSTOMER'
const MISSIONS_MAIN_ALL = 'MISSIONS_MAIN_ALL'
const CATEGORIES_REMOVE = 'CATEGORIES_REMOVE'
const SUB_CATEGORIES_DELETE = 'SUB_CATEGORIES_DELETE'
const SUB_CATEGORIES_DETAIL = 'SUB_CATEGORIES_DETAIL'
const SUB_CATEGORIES_CREATE = 'SUB_CATEGORIES_CREATE'
const SUB_CATEGORIES_UPDATE = 'SUB_CATEGORIES_UPDATE'
const BADGES_MISSIONS_MAIN_UPDATE = 'BADGES_MISSIONS_MAIN_UPDATE'
const MISSIONS_MAIN_UPDATE_STATUS = 'MISSIONS_MAIN_UPDATE_STATUS'
const CATEGORY_MISSIONS_MAIN_CREATE = 'CATEGORY_MISSIONS_MAIN_CREATE'

const average = responseData(AVERAGE, 'array')
const categories = responseData(CATEGORIES, 'array')
const categoryByIdData = responseData(CATEGORY_DETAIL, 'object')
const categoryUpdateData = responseData(CATEGORY_UPDATE, 'object')
const subCategories = responseData(SUB_CATEGORIES, 'array')
const subCategoriesAll = responseData(MISSIONS_MAIN_ALL, 'array')
const subCategoriesById = responseData(SUB_CATEGORIES_DETAIL, 'object')
const subCategoriesCreate = responseData(SUB_CATEGORIES_CREATE, 'object')
const subCategoriesUpdate = responseData(SUB_CATEGORIES_UPDATE, 'object')
const subCategoriesUpdateBadge = responseData(
  BADGES_MISSIONS_MAIN_UPDATE,
  'object'
)
const removeCategory = responseData(CATEGORIES_REMOVE, 'object')
const removeSubCategory = responseData(SUB_CATEGORIES_DELETE, 'object')
const categoriesByCustomer = responseData(CAT_BY_CUSTOMER, 'array')
const createCategory = responseData(CATEGORY_MISSIONS_MAIN_CREATE, 'object')
const subCategoriesUpdateStatus = responseData(
  MISSIONS_MAIN_UPDATE_STATUS,
  'object'
)
const getSubCategories = responseData(GET_SUB_CATEGORIES, 'array')

export default {
  average,
  categories,
  categoryByIdData,
  categoryUpdateData,
  subCategories,
  removeCategory,
  createCategory,
  subCategoriesAll,
  removeSubCategory,
  subCategoriesById,
  subCategoriesUpdate,
  subCategoriesCreate,
  categoriesByCustomer,
  subCategoriesUpdateStatus,
  subCategoriesUpdateBadge,
  getSubCategories,
}
