import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SemanticToastContainer } from 'react-semantic-toasts'

import { format } from 'date-fns'
import { head } from 'ramda'
import { Table, Popup, Button, Divider } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import TableComponent from '~/components/Table'
import usePaginationTable from '~/hooks/usePaginationTable'
import useQueryFilters from '~/hooks/useQueryFilters'
import BudgetFilter from '~/pages/Budget/BudgetFilter'
import BudgetHeaderData from '~/pages/Budget/BudgetHeaderData'
import api from '~/services/api'
import { PAYMENTS_REPORT, PAYMENTS, DASHBOARD } from '~/services/api/endpoints'
import { uploadFile, update } from '~/store/modules/payments/actions'
import message from '~/utils/messages'

const initialFilters = {
  initial: null,
  final: null,
  status: 0,
  limit: 100,
  offset: 0,
}

const columns = [
  'Usuário',
  'Nome Conta',
  'CPF/CNPJ',
  'AG',
  'CC',
  'Conta Bancária',
  'Criado em',
  'Atualizado em',
  'Valor',
  '',
]

export default function Budget() {
  const [payments, setPayments] = useState(null)
  const [isPaymentsLoading, setIsPaymentsLoading] = useState(true)

  const [dashboardInfo, setDashboardInfo] = useState({})
  const [isDashboardInfoLoading, setIsDashboardInfoLoading] = useState(true)

  const [paymentsActionsStatus, setPaymentsActionsStatus] = useState({})
  const [errorsModalAttach, setErrorsModalAttach] = useState([])

  const user = useSelector((store) => store.user)
  const updatePayment = useSelector((store) => store.updatePayment)
  const uploadPaymentFile = useSelector((store) => store.uploadPaymentFile)

  const dispatch = useDispatch()

  const parseQueryParamsBeforeSetFilters = useCallback((queryObj) => {
    const parsedParams = {}

    if (queryObj.limit) {
      parsedParams.limit = parseInt(queryObj.limit, 0)
    }
    if (queryObj.offset) {
      parsedParams.offset = parseInt(queryObj.offset, 0)
    }
    if (queryObj.initial) {
      parsedParams.initial = new Date(parseInt(queryObj.initial, 0))
    }
    if (queryObj.final) {
      parsedParams.final = new Date(parseInt(queryObj.final, 0))
    }
    if (queryObj.status) {
      parsedParams.status = parseInt(queryObj.status, 0)
    }

    return parsedParams
  }, [])

  const {
    filters,
    debouncedFilters,
    setFilters,
    onChangeFilter,
    clearFilters,
  } = useQueryFilters(initialFilters, parseQueryParamsBeforeSetFilters)

  const {
    count,
    totalCount,
    activePage,
    calculatePagination,
    onPageChange,
    emptyText,
  } = usePaginationTable(debouncedFilters, setFilters)

  const getPayments = useCallback(async () => {
    try {
      const validParams = { ...debouncedFilters }
      if (validParams.initial) {
        validParams.initial = new Date(validParams.initial).getTime()
      }
      if (validParams.final) {
        validParams.final = new Date(validParams.final).getTime()
      }

      setIsPaymentsLoading(true)
      const res = await api.get(PAYMENTS, { params: validParams })
      setPayments(res.data.data)
      calculatePagination(res.data.count)
      setIsPaymentsLoading(false)
    } catch (error) {
      console.log(error)
      setIsPaymentsLoading(false)
      message().error(error.response.data.data || 'Houve um erro no servidor')
    }
  }, [debouncedFilters, calculatePagination])

  const getDashboardInfo = useCallback(async () => {
    setIsDashboardInfoLoading(true)

    try {
      const res = await api.get(DASHBOARD)
      setDashboardInfo(res.data.data)
      setIsDashboardInfoLoading(false)
    } catch (error) {
      console.log(error)
      setIsDashboardInfoLoading(false)
      message().error('Houve um erro no servidor')
    }
  }, [])

  const submitExport = useCallback(() => {
    const { initial, final, status } = filters

    const initialToMili = new Date(initial).getTime()
    const finalToMili = new Date(final).getTime()

    window.open(
      `${PAYMENTS_REPORT}?initial=${initialToMili}&final=${finalToMili}&status=${status}`
    )
  }, [filters])

  const uploadAttachments = useCallback(
    (e, uid) => {
      setPaymentsActionsStatus((oldCustomState) => ({
        ...oldCustomState,
        [`isLoading${uid}`]: true,
      }))

      const file = head(e.target.files)
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = (event) => {
        const { size, name, type } = file
        if (size >= 3000000) {
          setErrorsModalAttach(
            errorsModalAttach.concat('Arquivo deve ser menor ou igual a 3MB!')
          )
          return
        }
        dispatch(
          uploadFile({
            file: {
              size,
              name,
              type,
              base64: event.target.result,
            },
            column: 'attachment',
            uid,
            table: 'payment_request',
          })
        )
      }
    },
    [dispatch, errorsModalAttach]
  )

  const confirmPayment = useCallback(
    (paymentIdParam) => {
      setPaymentsActionsStatus((oldCustomState) => ({
        ...oldCustomState,
        [`isConfirmLoading${paymentIdParam}`]: true,
      }))
      dispatch(update({ status: 1, paymentId: paymentIdParam }))
    },
    [dispatch]
  )

  const onClickUpload = useCallback((uid) => {
    const input = document.getElementById(`uploadAttachment-${uid}`)
    input.value = null
    input.click()
  }, [])

  useEffect(() => {
    if (debouncedFilters !== null) {
      getPayments()
    }
  }, [debouncedFilters, getPayments])

  useEffect(() => {
    getDashboardInfo()
  }, [getDashboardInfo])

  useEffect(() => {
    if (uploadPaymentFile.success) {
      const { uid, attachment } = uploadPaymentFile.data

      getDashboardInfo()

      const newPayments = [...payments]
      newPayments.forEach((p) => {
        if (p.uid === uid) {
          p.attachment = attachment
        }
      })
      setPayments(newPayments)

      setPaymentsActionsStatus((oldCustomState) => ({
        ...oldCustomState,
        [`isLoading${uid}`]: false,
      }))

      message().success('Comprovante enviado!')
      uploadPaymentFile.success = false
    } else if (uploadPaymentFile.error) {
      message().error('Algo deu errado :(')
      uploadPaymentFile.error = false
    }
  }, [uploadPaymentFile, getDashboardInfo, payments])

  useEffect(() => {
    if (updatePayment.success) {
      const { uid } = updatePayment.data

      getDashboardInfo()

      const newPayments = [...payments]
      setPayments(newPayments.filter((p) => p.uid !== uid))

      setPaymentsActionsStatus((oldCustomState) => ({
        ...oldCustomState,
        [`isConfirmLoading${uid}`]: false,
      }))

      message().success('Pagamento confirmado!')
      updatePayment.success = false
    } else if (updatePayment.error) {
      message().error('Algo deu errado :(')
      updatePayment.error = false
    }
  }, [updatePayment, getDashboardInfo, payments])

  const renderItem = useCallback(
    (item) => (
      <Table.Row
        key={item.uid}
        warning={item.payment_status === 0}
        positive={item.payment_status === 1}
        negative={item.payment_status === 2}
      >
        <Table.Cell>{item.userName}</Table.Cell>
        <Table.Cell>{item.name_account_holder}</Table.Cell>
        <Table.Cell>{item.cnpj_cpf}</Table.Cell>
        <Table.Cell>{item.ag}</Table.Cell>
        <Table.Cell>{item.cc}</Table.Cell>
        <Table.Cell>{item.bankName}</Table.Cell>
        <Table.Cell>
          {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
        </Table.Cell>
        <Table.Cell>
          {item.updated_at &&
            format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
        </Table.Cell>
        <Table.Cell>R$ {item.value}</Table.Cell>
        <Table.Cell textAlign="center" style={{ width: 200 }}>
          <input
            type="file"
            id={`uploadAttachment-${item.uid}`}
            accept="application/pdf"
            style={{ display: 'none' }}
            onChange={(e) => uploadAttachments(e, item.uid)}
          />
          <Popup
            inverted
            trigger={
              <Button
                icon="upload"
                loading={paymentsActionsStatus[`isLoading${item.uid}`]}
                onClick={() => onClickUpload(item.uid)}
              />
            }
            content="Upload do Comprovante"
          />
          {item.attachment !== null && (
            <Popup
              inverted
              trigger={
                <Button
                  primary
                  icon="download"
                  onClick={() => window.open(item.attachment, '_blank')}
                />
              }
              content="Download"
            />
          )}
          {item.url_invoice && (
            <Popup
              inverted
              trigger={
                <Button
                  color="grey"
                  icon="barcode"
                  onClick={() => window.open(item.url_invoice, '_blank')}
                />
              }
              content="Download NF"
            />
          )}
          {item.attachment !== null && item.payment_status === 0 && (
            <Popup
              inverted
              trigger={
                <Button
                  positive
                  icon="check"
                  onClick={() => confirmPayment(item.uid)}
                  loading={paymentsActionsStatus[`isConfirmLoading${item.uid}`]}
                />
              }
              content="Confirmar Pagamento"
            />
          )}
        </Table.Cell>
      </Table.Row>
    ),
    [confirmPayment, paymentsActionsStatus, uploadAttachments, onClickUpload]
  )

  if (
    user.profile.role_name === 'Basic' ||
    user.profile.role_name === 'Leader'
  ) {
    return <EmptyList icon="lock" text="Acesso restrito" />
  }

  return (
    <>
      {!isDashboardInfoLoading && (
        <>
          <BudgetHeaderData
            paymentsNumbersInfo={dashboardInfo}
            onClickStatus={onChangeFilter}
            statusToBorderColor={filters.status}
          />
          <Divider inverted />
        </>
      )}

      {filters && (
        <>
          <BudgetFilter
            filters={filters}
            isLoading={isPaymentsLoading}
            clearFilters={clearFilters}
            onChangeFilter={onChangeFilter}
            submitExport={submitExport}
          />

          <Divider inverted />
        </>
      )}

      <TableComponent
        data={payments}
        columns={columns}
        renderItem={renderItem}
        isLoading={isPaymentsLoading}
        count={count}
        totalCount={totalCount}
        activePage={activePage}
        offset={filters?.offset}
        handlePaginationChange={onPageChange}
        emptyText={emptyText}
        siblingRange={1}
        boundaryRange={0}
        showEllipsis
        showFirstAndLastNav
        showPreviousAndNextNav
      />

      <SemanticToastContainer position="top-right" />
    </>
  )
}
