import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { SemanticToastContainer } from 'react-semantic-toasts'

import updateArray from 'immutability-helper'
import { Divider } from 'semantic-ui-react'

import UserBalance from '~/components/Modal/UserBalance'
import TableComponent from '~/components/Table'
import usePaginationTable from '~/hooks/usePaginationTable'
import useQueryFilters from '~/hooks/useQueryFilters'
import api from '~/services/api'
import {
  USERS,
  USERS_BANNED,
  USER_UPDATE,
  USERS_LEADER_CREATE,
  PAYMENTS_USERS,
} from '~/services/api/endpoints'
import message from '~/utils/messages'

import Item from './components/Item'
import ModalLeader from './components/Modal'
import ModalExport from './components/ModalExport'
import UserFilter from './components/UserFilter'

const columns = [
  'Nome',
  'Email',
  'Telefone',
  'Code',
  'OS',
  'Saldo',
  'Pagos',
  'Versão',
  'Acesso',
  'Criação',
  'Ações',
]

/* eslint-disable */
const columnsMapForApiSort = {
  'Nome': 'name',
  'Email': 'email',
  'Telefone': 'phone',
  'Code': 'code',
  'OS': 'platform',
  'Saldo': 'balance',
  'Pagos': 'paid',
  'Versão': 'app_version',
  'Acesso': 'last_access',
  'Criação': 'created_at',
}
/* eslint-enable */

const initialFilters = {
  name: null,
  email: null,
  phone: null,
  code: null,
  platform: null,
  balance: null,
  paid: null,
  app_version: null,
  badges: null,
  cities: null,
  ufs: null,
  last_access: null,
  created_at: null,
  direction: 'desc',
  order: 'created_at',
  limit: 50,
  offset: 0,
}

export default function Users() {
  const [users, setUsers] = useState([])
  const [isLoadingUsers, setIsLoadingUsers] = useState(true)

  const [leader, setLeader] = useState(null)
  const [userId, setUserId] = useState('')

  const [userBalanceFields, setUserBalanceFields] = useState({
    obs: '',
    value: '',
    userName: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [modalVisible, setModalVisible] = useState(false)

  const [modalLeaderFields, setModalLeaderFields] = useState({
    badgeId: null,
    leaderLimit: '',
  })
  const [isLoadingModal, setIsLoadingModal] = useState(false)
  const [modalLeader, setModalLeader] = useState(false)

  const [showModalExport, setShowModalExport] = useState(false)

  const user = useSelector((store) => store.user)

  const parseQueryParamsBeforeSetFilters = useCallback((queryObj) => {
    const parsedParams = {}

    if (queryObj.limit) {
      parsedParams.limit = Number(queryObj.limit)
    }
    if (queryObj.offset) {
      parsedParams.offset = Number(queryObj.offset)
    }
    if (queryObj.last_access) {
      parsedParams.last_access = new Date(Number(queryObj.last_access))
    }
    if (queryObj.created_at) {
      parsedParams.created_at = new Date(Number(queryObj.created_at))
    }

    return parsedParams
  }, [])

  const parseFiltersBeforeSetQueryParams = useCallback((filters) => {
    const parsedFilters = {}

    if (filters.cities) {
      parsedFilters.cities = filters.cities.map((cityObj) => cityObj.cityName)
    }

    return parsedFilters
  }, [])

  const {
    filters,
    debouncedFilters,
    setFilters,
    onChangeFilters,
    clearFilters,
  } = useQueryFilters(
    initialFilters,
    parseQueryParamsBeforeSetFilters,
    parseFiltersBeforeSetQueryParams
  )

  const {
    count,
    totalCount,
    activePage,
    calculatePagination,
    handleSort,
    onPageChange,
    emptyText,
  } = usePaginationTable(debouncedFilters, setFilters, columnsMapForApiSort)

  const catchError = (error) => {
    console.log(error)
    message().error(error.response.data.data || 'Houve um erro no servidor')
  }

  const getValidParams = useCallback(() => {
    const validParams = { ...debouncedFilters }

    if (validParams.last_access) {
      validParams.last_access = new Date(validParams.last_access).getTime()
    }
    if (validParams.created_at) {
      validParams.created_at = new Date(validParams.created_at).getTime()
    }
    if (validParams.cities) {
      validParams.cities = validParams.cities.map((cityObj) => cityObj.cityName)
    }

    return validParams
  }, [debouncedFilters])

  const getUsers = useCallback(async () => {
    try {
      const validParams = getValidParams()
      setIsLoadingUsers(true)
      const res = await api.get(USERS, { params: validParams })
      setUsers(res.data.data)
      calculatePagination(res.data?.data[0]?.total_rows || 0)
    } catch (error) {
      catchError(error)
    } finally {
      setIsLoadingUsers(false)
    }
  }, [calculatePagination, getValidParams])

  useEffect(() => {
    if (debouncedFilters !== null) {
      getUsers()
    }
  }, [debouncedFilters, getUsers])

  const updateBanned = useCallback(
    async ({ banned, uid }) => {
      try {
        await api.put(USERS_BANNED, {
          userId: uid,
          banned: !banned,
        })
        getUsers()
      } catch (error) {
        catchError(error)
      }
    },
    [getUsers]
  )

  const submit = useCallback(async () => {
    setIsLoading(true)

    if (!userBalanceFields.userName) {
      setIsLoading(false)
      setErrors((er) =>
        er.concat('Digite o nome do responsável pela requisição!')
      )
      return false
    }

    if (!userBalanceFields.obs) {
      setIsLoading(false)
      setErrors((er) => er.concat('Descreva na observação o motivo do saque!'))
      return false
    }

    try {
      await api.post(PAYMENTS_USERS, {
        obs: userBalanceFields.obs,
        value: userBalanceFields.value,
        user_name: userBalanceFields.userName,
        payment_status: 0,
        user_id: userId,
      })
      getUsers()
      setModalVisible(false)
      return true
    } catch (error) {
      catchError(error)
      return false
    } finally {
      setIsLoading(false)
    }
  }, [getUsers, userBalanceFields, userId])

  const handleUserBalanceInputChange = useCallback(
    (e, { id, value }) =>
      setUserBalanceFields((ubf) => ({ ...ubf, [id]: value })),
    []
  )

  const setMember = useCallback(
    async (item) => {
      const index = users.findIndex((i) => i.uid === item.uid)
      const updateData = updateArray(users[index], {
        $set: {
          ...item,
          unlimited_members: !item.unlimited_members,
        },
      })
      const newData = updateArray(users, {
        $splice: [[index, 1, updateData]],
      })
      setUsers(newData)

      try {
        await api.put(USER_UPDATE(item.uid), {
          ...item,
          unlimited_members: !item.unlimited_members,
        })
        getUsers()
      } catch (error) {
        catchError(error)
      }
    },
    [getUsers, users]
  )

  const openModal = useCallback((id) => {
    setModalLeader(true)
    setLeader(id)
  }, [])

  const submitLeaderLimit = useCallback(async () => {
    if (Number(modalLeaderFields.leaderLimit) === 0) {
      return message().error('Limite deve ser maior que zero!')
    }

    try {
      await api.post(USERS_LEADER_CREATE, {
        status: true,
        is_leader: true,
        user_id: leader,
        badge_id: modalLeaderFields.badgeId,
        max_users: modalLeaderFields.leaderLimit,
      })
      getUsers()
      message().success('Líder criado com sucesso!')
      return true
    } catch (error) {
      catchError(error)
      return false
    } finally {
      setModalLeader(false)
      setIsLoadingModal(false)
    }
  }, [getUsers, leader, modalLeaderFields])

  const handleUpdate = useCallback((target, key) => {
    setModalLeaderFields((mlf) => ({ ...mlf, [key]: target.value }))
  }, [])

  const onClickExport = useCallback(() => {
    setShowModalExport(true)
  }, [])

  const renderItem = useCallback(
    (item, index) => (
      <Item
        item={item}
        index={index}
        key={item.uid}
        banned={updateBanned}
        openModal={openModal}
        setMember={setMember}
        role={user.profile.role_name}
        request={() => {
          setUserId(item.uid)
          setModalVisible(true)
          setUserBalanceFields((ubf) => ({ ...ubf, value: item.balance }))
        }}
      />
    ),
    [openModal, setMember, user.profile.role_name] //eslint-disable-line
  )

  return (
    <>
      {filters && (
        <>
          <UserFilter
            role={user.profile.role_name}
            filters={filters}
            isLoading={isLoadingUsers}
            clearFilters={clearFilters}
            onClickExport={onClickExport}
            onChangeFilters={onChangeFilters}
          />

          <Divider inverted />
        </>
      )}

      <TableComponent
        data={users}
        columns={columns}
        renderItem={renderItem}
        isLoading={isLoadingUsers}
        count={count}
        totalCount={totalCount}
        activePage={activePage}
        offset={filters?.offset}
        handleColumnSort={handleSort}
        handlePaginationChange={onPageChange}
        emptyText={emptyText}
        siblingRange={1}
        boundaryRange={0}
        showEllipsis
        showFirstAndLastNav
        showPreviousAndNextNav
      />

      <UserBalance
        fields={userBalanceFields}
        handlerInput={handleUserBalanceInputChange}
        isLoading={isLoading}
        submit={submit}
        errors={errors}
        open={modalVisible}
        close={() => setModalVisible(false)}
      />

      <ModalLeader
        fields={modalLeaderFields}
        handleUpdate={handleUpdate}
        onPress={submitLeaderLimit}
        isLoading={isLoadingModal}
        open={modalLeader}
        close={() => setModalLeader(false)}
      />

      <ModalExport
        filters={filters}
        getValidParams={getValidParams}
        open={showModalExport}
        close={() => setShowModalExport(false)}
      />

      <SemanticToastContainer position="top-right" />
    </>
  )
}
