import React from 'react'
import { connect } from 'react-redux'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import { format } from 'date-fns'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { head, length, isEmpty } from 'ramda'
import {
  Table,
  Popup,
  Loader,
  Button,
  Message,
  Confirm,
  Pagination,
} from 'semantic-ui-react'

import CreateBadge from '~/components/CreateBadge'
import EmptyList from '~/components/EmptyList'
import {
  create,
  findAll,
  findById,
  update,
  removeById,
} from '~/store/modules/badges/actions'

import Main from '../Main'

import '~/assets/css/react-draft-wysiwyg.css'

class Badges extends React.Component {
  state = {
    limit: 10,
    offset: 0,
    activePage: 0,
    badges: [],
    siblingRange: 1,
    boundaryRange: 0,
    showEllipsis: true,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,

    open: false,
    show: false,
    success: true,
    isLoading: false,

    errors: [],
    removeId: '',
    isFilterLoading: false,

    title: '',
    avatar: '',
    urlVideo: '',
    description: '',
    isExclusive: false,
    editorState: EditorState.createEmpty(),
  }

  componentDidMount() {
    this.props.findAll()
  }

  handlerInput = (e, { id, value }) => this.setState({ [id]: value })

  onChange = () => this.setState({ isExclusive: !this.state.isExclusive })

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    })
  }

  uploadImage = (e) => {
    const file = head(e.target.files)
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = (event) => {
      this.setState({
        avatar: {
          src: event.target.result,
          data: file,
        },
      })
    }
  }

  create = () => {
    const { avatar, title, isExclusive, description, urlVideo } = this.state

    if (isEmpty(avatar)) {
      this.showToast('Imagem não pode estar vazia!')
      return false
    }

    if (isEmpty(title)) {
      this.showToast('Título não pode estar vazio!')
      return false
    }

    if (!isExclusive) {
      if (isEmpty(urlVideo)) {
        this.showToast('Vídeo não pode estar vazio!')
        return false
      }

      if (isEmpty(description)) {
        this.showToast('Descrição não pode estar vazia!')
        return false
      }
    }

    this.setState({ errors: [], isLoading: true })

    const newData = new FormData()
    newData.set('title', title)
    newData.set('exclusive', isExclusive)
    newData.set('url_video', urlVideo)
    newData.set('description', description)
    newData.append('avatar', avatar.data)

    this.props.create(newData)
  }

  showToast = (description, type) => {
    toast(
      {
        description,
        title: 'Alerta',
        type: type ? 'success' : 'warning',
        icon: type ? 'check' : 'remove',
      },
      () => console.log('toast closed')
    )
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.badgeCreate !== this.props.badgeCreate) {
      if (nextProps.badgeCreate.success) {
        setTimeout(() => {
          this.setState({
            title: '',
            avatar: '',
            description: '',
            isLoading: false,
          })
          this.props.findAll()
          nextProps.badgeCreate.success = false
          this.showToast('Categoria criada com sucesso!', true)
        }, 1000)
      } else if (nextProps.badgeCreate.error) {
        this.setState({ isLoading: false })
        this.showToast(nextProps.badgeCreate.message)
        nextProps.badgeCreate.error = false
      }
    }

    if (nextProps.badgeUpdate !== this.props.badgeUpdate) {
      if (nextProps.badgeUpdate.success) {
        this.props.findAll()
        nextProps.badgeUpdate.success = false
        this.showToast('Item atualizado com sucesso!', true)
      } else if (nextProps.badgeUpdate.error) {
        nextProps.badgeUpdate.error = false
      }
    }
  }

  handlerCancel = () => this.setState({ open: false })

  updateItem = (item) => {
    const newData = new FormData()
    newData.set('active', !item.active)
    this.props.update(newData, item.uid)
  }

  openRemoveId = (removeId) =>
    setTimeout(() => {
      this.setState({ removeId, open: true })
    }, 200)

  renderCells = (item, index) => (
    <Table.Row
      key={`index-${index}`}
      negative={!item.active}
      positive={item.active}
    >
      <Table.HeaderCell
        style={{
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img alt="avatar" src={item.avatar} style={{ width: 50, height: 30 }} />
      </Table.HeaderCell>
      <Table.Cell>{item.id}</Table.Cell>
      <Table.Cell>{item.title}</Table.Cell>
      <Table.Cell>
        {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        {format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Popup
          inverted
          trigger={
            <Button
              icon="search"
              onClick={() => this.props.history.push(`badges/${item.uid}`)}
            />
          }
          content="Editar"
        />
        {!item.exclusive && (
          <Popup
            inverted
            trigger={
              <Button
                color="purple"
                icon="file"
                onClick={() =>
                  this.props.history.push(`badges/${item.uid}/form`)
                }
              />
            }
            content="Exame"
          />
        )}
        <Button
          icon={item.active ? 'remove' : 'check'}
          color={item.active ? 'red' : 'green'}
          onClick={() => this.updateItem(item)}
        />
      </Table.Cell>
    </Table.Row>
  )

  renderComponent = () => (
    <>
      <Confirm
        header="Alerta"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        open={this.state.open}
        onConfirm={this.removeItem}
        onCancel={this.handlerCancel}
        content="Tem certeza deseja alterar o status desse item?"
      />
      <SemanticToastContainer position="bottom-right" />
      {length(this.state.errors) > 0 && (
        <Message
          fluid
          warning
          header="Alerta!"
          style={{ padding: 10 }}
          list={this.state.errors}
        />
      )}
      <Button
        size="small"
        color={this.state.show ? 'red' : 'blue'}
        onClick={() => this.setState({ show: !this.state.show })}
      >
        {this.state.show ? 'Fechar' : 'Criar'}
      </Button>
      <CreateBadge
        {...this}
        {...this.state}
        create={() => this.create()}
        myRef={(ref) => (this.upload = ref)}
        onClick={() => this.upload.click()}
        onEditorStateChange={this.onEditorStateChange}
      />
      {this.props.findBadges.isFetching ? (
        <div>
          <Loader active inline="centered" />
        </div>
      ) : length(this.props.findBadges.data) ? (
        <div className="container">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Criado Em</Table.HeaderCell>
                <Table.HeaderCell>Atualizado Em</Table.HeaderCell>
                <Table.HeaderCell>Ações</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {length(this.props.findBadges.data) > 0 &&
                this.props.findBadges.data.map(this.renderCells)}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <Pagination
                    totalPages={this.state.limit}
                    activePage={this.state.activePage}
                    siblingRange={this.state.siblingRange}
                    boundaryRange={this.state.boundaryRange}
                    onPageChange={this.handlePaginationChange}
                    ellipsisItem={this.state.showEllipsis ? undefined : null}
                    firstItem={
                      this.state.showFirstAndLastNav ? undefined : null
                    }
                    lastItem={this.state.showFirstAndLastNav ? undefined : null}
                    prevItem={
                      this.state.showPreviousAndNextNav ? undefined : null
                    }
                    nextItem={
                      this.state.showPreviousAndNextNav ? undefined : null
                    }
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      ) : (
        <EmptyList icon="shield" text="Nenhum Badge..." />
      )}
    </>
  )

  render() {
    return (
      <Main {...this.props}>
        {this.props.user.profile.role_name === 'Basic' ||
        this.props.user.profile.role_name === 'Leader' ? (
          <EmptyList icon="lock" text="Acesso restrito" />
        ) : (
          this.renderComponent()
        )}
      </Main>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    user: store.user,
    findBadge: store.findBadge,
    findBadges: store.findBadges,
    badgeCreate: store.badgeCreate,
    badgeUpdate: store.badgeUpdate,
    badgeRemove: store.badgeRemove,
  }
}

export default connect(mapStateToProps, {
  create,
  update,
  removeById,
  findAll,
  findById,
})(Badges)
