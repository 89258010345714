import React from 'react'

import { Form, Modal, Button, Dropdown } from 'semantic-ui-react'

const AccountUpdate = ({
  close,
  openModal,
  submitUpdate,
  handlerUpdate,
  handlerUpdateRole,

  name,
  email,
  login,
  role_id,
  roles,
  newPass,
  isLoading,
}) => (
  <Modal size="small" open={openModal} onClose={() => openModal}>
    <Modal.Header>Atualizar Conta</Modal.Header>
    <Modal.Content>
      <Form>
        <Form.Input
          type="text"
          id="name-update"
          value={name || ''}
          onChange={handlerUpdate}
          placeholder="Nome"
        />

        <Form.Input
          type="text"
          id="email-update"
          placeholder="Email"
          value={email || ''}
          onChange={handlerUpdate}
          autoComplete="username"
        />

        <Form.Input
          type="text"
          id="login-update"
          placeholder="Username"
          value={login || ''}
          onChange={handlerUpdate}
          autoComplete="username"
        />

        <Form.Input
          type="password"
          id="password-update"
          placeholder="Nova Senha"
          value={newPass || ''}
          onChange={handlerUpdate}
          autoComplete="new-password"
        />

        <Dropdown
          fluid
          selection
          options={roles}
          placeholder="Role"
          value={role_id || null}
          onChange={handlerUpdateRole}
        />
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button content="Cancelar" onClick={close} />
      <Button
        positive
        icon="checkmark"
        content="Atualizar"
        labelPosition="right"
        loading={isLoading}
        disabled={isLoading}
        onClick={submitUpdate}
      />
    </Modal.Actions>
  </Modal>
)

export default AccountUpdate
