import React from 'react'
import { Editor } from 'react-draft-wysiwyg'

import t from 'prop-types'
import { isEmpty, is } from 'ramda'
import { Card, Form, Grid, Image, Button, Icon } from 'semantic-ui-react'

const CreateCategories = ({
  show,
  name,
  avatar,
  create,
  isLoading,
  myRef,
  onClick,
  uploadImage,
  editorState,
  handlerInput,
  onEditorStateChange,
}) => {
  let avatarImg = avatar

  if (isEmpty(avatar)) {
    avatarImg = 'https://react.semantic-ui.com/images/avatar/large/matthew.png'
  } else if (is(Object, avatar)) {
    avatarImg = avatar.base64
  }

  return (
    <Card
      centered
      fluid
      style={{ marginTop: 10, display: show ? 'block' : 'none' }}
    >
      <Card.Content>
        <Grid columns={2} relaxed>
          <Grid.Column width={3}>
            <Card>
              <Image size="medium" verticalAlign="middle" src={avatarImg} />
              <Card.Content>
                <input
                  type="file"
                  id="uploadImage"
                  accept="image/*"
                  style={{ display: 'none' }}
                  ref={myRef}
                  onChange={uploadImage}
                />
                <Button
                  icon="upload"
                  content="Upload"
                  onClick={onClick}
                  labelPosition="right"
                />
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column width={13}>
            <Form>
              <Form.Input
                id="name"
                type="text"
                value={name || ''}
                onChange={handlerInput}
                label="Nome (Obrigatório)"
                placeholder="Ex: Pesquisas"
              />
              <div className="field">
                <label>Descrição (Obrigatório)</label>
                <div className="editor">
                  <Editor
                    editorState={editorState}
                    editorClassName="editorClassName"
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    onEditorStateChange={onEditorStateChange}
                    style={{ borderWidth: 1, borderColor: 'gray' }}
                  />
                </div>
              </div>
            </Form>
          </Grid.Column>
        </Grid>
      </Card.Content>
      <Card.Content extra textAlign="right">
        <Button
          icon
          positive
          onClick={create}
          loading={isLoading}
          disabled={isLoading}
          labelPosition="left"
        >
          <Icon name="check" /> Salvar
        </Button>
      </Card.Content>
    </Card>
  )
}

CreateCategories.propTypes = {
  show: t.bool,
  name: t.string,
  avatar: t.string,
  create: t.func,
  isLoading: t.bool,
  myRef: t.string,
  onClick: t.func,
  uploadImage: t.func,
  editorState: t.objectOf(t.any),
  handlerInput: t.func,
  onEditorStateChange: t.func,
}

export default CreateCategories
