import React from 'react'

import { format } from 'date-fns'
import t from 'prop-types'
import { Table, Dropdown } from 'semantic-ui-react'

import history from '~/services/history'

const Item = ({ item, role, banned, request, setMember, openModal }) => (
  <Table.Row
    key={item.uid}
    positive={item.status && !item.banned}
    negative={!item.status && !item.banned}
    warning={(item.status || !item.status) && item.banned}
  >
    <Table.Cell>{item.name}</Table.Cell>
    <Table.Cell>{item.email}</Table.Cell>
    <Table.Cell>{item.phone}</Table.Cell>
    <Table.Cell>{item.code}</Table.Cell>
    <Table.Cell>{item.platform}</Table.Cell>
    <Table.Cell>{item.balance}</Table.Cell>
    <Table.Cell>{item.paid}</Table.Cell>
    <Table.Cell>{item.app_version}</Table.Cell>
    <Table.Cell>
      {format(new Date(item.last_access), 'dd/MM/yyyy HH:mm')}
    </Table.Cell>
    <Table.Cell>
      {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
    </Table.Cell>
    <Table.Cell textAlign="center" style={{ width: 150 }}>
      <Dropdown text="Opções" pointing="right" className="link item">
        <Dropdown.Menu>
          <Dropdown.Item
            icon="edit"
            content="Dados"
            onClick={() => history.push(`user/${item.uid}`)}
          />

          <Dropdown.Item
            onClick={() => banned(item)}
            icon={!item.banned ? 'ban' : 'hand peace'}
            content={!item.banned ? 'Banir' : 'Desbanir'}
          />

          {role === 'Admin' && (
            <>
              <Dropdown.Item
                icon="shield"
                content="Badges"
                onClick={() => history.push(`user/${item.uid}/badges`)}
              />

              {item.balance > 0 && (
                <Dropdown.Item
                  content="Saque Avulso"
                  icon="cart arrow down"
                  onClick={() => request()}
                />
              )}

              <Dropdown.Item
                onClick={() => setMember(item)}
                icon={!item.unlimited_members ? 'star outline' : 'star'}
                content={
                  !item.unlimited_members
                    ? 'Ativar Member Get Member Ilimitado'
                    : 'Desativar Member Get Member Ilimitado'
                }
              />
              {!item.is_leader && (
                <Dropdown.Item
                  icon="sitemap"
                  content="Ativar Líder Consultor"
                  onClick={() => openModal(item.uid)}
                />
              )}
              {item.is_leader && (
                <>
                  <Dropdown.Item
                    icon="users"
                    content="Consultores"
                    onClick={() => history.push(`user/${item.uid}/consultants`)}
                  />
                  <Dropdown.Item
                    icon="sitemap"
                    style={{ color: 'red' }}
                    content="Remover Líder Consultor"
                    onClick={() => console.log(item.uid)}
                  />
                </>
              )}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Table.Cell>
  </Table.Row>
)

Item.propTypes = {
  item: t.objectOf(t.any),
  role: t.string,
  banned: t.bool,
  request: t.func,
  setMember: t.func,
  openModal: t.func,
}

export default Item
