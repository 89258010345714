import React from 'react'

import moment from 'moment'
import t from 'prop-types'
import { isNil } from 'ramda'
import { Table, Button, Label, Icon } from 'semantic-ui-react'

const MissionsCell = ({
  item,
  openModalDetail,
  removeItem,
  updateStatus,
  openStatistics,
  openModalForms,
  openModalAttachments,
  openModalParticipants,
}) => (
  <Table.Row key={item.uid} negative={!item.status}>
    <Table.Cell>
      {item.participants === parseInt(item.has_finished, 2) && (
        <Label color="teal" size="small" ribbon>
          Finalizado
        </Label>
      )}
      {parseInt(item.has_started, 0) > 0 && (
        <Label color="blue" ribbon size="small">
          Iniciada
        </Label>
      )}
      {item.mission_key}
    </Table.Cell>
    <Table.Cell>{item.identifier}</Table.Cell>
    <Table.Cell>{item.customers_name}</Table.Cell>
    <Table.Cell>{item.title}</Table.Cell>
    <Table.Cell>
      {moment(item.created_at).format('YYYY-MM-DD') ===
        moment().format('YYYY-MM-DD') && (
        <Label color="red" size="small">
          Novo
        </Label>
      )}{' '}
      {item.name}
    </Table.Cell>
    <Table.Cell>{isNil(item.username) ? 'MASTER' : item.username}</Table.Cell>
    <Table.Cell>
      {moment(item.created_at).utc().local().format('DD/MM/YYYY HH:mm')}
    </Table.Cell>
    <Table.Cell>
      {moment(item.expired_at).utc().local().format('DD/MM/YYYY HH:mm')}
    </Table.Cell>
    <Table.Cell>
      {item.status ? (
        <Button negative onClick={updateStatus}>
          Inativar
        </Button>
      ) : (
        <Button positive onClick={updateStatus}>
          Ativar
        </Button>
      )}
    </Table.Cell>
    <Table.Cell>
      <Button.Group basic size="small">
        <Button icon onClick={openModalDetail}>
          <Icon name="edit" />
        </Button>
        {item.participants > 0 && (
          <Button icon onClick={openModalParticipants}>
            <Icon name="users" />
          </Button>
        )}
        {item.has_attachment && (
          <Button icon onClick={openModalAttachments}>
            <Icon name="file pdf outline" />
          </Button>
        )}
        {item.has_forms && (
          <Button icon onClick={openModalForms}>
            <Icon name="file text" />
          </Button>
        )}
        {item.has_forms && (
          <Button icon onClick={openStatistics}>
            <Icon name="bar chart" />
          </Button>
        )}
        {parseInt(item.has_started, 0) === 0 &&
          parseInt(item.has_finished, 0) === 0 && (
            <Button icon onClick={removeItem}>
              <Icon name="trash" />
            </Button>
          )}
      </Button.Group>
    </Table.Cell>
  </Table.Row>
)

MissionsCell.propTypes = {
  item: t.objectOf(t.any),
  openModalDetail: t.func,
  removeItem: t.func,
  updateStatus: t.func,
  openStatistics: t.func,
  openModalForms: t.func,
  openModalAttachments: t.func,
  openModalParticipants: t.func,
}

export default MissionsCell
