import React, { useMemo, memo } from 'react'

import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Table, Label, Dropdown } from 'semantic-ui-react'

import config from '~/utils/configPaymentOptions'

import styles from '../styles'

const situations = {
  Ativo: 'green',
  OnHold: 'yellow',
  Inativo: 'red',
}

function Item({
  item,
  index,
  history,
  handlerRates,
  showConfirmRemove,
  getCustomersCategories,
}) {
  const trimSitutation = item.situation
    ? item.situation.replace(/\s/g, '')
    : null

  const dateFormatted = useMemo(
    () => format(new Date(item.contract_start), 'dd/MM/yyyy'),
    [item.contract_start]
  )

  return (
    <Table.Row key={`index-${index}`}>
      <Table.Cell>{item.contract_start && dateFormatted}</Table.Cell>
      <Table.Cell>
        {item.situation && (
          <Label color={situations[trimSitutation]} horizontal>
            {item.situation}
          </Label>
        )}
      </Table.Cell>
      <Table.Cell>{item.name}</Table.Cell>
      <Table.Cell>{item.phone}</Table.Cell>
      <Table.Cell>{config[item.customer_payment_type]}</Table.Cell>
      <Table.Cell>{item.limit}</Table.Cell>
      <Table.Cell>{item.amount}</Table.Cell>
      <Table.Cell>{item.total_spend}</Table.Cell>
      <Table.Cell>{item.billing_estimated}</Table.Cell>
      <Table.Cell style={styles.rateButton} onClick={() => handlerRates(item)}>
        {item.rate_avg}
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          button
          labeled
          floating
          text="Opções"
          icon="options"
          className="icon"
        >
          <Dropdown.Menu>
            <Dropdown.Item
              text="Ver Categorias"
              icon="tasks"
              onClick={() => getCustomersCategories(item)}
            />
            <Dropdown.Item
              text="Editar"
              icon="pencil alternate"
              onClick={() => history.push(`customers/${item.uid}`)}
            />
            <Dropdown.Item
              text="Remover"
              icon="trash"
              onClick={() => showConfirmRemove(item)}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
    </Table.Row>
  )
}

Item.propTypes = {
  index: PropTypes.number,
  handlerRates: PropTypes.func,
  showConfirmRemove: PropTypes.func,
  getCustomersCategories: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
}

export default memo(Item)
