import React from 'react'
import update from 'react-addons-update'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { connect } from 'react-redux'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'
import { SortableContainer, arrayMove } from 'react-sortable-hoc'

import filesize from 'filesize'
import { isNil, isEmpty } from 'ramda'
import { Tab, Confirm, Dropdown } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import RegisterImages from '~/components/Modal/RegisterImages'
import ReproveList from '~/components/Modal/ReproveList'
import PDFList from '~/components/PDF'
import PhotosList from '~/components/Photos'
import { sendFiles, getFiles, removeFile } from '~/store/modules/files/actions'
import { searchPlaces } from '~/store/modules/missions/actions'
import {
  rotate,
  removeById,
  sendImages,
  updateOrder,
  getAllImages,
  getImagesCount,
} from '~/store/modules/photos/actions'
import { updateStatus, findById } from '~/store/modules/registers/actions'

const SortablePhotos = SortableContainer(PhotosList)

class Photos extends React.Component {
  state = {
    itemLoading: null,
    limit: 15,
    items: [],
    address: [],
    newItems: [],
    scrollTop: 0,
    className: '',
    hasChange: false,
    isLoading: false,
    removeItem: null,
    editPhoto: false,
    registerStatus: null,
    isLoadingRotate: false,
    isLoadingImages: false,
    openReprovedModal: false,

    hasReproved: '',
    reproveError: '',
    approvedBy: '',
    hasOtherReproved: '',
    files: [],
    images: [],
    attachs: [],
    errors: [],
    place: '',
    lat: null,
    lng: null,
    open: false,
    lightboxIsOpen: false,
    openImageModal: false,
    progress: null,
    sendLoading: false,
    isLoadingRemove: false,

    currentImage: 0,
  }

  constructor(props) {
    super(props)
    this.registerId = this.props.match.params.id
    this.loadingMore = this.loadingMore.bind(this)
    this.missionsUsersId = this.props.match.params.missionsUsersId
  }

  componentWillMount() {
    this.onLoad()
  }

  componentDidMount() {
    this.props.findById(this.registerId)
  }

  addItems = (e, { id }) => {
    const itemIndex = this.state.items.findIndex(e => e.uid === id)
    const value = this.state.items[itemIndex]
    const index = this.state.newItems.indexOf(value)
    index !== -1
      ? this.setState({
          newItems: update(this.state.newItems, { $splice: [[index, 1]] }),
        })
      : this.setState({ newItems: [...this.state.newItems, value] })
  }

  confirmStatus = statusRegister => {
    const {
      uid,
      mission_id,
      customer_id,
      status,
      customer_value,
      has_value_percentage,
    } = this.props.registerDetail.data

    if (has_value_percentage && status === 3) {
      if (
        this.state.cost_value === 0 ||
        isNil(this.state.cost_value) ||
        isEmpty(this.state.cost_value)
      ) {
        this.showToast('Valor do custo deve ser maior que zero!')
        return false
      }
    }

    if (
      status === 5 &&
      this.state.hasReproved.length === 0 &&
      this.state.hasOtherReproved.length === 0
    ) {
      this.showToast('Resposta de correção não pode estar vazia!')
      return false
    }

    this.setState({ openReprovedModal: false, isLoading: true })

    this.props.updateStatus({
      customer_id,
      customer_value,
      registerId: uid,
      missionId: mission_id,
      status: statusRegister,
      cost_value: this.state.cost_value,
      has_reproved: this.state.hasReproved || this.state.hasOtherReproved,
    })
  }

  gotoNext = () => this.setState({ currentImage: this.state.currentImage + 1 })

  closeLightbox = () =>
    this.setState({ currentImage: 0, lightboxIsOpen: false })

  gotoPrevious = () =>
    this.setState({ currentImage: this.state.currentImage - 1 })

  openLightbox = index =>
    this.setState({ currentImage: index, lightboxIsOpen: true })

  onLoad = () => {
    this.setState({ hasChange: false })
    this.props.getImagesCount(this.missionsUsersId)
    this.props.getFiles(this.missionsUsersId)
    this.props.getAllImages({
      limit: this.state.limit,
      missionsUsersId: this.missionsUsersId,
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { items } = this.state
    this.setState({ items: arrayMove(items, oldIndex, newIndex) })
    this.props.updateOrder({ items: arrayMove(items, oldIndex, newIndex) })
  }

  isChecked = item => this.state.newItems.findIndex(e => e === item) !== -1

  rotateImage = (uid, rotate) => {
    this.showToast(`Iniciando rotação (${rotate}˚)`)
    this.props.rotate({ uid, rotate })
  }

  sendImages = () => {
    if (isNil(this.state.lat) && isNil(this.state.lng)) {
      this.showToast('Você precisa adicionar uma localização!', true)
      return false
    }

    if (this.state.images.length === 0) {
      this.showToast('Você precisa enviar pelo menos uma imagem!', true)
      return false
    }

    this.setState({ isLoadingImages: true })

    this.state.images.forEach(data =>
      this.props.sendImages({
        data,
        ...this.state,
      })
    )
  }

  hasFinished = (item, index, array) => item.progress === 100

  removeImage = id =>
    this.setState({
      images: this.state.images.filter(item => item.uniqueId !== id),
    })

  searchAddress = address => this.props.searchPlaces(address)

  loadingMore = e => {
    e.preventDefault()
    const limit = this.state.limit + 20
    this.setState({ limit, isLoading: true })
    this.props.getAllImages({
      limit,
      missionsUsersId: this.missionsUsersId,
    })
  }

  handleContextRef = contextRef => this.setState({ contextRef })

  showToast = (text, flag) =>
    toast(
      {
        title: 'Alerta',
        type: flag ? 'warning' : 'success',
        icon: flag ? 'remove' : 'check',
        description: text,
      },
      () => console.log('toast closed')
    )

  handlerResponse = props => {
    if (props.success) {
      this.setState({ items: props.data, isLoading: false })
    }
  }

  handlerStateResponse = (props, state) => {
    if (props.success) {
      this.setState({ [`${state}`]: props.data })
      props.success = false
    }
  }

  handlerRemoveResponse = props => {
    if (props.success) {
      this.showToast('Foto removida com sucesso!', true)
      this.onLoad()

      this.state.newItems.length === 0 &&
        this.setState({ isLoadingRemove: false })

      if (this.state.newItems.length) {
        const index = this.state.newItems.filter(
          e => e === this.state.removeItem
        )
        this.setState({
          newItems: update(this.state.newItems, { $splice: [[index, 1]] }),
          isLoadingRemove: false,
        })
      }
      props.success = false
    } else if (props.error) {
      this.showToast(props.message)
      props.error = false
      return false
    }
  }

  handlerUpdateRegister = props => {
    if (props.success) {
      this.setState({ isLoading: false })
      this.props.findById(this.registerId)
      this.showToast('Registro atualizado com sucesso!')
      props.success = false
    } else if (props.error) {
      this.showToast(props.message, true)
      this.setState({ isLoading: false })
      props.error = false
      return false
    }
  }

  uploadFile = (id, data) => {
    this.setState({
      images: this.state.images.map(file =>
        id === file.uniqueId ? { ...file, ...data } : file
      ),
    })
  }

  handlerPhotosCreate = props => {
    if (props.success) {
      const { progress, uniqueId } = props
      this.uploadFile(uniqueId, { progress })
      if (this.state.images.every(this.hasFinished)) {
        this.onLoad()
        this.setState({
          images: [],
          openImageModal: false,
          isLoadingImages: false,
        })
      }
      props.success = false
    } else if (props.error) {
      const { uniqueId } = props
      this.uploadFile(uniqueId, { error: true })
      props.error = false
    }
  }

  uploadAttachments = e => {
    const arr = []
    const { files } = e.target

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      arr.push(file)
      this.setState({ files: this.state.files.concat(file) })
    }

    setTimeout(() => {
      this.setState({ progress: 0 })
      this.props.sendFiles({ missionsUsersId: this.missionsUsersId, data: arr })
    }, 1000)
  }

  uploadImages = e => {
    const { files } = e.target
    const { mission_id, user_id } = this.props.registerDetail.data
    for (let i = 0; i < files.length; i++) {
      const uniqueId = new Date().getTime().toString()
      setTimeout(() => {
        this.setState({
          openImageModal: true,
          images: [
            ...this.state.images,
            {
              user_id,
              mission_id,
              progress: 0,
              error: false,
              src: files[i],
              size: filesize(files[i].size),
              table: 'photos',
              preview: URL.createObjectURL(files[i]),
              missions_users_id: this.missionsUsersId,
              uniqueId: `${uniqueId}${this.state.items.length + i + 1}`,
              order: this.state.images.length + this.state.items.length + 1,
            },
          ],
        })
      }, 1000)
    }
  }

  removeId = id => this.props.removeFile(id)

  openModal = () => this.setState({ open: true })

  handlerInput = (e, { id, value }) => {
    id === 'place' && this.searchAddress(value)
    this.setState({ [id]: value })
  }

  changeAddress = ({ place, lat, lng }) =>
    this.setState({ place, lat, lng, address: [] })

  removeImages = () => {
    this.setState({ isLoadingRemove: true })
    this.state.newItems.forEach(item => {
      this.props.removeById(item.uid)
      setTimeout(() => this.setState({ removeItem: item }), 500)
    })
  }

  handlerPhotosRotate = props => {
    if (props.success) {
      this.showToast('Foto alterada com sucesso!')
      props.success = false
    } else if (props.error) {
      this.showToast(props.message)
      props.error = false
      return false
    }
  }

  componentWillReceiveProps({
    files,
    photos,
    filePost,
    fileRemove,
    photosCreate,
    photosRemove,
    photosRotate,
    registersUpdate,
    searchPlacesResponse,
  }) {
    if (this.props.registersUpdate !== registersUpdate) {
      this.handlerUpdateRegister(registersUpdate)
    }

    if (this.props.photosRotate !== photosRotate) {
      this.handlerPhotosRotate(photosRotate)
    }

    if (this.props.photos !== photos) {
      this.handlerResponse(photos)
    }

    if (this.props.photosRemove !== photosRemove) {
      this.handlerRemoveResponse(photosRemove)
    }

    if (searchPlacesResponse !== this.props.searchPlacesResponse) {
      this.handlerStateResponse(searchPlacesResponse, 'address')
    }

    if (this.props.photosCreate !== photosCreate) {
      if (photosCreate.progress > 0) {
        const { progress, uniqueId } = photosCreate
        this.uploadFile(uniqueId, { progress })
      }

      this.handlerPhotosCreate(photosCreate)
    }

    if (this.props.filePost !== filePost) {
      if (filePost.progress > 0) {
        this.setState({ sendLoading: true, progress: filePost.progress })
      }

      if (this.state.progress === 100) {
        this.showToast('Item enviado com sucesso!')
        this.setState({ sendLoading: false, progress: null })
        this.props.getFiles(this.missionsUsersId)
        files.success = false
      }
    }

    if (this.props.files !== files) {
      if (files.success) {
        this.setState({ attachs: files.data })
      } else if (files.error) {
        console.log(files.message)
      }
    }

    if (this.props.fileRemove !== fileRemove) {
      if (fileRemove.success) {
        this.props.getFiles(this.missionsUsersId)
        this.showToast('Item removido com sucesso!')
        files.fileRemove = false
      } else if (fileRemove.error) {
        this.showToast(fileRemove.message, true)
      }
    }
  }

  gridSortablePhotos = () => (
    <SortablePhotos
      axis="xy"
      {...this}
      {...this.props}
      {...this.state}
      {...this.handlersPhotosList}
      useDragHandle={this.state.editPhoto}
      myRef={ref => (this.uploadImage = ref)}
      onClick={() => this.uploadImage.click()}
    />
  )

  handlersPhotosList = {
    onSortEnd: this.onSortEnd.bind(this),
    onClickSelection: () =>
      this.setState({
        newItems: this.state.newItems.length > 0 ? [] : this.state.items,
        editPhoto: true,
      }),
    onClickDrag: () =>
      this.setState({
        editPhoto: !this.state.editPhoto,
      }),
  }

  handleConfirm = () => {
    this.confirmStatus(this.state.registerStatus)
    this.setState({ openConfirm: false })
  }

  handleChangeReproved = (e, value) =>
    this.setState({
      hasOtherReproved: '',
      hasReproved: value.label,
    })

  handlerChangeOthersReproved = (e, { value }) =>
    this.setState({
      hasReproved: '',
      hasOtherReproved: value,
    })

  render() {
    const panes = [
      {
        menuItem: { key: 'f', icon: 'file image outline', content: 'Fotos' },
        render: () => (
          <Tab.Pane attached={false}>{this.gridSortablePhotos()}</Tab.Pane>
        ),
      },
      {
        menuItem: { key: 'p', icon: 'file pdf outline', content: 'PDF' },
        render: () => (
          <Tab.Pane attached={false}>
            <PDFList
              {...this}
              {...this.state}
              myRef={ref => (this.upload = ref)}
              onClick={() => this.upload.click()}
            />
          </Tab.Pane>
        ),
      },
    ]
    const { uid, status, missionName } = this.props.registerDetail.data
    const breadcrumbsList = [
      {
        active: false,
        hasLink: true,
        name: 'Registros',
        link: '/registers',
      },
      {
        active: false,
        hasLink: true,
        name: missionName,
        link: `/register/${uid}`,
      },
      {
        active: true,
        hasLink: false,
        name: `Arquivos (${this.props.photosCount.data.count})`,
      },
    ]
    const tagOptions = [
      {
        text: 'Aprovar',
        value: 'Aprovar',
        label: { color: 'green', empty: true, circular: true },
        onClick: () => this.setState({ registerStatus: 0, openConfirm: true }),
        status: 3,
      },
      {
        text: 'Correção',
        value: 'Correção',
        label: { color: 'blue', empty: true, circular: true },
        onClick: () => this.setState({ openReprovedModal: true }),
        status: 3,
      },
      {
        text: 'Reverter',
        value: 'Reverter',
        label: { color: 'yellow', empty: true, circular: true },
        onClick: () => this.setState({ registerStatus: 3, openConfirm: true }),
        status: 0,
      },
    ]
    return (
      <>
        <Breadcrumbs
          hasButton
          items={breadcrumbsList}
          history={this.props.history}
        >
          <Dropdown item text="Avaliação" disabled={status === 2}>
            <Dropdown.Menu>
              {tagOptions.map(
                option =>
                  status === option.status && (
                    <Dropdown.Item key={option.value} {...option} />
                  )
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Breadcrumbs>
        <RegisterImages
          {...this}
          {...this.state}
          close={() => this.setState({ openImageModal: false })}
        />
        <Tab
          panes={panes}
          className="tab-segment"
          style={{ marginTop: 10 }}
          menu={{ secondary: true, pointing: true }}
        />
        <Confirm
          header="Alerta"
          cancelButton="Não"
          confirmButton="Sim"
          open={this.state.openConfirm}
          onConfirm={this.handleConfirm}
          content="Tem certeza que deseja alterar esse registro?"
          onCancel={() => this.setState({ openConfirm: false })}
        />
        <ReproveList
          {...this.state}
          evaluationHandler={this.confirmStatus}
          handleChangeReproved={this.handleChangeReproved}
          close={() => this.setState({ openReprovedModal: false })}
          handlerChangeOthersReproved={this.handlerChangeOthersReproved}
        />
        <ModalGateway>
          {this.state.lightboxIsOpen ? (
            <Modal onClose={this.closeLightbox}>
              <Carousel showThumbnails views={this.state.items} />
            </Modal>
          ) : null}
        </ModalGateway>
        <SemanticToastContainer position="top-right" />
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    files: store.files,
    photos: store.photos,
    filePost: store.filePost,
    hasOrder: store.updateOrder,
    fileRemove: store.fileRemove,
    photosCount: store.photosCount,
    photosCreate: store.photosCreate,
    photosRotate: store.photosRotate,
    photosRemove: store.photosRemove,
    registerDetail: store.registerDetail,
    registersUpdate: store.registersUpdate,
    searchPlacesResponse: store.searchPlaces,
  }
}

export default connect(mapStateToProps, {
  rotate,
  getFiles,
  findById,
  sendFiles,
  sendImages,
  removeById,
  searchPlaces,
  removeFile,
  updateOrder,
  updateStatus,
  getAllImages,
  getImagesCount,
})(Photos)
