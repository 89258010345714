import { FORMS_USERS_DATA } from '~/services/api/endpoints'
import { get } from '~/utils/actionsRequests'

const findByUserId = (id) =>
  get({
    url: `${FORMS_USERS_DATA}?user_id=${id}`,
    typeFetch: 'FORMS_USERS_DATA',
    typeError: 'FORMS_USERS_DATA_FAIL',
    typeResponse: 'FORMS_USERS_DATA_OK',
  })

export { findByUserId }
