import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-semantic-toasts'

import { Button, Form, Card } from 'semantic-ui-react'

import logo from '~/assets/logo-white.png'
import { signInRequest } from '~/store/modules/auth/actions'

function showWarning(title) {
  return toast({ type: 'warning', title })
}

export default function Login() {
  const [values, setValues] = useState({ username: '', password: '' })

  const auth = useSelector((store) => store.auth)

  const dispatch = useDispatch()

  function submit() {
    if (!values.username) {
      showWarning('Login não pode estar vazio!')
      return false
    }

    if (!values.password) {
      showWarning('Senha não pode estar vazia!')
      return false
    }

    return dispatch(signInRequest({ ...values }))
  }

  function onChange(key, value) {
    return setValues({ ...values, [key]: value })
  }

  return (
    <header className="App-header">
      <div style={{ textAlign: 'center' }}>
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <Card centered>
        <Card.Content>
          <Form warning size="large">
            <Form.Input
              type="text"
              label="Email/Username"
              placeholder="Email/Username"
              onChange={(e) => onChange('username', e.target.value)}
            />
            <Form.Input
              type="password"
              label="Senha"
              placeholder="Digite sua senha"
              onChange={(e) => onChange('password', e.target.value)}
            />
            <Button
              color="orange"
              content="Entrar"
              onClick={submit}
              loading={auth.isLoading}
              disabled={auth.isLoading}
            />
          </Form>
        </Card.Content>
      </Card>
    </header>
  )
}
