const initialState = {
  error: false,
  success: false,
  data: [],
  message: '',
  isFetching: false,
}

const FEEDS = 'FEEDS'
const FEEDS_OK = 'FEEDS_OK'
const FEEDS_FAIL = 'FEEDS_FAIL'

export default function feedsReducers(state = initialState, action) {
  const { data, message, type } = action

  switch (type) {
    case FEEDS:
      return { isFetching: true }

    case FEEDS_OK:
      return { isFetching: false, data, success: true }

    case FEEDS_FAIL:
      return { isFetching: false, error: true, message, success: false }

    default:
      return state
  }
}
