import {
  PAYMENTS,
  PAYMENTS_FILE,
  PAYMENTS_USERS,
  PAYMENTS_UPDATE,
} from '~/services/api/endpoints'
import { get, put, post } from '~/utils/actionsRequests'

const report = ({ initial, final, status }) =>
  get({
    url: `${PAYMENTS}?status=${status}&initial=${initial}&final=${final}`,
    typeFetch: 'PAYMENTS',
    typeError: 'PAYMENTS_FAIL',
    typeResponse: 'PAYMENTS_OK',
  })

const uploadFile = (data) =>
  post({
    data,
    url: PAYMENTS_FILE,
    typeFetch: 'PAYMENTS_FILE',
    typeError: 'PAYMENTS_FILE_FAIL',
    typeResponse: 'PAYMENTS_FILE_OK',
  })

const update = (data) =>
  put({
    data,
    url: PAYMENTS_UPDATE,
    typeFetch: 'PAYMENTS_UPDATE',
    typeError: 'PAYMENTS_UPDATE_FAIL',
    typeResponse: 'PAYMENTS_UPDATE_OK',
  })

const paymentRequest = (data) =>
  post({
    data,
    url: PAYMENTS_USERS,
    typeFetch: 'PAYMENTS_USERS',
    typeError: 'PAYMENTS_USERS_FAIL',
    typeResponse: 'PAYMENTS_USERS_OK',
  })

export { report, update, uploadFile, paymentRequest }
