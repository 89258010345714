import React from 'react'

import t from 'prop-types'
import { isNil } from 'ramda'
import { Image, Button, Card, Input } from 'semantic-ui-react'

import Avatar from '~/assets/avatar.png'

const Item = ({
  item,
  index,
  loading,
  addUser,
  remove,
  handleChange,
  percentualValue,
  getConsultantFormsUsers,
}) => {
  const avatar = isNil(item.avatar) ? Avatar : item.avatar
  return (
    <Card key={`index-${index}`}>
      <Card.Content>
        <Image floated="right" size="mini" src={avatar} />
        <Card.Header>{item.user_name}</Card.Header>
        <Card.Meta style={{ color: item.status ? 'green' : 'red' }}>
          {`Consultor ${item.status ? 'Ativo' : 'Inativo'}`}
        </Card.Meta>
        <Card.Description>
          <Input
            type="number"
            icon="percent"
            iconPosition="left"
            placeholder={
              item.percentual ? `Comissão (${item.percentual})` : 'Comissão'
            }
            value={percentualValue[item.uid]}
            onChange={(e, { value }) =>
              item.uid && handleChange(value, item.uid)
            }
          />
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Button.Group fluid>
          <Button
            basic
            compact
            color="green"
            loading={loading}
            disabled={loading}
            onClick={() => addUser(item)}
            content={item.status ? 'Atualizar' : 'Adicionar'}
          />
          <Button
            basic
            compact
            color="blue"
            content="Dados"
            onClick={() => getConsultantFormsUsers(item)}
          />
          {item.status && (
            <Button
              basic
              compact
              color="red"
              content="Remover"
              loading={loading}
              disabled={loading}
              onClick={() => remove(item)}
            />
          )}
        </Button.Group>
      </Card.Content>
    </Card>
  )
}

Item.propTypes = {
  item: t.objectOf(t.any),
  index: t.string,
  loading: t.bool,
  addUser: t.func,
  remove: t.func,
  handleChange: t.func,
  percentualValue: t.string,
  getConsultantFormsUsers: t.func,
}

export default Item
