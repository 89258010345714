import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'

import PropTypes from 'prop-types'
import { Form, List, Image, Icon, Modal, Button } from 'semantic-ui-react'

import 'react-circular-progressbar/dist/styles.css'

const MissionsDetail = ({
  close,
  images,
  place,
  address,
  sendImages,
  removeImage,
  handlerInput,
  changeAddress,
  openImageModal,
  isLoadingImages,
}) => (
  <Modal size="tiny" open={openImageModal} onClose={close}>
    <Modal.Header content="Enviar Imagens" />
    <Modal.Content scrolling>
      <Form>
        <Form.Input
          type="text"
          id="place"
          value={place}
          onChange={handlerInput}
          label="Digite o endereço (Obrigatório)"
          placeholder="Ex: Florianópolis"
        />
        {place.length > 2 && (
          <List selection verticalAlign="middle">
            {address.map((i, index) => {
              const place = i.formatted_address
              const { lat, lng } = i.geometry.location
              return (
                <List.Item
                  key={`key-geo-${index}`}
                  onClick={() => changeAddress({ place, lat, lng })}
                >
                  <List.Icon style={{ color: 'red' }} name="marker" />
                  <List.Content>
                    <List.Header as="a">{place}</List.Header>
                  </List.Content>
                </List.Item>
              )
            })}
          </List>
        )}
        {images.length > 0 && (
          <List divided verticalAlign="middle">
            {images.map((i, index) => {
              return (
                <List.Item
                  key={`key-${index}`}
                  style={{ alignItems: 'center' }}
                  onClick={() => removeImage(i.uniqueId)}
                >
                  <List.Content floated="right">
                    {!i.error && i.progress !== 100 && (
                      <CircularProgressbar
                        styles={{
                          root: { width: 40 },
                          path: { stroke: '#7159c1' },
                        }}
                        strokeWidth={10}
                        percentage={i.progress}
                      />
                    )}

                    {i.error && (
                      <Icon name="remove circle" size={40} color="red" />
                    )}

                    {i.progress === 100 && (
                      <Icon
                        name="chevron circle down"
                        size={40}
                        color="green"
                      />
                    )}
                  </List.Content>
                  <Image
                    size="tiny"
                    src={i.preview}
                    verticalAlign="middle"
                    style={{ borderRadius: 4, maxHeight: 40 }}
                  />
                  <List.Content>
                    <List.Header>{i.order}. Ordem</List.Header>
                    <span>{i.size}</span>
                  </List.Content>
                </List.Item>
              )
            })}
          </List>
        )}
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button
        negative
        onClick={close}
        content="Cancelar"
        loading={isLoadingImages}
        disabled={isLoadingImages}
      />
      <Button
        positive
        icon="checkmark"
        content="Enviar"
        labelPosition="right"
        onClick={sendImages}
        loading={isLoadingImages}
        disabled={isLoadingImages}
      />
    </Modal.Actions>
  </Modal>
)

export default MissionsDetail

MissionsDetail.propTypes = {
  mission: PropTypes.object,
  open: PropTypes.bool,
  close: PropTypes.func,
}
