import api from '~/services/api'
import {
  PHOTOS,
  PHOTO_SINGLE,
  PHOTOS_COUNT,
  PHOTOS_ORDER,
  PHOTOS_ROTATE,
} from '~/services/api/endpoints'
import { get, put, remove } from '~/utils/actionsRequests'

const getAllImages = ({ missionsUsersId, limit }) =>
  get({
    url: `${PHOTOS}/${missionsUsersId}?limit=${limit}`,
    typeFetch: 'PHOTOS',
    typeError: 'PHOTOS_FAIL',
    typeResponse: 'PHOTOS_OK',
  })

const getImagesCount = (id) =>
  get({
    url: `${PHOTOS_COUNT}${id}`,
    typeFetch: 'PHOTOS_COUNT',
    typeError: 'PHOTOS_COUNT_FAIL',
    typeResponse: 'PHOTOS_COUNT_OK',
  })

const updateOrder = (data) =>
  put({
    data,
    url: `${PHOTOS_ORDER}`,
    typeFetch: 'PHOTOS_ORDER',
    typeError: 'PHOTOS_ORDER_FAIL',
    typeResponse: 'PHOTOS_ORDER_OK',
  })

const removeById = (id) =>
  remove({
    url: `${PHOTOS}/${id}`,
    typeFetch: 'PHOTOS_REMOVE',
    typeError: 'PHOTOS_REMOVE_FAIL',
    typeResponse: 'PHOTOS_REMOVE_OK',
  })

const rotate = (data) =>
  put({
    data,
    url: PHOTOS_ROTATE,
    typeFetch: 'PHOTOS_ROTATE',
    typeError: 'PHOTOS_ROTATE_FAIL',
    typeResponse: 'PHOTOS_ROTATE_OK',
  })

const sendImages = ({ data, lat, lng }) => {
  const { uniqueId, src, order, table } = data
  return async (dispatch) => {
    const url = PHOTO_SINGLE
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        dispatch({
          type: 'PHOTOS_CREATE',
          progress: percentCompleted,
          uniqueId,
        })
      },
    }

    try {
      const newData = new FormData()
      newData.append(
        'info',
        JSON.stringify({
          lat,
          lng,
          src,
          order,
          table,
          user_id: data.user_id,
          mission_id: data.mission_id,
          missions_users_id: data.missions_users_id,
        })
      )
      newData.append('src', src)
      const res = await api.post(url, newData, config)

      if (res.status === 200 || res.status === 201) {
        dispatch({
          type: 'PHOTOS_CREATE_OK',
          data: res.data.data || res.data,
          uniqueId,
        })
      }
    } catch (e) {
      const message = e || 'Houve um erro no servidor'
      dispatch({
        type: 'PHOTOS_CREATE_FAIL',
        message,
        uniqueId,
        error: true,
      })
    }
  }
}

export {
  rotate,
  sendImages,
  updateOrder,
  removeById,
  getAllImages,
  getImagesCount,
}
