import { produce } from 'immer'

const INITIAL_STATE = {
  isLoading: false,
  signed: false,
}

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS':
        draft.signed = true
        draft.isLoading = false
        break
      case '@auth/SIGN_IN_REQUEST':
        draft.isLoading = true
        break
      case '@auth/SIGN_FAILURE':
        draft.signed = false
        draft.isLoading = false
        break
      case '@auth/SIGN_OUT':
        draft.signed = false
        draft.isLoading = false
        break

      default:
    }
  })
}
