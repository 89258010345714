export default [
  {
    key: 0,
    text: 'Atividade',
    value: 'activity',
  },
  {
    key: 1,
    text: 'Expirado Em',
    value: 'expired_at',
  },
  {
    key: 2,
    text: 'Identificador',
    value: 'identifier',
  },
  {
    key: 3,
    text: 'Nome da Missão',
    value: 'mission_name',
  },
  {
    key: 4,
    text: 'Tipo Logradouro',
    value: 'type_street',
  },
  {
    key: 5,
    text: 'Endereço',
    value: 'street',
  },
  {
    key: 6,
    text: 'Número',
    value: 'number',
  },
  {
    key: 7,
    text: 'CEP',
    value: 'zip_code',
  },
  {
    key: 8,
    text: 'Município',
    value: 'city',
  },
  {
    key: 9,
    text: 'Bairro',
    value: 'neighborhood',
  },
  {
    key: 10,
    text: 'Complemento',
    value: 'complement',
  },
  {
    key: 11,
    text: 'UF',
    value: 'state',
  },
  {
    key: 12,
    text: 'Criado Em',
    value: 'created_at',
  },
  {
    key: 13,
    text: 'Formulário',
    value: 'form_id',
  },
  {
    key: 14,
    text: 'Extra',
    value: 'extra',
  },
  {
    key: 15,
    text: 'Extra 1',
    value: 'extra_1',
  },
  {
    key: 16,
    text: 'Extra 2',
    value: 'extra_2',
  },
  {
    key: 17,
    text: 'Extra 3',
    value: 'extra_3',
  },
]
