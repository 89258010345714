import React from 'react'

import { Form, Radio, Modal, Button, Message } from 'semantic-ui-react'

function BadgesList({
  close,
  error,
  openModal,
  reproveError,
  badgeId,
  handlerChangeRadio,
  badges,
  onSaveBadge,
  onRemoveBadge,
}) {
  return (
    <Modal size="small" onClose={close} open={openModal}>
      <Modal.Header>Escolha o Badge</Modal.Header>
      <Modal.Content>
        {error && <Message warning header="Alerta!" content={reproveError} />}
        <Form>
          {badges.map((item, index) => {
            return (
              <Form.Field key={`key-${index}`}>
                <Radio
                  name="radio"
                  label={item.title}
                  value={item.uid}
                  checked={badgeId === item.uid}
                  onChange={handlerChangeRadio}
                />
              </Form.Field>
            )
          })}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          content="Remover Badge"
          onClick={() => onRemoveBadge()}
        />
        <Button content="Cancelar" onClick={close} />
        <Button positive content="Salvar" onClick={() => onSaveBadge()} />
      </Modal.Actions>
    </Modal>
  )
}

export default BadgesList
