import React, { memo, useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'

import PropTypes from 'prop-types'
import {
  Select,
  Button,
  Card,
  Form,
  Input,
  Icon,
  Dropdown,
} from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'
import useUfsAndCitiesFilters from '~/hooks/useUfsAndCitiesFilters'
import api from '~/services/api'
import { BADGET_FETCH } from '~/services/api/endpoints'
import message from '~/utils/messages'

function UserFilter({
  role,
  filters,
  isLoading,
  clearFilters,
  onClickExport,
  onChangeFilters,
}) {
  const [badgesOptions, setBadgesOptions] = useState([])

  const {
    ufsOptions,
    citiesOptions,
    onChangeUFs,
    onChangeCities,
  } = useUfsAndCitiesFilters(filters, onChangeFilters)

  useEffect(() => {
    ;(async () => {
      try {
        const res = await api.get(BADGET_FETCH)
        setBadgesOptions(
          res.data.data.map((i, key) => ({
            key,
            value: i.uid,
            text: i.title,
          }))
        )
      } catch (error) {
        console.log(error)
        message().error(error.response.data.data || 'Houve um erro no servidor')
      }
    })()
  }, [])

  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Nome"
              value={filters.name || ''}
              onChange={(e, { value }) => onChangeFilters({ name: value })}
            />

            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Email"
              value={filters.email || ''}
              onChange={(e, { value }) => onChangeFilters({ email: value })}
            />

            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Telefone"
              value={filters.phone || ''}
              onChange={(e, { value }) => onChangeFilters({ phone: value })}
            />

            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Code"
              value={filters.code || ''}
              onChange={(e, { value }) => onChangeFilters({ code: value })}
            />

            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="OS"
              value={filters.platform || ''}
              onChange={(e, { value }) => onChangeFilters({ platform: value })}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Saldo"
              value={filters.balance || ''}
              onChange={(e, { value }) => onChangeFilters({ balance: value })}
            />

            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Pagos"
              value={filters.paid || ''}
              onChange={(e, { value }) => onChangeFilters({ paid: value })}
            />

            <Form.Field
              fluid
              control={Input}
              icon="search"
              className="icon"
              placeholder="Versão"
              value={filters.app_version || ''}
              onChange={(e, { value }) =>
                onChangeFilters({ app_version: value })
              }
            />

            <Form.Field fluid>
              <DatePicker
                id="last_access"
                selectsStart
                dateFormat="dd/MM/yyyy"
                placeholderText="Último Acesso"
                selected={filters.last_access || ''}
                onChange={(value) => onChangeFilters({ last_access: value })}
              />
            </Form.Field>

            <Form.Field fluid>
              <DatePicker
                id="created_at"
                selectsStart
                dateFormat="dd/MM/yyyy"
                placeholderText="Criado em"
                selected={filters.created_at || ''}
                onChange={(value) => onChangeFilters({ created_at: value })}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field fluid disabled={!badgesOptions.length}>
              <Dropdown
                search
                selection
                multiple
                options={badgesOptions}
                placeholder="Badges"
                value={filters.badges || []}
                onChange={(e, { value }) => onChangeFilters({ badges: value })}
              />
            </Form.Field>

            <Form.Field fluid disabled={!ufsOptions.length}>
              <Dropdown
                search
                selection
                multiple
                options={ufsOptions}
                placeholder="UFs"
                value={filters.ufs || []}
                onChange={(e, { value }) => onChangeUFs(value)}
              />
            </Form.Field>

            <Form.Field
              fluid
              disabled={!citiesOptions.length || !filters?.ufs?.length}
            >
              <Dropdown
                search
                selection
                multiple
                options={citiesOptions}
                placeholder="Cidades"
                value={filters.cities || []}
                onChange={(e, { value }) => onChangeCities(value)}
              />
            </Form.Field>

            <Form.Field
              fluid
              control={Select}
              placeholder="Resultados por página"
              selection
              value={filters.limit || ''}
              options={[
                { key: 1, value: 15, text: 15 },
                { key: 2, value: 50, text: 50 },
                { key: 3, value: 100, text: 100 },
              ]}
              onChange={(e, { value }) => onChangeFilters({ limit: value })}
            />
          </Form.Group>
        </Form>
      </Card.Content>

      <Card.Content>
        <Button
          icon="undo"
          color="grey"
          content="Limpar Filtros"
          labelPosition="left"
          onClick={clearFilters}
          loading={isLoading}
        />

        {role === 'Admin' && (
          <Button
            icon
            color="orange"
            labelPosition="left"
            onClick={onClickExport}
            loading={isLoading}
            disabled={isLoading}
          >
            <Icon name="download" />
            Gerar Relatório
          </Button>
        )}
      </Card.Content>
    </Card>
  )
}

UserFilter.propTypes = {
  role: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool,
  clearFilters: PropTypes.func,
  onClickExport: PropTypes.func,
  onChangeFilters: PropTypes.func,
}

export default memo(UserFilter)
