import { baseURL } from '.'

export const GOOGLE_GEOCODING = `https://maps.google.com/maps/api/geocode/json`

// DASHBOARD
export const DASHBOARD = `master/dashboard`
export const DASHBOARD_REPORT_DATA = `master/report-data`
export const DASHBOARD_REPORT_CATEGORIES = `master/daily-report-totalizers`
export const DASHBOARD_FETCH_COORDINATES = `missions/heatmap/fetch`

// BADGET
export const BADGET_CREATE = `badges/create`
export const BADGET_FETCH = `badges/fetch`
export const BADGET_GET = (id) => `badges/${id}/get`
export const BADGET_UPDATE = (id) => `badges/${id}/update`
export const BADGET_REMOVE = (id) => `badges/${id}/delete`

// USER BADGE
export const BADGES = `badges/fetch`
export const USER_BADGE = (id) => `users/badges/${id}/fetch`
export const USER_BADGE_CREATE = `users/badges/create`
export const USER_BADGE_UPDATE = (id) => `users/badges/${id}/update`
export const USER_BADGE_REMOVE = (id) => `users/badges/${id}/delete`

// BADGET FORM
export const BADGET_FORM_CREATE = `badges/questions/create-with-answers`
export const BADGET_FORM_FETCH = `badges/questions/fetch`
export const BADGET_FORM_GET = `badges/answers/fetch/questions-answers`
export const BADGET_FORM_UPDATE = (id) => `badges/questions/${id}/update`
export const BADGET_FORM_UPDATE_ANSWER = (id) => `badges/answers/${id}/update`
export const BADGET_FORM_REMOVE_QUESTION = (id) =>
  `badges/questions/${id}/delete`
export const BADGET_FORM_CREATE_ANSWER = `badges/answers/create`
export const BADGET_FORM_REMOVE_ANSWER = (id) => `badges/answers/${id}/delete`

export const BADGET_FORM_CHANGE_ORDER_ANSWER = `badges/answers/change-order`
export const BADGET_FORM_CHANGE_ORDER_QUESTIONS = `badges/questions/change-order`

// BADGET FORMS USERS
export const FORMS_USERS_FETCH_TYPE = `users/forms-users/type/fetch`
export const FORMS_USERS_FETCH = `users/forms-users/fetch`
export const FORMS_USERS_CREATE = `users/forms-users/insert`
export const FORMS_USERS_CREATE_TYPE = `users/forms-users/type/insert`
export const FORMS_USERS_UPDATE = (id) => `users/forms-users/${id}/update`
export const FORMS_USERS_DELETE = (id) => `users/forms-users/${id}/remove`

// BADGET FORMS USERS ITEMS
export const FORMS_USERS_ITEMS_FETCH = `users/forms-users-items/fetch`
export const FORMS_USERS_ITEMS_CREATE = `users/forms-users-items/insert`
export const FORMS_USERS_ITEMS_UPDATE = (id) =>
  `users/forms-users-items/${id}/update`
export const FORMS_USERS_ITEMS_DELETE = (id) =>
  `users/forms-users-items/${id}/remove`
export const FORMS_USERS_ITEMS_REORDER = (id) =>
  `users/forms-users-items/${id}/reorder`

// INVOICE
export const INVOICE_FETCH = (id) => `invoices/${id}`

// FORMS USERS DATA
export const FORMS_USERS_DATA = `users/forms-users-data/fetch`

// LEADER
export const USERS_LEADER_FETCH = `user/consultants/fetch`
export const USERS_LEADER_CREATE = `user/consultants/insert`
export const USERS_LEADER_UPDATE = (id) => `user/consultants/${id}/update`
export const USERS_LEADER_REMOVE = (id, badgeId) =>
  `user/consultants/${id}/remove/${badgeId}`

// BADGE MISSIONS MAIN
export const BADGES_MISSIONS_MAIN_UPDATE = (missionsMainId, badgeId) =>
  `missions-main/${missionsMainId}/badges/${badgeId}/add`

// USERS
export const USERS = `master/users`
export const USERS_REPORT = `${baseURL}master/users/report/generate`
export const USERS_BANNED = `master/user-banned`
export const USER_DATA = `master/user-data/`
export const USER_DATA_DETAIL = `master/users/`
export const USER_DATA_FEEDS = `master/feeds-by-user/`
export const USER_DATA_REGISTERS = `master/registers/`
export const USER_UPDATE = (id) => `user/update/${id}`
export const DELIVERY_MESSAGES = `users/delivery-messages`
export const USERS_IN_PROGRESS = (id) => `missions/${id}/users-data`
export const SEARCH_LEADERS = `user/consultants/leaders/fetch`
export const USER_DATA_PAYMENTS = `master/payments-requests-by-user/`
export const USER_NEARBY_BY_MISSION = `master/users-nearby-by-missions`

// REGISTERS
export const MASTER_REGISTERS = `master/registers`
export const REGISTERS_UPDATE = `customers/registers/update`

// CATEGORIES
export const CATEGORY_MISSIONS_MAIN = `master/category-missions-main`
export const CATEGORY_MISSIONS_MAIN_CREATE = `master/category-missions-main-create`
export const CATEGORY_MISSIONS_MAIN_UPDATE = `master/category-missions-main-update`

// MISSIONS MAIN VALUES
export const MISSIONS_MAIN_VALUE_CREATE = `missions-main-value/create`
export const MISSIONS_MAIN_VALUE_UPDATE = `missions-main-value/`
export const MISSIONS_MAIN_VALUE_REMOVE = `missions-main-value/`
export const MISSIONS_MAIN_VALUE_FETCH = `missions-main-value/`

// MISSIONS MAIN
export const MISSIONS_MAIN = `master/missions-main/`
export const MISSIONS_MAIN_ALL = `master/missions-main-customers`
export const MISSIONS_MAIN_CREATE = `master/missions-main-create`
export const MISSIONS_MAIN_BY_CATEGORY = `master/missions-main-by-category/`
export const MISSIONS_MAIN_UPDATE_STATUS = `master/missions-main-update-status`
export const MISSIONS_MAIN_UPDATE = `master/missions-main/update`
export const MISSIONS_MAIN_DELETE = `master/missions-main-delete/`

// PAYMENTS
export const PAYMENTS = `master/payments-requests`
export const PAYMENTS_REPORT = `${baseURL}master/payments-requests/report`
export const PAYMENTS_FILE = `master/payment-request/receipt`
export const PAYMENTS_USERS = `payments-requests/admin/create`
export const PAYMENTS_UPDATE = `master/payment-request/update`
export const PAYMENTS_DISCLAIMER = `master/payment-disclaimer/create`

// LOGS
export const LOGS = `master/logs`

// CREDIT REQUEST
export const CREDIT_REQUESTS = `master/credit-requests/all`
export const UPDATE_CREDIT_REQUESTS = `master/credit-requests/update`

// FILES
export const MISSIONS_FILES_GET = (id) => `missions/${id}/files/fetch`
export const MISSIONS_FILES_POST = (id) => `missions/${id}/files/upload`
export const MISSIONS_FILES_DEL = (id) => `missions/${id}/file/delete`

export const CUSTOMERS = `master/customers`
export const CUSTOMERS_CREATE = `master/customers/create`
export const CUSTOMERS_UPDATE = `master/customers/update`
export const CUSTOMERS_RESTRICT = `customers/`
export const CUSTOMERS_PAYMENT_CONFIG_UPDATE = `customers/payment/config/`
export const CUSTOMERS_PROCESS_PAYMENT = `customers/month-end-closing/process`
export const CUSTOMERS_ID_RESTRICTED = (customerId) =>
  `customers/${customerId}/restricted-sub-categories`

// PROMOTIONS

export const CONFIG_MEMBER_FETCH = `user/get-member/config/fetch`
export const CONFIG_MEMBER_CREATE = `user/get-member/config/create`
export const CONFIG_MEMBER_GET = (id) => `user/get-member/${id}/config/get`
export const CONFIG_MEMBER_UPDATE = (id) =>
  `user/get-member/${id}/config/update`
export const CONFIG_MEMBER_REMOVE = (id) =>
  `user/get-member/${id}/config/remove`

// CONFIG CUSTOMER PAYMENT

export const CONFIG_PAYMENT_UPDATE = (id) =>
  `customers/payment/config/${id}/update`

// ACCOUNTS
export const ACCOUNTS = `logins/fetch`
export const ACCOUNTS_AUTH = `logins/login`

export const CUSTOMER_LOGIN = `customers/login`

export const ACCOUNTS_CREATE = `logins/create`
export const ACCOUNTS_UPDATE = `logins/update`
export const ACCOUNTS_REMOVE = (id) => `logins/${id}/remove`

// ROLES

export const ACCOUNTS_ROLES = `roles/fetch`
export const ACCOUNTS_ROLES_CREATE = `roles/create`
export const ACCOUNTS_ROLES_UPDATE = `roles/update`
export const ACCOUNTS_ROLES_REMOVE = (id) => `roles/${id}/remove`

export const UFS = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados'
export const CITIES = (ufIdsSeparatedByPipe) =>
  `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufIdsSeparatedByPipe}/municipios`

export const UFS_MIS = 'master/helper/states'
export const CITIES_MIS = 'master/helper/cities'

// NOTIFICATIONS

export const CAMPAIGN = 'campaigns'
export const CAMPAIGN_REMOVE = 'campaigns/remove/'
export const CAMPAIGN_CREATE = 'campaigns/create'
export const CAMPAIGN_UPDATE = 'campaigns/update'

// RATES

export const GET_CUSTOMERS_RATES_LIST = 'rates/missions/fetch'

// CUSTOMERS

export const CUSTOMERS_FORGOT = 'customers/recovery'
export const CUSTOMERS_REPORT = 'customers/register-filter/'

// BUDGET
export const BUDGET = 'customers/budget/'
export const BUDGET_REQUEST = 'customers/budget-request'
export const TRANSACTIONS = 'customers/transactions/'
export const TRANSACTIONS_FILTER = 'customers/transactions-report/'
export const AVERAGE_CATEGORIES = 'customers/missions-main/'
export const REPORT_TRANSACTIONS = 'customers/'

// MISSIONS
export const MISSIONS = 'customers/missions/find'
export const MISSIONS_SEARCH = 'missions-search'

export const MISSIONS_CREATE = 'customers/missions-create'
export const MISSIONS_UPDATE = 'customers/missions/update/'
export const MISSIONS_UPDATE_STATUS = 'customers/missions-update-status'
export const MISSIONS_OPEN = 'master/missions-open'
export const MISSION_FEEDS_BY_USER = 'missions-feeds-count-by-user'

export const PHOTOS = 'missions-photos'
export const PHOTOS_COUNT = 'missions-photos-count/'
export const PHOTOS_ORDER = 'missions-photos/change-order'
export const PHOTOS_ROTATE = 'missions-photos/rotate'
export const PHOTO_SINGLE = 'upload-single-photo-mult'

export const METRICS = `${baseURL}reports/missions/grouped`

export const MISSIONS_FEEDS = `customers/missions-feeds/`
export const MISSIONS_PLACES = `missions-places`
export const MISSIONS_PARTICIPANTS = `missions/participants/`

export const ATTACHMENTS = `customers/missions-files/`
export const ATTACHMENTS_UPLOAD = `customers/attachment/`
export const ATTACHMENTS_REMOVE = `customers/missions-files-remove`

export const MISSIONS_USERS_EXPIRING = `missions-users/expiring/fetch`
export const MISSIONS_USERS_EXPIRING_REMOVE = (id) =>
  `missions-users/expiring/${id}/remove-user`
export const MISSIONS_USERS_EXPIRING_EXTEND = (id) =>
  `missions-users/expiring/${id}/extend-expiration`

// REGISTERS
export const REGISTERS_CUSTOMERS = `customers/registers`
export const REGISTERS_FEEDS = `registers/missions-feeds/`
export const REGISTERS_DETAIL = `customers/registers-detail/`
export const REGISTERS_SEARCH = `customers/registers-search/`
export const REGISTERS_FILTER = `customers/register-data-period/`
export const REGISTERS_REPORT = `${baseURL}registers/report/photos/`
export const REGISTERS_ZIP = `${baseURL}customers/extract-files/`
export const REGISTERS_RECEIPT = `${baseURL}registers/receipt/user/`

// CUSTOM STATUS
export const CUSTOM_STATUS_FETCH = `custom-status`
export const CUSTOM_STATUS_UPDATE = `custom-status/update`
export const CUSTOM_STATUS_CREATE = `custom-status/create`
export const CUSTOM_STATUS_REORDER = `custom-status/reorder`
export const CUSTOM_STATUS_REMOVE = (id) => `custom-status/remove/${id}`

// FORMS
export const FORMS_LIST = `customers/forms/`
export const FORMS_DETAIL = `customers/forms-detail/`
export const FORMS_CREATE = `customers/forms/create`
export const FORMS_UPDATE = `customers/forms/update`
export const FORMS_REMOVE = `customers/forms-remove/`

// FORMS ITEMS
export const FORMS_ITEMS_LIST = `customers/forms-items/`
export const FORMS_ITEMS_CREATE = `customers/forms-items/create`
export const FORMS_ITEMS_REMOVE = `customers/forms-items-remove/`
export const FORMS_ITEMS_UPDATE = `customers/forms-items/update`
export const FORMS_ITEMS_UPDATE_ORDER = `customers/forms-items/change-order`

export const FORMS_HAS_MISSIONS = `customers/has-forms-missions/`
export const FORMS_MISSIONS_CREATE = `customers/forms-missions/create`
export const FORMS_MISSIONS_UPDATE = `customers/forms-missions/update`

// FORMS ITEMS QUESTIONS MULTIPLES
export const FORMS_ITEMS_MULTIPLES_CREATE = `customers/forms-items-questions-multiples/create`
export const FORMS_ITEMS_MULTIPLES_REMOVE = `customers/forms-items-questions-multiples-remove/`
export const FORMS_ITEMS_MULTIPLES_UPDATE_ORDER = `customers/forms-items-questions-multiples/change-order`

export const FORMS_DATA = `customers/forms-data-by-mission/`
export const FORMS_DATA_STATISTICS = `customers/forms-data-statistics/`
export const FORMS_DATA_EXPORT = `${baseURL}customers/forms-data-export/`
export const HEAT_MAP = `customers/heat-map/`
export const QUESTIONS_TYPES = `customers-questions-types`
export const APP_VERSIONS_ALL = `customers/versions/all`
export const PHOTO_UPDATE_ORDER = `missions-photos/change-order`
export const PHOTO_DOWNLOAD_ZIP = `${baseURL}customers/extract-files/`

// INTEGRATION
export const IMPORT_PRODUCTS_PDV = `${baseURL}import-products`

// INTEGRATION
export const IMPORT_XLS = `${baseURL}integration/xls/import`
export const IMPORT_FILE = `${baseURL}integration/import-file/fetch`
export const IMPORT_FILE_REMOVE = (id) =>
  `${baseURL}integration/importFile/${id}`
export const IMPORT_FILE_ID = (id) =>
  `${baseURL}integration/importFile/${id}/fetch`
export const IMPORT_XLS_CREATE = (id) =>
  `${baseURL}integration/customer/${id}/import`
export const IMPORT_FILE_CREATE = (id) =>
  `${baseURL}integration/importFile/${id}/create-missions`
export const IMPORT_FILE_UPDATE = (fileId, id) =>
  `${baseURL}integration/importFile/${fileId}/item/${id}/update`
export const IMPORT_FILE_MISSIONS_OPEN = `${baseURL}master/missions-open/excel`

// OTHERS
export const XLS_FILE_S3 =
  'https://s3-us-west-2.amazonaws.com/assetsmissionbr/IMPORT/Exemplo_Importacao.xlsx'

// MEMBER GET MEMBER
export const MGM_FIND_HOST = (guestId) => `user/get-member/${guestId}/find-host`
export const MGM_FIND_GUESTS = (hostId) => `user/get-member/${hostId}/fetch`
