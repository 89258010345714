import { responseData } from '~/utils/reducer'

const CUSTOMERS_LIST = 'CUSTOMERS_LIST'
const CUSTOMERS_DETAIL = 'CUSTOMERS_DETAIL'
const CUSTOMERS_UPDATE = 'CUSTOMERS_UPDATE'
const CUSTOMERS_CREATE = 'CUSTOMERS_CREATE'
const CUSTOMERS_REMOVE = 'CUSTOMERS_REMOVE'
const CUSTOMERS_RESTRICT = 'CUSTOMERS_RESTRICT'
const CUSTOMERS_PROCESS_PAYMENT = 'CUSTOMERS_PROCESS_PAYMENT'
const CONFIG_PAYMENT_UPDATE = 'CONFIG_PAYMENT_UPDATE'
const CUSTOMERS_RESTRICT_UPDATE = 'CUSTOMERS_RESTRICT_UPDATE'

const createCustomer = responseData(CUSTOMERS_CREATE, 'object')
const customerRemove = responseData(CUSTOMERS_REMOVE, 'object')
const customers = responseData(CUSTOMERS_LIST, 'array')
const customersDetail = responseData(CUSTOMERS_DETAIL, 'object')
const customersUpdate = responseData(CUSTOMERS_UPDATE, 'object')
const customerCategories = responseData(CUSTOMERS_RESTRICT, 'array')
const processRequest = responseData(CUSTOMERS_PROCESS_PAYMENT, 'object')
const customersPaymentUpdate = responseData(CONFIG_PAYMENT_UPDATE, 'array')
const customersUpdateSubcategorie = responseData(
  CUSTOMERS_RESTRICT_UPDATE,
  'object'
)

export default {
  createCustomer,
  customers,
  processRequest,
  customerRemove,
  customersDetail,
  customersUpdate,
  customerCategories,
  customersPaymentUpdate,
  customersUpdateSubcategorie,
}
