import React from 'react'
import DatePicker from 'react-datepicker'

import { format } from 'date-fns'
import { always, cond, equals } from 'ramda'
import { Card, Button, Popup, Form } from 'semantic-ui-react'

import { Container, Row } from './styles'

const color = cond([
  [equals('Iniciado'), always('blue')],
  [equals('Missão Incompleta'), always('orange')],
])

function CardItem({
  mission,
  onClick,
  onClickUpdate,
  new_expiration,
  handleExpiration,
}) {
  return (
    <Container>
      <Card.Content>
        <Card.Header>{mission.user_name}</Card.Header>
        <Card.Meta>{mission.email}</Card.Meta>
        <Card.Meta
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={() =>
            window.open(`https://wa.me/${mission.phone.replace('+', '')}`)
          }
        >
          {mission.phone}
        </Card.Meta>
        <Card.Description>
          <Row>
            <strong>MISSAO: </strong>
            <span>{mission.mission_name} </span>
          </Row>
          <Row>
            <strong>STATUS: </strong>
            <span style={{ color: color(mission.mu_progress) }}>
              {mission.mu_progress}
            </span>
          </Row>
          <Row>
            <strong>MB: </strong>
            <span>{mission.mission_key} </span>
          </Row>
          <Row>
            <strong>MU: </strong>
            <span>{mission.mu_key} </span>
          </Row>
          <Row>
            <strong>EXP. MISSAO: </strong>
            <span>
              {format(new Date(mission.expired_at), 'dd/MM/yyyy HH:mm')}
            </span>
          </Row>
          <Row>
            <strong>EXP. USUARIO: </strong>
            <span style={{ cursor: 'pointer' }}>
              <Popup
                flowing
                on="click"
                trigger={
                  <span style={{ color: 'red' }}>{mission.time_to_expire}</span>
                }
              >
                <Form>
                  <Form.Field
                    minDate={new Date()}
                    control={DatePicker}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Alterar Expiração"
                    selected={new_expiration}
                    onChange={(value) =>
                      handleExpiration(value, mission.missions_users_id)
                    }
                  />
                  <Button content="Alterar" onClick={() => onClickUpdate()} />
                </Form>
              </Popup>
            </span>
          </Row>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button
            negative
            icon="trash"
            color="red"
            content="Excluir da Missão"
            onClick={() => onClick(mission)}
          />
        </div>
      </Card.Content>
    </Container>
  )
}

export default CardItem
