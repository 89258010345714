import React from 'react'
import DatePicker from 'react-datepicker'

import { Grid, Card, Form, Button, Dropdown, Checkbox } from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'

const conditional = ({ addValues, customers, categories, subCategories }) => (
  <Grid.Column>
    <h1>Condicional</h1>
    {customers.length > 0 && (
      <Dropdown
        search
        selection
        multiple
        placeholder="Clientes"
        options={customers}
        onChange={(e, { value }) => addValues('customer', value)}
      />
    )}
    {categories.length > 0 && (
      <Dropdown
        search
        selection
        multiple
        options={categories}
        placeholder="Categorias"
        style={{ marginTop: 10 }}
        onChange={(e, { value }) => addValues('category', value)}
      />
    )}
    {subCategories.length > 0 && (
      <Dropdown
        search
        selection
        multiple
        options={subCategories}
        style={{ marginTop: 10 }}
        placeholder="Sub-Categoria"
        onChange={(e, { value }) => addValues('mission_main', value)}
      />
    )}
  </Grid.Column>
)

const RegisterFilter = ({
  final,
  types,
  range,
  groups,
  ranges,
  initial,
  isLoading,
  handlerArray,
  resetFilterSubmit,
  submitFilter,
  customers,
  categories,
  addValues,
  subCategories,
  handlerChange,
}) => (
  <Card fluid>
    <Card.Content>
      <Grid columns={4} divided="vertically">
        <Grid.Row>
          <Grid.Column>
            <h1>Grupos</h1>
            <Form>
              {groups.map((i, index) => (
                <Form.Field key={`i-${index}`}>
                  <Checkbox
                    key={`key-${index}`}
                    label={i.text}
                    value={i.value}
                    onClick={(e, { value }) =>
                      handlerArray('typesGroups', value)
                    }
                  />
                </Form.Field>
              ))}
            </Form>
          </Grid.Column>
          <Grid.Column>
            <h1>Tipos</h1>
            <Form>
              {types.map((i, index) => (
                <Form.Field key={`t-${index}`}>
                  <Checkbox
                    key={`key-${index}`}
                    label={i.text}
                    value={i.value}
                    onClick={(e, { value }) =>
                      handlerArray('typesMissions', value)
                    }
                  />
                </Form.Field>
              ))}
            </Form>
          </Grid.Column>
          <Grid.Column>
            <h1>Período</h1>
            <Button.Group basic style={{ flex: 1 }}>
              {ranges.map((i, idx) => {
                return (
                  <Button
                    key={`key-${idx}`}
                    active={range === i.value}
                    onClick={() => handlerChange('range', i.value)}
                  >
                    {i.text}
                  </Button>
                )
              })}
            </Button.Group>
            <div style={{ marginTop: 10 }}>
              <div
                className="ui input react-datepicker-manage"
                style={{ marginBottom: 10, width: '100%' }}
              >
                <DatePicker
                  id="initial"
                  selectsStart
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Inicio"
                  selected={initial}
                  startDate={initial}
                  endDate={final}
                  onChange={(value) => handlerChange('initial', value)}
                />
              </div>
              <div
                className="ui input react-datepicker-manage"
                style={{ flex: 1 }}
              >
                <DatePicker
                  id="final"
                  selectsEnd
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Data Fim"
                  selected={final}
                  startDate={initial}
                  endDate={final}
                  onChange={(value) => handlerChange('final', value)}
                />
              </div>
            </div>
          </Grid.Column>
          {conditional({
            customers,
            addValues,
            categories,
            subCategories,
          })}
        </Grid.Row>
      </Grid>
    </Card.Content>
    <Card.Content>
      <Button
        icon="check"
        content="Filtrar"
        labelPosition="left"
        color="green"
        loading={isLoading}
        disabled={isLoading}
        onClick={submitFilter}
      />
      <Button
        icon="filter"
        content="Resetar Filtro"
        labelPosition="left"
        color="orange"
        onClick={resetFilterSubmit}
      />
      <Checkbox
        label="Analitico"
        style={{ marginLeft: 10 }}
        onChange={(e, { checked }) => handlerChange('analytical', checked)}
      />
      <Checkbox
        label="Extrair Relatório"
        style={{ marginLeft: 10 }}
        onChange={(e, { checked }) => handlerChange('xls', checked)}
      />
    </Card.Content>
  </Card>
)
export default RegisterFilter
