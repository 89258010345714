import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Header, Icon, Modal, Button, Image, List } from 'semantic-ui-react'

import { findParticipantsById } from '~/store/modules/missions/actions'

export default function MissionParticipants({
  selectedMissionUid,
  missionParticipants,
  isOpenModalParticipants,
  onCloseModalParticipants,
}) {
  const [participants, setParticipants] = useState([])

  const storeMissionsParticipants = useSelector(
    (store) => store.missionsParticipants
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(findParticipantsById({ id: selectedMissionUid }))
  }, [dispatch, selectedMissionUid])

  useEffect(() => {
    if (storeMissionsParticipants.success) {
      setParticipants(storeMissionsParticipants.data)
      storeMissionsParticipants.success = false
    }
  }, [storeMissionsParticipants])

  return (
    <Modal
      size="small"
      open={isOpenModalParticipants}
      onClose={onCloseModalParticipants}
    >
      <Modal.Header>
        {participants.length}/{missionParticipants} Participante(s)
      </Modal.Header>
      <Modal.Content scrolling>
        {participants.length === 0 && (
          <div style={{ textAlign: 'center' }}>
            <Header icon style={{ marginTop: 20 }}>
              <Icon style={{ color: '#b9b9b9' }} name="user outline" />
              <span style={{ color: '#b9b9b9' }}>Nenhum participante...</span>
            </Header>
          </div>
        )}
        <List divided verticalAlign="middle">
          {participants.length > 0 &&
            participants.map((item, index) => {
              const avatar =
                item.userAvatar === null
                  ? require('~/assets/avatar.png')
                  : item.userAvatar

              return (
                <List.Item key={`key-${index}`}>
                  <Image avatar src={avatar} />
                  <List.Content>
                    <List.Header>{item.userName}</List.Header>
                    {item.description}
                  </List.Content>
                </List.Item>
              )
            })}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCloseModalParticipants}>OK</Button>
      </Modal.Actions>
    </Modal>
  )
}
