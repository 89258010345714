import {
  MISSIONS_MAIN,
  MISSIONS_MAIN_ALL,
  AVERAGE_CATEGORIES,
  CUSTOMERS_RESTRICT,
  MISSIONS_MAIN_CREATE,
  MISSIONS_MAIN_UPDATE,
  MISSIONS_MAIN_DELETE,
  CATEGORY_MISSIONS_MAIN,
  BADGES_MISSIONS_MAIN_UPDATE,
  MISSIONS_MAIN_UPDATE_STATUS,
  CATEGORY_MISSIONS_MAIN_CREATE,
  CATEGORY_MISSIONS_MAIN_UPDATE,
  CUSTOMERS_ID_RESTRICTED,
  MISSIONS_MAIN_BY_CATEGORY,
} from '~/services/api/endpoints'
import { get, post, remove, put } from '~/utils/actionsRequests'

const categories = (queryString) =>
  get({
    url: `${CATEGORY_MISSIONS_MAIN}?${queryString}`,
    typeFetch: 'CATEGORIES',
    typeError: 'CATEGORIES_FAIL',
    typeResponse: 'CATEGORIES_OK',
  })

const findCategoryById = (id) =>
  get({
    url: `${CATEGORY_MISSIONS_MAIN}/${id}`,
    typeFetch: 'CATEGORY_DETAIL',
    typeError: 'CATEGORY_DETAIL_FAIL',
    typeResponse: 'CATEGORY_DETAIL_OK',
  })

const categoryUpdate = (data) =>
  put({
    data,
    url: `${CATEGORY_MISSIONS_MAIN_UPDATE}`,
    typeFetch: 'CATEGORY_UPDATE',
    typeError: 'CATEGORY_UPDATE_FAIL',
    typeResponse: 'CATEGORY_UPDATE_OK',
  })

const removeById = (id) =>
  remove({
    url: `${CATEGORY_MISSIONS_MAIN}/${id}`,
    typeFetch: 'CATEGORIES_REMOVE',
    typeError: 'CATEGORIES_REMOVE_FAIL',
    typeResponse: 'CATEGORIES_REMOVE_OK',
  })

const findSubcategories = (id, queryString) =>
  get({
    url: `${MISSIONS_MAIN_BY_CATEGORY}${id}?${queryString}`,
    typeFetch: 'GET_SUB_CATEGORIES',
    typeError: 'GET_SUB_CATEGORIES_FAIL',
    typeResponse: 'GET_SUB_CATEGORIES_OK',
  })

const subCategories = (id) =>
  get({
    url: CUSTOMERS_ID_RESTRICTED(id),
    typeFetch: 'SUB_CATEGORIES',
    typeError: 'SUB_CATEGORIES_FAIL',
    typeResponse: 'SUB_CATEGORIES_OK',
  })

const subCategoriesAll = () =>
  get({
    url: MISSIONS_MAIN_ALL,
    typeFetch: 'MISSIONS_MAIN_ALL',
    typeError: 'MISSIONS_MAIN_ALL_FAIL',
    typeResponse: 'MISSIONS_MAIN_ALL_OK',
  })

const findSubCategoriesById = (id) =>
  get({
    url: `${MISSIONS_MAIN}${id}`,
    typeFetch: 'SUB_CATEGORIES_DETAIL',
    typeError: 'SUB_CATEGORIES_DETAIL_FAIL',
    typeResponse: 'SUB_CATEGORIES_DETAIL_OK',
  })

const subCategoriesCreate = (data) =>
  post({
    data,
    url: MISSIONS_MAIN_CREATE,
    typeFetch: 'SUB_CATEGORIES_CREATE',
    typeError: 'SUB_CATEGORIES_CREATE_FAIL',
    typeResponse: 'SUB_CATEGORIES_CREATE_OK',
  })

const subCategoriesUpdate = (data) =>
  put({
    data,
    url: MISSIONS_MAIN_UPDATE,
    typeFetch: 'SUB_CATEGORIES_UPDATE',
    typeError: 'SUB_CATEGORIES_UPDATE_FAIL',
    typeResponse: 'SUB_CATEGORIES_UPDATE_OK',
  })

const subCategoriesUpdateStatus = (data) =>
  put({
    data,
    url: MISSIONS_MAIN_UPDATE_STATUS,
    typeFetch: 'MISSIONS_MAIN_UPDATE_STATUS',
    typeError: 'MISSIONS_MAIN_UPDATE_STATUS_FAIL',
    typeResponse: 'MISSIONS_MAIN_UPDATE_STATUS_OK',
  })

const subCategoriesUpdateBadge = (data) =>
  put({
    data,
    url: BADGES_MISSIONS_MAIN_UPDATE(data.missionsMainId, data.badgeId),
    typeFetch: 'BADGES_MISSIONS_MAIN_UPDATE',
    typeError: 'BADGES_MISSIONS_MAIN_UPDATE_FAIL',
    typeResponse: 'BADGES_MISSIONS_MAIN_UPDATE_OK',
  })

const removeSubCategorieById = (id) =>
  remove({
    url: `${MISSIONS_MAIN_DELETE}${id}`,
    typeFetch: 'SUB_CATEGORIES_DELETE',
    typeError: 'SUB_CATEGORIES_DELETE_FAIL',
    typeResponse: 'SUB_CATEGORIES_DELETE_OK',
  })

const create = (data) =>
  post({
    data,
    url: CATEGORY_MISSIONS_MAIN_CREATE,
    typeFetch: 'CATEGORY_MISSIONS_MAIN_CREATE',
    typeError: 'CATEGORY_MISSIONS_MAIN_CREATE_FAIL',
    typeResponse: 'CATEGORY_MISSIONS_MAIN_CREATE_OK',
  })

const findByCustomer = (id) =>
  get({
    url: `${CUSTOMERS_RESTRICT}${id}/restricted-sub-categories`,
    typeFetch: 'CAT_BY_CUSTOMER',
    typeError: 'CAT_BY_CUSTOMER_FAIL',
    typeResponse: 'CAT_BY_CUSTOMER_OK',
  })

const average = (id) =>
  get({
    url: `${AVERAGE_CATEGORIES}${id}`,
    typeFetch: 'AVERAGE',
    typeError: 'AVERAGE_FAIL',
    typeResponse: 'AVERAGE_OK',
  })

export {
  findByCustomer,
  average,
  categories,
  findCategoryById,
  categoryUpdate,
  create,
  findSubcategories,
  subCategoriesAll,
  subCategoriesCreate,
  removeById,
  subCategories,
  removeSubCategorieById,
  subCategoriesUpdateStatus,
  subCategoriesUpdate,
  subCategoriesUpdateBadge,
  findSubCategoriesById,
}
