import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import t from 'prop-types'
import { head, isEmpty } from 'ramda'

import Breadcrumbs from '~/components/Breadcrumbs'
import CreateSubCategory from '~/components/CreateSubCategory'
import Main from '~/pages/Main'
import {
  subCategoriesUpdate,
  findSubCategoriesById,
} from '~/store/modules/categories/actions'
import { validURL } from '~/utils/helpers'

import '~/assets/css/react-draft-wysiwyg.css'

const subcategoryData = {
  uid: '',
  title: '',
  avatar: '',
  fix_time: 0,
  user_value: 0,
  mission_commission_percent: 0,
  image_path: '',
  description: '',
  participants: 0,
  expiring_time: 0,
  has_commission: false,
  show_only_if_badge: false,
  has_check_availability: false,
  has_bonus: false,
  has_forms: false,
  has_photo: false,
  customer_value: 0,
  can_restart: false,
  no_location: false,
  has_scanner: false,
  can_multiple: false,
  has_timeline: false,
  user_value_bonus: 0,
  has_attachment: false,
  time_to_appreciate: 0,
  has_import_image: false,
  customer_value_bonus: 0,
  has_custom_status: false,
  has_auto_approval: false,
  has_value_variable: false,
  is_expiring_weekend: false,
  has_value_percentage: false,
  has_import_document: false,
  should_inactivate_when_reproved: false,
}

function Detail({ history, location }) {
  const uploadRef = useRef()
  const uploadBackRef = useRef()
  const { subcategoryId, categoryId } = useParams()

  const dispatch = useDispatch()
  const subCategoriesById = useSelector((state) => state.subCategoriesById)
  const subCategoriesUpdateData = useSelector(
    (state) => state.subCategoriesUpdate
  )

  const [loading, setLoading] = useState(false)
  const [subcategory, setSubcategory] = useState(subcategoryData)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const getDescription = useCallback(
    (data) => {
      const contentBlock = htmlToDraft(data.description)
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      setEditorState(EditorState.createWithContent(contentState))
      return setSubcategory({ ...subcategory, ...data })
    },
    [subcategory]
  )

  const changeState = useCallback(() => {
    if (location.state !== undefined) {
      getDescription(location.state.item)
    }

    return dispatch(findSubCategoriesById(subcategoryId))
  }, [dispatch, location, subcategoryId, getDescription])

  const subcategorySuccessResponse = useCallback(
    (prop) => {
      setSubcategory(prop.data)
      getDescription(prop.data)
      setLoading(false)
      prop.success = false
    },
    [getDescription]
  )

  function handleInput(e, { id, value }) {
    if (id === 'mission_commission_percent' && value === '') {
      return setSubcategory({ ...subcategory, [id]: null })
    }
    return setSubcategory({ ...subcategory, [id]: value })
  }

  function handleChange(e, { id }) {
    if (id === 'has_value_percentage' || id === 'has_value_variable') {
      return setSubcategory({
        ...subcategory,
        user_value: 0.3,
        customer_value: 1,
        mission_commission_percent: null,
        [id]: !subcategory[id],
      })
    }
    return setSubcategory({ ...subcategory, [id]: !subcategory[id] })
  }

  function onEditorStateChange(desc) {
    setEditorState(desc)
    setSubcategory({
      ...subcategory,
      description: draftToHtml(convertToRaw(desc.getCurrentContent())),
    })
  }

  function uploadImage(e) {
    const file = head(e.target.files)
    const reader = new FileReader()
    const state = `${e.target.id}`
    reader.readAsDataURL(file)
    reader.onloadend = (event) => {
      setSubcategory({
        ...subcategory,
        [state]: {
          size: file.size,
          name: file.name,
          type: file.type,
          base64: event.target.result,
        },
      })
    }
  }

  function showToast(description, type) {
    return toast(
      {
        description,
        title: 'Alerta',
        type: type ? 'success' : 'warning',
        icon: type ? 'check' : 'remove',
      },
      () => console.log('toast closed')
    )
  }

  function update() {
    if (isEmpty(subcategory.title)) {
      return showToast('Título não pode estar vazio!')
    }

    if (subcategory.time_to_appreciate <= 0) {
      return showToast('Tempo de Aprovação deve ser maior que zero!')
    }

    if (subcategory.expiring_time <= 0) {
      return showToast('Expiração deve ser maior que zero!')
    }

    if (subcategory.fix_time <= 0) {
      return showToast('Tempo de correção deve ser maior que zero!')
    }

    if (Number(subcategory.customer_value) <= 0) {
      return showToast('Valor do Cliente deve ser maior que zero!')
    }

    if (Number(subcategory.user_value) <= 0) {
      return showToast('Valor do usuário deve ser maior que zero!')
    }

    if (subcategory.has_bonus) {
      if (Number(subcategory.customer_value_bonus) <= 0) {
        return showToast('Valor Bônus do Cliente deve ser maior que zero!')
      }

      if (Number(subcategory.user_value_bonus) <= 0) {
        return showToast('Valor Bônus do usuário deve ser maior que zero!')
      }
    }

    if (subcategory.has_webview) {
      if (!validURL(subcategory.webview_link)) {
        return showToast('URL inválida!')
      }
    }

    if (Number(subcategory.customer_value) <= Number(subcategory.user_value)) {
      return showToast('Valor do Cliente deve ser maior que do usuário!')
    }

    if (subcategory.participants <= 0) {
      return showToast('Participantes deve ser maior que zero!')
    }

    if (isEmpty(subcategory.avatar)) {
      return showToast('Icone não pode estar vazio!')
    }

    if (isEmpty(subcategory.image_path)) {
      return showToast('Background não pode estar vazia!')
    }

    if (isEmpty(subcategory.description)) {
      return showToast('Descrição não pode estar vazia!')
    }

    if (subcategory.has_value_percentage && subcategory.has_value_variable) {
      return showToast(
        'Uma subcategoria só pode ser valor variável ou porcentagem!'
      )
    }

    if (subcategory.total_rows) {
      delete subcategory.total_rows
    }

    setLoading(true)

    return dispatch(subCategoriesUpdate(subcategory))
  }

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      name: 'Categorias',
      link: '/categories',
    },
    {
      active: false,
      hasLink: true,
      name: 'Sub-Categorias',
      link: `/categories/${categoryId}/sub-categories`,
    },
    {
      active: true,
      hasLink: false,
      name: subcategory.title,
    },
  ]

  useEffect(() => {
    changeState()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (subCategoriesById.success) {
      subcategorySuccessResponse(subCategoriesById)
    }

    if (subCategoriesUpdateData.success) {
      showToast('Subcategoria atualizada com sucesso!', true)
      subcategorySuccessResponse(subCategoriesUpdateData)
    }

    if (subCategoriesUpdateData.error) {
      showToast(subCategoriesUpdateData.message)
      setLoading(false)
      subCategoriesUpdateData.error = false
    }
  }, [subCategoriesById, subCategoriesUpdateData, subcategorySuccessResponse])

  return (
    <Main history={history} location={location}>
      <Breadcrumbs items={breadcrumbsList} history={history} />
      <SemanticToastContainer position="top-right" />
      <CreateSubCategory
        show
        {...subcategory}
        myRef={uploadRef}
        isLoading={loading}
        create={() => update()}
        editorState={editorState}
        uploadImage={uploadImage}
        myRefBack={uploadBackRef}
        handleInput={handleInput}
        handleChange={handleChange}
        uploadBackground={uploadImage}
        onEditorStateChange={onEditorStateChange}
        onClick={() => uploadRef.current.click()}
        onClickBack={() => uploadBackRef.current.click()}
      />
    </Main>
  )
}

Detail.propTypes = {
  history: t.objectOf(t.any),
  location: t.objectOf(t.any),
}

export default Detail
