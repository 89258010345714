import React from 'react'

import { format } from 'date-fns'
import { Table, Button, Popup } from 'semantic-ui-react'

export default ({ item, index, openDetail, openRemoveId }) => (
  <>
    <Table.Row key={`index-${index}`}>
      <Table.Cell>{item.id}</Table.Cell>
      <Table.Cell>{item.description}</Table.Cell>
      <Table.Cell>{item.user_value}</Table.Cell>
      <Table.Cell>{item.customer_value}</Table.Cell>
      <Table.Cell>
        {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        {format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Button icon="search" onClick={() => openDetail(item)} />
        <Popup
          inverted
          content="Remover"
          trigger={
            <Button
              icon="trash"
              negative
              onClick={() => openRemoveId(item.uid)}
            />
          }
        />
      </Table.Cell>
    </Table.Row>
  </>
)
