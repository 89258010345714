import React, { memo } from 'react'

import PropTypes from 'prop-types'
import { length } from 'ramda'
import { Card, Table, Loader } from 'semantic-ui-react'

import EmptyList from '../EmptyList'

function CustomerSelectedCategories({ subCategories, isLoadingCategories }) {
  const filteredCategories = subCategories.filter(
    (category) => category.deleted
  )
  return (
    <Card
      centered
      fluid
      style={{
        marginBottom: 20,
        overflowY: 'auto',
        position: 'sticky',
        top: 20,
      }}
    >
      <Card.Content>
        <Card.Header>Sub-categorias selecionadas</Card.Header>
      </Card.Content>

      <Table
        unstackable
        style={{ margin: 0, borderBottom: 0, borderLeft: 0, borderRight: 0 }}
        singleLine
        collapsing
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>NOME</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">ID ATIVIDADE</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!isLoadingCategories &&
            filteredCategories.map((item, index) => (
              <Table.Row key={`keys-${index}`}>
                <Table.Cell>{item.title}</Table.Cell>
                <Table.Cell textAlign="right">{item.id}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {isLoadingCategories && (
        <Card.Content>
          <Loader active inline="centered" />
        </Card.Content>
      )}
      {!isLoadingCategories && length(filteredCategories) === 0 && (
        <EmptyList
          style={{ width: '100%' }}
          marginTop={20}
          icon="tag"
          text="Nenhuma categoria selecionada."
        />
      )}
    </Card>
  )
}

CustomerSelectedCategories.propTypes = {
  subCategories: PropTypes.arrayOf(PropTypes.object),
  isLoadingCategories: PropTypes.bool,
}

export default memo(CustomerSelectedCategories)
