import React from 'react'
import { connect } from 'react-redux'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import _ from 'lodash'
import moment from 'moment'
import { Icon, Table, Button } from 'semantic-ui-react'

import AccountsCreate from '~/components/AccountsCreate'
import AccountsHeader from '~/components/AccountsHeader'
import EmptyList from '~/components/EmptyList'
import AccountsUpdate from '~/components/Modal/AccountUpdate'
import TableComponent from '~/components/Table'
import {
  create,
  findRoles,
  removeById,
  findAccounts,
  update as updateAccount,
} from '~/store/modules/accounts/actions'

import Main from '../Main'

const columns = [
  'ID',
  'Nome',
  'Email',
  'Username',
  'Role',
  'Criado em',
  'Ações',
]

class Accounts extends React.Component {
  state = {
    count: null,
    show: false,
    name: '',
    email: '',
    login: '',
    password: '',
    role_id: null,
    roles: [],

    update: {
      name: '',
      email: '',
      login: '',
      newPass: '',
      role_id: null,
      roles: [],
    },

    errors: [],

    search: {
      uid: '',
      role: '',
      name: '',
      status: '',
      login: '',
    },

    openModal: false,

    limit: 10,
    offset: 0,
    activePage: 0,
    siblingRange: 1,
    totalCount: 0,
    actualCount: 0,
    boundaryRange: 1,
    showEllipsis: true,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,
  }

  constructor() {
    super()
    this.handlerSearch = _.debounce(this.handlerSearch, 500)
  }

  componentDidMount() {
    this.onRefresh()
  }

  showToast = (description, type) => {
    toast(
      {
        description,
        title: 'Alerta',
        type: type ? 'success' : 'warning',
        icon: type ? 'check' : 'remove',
      },
      () => console.log('toast closed')
    )
  }

  onRefresh = () => {
    this.setState({ errors: [] })
    this.props.findAccounts({ ...this.state })
    this.props.findRoles({ ...this.state })
  }

  handlerSearch = (e, { value }) => {
    this.setState({ search: { name: value, login: value } })
    this.props.findAccounts({ ...this.state.search })
  }

  handlePaginationChange = (e, { activePage }) => {
    const offset = (activePage - 1) * this.state.limit
    this.setState((prevState) => ({
      ...prevState,
      activePage,
      offset,
      limit: this.state.limit,
    }))
    this.props.findAccounts({ ...this.state, offset, limit: this.state.limit })
  }

  calculatePagination = (props) => {
    if (props.success) {
      const newCount = Number(props.count)
      const value = newCount / this.state.limit
      const currentCount =
        this.state.limit < newCount
          ? props.data.length + this.state.limit * (this.state.activePage - 1)
          : newCount

      this.setState({
        totalCount: newCount,
        accounts: props.data,
        count: Math.ceil(value),
        actualCount: currentCount,
      })
    }
  }

  handlerRole = (e, { value }) => this.setState({ role_id: value })

  handlerInput = ({ target }) =>
    this.setState({
      [target.id]: target.value,
    })

  handlerUpdateRole = (e, { value }) => {
    const set = { ...this.state.update, role_id: value }

    this.setState({ update: { ...set } })
  }

  handlerUpdate = ({ target }) => {
    const { id, value } = target
    const set = {
      ...this.state.update,
      name: id === 'name-update' ? value : this.state.update.name,
      email: id === 'email-update' ? value : this.state.update.email,
      login: id === 'login-update' ? value : this.state.update.login,
      newPass: id === 'password-update' ? value : this.state.update.newPass,
    }
    this.setState({ update: { ...set } })
  }

  submitAccount = () => {
    const { name, email, login, role_id, password } = this.state
    if (!name) {
      this.showToast('Nome do usuário não pode estar vazio!')
      return false
    }

    if (!email) {
      this.showToast('Email não pode estar vazio!')
      return false
    }

    if (!login) {
      this.showToast('Username não pode estar vazio!')
      return false
    }

    if (!role_id) {
      this.showToast('Escolha um role para este usuário!')
      return false
    }

    if (!password && password.length >= 6) {
      this.showToast(
        'Senha não pode estar vazia e deve ser maior ou igual 6 digitos!'
      )
      return false
    }

    this.setState({ isLoading: true })
    this.props.create({ name, email, login, role_id, password })
  }

  submitUpdate = () => {
    const { newPass, name, email, login, role_id, uid } = this.state.update
    let obj = { name, email, login, role_id, uid }

    if (!name) {
      this.showToast('Nome do usuário não pode estar vazio!')
      return false
    }

    if (!email) {
      this.showToast('Email não pode estar vazio!')
      return false
    }

    if (!login) {
      this.showToast('Username não pode estar vazio!')
      return false
    }

    if (!role_id) {
      this.showToast('Escolha um role para este usuário!')
      return false
    }

    if (newPass.trim() !== '') {
      if (newPass.length >= 6 && newPass.trim() === '') {
        this.showToast(
          'Senha não pode estar vazia e deve ser maior ou igual 6 digitos!'
        )
        return false
      }
      obj = Object.assign(obj, { password: newPass })
    }

    this.setState({ openModal: false })
    this.props.updateAccount({ ...obj })
  }

  removeItem = (item) => this.props.removeById(item.uid)

  handlerResponse = (props) => {
    if (props.success) {
      this.onRefresh()
      this.showToast('Conta salva com sucesso!', true)
      this.setState({ isLoading: false })
      props.success = false
    } else if (props.error) {
      this.showToast(props.message)
      this.setState({ isLoading: false })
      props.error = false
    }
  }

  componentWillReceiveProps({
    accounts,
    accountsRoles,
    accountsCreate,
    accountsUpdate,
    accountsRemove,
  }) {
    if (accounts !== this.props.accounts) {
      this.calculatePagination(accounts)
    }

    if (accountsRoles !== this.props.accountsRoles) {
      const roles = accountsRoles.data.map(({ uid, name }, idx) => ({
        key: `key-${idx}`,
        text: name,
        value: uid,
        role_id: uid,
      }))
      this.setState({ roles, update: { roles } })
    }

    if (accountsCreate !== this.props.accountsCreate) {
      this.handlerResponse(accountsCreate)
    }

    if (accountsUpdate !== this.props.accountsUpdate) {
      this.handlerResponse(accountsUpdate)
    }

    if (accountsRemove !== this.props.accountsRemove) {
      this.handlerResponse(accountsRemove)
    }
  }

  openModal = (item) => {
    const newItem = {
      ...item,
      newPass: '',
      ...this.state.update,
    }

    this.setState({ openModal: true, update: newItem })
  }

  renderItem = (item, index) => (
    <Table.Row key={`key-${index}`}>
      <Table.Cell>#{item.id}</Table.Cell>
      <Table.Cell>{item.name}</Table.Cell>
      <Table.Cell>{item.email}</Table.Cell>
      <Table.Cell>{item.login}</Table.Cell>
      <Table.Cell>{item.role_name}</Table.Cell>
      <Table.Cell>
        {moment(item.created_at).format('DD/MM/YYYY HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Button icon onClick={() => this.openModal(item)}>
          <Icon name="edit" />
        </Button>
        <Button
          color={item.status ? 'red' : 'green'}
          icon={item.status ? 'remove' : 'check'}
          onClick={() => this.removeItem(item)}
        />
      </Table.Cell>
    </Table.Row>
  )

  renderComponent = () => (
    <>
      <AccountsHeader
        {...this.state}
        handlerSearch={this.handlerSearch}
        showForm={() => this.setState({ show: !this.state.show })}
      />

      <AccountsCreate
        {...this.state}
        handlerRole={this.handlerRole}
        handlerInput={this.handlerInput}
        submitAccount={() => this.submitAccount()}
      />

      <AccountsUpdate
        {...this.state.update}
        openModal={this.state.openModal}
        handlerUpdate={this.handlerUpdate}
        handlerUpdateRole={this.handlerUpdateRole}
        submitUpdate={() => this.submitUpdate()}
        close={() => this.setState({ openModal: false })}
      />

      <TableComponent
        {...this.state}
        columns={columns}
        data={this.props.accounts.data}
        renderItem={this.renderItem}
        isLoading={this.props.accounts.isFetching}
        handlePaginationChange={this.handlePaginationChange}
        emptyText={{
          icon: 'check circle outline',
          text: 'Nenhuma conta adicionada...',
        }}
      />
      <SemanticToastContainer position="top-right" />
    </>
  )

  render() {
    return (
      <Main {...this.props}>
        {this.props.user.profile.role_name === 'Basic' ||
        this.props.user.profile.role_name === 'Leader' ? (
          <EmptyList icon="lock" text="Acesso restrito" />
        ) : (
          this.renderComponent()
        )}
      </Main>
    )
  }
}

const mapStateToProps = (store) => ({
  user: store.user,
  accounts: store.accounts,
  accountsRoles: store.accountsRoles,
  accountsRemove: store.accountsRemove,
  accountsCreate: store.accountsCreate,
  accountsUpdate: store.accountsUpdate,
  accountsRolesRemove: store.accountsRolesRemove,
  accountsRolesCreate: store.accountsRolesCreate,
  accountsRolesUpdate: store.accountsRolesUpdate,
})

export default connect(mapStateToProps, {
  create,
  removeById,
  findRoles,
  findAccounts,
  updateAccount,
})(Accounts)
