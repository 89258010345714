import React, { useCallback, useState, useEffect } from 'react'

import { Button, Confirm } from 'semantic-ui-react'

import TableComponent from '~/components/Table'
import api from '~/services/api'
import {
  UFS_MIS,
  CITIES_MIS,
  BADGES,
  CAMPAIGN,
  CAMPAIGN_REMOVE,
  CAMPAIGN_CREATE,
  CAMPAIGN_UPDATE,
} from '~/services/api/endpoints'
import message from '~/utils/messages'
import { campaignsColumns, recurrency, weeks } from '~/utils/types'

import Form from './components/Form'
import Item from './components/Item'

const campaign = {
  initial: '',
  final: '',
  weeks,
  type: '',
  name: '',
  types: recurrency,
  week: [],
  period: [],
  message: '',
  removeId: '',
  uf: [],
  days: [],
  places: {},
  recurrence: {},

  cities: [],
  citiesList: [],

  ufs: [],
  periods: [],
  badges: [],

  badge: [],
  times: [],
  hours: [],
  states: [],
  campaigns: [],
}

function Campaign() {
  const [items, setItems] = useState([])
  const [badges, setBadges] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [item, setItem] = useState(campaign)
  const [loading, setLoading] = useState(true)
  const [itemSelected, setItemSelected] = useState({})
  const [citiesLoading, setCitiesLoading] = useState(false)
  const [confirmRemove, setConfirmRemove] = useState(false)
  const [confirmActive, setConfirmActive] = useState(false)
  const [openModal, setOpenModal] = useState({ type: '', show: false })
  const [totalCount, setTotalCount] = useState(0)
  const [perPage, setPerPage] = useState(0)

  const semanticType = (data) =>
    data?.map((value, key) => ({
      key,
      value: value.uid || value.id || value.uf || value,
      text: value.title || value.uf || value,
    }))

  const getBadges = useCallback(async () => {
    const res = await api.get(BADGES)
    setBadges(semanticType(res.data.data))
  }, [])

  const getStates = useCallback(async () => {
    const res = await api.get(UFS_MIS)
    setStates(semanticType(res.data.data))
  }, [])

  const getCities = useCallback(async (valueState) => {
    setCitiesLoading(true)
    const res = await api.get(CITIES_MIS, {
      params: {
        ufs: valueState.join('|'),
      },
      validateStatus() {
        return true
      },
    })

    if (res.status !== 200) {
      throw new Error('Houve um erro no servidor tente novamente!')
    }

    setCitiesLoading(false)
    return setCities(semanticType(res.data.data))
  }, [])

  const getItems = useCallback(async () => {
    const res = await api.get(CAMPAIGN)

    if (res.status === 200) {
      const count = res.headers['x-total-count']
      const pageCount = res.headers['x-total-page']
      setTotalCount(count)
      setPerPage(pageCount)
      setItems(res.data)
      setLoading(false)
    }
  }, [])

  function processNumbers(value) {
    let arr = []

    for (let i = 1; i <= value; i += 1) {
      arr = [...arr, { value: i, key: i, text: i }]
    }

    return arr
  }

  const updateCampaign = useCallback(async (data) => {
    setLoading(true)
    try {
      const res = await api.patch(CAMPAIGN_UPDATE, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status === 500) {
        throw new Error(res.data.message)
      }

      setLoading(false)
      getItems()
      return message().success('Campanha atualizada com sucesso...')
    } catch (error) {
      setLoading(false)
      throw message().error(error.message)
    }
  }, []) //eslint-disable-line

  const createCampaign = useCallback(async (data) => {
    setLoading(true)
    try {
      const res = await api.post(CAMPAIGN_CREATE, data, {
        validateStatus() {
          return true
        },
      })

      if (res.status === 500) {
        throw new Error(res.data.message)
      }

      setLoading(false)
      getItems()
      return message().success('Campanha atualizada com sucesso...')
    } catch (error) {
      setLoading(false)
      throw message().error(error.message)
    }
  }, []) //eslint-disable-line

  function onChange(key, value) {
    console.log(key, value)
    return setItem((oldValue) => ({ ...oldValue, [key]: value }))
  }

  function handlePagination(d, t, y) {
    console.log(d, t, y)
  }

  function handleState(e, { value }) {
    setItem((oldValues) => ({ ...oldValues, states: value }))
    return getCities(value)
  }

  async function onRemove() {
    setConfirmRemove(false)

    try {
      const res = await api.delete(`${CAMPAIGN_REMOVE}${itemSelected.uid}`, {
        validateStatus() {
          return true
        },
      })

      if (res.status === 500) {
        throw new Error(res.data)
      }

      const newItems = items.filter(
        (itemDel) => itemDel.uid !== itemSelected.uid
      )

      setItems(newItems)

      return message().success('Item removido com sucesso!')
    } catch (error) {
      return message().error(error.message)
    }
  }

  async function onActive() {
    setConfirmActive(false)
    return submit()
  }

  function handleConfirmRemove(newItem) {
    setConfirmRemove(true)
    setItemSelected((oldValue) => ({ ...oldValue, ...newItem }))
  }

  function handleConfirmActive(newItem) {
    setConfirmActive(true)
    setOpenModal((oldValues) => ({ ...oldValues, type: 'update' }))
    return setItem((oldValue) => ({
      ...oldValue,
      ...newItem,
      ...newItem.recurrence,
      ...newItem.places,
      active: !newItem.active,
      days: newItem.recurrence.period,
      week: newItem.recurrence.period,
      initial: Date.parse(newItem.started_at),
      final: Date.parse(newItem.finished_at),
    }))
  }

  async function onOpen(currentItem) {
    setCitiesLoading(true)

    await getCities(currentItem.places.states)

    await setItem((oldValues) => ({
      ...oldValues,
      ...currentItem,
      ...currentItem.places,
      ...currentItem.recurrence,
      days: currentItem.recurrence.period,
      week: currentItem.recurrence.period,
      initial: Date.parse(currentItem.started_at),
      final: Date.parse(currentItem.finished_at),
    }))

    return (
      !citiesLoading &&
      setOpenModal((oldValues) => ({
        ...oldValues,
        type: 'update',
        show: !oldValues.active,
      }))
    )
  }

  function submit() {
    const campaignData = {
      name: item.name,
      message: item.message,
      started_at: item.initial,
      finished_at: item.final,
      places: {
        states: item.states,
        cities: item.cities,
      },
      recurrence: {
        type: item.type,
        hours: item.hours,
        period: item.type === 'monthly' ? item.days : item.week,
      },
    }

    if (openModal.type === 'create') {
      return createCampaign(campaignData)
    }

    return updateCampaign({
      ...campaignData,
      active: item.active,
      started_at: new Date(item.initial),
      finished_at: new Date(item.final),
      uid: item.uid,
    })
  }

  useEffect(() => {
    getItems()
  }, []) //eslint-disable-line

  useEffect(() => {
    getBadges()
  }, []) //eslint-disable-line

  useEffect(() => {
    getStates()
  }, []) //eslint-disable-line

  return (
    <>
      <Confirm
        header="Alerta"
        open={confirmRemove}
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onConfirm={() => onRemove()}
        onCancel={() => setConfirmRemove(false)}
        content="Tem certeza deseja remover este item?"
      />
      <Confirm
        header="Alerta"
        open={confirmActive}
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onConfirm={() => onActive()}
        onCancel={() => setConfirmActive(false)}
        content="Tem certeza deseja atualizar o status deste item?"
      />
      <Button
        size="small"
        content="Criar"
        onClick={() => {
          setItem(campaign)
          setOpenModal((oldValues) => ({
            ...oldValues,
            type: 'create',
            show: true,
          }))
        }}
      />
      <Form
        item={item}
        weeks={weeks}
        states={states}
        badges={badges}
        cities={cities}
        submit={submit}
        loading={loading}
        types={recurrency}
        onChange={onChange}
        openModal={openModal}
        handleState={handleState}
        citiesLoading={citiesLoading}
        times={processNumbers(24)}
        periods={processNumbers(31)}
        onClose={() =>
          setOpenModal((oldValues) => ({ ...oldValues, show: false }))
        }
      />
      <TableComponent
        data={items}
        offset={perPage}
        count={perPage}
        totalCount={totalCount}
        columns={campaignsColumns}
        renderItem={(itemData, index) => (
          <Item
            item={itemData}
            onOpen={onOpen}
            key={String(index)}
            citiesLoading={citiesLoading}
            onRemove={handleConfirmRemove}
            onActive={handleConfirmActive}
          />
        )}
        isLoading={loading}
        handlePaginationChange={handlePagination}
        emptyText={{ icon: 'bolt', text: 'Nenhuma campanha...' }}
      />
    </>
  )
}

export default Campaign
