import React, { useCallback, useState, useEffect } from 'react'
import Avatar from 'react-avatar'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import { Grid, Header, Divider } from 'semantic-ui-react'

import Graphic from '~/components/Charts/Basic'
import {
  dashboard,
  filter as filterDash,
} from '~/store/modules/dashboard/actions'

import Metrics from './components/Metrics'

import 'react-count-animation/dist/count.min.css'

const periods = ['day', 'week', 'month', 'year']
const currentYear = format(new Date(), 'yyyy')

export default function Dashboard() {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const dataMaster = useSelector((state) => state.dashboard)
  const dashboardFilter = useSelector((state) => state.dashboardFilter)
  const [filters, setFilters] = useState({
    status: 1,
    limit: 12,
    active: 'u',
    table: 'users',
    where: 'status',
    typeData: 'count',
    label: 'Usuários',
    year: currentYear,
    columnDate: 'created_at',
  })
  const [period, setPeriod] = useState('week')
  const [loading, setLoading] = useState(true)
  const [metrics, setMetrics] = useState([])
  const [countData, setCountData] = useState({})

  const onRefresh = useCallback(() => {
    return Promise.all([
      dispatch(dashboard()),
      dispatch(filterDash({ ...filters, period })),
    ])
  }, [dispatch, filters, period])

  function handleClick(filterData) {
    setFilters({ ...filters, ...filterData })
    dispatch(filterDash({ ...filters, period }))
  }

  function handleClickPeriod(periodType) {
    setPeriod(periodType)
    dispatch(filterDash({ ...filters, period: periodType }))
  }

  function onClickSearch(path, query, status = {}) {
    return history.push({ search: query, pathname: path, state: { status } })
  }

  useEffect(() => {
    onRefresh()
  }, []) //eslint-disable-line

  useEffect(() => {
    if (dashboardFilter.success) {
      setLoading(false)
      setMetrics(
        dashboardFilter?.data.map((i) => ({
          name: format(new Date(i.period), 'dd/MM/yy'),
          Valor: Number(i.count),
        }))
      )
      dashboardFilter.success = false
    }

    if (dataMaster.success) {
      setCountData(dataMaster.data)
      dataMaster.success = false
    }
  }, [dashboardFilter, dataMaster])

  return (
    <>
      <Header as="h2" style={{ color: '#33334f' }}>
        <Avatar name={user.profile.name} size="50" round /> {user.profile.name}
      </Header>
      <Divider />
      <Metrics
        data={countData}
        loading={loading}
        history={history}
        onPress={handleClick}
        active={filters.active}
        onClickSearch={onClickSearch}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column className="content">
            <Graphic
              data={metrics}
              active={period}
              periods={periods}
              label={filters.label}
              onPress={handleClickPeriod}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}
