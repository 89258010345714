export default [
  {
    icon: 'dashboard',
    text: 'Dashboard',
    path: '/dashboard',
    restrictions: [],
  },
  {
    icon: 'warning',
    text: 'Missões Expirando',
    path: '/missions/expiring',
    restrictions: [],
  },
  {
    icon: 'chart bar',
    text: 'Métricas',
    path: '/metrics',
    restrictions: [],
  },
  {
    icon: 'shield',
    text: 'Badges',
    path: '/badges',
    restrictions: ['Leader', 'Basic'],
  },
  {
    icon: 'wpforms',
    text: 'Formulários Extras',
    path: '/forms-users-extra',
    restrictions: ['Leader', 'Basic'],
  },
  {
    icon: 'archive',
    text: 'Registros',
    path: '/registers',
    restrictions: [],
  },
  {
    icon: 'rocket',
    text: 'Missões',
    path: '/missions',
    restrictions: [],
  },
  {
    icon: 'file excel outline',
    text: 'Importação (BETA)',
    path: '/imports',
    restrictions: ['Basic'],
  },
  {
    icon: 'file excel outline',
    text: 'Produtos PDV',
    path: '/import-products',
    restrictions: ['Basic', 'Leader'],
  },
  {
    icon: 'clipboard',
    text: 'Categorias',
    path: '/categories',
    restrictions: ['Basic'],
  },
  {
    icon: 'exchange',
    text: 'Requisições',
    path: '/requests',
    restrictions: ['Leader', 'Basic'],
  },
  {
    icon: 'money',
    text: 'Financeiro',
    path: '/budget',
    restrictions: ['Leader', 'Basic'],
  },
  {
    icon: 'building',
    text: 'Clientes',
    path: '/customers',
    restrictions: ['Basic'],
  },
  {
    icon: 'users',
    text: 'Usuários',
    path: '/users',
    restrictions: [],
  },
  {
    icon: 'user circle',
    text: 'Contas',
    path: '/accounts',
    restrictions: ['Leader', 'Basic'],
  },
  {
    icon: 'bell',
    text: 'Campanhas',
    path: '/campaign',
    restrictions: ['Leader', 'Basic'],
  },
  {
    icon: 'bug',
    text: 'Log',
    path: '/logs',
    restrictions: ['Leader', 'Basic'],
  },
  {
    icon: 'star',
    text: 'Promoções',
    path: '/promotions',
    restrictions: ['Leader', 'Basic'],
  },
]
