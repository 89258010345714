import {
  FORMS_LIST,
  FORMS_REMOVE,
  FORMS_CREATE,
  FORMS_UPDATE,
  FORMS_DETAIL,
} from '~/services/api/endpoints'
import { get, post, remove, put } from '~/utils/actionsRequests'

const findFormsByCustomerId = ({ id, search, limit, offset }) =>
  get({
    url: `${FORMS_LIST}${id}?search=${search}&limit=${limit}&offset=${offset}`,
    typeFetch: 'FORMS_LIST',
    typeError: 'FORMS_LIST_FAIL',
    typeResponse: 'FORMS_LIST_OK',
  })

const findById = ({ id, customerId }) =>
  get({
    url: `${FORMS_DETAIL}${id}/${customerId}`,
    typeFetch: 'FORMS_DETAIL',
    typeError: 'FORMS_DETAIL_FAIL',
    typeResponse: 'FORMS_DETAIL_OK',
  })

const removeById = (id, customerId) =>
  remove({
    url: `${FORMS_REMOVE}${id}/${customerId}`,
    typeFetch: 'FORMS_REMOVE',
    typeError: 'FORMS_REMOVE_FAIL',
    typeResponse: 'FORMS_REMOVE_OK',
  })

const create = (data) =>
  post({
    data,
    url: `${FORMS_CREATE}`,
    typeFetch: 'FORMS_CREATE',
    typeError: 'FORMS_CREATE_FAIL',
    typeResponse: 'FORMS_CREATE_OK',
  })

const update = (data) =>
  put({
    data,
    url: `${FORMS_UPDATE}`,
    typeFetch: 'FORMS_UPDATE',
    typeError: 'FORMS_UPDATE_FAIL',
    typeResponse: 'FORMS_UPDATE_OK',
  })

export { create, update, findById, removeById, findFormsByCustomerId }
