import React from 'react'
import { Editor } from 'react-draft-wysiwyg'

import t from 'prop-types'
import { isEmpty, is } from 'ramda'
import {
  Card,
  Icon,
  Form,
  Grid,
  Image,
  Button,
  Divider,
  Checkbox,
} from 'semantic-ui-react'

function CreateSubCategory({
  show,
  myRef,
  onClick,
  myRefBack,
  onClickBack,
  avatar,
  image_path,
  customer_value,
  can_multiple,
  expiring_time,
  should_inactivate_when_reproved,
  can_restart,
  has_attachment,
  has_auto_approval,
  has_forms,
  webview_link,
  has_webview,
  has_import_document,
  has_import_image,
  has_photo,
  has_scanner,
  has_value_variable,
  has_value_percentage,
  no_location,
  time_to_appreciate,
  participants,
  title,
  fix_time,
  is_expiring_weekend,
  has_custom_status,
  user_value,
  mission_commission_percent,
  create,
  has_commission,
  has_check_availability,
  show_only_if_badge,
  has_bonus,
  user_value_bonus,
  customer_value_bonus,
  isLoading,
  uploadImage,
  has_products,
  editorState,
  handleInput,
  handleChange,
  uploadBackground,
  onEditorStateChange,
}) {
  return (
    <Card
      centered
      fluid
      style={{ marginTop: 10, display: show ? 'block' : 'none' }}
    >
      <Card.Content>
        <Form>
          <Grid columns={4} relaxed>
            <Grid.Column width={5}>
              <Form.Input
                id="title"
                type="text"
                value={title || ''}
                onChange={handleInput}
                label="Título (Obrigatório)"
                placeholder="Ex: Pesquisas"
              />
              <Form.Input
                id="time_to_appreciate"
                type="number"
                value={time_to_appreciate || ''}
                onChange={handleInput}
                label="Tempo de Aprovação(hrs) (Obrigatório)"
                placeholder="Ex: 10"
              />
              <Form.Input
                id="expiring_time"
                type="number"
                value={expiring_time || ''}
                onChange={handleInput}
                label="Expiração(hrs) (Obrigatório)"
                placeholder="Ex: 10"
              />
              <Form.Input
                id="fix_time"
                type="number"
                value={fix_time || ''}
                onChange={handleInput}
                label="Tempo de Realização para Correção"
                placeholder="Ex: 10"
              />
              <Form.Input
                id="customer_value"
                type="number"
                value={customer_value || ''}
                disabled={has_value_percentage || has_value_variable}
                onChange={handleInput}
                label="Valor Cobrado do Cliente (Obrigatório)"
                placeholder="Ex: 30"
              />
              <Form.Input
                id="user_value"
                type="number"
                disabled={has_value_percentage || has_value_variable}
                value={user_value || ''}
                onChange={handleInput}
                label="Valor Pago ao Usuário (Obrigatório)"
                placeholder="Ex: 20"
              />
              {has_value_percentage && (
                <Form.Input
                  id="mission_commission_percent"
                  type="number"
                  value={mission_commission_percent || null}
                  onChange={handleInput}
                  label="Valor Comissão Fixado ao Mission"
                  placeholder="Ex: 20"
                />
              )}
              {has_bonus && (
                <div style={{ backgroundColor: '#d8edff', padding: 5 }}>
                  <Form.Input
                    id="customer_value_bonus"
                    type="number"
                    onChange={handleInput}
                    value={customer_value_bonus || ''}
                    disabled={has_value_percentage || has_value_variable}
                    label="Valor Cobrado do Cliente Bônus (Obrigatório)"
                    placeholder="Ex: 30"
                  />
                  <Form.Input
                    id="user_value_bonus"
                    type="number"
                    onChange={handleInput}
                    value={user_value_bonus || ''}
                    disabled={has_value_percentage || has_value_variable}
                    label="Valor Pago ao Usuário Bônus (Obrigatório)"
                    placeholder="Ex: 20"
                  />
                </div>
              )}
              <Form.Input
                id="participants"
                type="number"
                value={participants || ''}
                onChange={handleInput}
                label="N˚ de Participante(s) (Obrigatório)"
                placeholder="Ex: 20"
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <Form.Field
                toggle
                id="has_commission"
                label="Comissão"
                control={Checkbox}
                checked={has_commission}
                onChange={handleChange}
              />
              <Form.Field
                toggle
                id="has_bonus"
                label="Valor Bônus"
                control={Checkbox}
                checked={has_bonus}
                onChange={handleChange}
              />
              <Form.Field
                toggle
                id="has_value_variable"
                label="Valor Variável"
                control={Checkbox}
                checked={has_value_variable}
                onChange={handleChange}
              />
              <Form.Field
                toggle
                id="has_value_percentage"
                label="Valor Percentual"
                control={Checkbox}
                checked={has_value_percentage}
                onChange={handleChange}
              />
              <Divider />
              <Form.Field
                toggle
                id="has_photo"
                label="Camera"
                control={Checkbox}
                checked={has_photo}
                onChange={handleChange}
              />
              <Form.Field
                toggle
                id="has_products"
                label="Lista de Produtos"
                control={Checkbox}
                checked={has_products}
                onChange={handleChange}
              />
              <Form.Field
                toggle
                id="has_webview"
                label="Ativar Webview"
                control={Checkbox}
                checked={has_webview}
                onChange={handleChange}
              />
              {has_webview && (
                <Form.Input
                  id="webview_link"
                  type="text"
                  value={webview_link || ''}
                  onChange={handleInput}
                  label="Adicione o link desejado"
                  placeholder="Ex: https://www.missionbrasil.com.br"
                />
              )}
              <Form.Field
                toggle
                id="has_import_image"
                label="Importar Fotos"
                control={Checkbox}
                checked={has_import_image}
                onChange={handleChange}
              />
              <Form.Field
                id="has_import_document"
                toggle
                onChange={handleChange}
                checked={has_import_document}
                label="Importar PDF"
                control={Checkbox}
              />
              <Form.Field
                toggle
                id="has_scanner"
                onChange={handleChange}
                checked={has_scanner}
                label="Scanner"
                control={Checkbox}
              />
              <Form.Field
                toggle
                id="has_forms"
                onChange={handleChange}
                checked={has_forms}
                label="Formulário"
                control={Checkbox}
              />
              <Divider />
              <Form.Field
                toggle
                id="has_attachment"
                onChange={handleChange}
                checked={has_attachment}
                label="Anexo em Missão"
                control={Checkbox}
              />
              <Form.Field
                toggle
                control={Checkbox}
                onChange={handleChange}
                id="should_inactivate_when_reproved"
                label="Inativar missão após reprovação"
                checked={should_inactivate_when_reproved}
              />
              <Form.Field
                toggle
                id="can_multiple"
                control={Checkbox}
                checked={can_multiple}
                onChange={handleChange}
                label="Standy By (Fazer diversas vezes a mesma missão sem finalizar)"
              />
              <Form.Field
                toggle
                id="can_restart"
                onChange={handleChange}
                checked={can_restart}
                label="Refazer Missão"
                control={Checkbox}
              />
              <Form.Field
                toggle
                id="has_auto_approval"
                onChange={handleChange}
                checked={has_auto_approval}
                label="Aprovação Automática"
                control={Checkbox}
              />
              <Form.Field
                toggle
                id="no_location"
                onChange={handleChange}
                checked={no_location}
                label="Não Localizado"
                control={Checkbox}
              />
              <Form.Field
                toggle
                id="is_expiring_weekend"
                onChange={handleChange}
                checked={is_expiring_weekend}
                label="Expira Final de Semana"
                control={Checkbox}
              />
              <Form.Field
                toggle
                id="has_custom_status"
                onChange={handleChange}
                checked={has_custom_status}
                label="Status Customizado"
                control={Checkbox}
              />
              <Form.Field
                toggle
                id="show_only_if_badge"
                onChange={handleChange}
                checked={show_only_if_badge}
                label="Esconder Missões (Usuário sem badge)"
                control={Checkbox}
              />
              <Form.Field
                toggle
                id="has_check_availability"
                onChange={handleChange}
                checked={has_check_availability}
                label="Verificar Cobertura de Instalação"
                control={Checkbox}
              />
              <Divider />
            </Grid.Column>
            <Grid.Column width={3}>
              <Card raised>
                <Image
                  size="small"
                  style={{ width: '100%' }}
                  verticalAlign="middle"
                  src={
                    isEmpty(avatar)
                      ? 'http://placehold.it/200x200'
                      : is(Object, avatar)
                      ? avatar.base64
                      : avatar
                  }
                />
                <Card.Content>
                  <input
                    type="file"
                    id="avatar"
                    accept="image/*"
                    style={{ display: 'none' }}
                    ref={myRef}
                    onChange={uploadImage}
                  />
                  <Button
                    fluid
                    icon="upload"
                    content="Icone"
                    onClick={onClick}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column width={2}>
              <Card raised>
                <Image
                  size="small"
                  style={{ width: '100%' }}
                  verticalAlign="middle"
                  src={
                    isEmpty(image_path)
                      ? 'http://placehold.it/500x300'
                      : is(Object, image_path)
                      ? image_path.base64
                      : image_path
                  }
                />
                <Card.Content>
                  <input
                    type="file"
                    id="image_path"
                    accept="image/*"
                    style={{ display: 'none' }}
                    ref={myRefBack}
                    onChange={uploadBackground}
                  />
                  <Button
                    fluid
                    icon="upload"
                    content="Background"
                    onClick={onClickBack}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid>
          <Grid columns={1} relaxed>
            <Grid.Column width={16}>
              <div className="field">
                <label>Descrição (Obrigatório)</label>
                <div className="editor">
                  <Editor
                    editorState={editorState}
                    editorClassName="editorClassName"
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    stripPastedStyles
                    onEditorStateChange={onEditorStateChange}
                    style={{ borderWidth: 1, borderColor: 'gray' }}
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </Form>
      </Card.Content>
      <Card.Content extra textAlign="right">
        <Button
          icon
          positive
          onClick={create}
          loading={isLoading}
          disabled={isLoading}
          labelPosition="left"
        >
          <Icon name="check" /> Salvar
        </Button>
      </Card.Content>
    </Card>
  )
}

CreateSubCategory.propTypes = {
  show: t.bool,
  myRef: t.string,
  onClick: t.func,
  myRefBack: t.string,
  onClickBack: t.func,
  avatar: t.string,
  image_path: t.string,
  customer_value: t.number,
  can_multiple: t.bool,
  expiring_time: t.string,
  should_inactivate_when_reproved: t.bool,
  can_restart: t.bool,
  has_attachment: t.bool,
  has_auto_approval: t.bool,
  has_forms: t.bool,
  webview_link: t.string,
  has_webview: t.bool,
  has_import_document: t.bool,
  has_import_image: t.bool,
  has_photo: t.bool,
  has_scanner: t.bool,
  has_value_variable: t.bool,
  has_value_percentage: t.bool,
  no_location: t.bool,
  participants: t.number,
  time_to_appreciate: t.bool,
  title: t.string,
  fix_time: t.string,
  is_expiring_weekend: t.bool,
  has_custom_status: t.bool,
  user_value: t.number,
  mission_commission_percent: t.bool,
  create: t.func,
  has_commission: t.bool,
  has_check_availability: t.bool,
  show_only_if_badge: t.bool,
  has_bonus: t.bool,
  user_value_bonus: t.number,
  customer_value_bonus: t.number,
  isLoading: t.bool,
  uploadImage: t.func,
  has_products: t.bool,
  editorState: t.objectOf(t.any),
  handleInput: t.func,
  handleChange: t.func,
  uploadBackground: t.func,
  onEditorStateChange: t.objectOf(t.any),
}

export default CreateSubCategory
