import {
  FORMS_DATA,
  FORMS_DATA_STATISTICS,
  FORMS_DATA_EXPORT,
} from '~/services/api/endpoints'
import { get } from '~/utils/actionsRequests'

const findDataByMissionId = (id) =>
  get({
    url: `${FORMS_DATA}${id}`,
    typeFetch: 'FORMS_DATA',
    typeError: 'FORMS_DATA_FAIL',
    typeResponse: 'FORMS_DATA_OK',
  })

const findStatisticsByMissionId = (id, customerId) =>
  get({
    url: `${FORMS_DATA_STATISTICS}${id}/${customerId}`,
    typeFetch: 'FORMS_DATA_STATISTICS',
    typeError: 'FORMS_DATA_STATISTICS_FAIL',
    typeResponse: 'FORMS_DATA_STATISTICS_OK',
  })

const exportStatistics = (id, customerId) =>
  get({
    url: `${FORMS_DATA_EXPORT}${id}/${customerId}`,
    typeFetch: 'FORMS_DATA_EXPORT',
    typeError: 'FORMS_DATA_EXPORT_FAIL',
    typeResponse: 'FORMS_DATA_EXPORT_OK',
  })

export { exportStatistics, findDataByMissionId, findStatisticsByMissionId }
