import React from 'react'

import { isNil } from 'ramda'
import { Card, Icon, Button } from 'semantic-ui-react'

export default function Bonus({ onClick, verifyBonus, value }) {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Importante! Missão Bônus</Card.Header>
      </Card.Content>
      <Card.Content>
        Essa é uma missão bônus no valor de <strong>R${value}</strong>,
        verifique se o usuário realizou as etapas exigidas na descrição para
        obter o valor do bônus.
      </Card.Content>
      <Card.Content>
        <Card.Description>
          {isNil(verifyBonus) ? (
            <div>
              <Button negative onClick={() => onClick(false)}>
                Não
              </Button>
              <Button positive onClick={() => onClick(true)}>
                Realizou
              </Button>
            </div>
          ) : (
            <Button
              icon
              labelPosition="left"
              onClick={() => onClick(null)}
              positive={verifyBonus && !isNil(verifyBonus)}
              negative={!verifyBonus && !isNil(verifyBonus)}
            >
              {verifyBonus && !isNil(verifyBonus) && <Icon name="check" />}
              {!verifyBonus && !isNil(verifyBonus) && <Icon name="remove" />}
              Desfazer
            </Button>
          )}
        </Card.Description>
      </Card.Content>
    </Card>
  )
}
