import { responseData } from '~/utils/reducer'

const ACCOUNTS = 'ACCOUNTS'
const ACCOUNTS_REMOVE = 'ACCOUNTS_REMOVE'
const ACCOUNTS_CREATE = 'ACCOUNTS_CREATE'
const ACCOUNTS_UPDATE = 'ACCOUNTS_UPDATE'
const ACCOUNTS_ROLES = 'ACCOUNTS_ROLES'
const ACCOUNTS_ROLES_REMOVE = 'ACCOUNTS_ROLES_REMOVE'
const ACCOUNTS_ROLES_CREATE = 'ACCOUNTS_ROLES_CREATE'
const ACCOUNTS_ROLES_UPDATE = 'ACCOUNTS_ROLES_UPDATE'

const accounts = responseData(ACCOUNTS, 'array')
const accountsRemove = responseData(ACCOUNTS_REMOVE, 'object')
const accountsCreate = responseData(ACCOUNTS_CREATE, 'object')
const accountsUpdate = responseData(ACCOUNTS_UPDATE, 'object')
const accountsRoles = responseData(ACCOUNTS_ROLES, 'array')
const accountsRolesRemove = responseData(ACCOUNTS_ROLES_REMOVE, 'object')
const accountsRolesCreate = responseData(ACCOUNTS_ROLES_CREATE, 'object')
const accountsRolesUpdate = responseData(ACCOUNTS_ROLES_UPDATE, 'object')

export default {
  accounts,
  accountsRemove,
  accountsCreate,
  accountsUpdate,
  accountsRoles,
  accountsRolesRemove,
  accountsRolesCreate,
  accountsRolesUpdate,
}
