import React from 'react'

import { format } from 'date-fns'
import t from 'prop-types'
import { cond, equals, always, isEmpty, isNil } from 'ramda'
import { Card, Icon, Image, Label, Loader, Dropdown } from 'semantic-ui-react'

import { REGISTERS_REPORT, REGISTERS_ZIP } from '~/services/api/endpoints'

const label = cond([
  [equals(0), always('Pendente')],
  [equals(1), always('Aprovado')],
  [equals(2), always('Reprovado')],
])
const color = cond([
  [equals(0), always('yellow')],
  [equals(1), always('green')],
  [equals(2), always('red')],
])

const Evaluation = ({ data, isLoading }) => {
  const date = new Date(data.created_at)
  const isoDate = date.toISOString()
  const dateFormat = format(new Date(data.created_at), 'dd/MM/yyyy')
  const time = isoDate.substr(11, 8)

  return (
    <Card>
      <Card.Content>
        {(data.status === 1 || data.status === 2) && (
          <Label
            as="a"
            attached="top"
            color={color(data.status)}
            content={label(data.status)}
          />
        )}
        <Image size="mini" floated="right" src={data.userAvatar} />
        <Card.Header>{data.userName}</Card.Header>
        <Card.Meta>
          {dateFormat} {time}
        </Card.Meta>
        <Card.Description>
          <Icon name="pin" />{' '}
          {isEmpty(data.place) ? 'Não Localizada' : data.place}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        {isLoading && (
          <div>
            <Loader active inline="centered" />
          </div>
        )}
        {data.status === 1 && (
          <div className="center">
            <Dropdown
              button
              upward
              labeled
              floating
              text="Exportar"
              className="icon"
            >
              <Dropdown.Menu className="left">
                <Dropdown.Item
                  onClick={() =>
                    window.open(
                      `${REGISTERS_REPORT}${data.missions_users_id}?missionName=${data.missionName}`,
                      '_self'
                    )
                  }
                >
                  <Icon name="file pdf outline" />
                  <span className="text">PDF</span>
                </Dropdown.Item>
                {!isNil(data.has_photo) && (
                  <Dropdown.Item
                    onClick={() =>
                      window.open(
                        `${REGISTERS_ZIP}${data.missions_users_id}?missionName=${data.missionName}`,
                        '_self'
                      )
                    }
                  >
                    <Icon name="zip" />
                    <span className="text">ZIP</span>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        {data.status === 2 && (
          <div>
            <strong>Motivo</strong>
            <p>{data.has_reproved}</p>
          </div>
        )}
      </Card.Content>
    </Card>
  )
}

Evaluation.propTypes = {
  data: t.objectOf(t.any),
  isLoading: t.bool,
}

export default Evaluation
