import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { format } from 'date-fns'
import queryString from 'query-string'
import { isNil, cond, equals, always, length, isEmpty } from 'ramda'
import { Icon, Table, Button, Divider, Message } from 'semantic-ui-react'

import RegisterFilter from '~/components/RegisterFilter'
import RegisterHeaderData from '~/components/RegisterHeaderData'
import TableComponent from '~/components/Table'
import { baseURL } from '~/services/api'
import {
  findUfsMission,
  findCitiesMission,
} from '~/store/modules/campaign/actions'
import {
  findSubcategories,
  categories as findCategories,
} from '~/store/modules/categories/actions'
import { findAll as findCustomers } from '~/store/modules/customers/actions'
import { dashboard } from '~/store/modules/dashboard/actions'
import { getAll, registerReceipt } from '~/store/modules/registers/actions'

import Main from '../Main'

const columns = [
  'COD',
  'Cliente',
  'ID',
  'Status',
  'Nome',
  'Sub Categoria',
  'Missão',
  'Criado Em',
  'Atualizado Em',
  'Ações',
]

const status = cond([
  [equals(0), always('Pendente')],
  [equals(1), always('Aprovado')],
  [equals(2), always('Reprovado')],
  [equals(3), always('Em Análise')],
  [equals(4), always('Em Correção')],
])

const direc = {
  0: 'asc',
  1: 'desc',
  2: 'desc',
  3: 'asc',
  4: 'asc',
}

const startQuery = {
  limit: 15,
  offset: 0,
  status: 3,
  final: null,
  initial: null,
  search: '',
  uf: [],
  city: [],
  ufsList: [],
  customers: [],
  activePage: 1,
  citiesList: [],
  categories: [],
  subCategories: [],
  customerId: '',
  missionsMainId: '',
}

class Registers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...startQuery,

      status: 3,
      totalCount: 0,
      siblingRange: 1,
      boundaryRange: 0,
      showEllipsis: true,
      showFirstAndLastNav: true,
      showPreviousAndNextNav: true,

      isLoading: false,
      missionLoading: '',

      errors: [],
      isFilterLoading: false,

      search: 'status=3&offset=0&limit=15',
    }
  }

  componentDidMount() {
    this.onRefresh()
    this.startQuery()

    const location = queryString.parse(this.props.location.search)

    if (!this.state.categoryId && location.categoryId) {
      this.props.findSubcategories(location.categoryId)
      this.setState({
        categoryId: location.categoryId,
        missionsMainId: location.missionsMainId,
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.registersReceiptResponse !== this.props.registersReceiptResponse
    ) {
      if (nextProps.registersReceiptResponse.success) {
        window.open(nextProps.registersReceiptResponse.data, '_self')
        this.setState({ [`isLoading${this.state.missionLoading}`]: false })
        nextProps.registersReceiptResponse.success = false
      }
    }

    if (nextProps.customers !== this.props.customers) {
      this.handlerList(nextProps.customers, 'customers')
    }

    if (nextProps.ufs !== this.props.ufs) {
      this.handlerList(nextProps.ufs, 'ufsList')
    }

    if (nextProps.cities !== this.props.cities) {
      this.handlerList(nextProps.cities, 'citiesList')
    }

    if (nextProps.categories !== this.props.categories) {
      this.handlerList(nextProps.categories, 'categories')
    }

    if (nextProps.subCategoriesAll !== this.props.subCategoriesAll) {
      this.handlerList(nextProps.subCategoriesAll, 'subCategories')
    }

    if (nextProps.registers !== this.props.registers) {
      this.calculatePagination(nextProps.registers)
    }
  }

  resetFilter = () => {
    this.setState({ ...startQuery })
    this.startQuery()
  }

  handlerChange = (value, key) => {
    if (key === 'categoryId') {
      this.props.findSubcategories(value)
    }

    if (key === 'uf') {
      const ufs = value.join(', ')
      this.props.findCitiesMission(`ufs=${ufs}`)
      this.handlerQuery(key, ufs)
      this.setState({ ...this.state, [key]: ufs })
      return false
    }

    if (key === 'initial' || key === 'final') {
      this.handlerQuery(key, new Date(value).getTime())
      this.setState({ ...this.state, [key]: value })
      return false
    }

    this.handlerQuery(key, value)
    this.setState({ ...this.state, [key]: value })
  }

  handlerQuery = (target, value) => {
    const queries = queryString.parse(this.props.location.search)
    const handlerQueries = { ...queries, [target]: value }
    const search = queryString.stringify(handlerQueries)
    this.props.history.replace({
      search,
      pahtname: this.props.location.pathname,
    })
  }

  handlerRegisterDetail = (id) => {
    const queries = queryString.parse(this.props.location.search)
    const search = queryString.stringify(queries)
    this.props.history.push(`register/${id}`, { search })
  }

  submitFilterRegister = () => {
    const { initial, final } = queryString.parse(this.props.location.search)
    const parsedQuery = {
      ...queryString.parse(this.props.location.search),
      limit: Number(queryString.parse(this.props.location.search).limit),
    }
    const queries = {
      ...parsedQuery,
      status: Number(this.state.status),
      offset: 0,
      order: 'r.created_at',
      direction: direc[Number(this.state.status)],
      limit: parsedQuery.limit > 15 ? parsedQuery.limit : 15,
    }

    if (queries.status === 1 || queries.status === 0) {
      Object.assign(queries, { order: 'r.updated_at' })
    }

    if (!isNil(initial) && !isNil(final)) {
      Object.assign(queries, {
        initial: Number(initial),
        final: Number(final),
      })
    }

    const search = queryString.stringify(queries)
    this.setState({ activePage: 1, ...queries })
    this.props.history.replace({ search })
    this.props.getAll(search)
  }

  handlePaginationChange = (e, { activePage }) => {
    const newOffset = (activePage - 1) * this.state.limit
    this.handlerQuery('offset', Number(newOffset))
    const queries = queryString.parse(this.props.location.search)
    const search = queryString.stringify({
      ...queries,
      offset: newOffset,
      limit: this.state.limit,
    })
    this.setState({ activePage, offset: Number(newOffset) })
    this.props.getAll(search)
  }

  calculatePagination = (props) => {
    if (props.success) {
      const newCount = parseInt(props.count, 0)
      const value = newCount / this.state.limit
      const currentCount =
        this.state.limit < newCount
          ? props.data.length + this.state.limit * (this.state.activePage - 1)
          : newCount

      this.setState({
        totalCount: newCount,
        registers: props.data,
        count: Math.ceil(value),
        actualCount: currentCount,
      })
    }
  }

  handlerUserReceipt = (id) => {
    this.props.registerReceipt(id)
    this.setState({ missionLoading: id, [`isLoading${id}`]: true })
  }

  handlerList = (props, key) => {
    if (props.success) {
      const newArray = props.data.map((item, index) => ({
        key: index,
        value: item.uid || item.uf || item,
        text: item.name || item.title || item,
      }))
      this.setState({ [key]: newArray })
    }
  }

  handlerRegisters = (props) => {
    if (props.success) {
      this.setState({ registers: props.data })
      props.success = false
    } else if (props.error) {
      alert(props.message)
      props.error = false
    }
  }

  onRefresh = () => {
    this.props.dashboard()
    this.props.findUfsMission()
    this.props.findCategories()
    this.props.findCustomers({ search: '', limit: 1000, offset: 0 })
  }

  startQuery = () => {
    const defaultQuery = 'limit=15&offset=0&status=3'
    const propSearch = queryString.parse(this.props.location.search)
    const query =
      this.props.location.search === ''
        ? defaultQuery
        : queryString.stringify(propSearch)
    const parsedQuery = queryString.parse(query)
    const search = {
      ...parsedQuery,
      limit: Number(parsedQuery.limit),
      status: Number(parsedQuery.status),
      initial: Number(parsedQuery.initial),
      final: Number(parsedQuery.final),
    }

    this.setState({
      ...search,
      search: query,
    })

    this.props.history.replace({ search: query })
    this.props.getAll(query)
  }

  renderItem = (item) => (
    <Table.Row key={item.uid}>
      <Table.Cell>{item.mu_key}</Table.Cell>
      <Table.Cell>{item.customer_name}</Table.Cell>
      <Table.Cell>{item.identifier}</Table.Cell>
      <Table.Cell positive={item.status === 1} negative={item.status === 2}>
        {status(item.status)}
      </Table.Cell>
      <Table.Cell>{item.userName}</Table.Cell>
      <Table.Cell>{item.missions_main_title}</Table.Cell>
      <Table.Cell>{item.missionName}</Table.Cell>
      <Table.Cell>
        {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        {item.updated_at &&
          format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Button
          icon
          primary
          size="small"
          labelPosition="left"
          onClick={() => this.handlerRegisterDetail(item.uid)}
        >
          <Icon name="write" />
          Avaliar
        </Button>
        {item.status === 1 && (
          <Button
            icon
            negative
            size="small"
            labelPosition="left"
            loading={this.state[`isLoading${item.missions_users_id}`]}
            disabled={this.state[`isLoading${item.missions_users_id}`]}
            onClick={() => this.handlerUserReceipt(item.missions_users_id)}
          >
            <Icon name="file pdf outline" />
            Recibo
          </Button>
        )}
      </Table.Cell>
    </Table.Row>
  )

  submitFilter = () => {
    this.setState({ isFilterLoading: true })

    const { startDate, endDate } = this.state

    if (isEmpty(startDate)) {
      this.setState({
        isFilterLoading: false,
        errors: this.state.errors.concat('Data Inicial não pode estar vazia!'),
      })
      return
    }

    if (isEmpty(endDate)) {
      this.setState({
        isFilterLoading: false,
        errors: this.state.errors.concat('Data Final não pode estar vazia!'),
      })
      return
    }

    const params = `?initial=${new Date(startDate).getTime()}&final=${new Date(
      endDate
    ).getTime()}`

    window.open(
      `${baseURL}${this.props.user.profile.url_integration_export}${params}`,
      '_self'
    )

    setTimeout(() => {
      this.setState({ errors: [], isFilterLoading: false })
    }, 2000)
  }

  sortedRequest = (order, direction) => {
    const { initial, final } = this.state
    const search =
      !!initial && !!final
        ? {
            ...queryString.parse(this.state.search),
            order,
            direction,
            status: Number(this.state.status),
            initial: Number(initial),
            final: Number(final),
          }
        : {
            ...queryString.parse(this.state.search),
            order,
            direction,
            status: Number(this.state.status),
          }

    const query = queryString.stringify(search)

    this.setState(search)
    this.props.history.replace({ search: query })
    this.props.getAll(query)
  }

  handleSort = (order, direction) => {
    let column = order
    switch (column) {
      case 'Cliente':
        column = 'c.name'
        this.sortedRequest(column, direction)
        break
      case 'Status':
        column = 'r.status'
        this.sortedRequest(column, direction)
        break
      case 'Nome':
        column = 'u.name'
        this.sortedRequest(column, direction)
        break
      case 'Sub Categoria':
        column = 'mm.title'
        this.sortedRequest(column, direction)
        break
      case 'Missão':
        column = 'm.name'
        this.sortedRequest(column, direction)
        break
      case 'Criado Em':
        column = 'r.created_at'
        this.sortedRequest(column, direction)
        break
      case 'Atualizado Em':
        column = 'r.updated_at'
        this.sortedRequest(column, direction)
        break
      default:
    }
  }

  render() {
    return (
      <Main {...this.props}>
        <RegisterHeaderData
          {...this}
          {...this.state}
          data={this.props.dataCustomer.data}
        />
        <Divider inverted />
        <RegisterFilter
          {...this.state}
          {...this}
          resetFilter={this.resetFilter}
        />
        <Divider inverted />
        {length(this.state.errors) > 0 && (
          <Message negative header="Alerta!" list={this.state.errors} />
        )}
        <TableComponent
          {...this.state}
          columns={columns}
          data={this.state.registers}
          renderItem={this.renderItem}
          handleColumnSort={this.handleSort}
          isLoading={this.props.registers.isFetching}
          handlePaginationChange={this.handlePaginationChange}
          emptyText={{
            icon: 'check circle outline',
            text: `Nenhum Registro ${status(this.state.status)}...`,
          }}
        />
      </Main>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  ufs: state.ufsMissions,
  cities: state.citiesMissions,
  registers: state.registers,
  customers: state.customers,
  categories: state.categories,
  dataCustomer: state.dashboard,
  subCategoriesAll: state.getSubCategories,
  registersReceiptResponse: state.registersReceipt,
})

export default connect(mapStateToProps, {
  getAll,
  dashboard,
  findSubcategories,
  findCustomers,
  findCategories,
  findUfsMission,
  findCitiesMission,
  registerReceipt,
})(withRouter(Registers))
