import React from 'react'

import { Menu, Button } from 'semantic-ui-react'

const AccountsHeader = ({ showForm, show }) => (
  <Menu>
    <Menu.Item>
      <Button color={show ? 'grey' : 'blue'} onClick={showForm}>
        {show ? 'Fechar' : 'Criar'}
      </Button>
    </Menu.Item>
  </Menu>
)

export default AccountsHeader
