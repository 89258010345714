import React, { useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'

import PropTypes from 'prop-types'
import { Card, Image, Button } from 'semantic-ui-react'

export default function BadgeItem(props) {
  const { badge, createBadge, removeBadge } = props

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(false)
  }, [badge.has_badge])

  const callCreateBadge = (badgeId) => {
    setIsLoading(true)
    createBadge(badgeId)
  }

  const callRemoveBadge = (usersBadgesId) => {
    setIsLoading(true)
    removeBadge(usersBadgesId)
  }
  return (
    <Card>
      <Card.Content>
        <Image floated="right" size="mini" src={badge.avatar} />
        <Card.Header>{badge.title}</Card.Header>
        <Card.Meta>{badge.exclusive ? 'Exclusivo' : null}</Card.Meta>
        <Card.Description>
          {ReactHtmlParser(badge.description)}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        {!badge.has_badge ? (
          <Button
            basic
            color="green"
            content="Adicionar"
            loading={isLoading}
            disabled={isLoading}
            onClick={() => callCreateBadge(badge.badge_id)}
          />
        ) : (
          <Button
            basic
            color="red"
            content="Remover"
            loading={isLoading}
            disabled={isLoading}
            onClick={() => callRemoveBadge(badge.users_badges_id)}
          />
        )}
      </Card.Content>
    </Card>
  )
}

BadgeItem.propTypes = {
  createBadge: PropTypes.func,
  removeBadge: PropTypes.func,
  badge: PropTypes.exact({
    avatar: PropTypes.string,
    avg_price: PropTypes.string,
    avg_time: PropTypes.string,
    badge_id: PropTypes.string,
    description: PropTypes.string,
    exclusive: PropTypes.bool,
    has_badge: PropTypes.bool,
    is_consultant: PropTypes.bool,
    name: PropTypes.string,
    require_approval: PropTypes.bool,
    score: PropTypes.number,
    title: PropTypes.string,
    total: PropTypes.string,
    url_video: PropTypes.string,
    users_badges_id: PropTypes.string,
  }),
}
