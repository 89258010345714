import React from 'react'

import t from 'prop-types'
import { Card, Statistic, Segment } from 'semantic-ui-react'

import Loader from '~/components/Loader/Statistic'

const CardStatistic = ({ label, value, onClick, borderColor, loading }) => (
  <Card
    fluid
    color={borderColor}
    style={{ height: 120, marginBottom: 20 }}
    onClick={onClick || null}
  >
    <Card.Content textAlign="center">
      <Segment raised>
        {loading ? (
          <Loader />
        ) : (
          <Statistic>
            <Statistic.Value>{value}</Statistic.Value>
            <Statistic.Label>{label}</Statistic.Label>
          </Statistic>
        )}
      </Segment>
    </Card.Content>
  </Card>
)

CardStatistic.propTypes = {
  label: t.string,
  value: t.string,
  onClick: t.func,
  loading: t.bool,
  borderColor: t.string,
}

export default CardStatistic
