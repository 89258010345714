import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SemanticToastContainer } from 'react-semantic-toasts'

import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import _ from 'lodash'
import { Confirm } from 'semantic-ui-react'

import MissionsCell from '~/components/Cell/MissionsCell'
import TableComponent from '~/components/Table'
import usePaginationTable from '~/hooks/usePaginationTable'
import useQueryFilters from '~/hooks/useQueryFilters'
import api from '~/services/api'
import { MISSIONS } from '~/services/api/endpoints'
import history from '~/services/history'
import {
  update,
  updateStatus,
  searchPlaces,
} from '~/store/modules/missions/actions'
import message from '~/utils/messages'

import MissionAttachments from './MissionAttachments'
import MissionDetail from './MissionDetail'
import MissionFilter from './MissionFilter'
import MissionForms from './MissionForms'
import MissionParticipants from './MissionParticipants'

const columns = [
  'COD',
  'Identificador',
  'Cliente',
  'Categoria',
  'Nome',
  'Criador',
  'Criado Em',
  'Expira Em',
  'Status',
  '',
]

/* eslint-disable */
const columnsMapForApiSort = {
  'Cliente': 'c.name',
  'Categoria': 'mm.title',
  'Nome': 'm.name',
  'Criado Em': 'm.created_at',
  'Expira Em': 'm.expired_at',
}
/* eslint-enable */

const initialFilters = {
  limit: 15,
  offset: 0,
  order: 'm.created_at',
  direction: 'desc',
  final: null,
  initial: null,
  search: null,
  ufs: null,
  city: null,
  customerId: null,
  categoryId: null,
  missionsMainId: null,
  iniCreate: null,
  finCreate: null,
  iniExpired: null,
  finExpired: null,
}

export default function Missions() {
  const [missions, setMissions] = useState([])
  const [isLoadingMissions, setIsLoadingMissions] = useState(true)

  const [showConfirm, setShowConfirm] = useState(false)

  const [isOpenModalForm, setIsOpenModalForm] = useState(false)
  const [isOpenModalAttachments, setIsOpenModalAttachments] = useState(false)
  const [isOpenModalParticipants, setIsOpenModalParticipants] = useState(false)
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false)

  const [missionParticipants, setMissionParticipants] = useState('')

  const [selectedMission, setSelectedMission] = useState(null)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [errorsMissionDetail, setErrorsMissionDetail] = useState([])
  const [address, setAddress] = useState([])

  const missionsUpdate = useSelector((store) => store.missionsUpdate)

  const searchPlacesResponse = useSelector((store) => store.searchPlaces)

  const parseQueryParamsBeforeSetFilters = useCallback((queryObj) => {
    const parsedParams = { ...queryObj }

    if (parsedParams.limit) {
      parsedParams.limit = parseInt(parsedParams.limit, 0)
    }
    if (parsedParams.offset) {
      parsedParams.offset = parseInt(parsedParams.offset, 0)
    }
    if (parsedParams.iniCreate) {
      parsedParams.iniCreate = new Date(parseInt(parsedParams.iniCreate, 0))
    }
    if (parsedParams.finCreate) {
      parsedParams.finCreate = new Date(parseInt(parsedParams.finCreate, 0))
    }
    if (parsedParams.iniExpired) {
      parsedParams.iniExpired = new Date(parseInt(parsedParams.iniExpired, 0))
    }
    if (parsedParams.finExpired) {
      parsedParams.finExpired = new Date(parseInt(parsedParams.finExpired, 0))
    }

    return parsedParams
  }, [])

  const parseFiltersBeforeSetQueryParams = useCallback((filters) => {
    const parsedFilters = {}

    if (filters.cities) {
      parsedFilters.cities = filters.cities.map((cityObj) => cityObj.cityName)
    }

    return parsedFilters
  }, [])

  const {
    filters,
    debouncedFilters,
    setFilters,
    onChangeFilters,
    clearFilters,
  } = useQueryFilters(
    initialFilters,
    parseQueryParamsBeforeSetFilters,
    parseFiltersBeforeSetQueryParams
  )

  const {
    count,
    totalCount,
    activePage,
    calculatePagination,
    handleSort,
    onPageChange,
    emptyText,
  } = usePaginationTable(debouncedFilters, setFilters, columnsMapForApiSort)

  const dispatch = useDispatch()

  const getValidParams = (paramsObj) => {
    const parsedParams = { ...paramsObj }

    if (parsedParams.iniCreate) {
      parsedParams.iniCreate = new Date(parsedParams.iniCreate).getTime()
    }
    if (parsedParams.finCreate) {
      parsedParams.finCreate = new Date(parsedParams.finCreate).getTime()
    }
    if (parsedParams.iniExpired) {
      parsedParams.iniExpired = new Date(parsedParams.iniExpired).getTime()
    }
    if (parsedParams.finExpired) {
      parsedParams.finExpired = new Date(parsedParams.finExpired).getTime()
    }

    return parsedParams
  }

  const getMissions = useCallback(async () => {
    try {
      setIsLoadingMissions(true)
      const validParams = getValidParams(debouncedFilters)

      if (_.isArray(validParams.ufs)) {
        validParams.uf = validParams.ufs.join(',')
        delete validParams.ufs
      }
      if (_.isArray(validParams.cities)) {
        const cities = validParams.cities.map((cityObj) => cityObj.cityName)
        validParams.city = cities.join(',')
        delete validParams.cities
      }

      const res = await api.get(MISSIONS, { params: validParams })
      setMissions(res.data.data)
      calculatePagination(res.data.count)
    } catch (error) {
      console.log(error)
      message().error(
        error?.response?.data?.data || 'Houve um erro no servidor'
      )
    } finally {
      setIsLoadingMissions(false)
    }
  }, [debouncedFilters, calculatePagination])

  const onEditorStateChange = (editorStateParam) => {
    setEditorState(editorStateParam)
    setSelectedMission((sm) => ({
      ...sm,
      description: draftToHtml(
        convertToRaw(editorStateParam.getCurrentContent())
      ),
    }))
  }

  const submitMissionDetail = useCallback(() => {
    const {
      uid,
      lat,
      lng,
      name,
      place,
      deleted,
      identifier,
      expired_at,
      no_location,
      description,
      custom_distance,
      missions_main_id,
    } = selectedMission

    setShowConfirm(false)
    setErrorsMissionDetail([])

    if (!name) {
      setErrorsMissionDetail((emd) => emd.concat('Nome não pode estar vazio!'))
      return false
    }

    if (!description) {
      setErrorsMissionDetail((emd) =>
        emd.concat('A descrição não pode estar vazia!')
      )
      return false
    }

    if (!place && !no_location) {
      setErrorsMissionDetail((emd) =>
        emd.concat('O endereço não pode estar vazio!')
      )
      return false
    }

    if (!lat && !lng && !no_location) {
      setErrorsMissionDetail((emd) =>
        emd.concat('O endereço deve vir selecionado da lista!')
      )
      return false
    }

    setIsOpenModalDetail(false)

    return dispatch(
      update(
        {
          lat,
          lng,
          name,
          place,
          deleted,
          identifier,
          expired_at,
          no_location,
          description,
          custom_distance,
          missions_main_id,
          customer_id: selectedMission.customer_id,
        },
        uid
      )
    )
  }, [dispatch, selectedMission])

  const handlerDate = (data) =>
    setSelectedMission((sm) => ({ ...sm, expired_at: data }))

  const handlerUpdateResponse = useCallback(
    (props) => {
      if (props.success) {
        const updatedMission = { ...selectedMission, ...props.data }

        const updatedMissionIdx = missions.findIndex(
          (item) => item.uid === updatedMission.uid
        )

        const newMissionsList = [...missions]

        if (updatedMission.deleted === true) {
          newMissionsList.splice(updatedMissionIdx, 1)
        } else {
          newMissionsList[updatedMissionIdx] = updatedMission
        }

        setMissions(newMissionsList)
        message().success('Atualizado com sucesso!')
        props.success = false
      }
    },
    [missions, selectedMission]
  )

  const changeAddress = ({ place, lat, lng }) => {
    setSelectedMission((sm) => ({ ...sm, place, lat, lng, address: [] }))
  }

  const searchAddress = (addressParam) => dispatch(searchPlaces(addressParam))

  const handlerInput = ({ target }) => {
    if (target.id === 'place') {
      searchAddress(target.value)
    }

    setSelectedMission((sm) => ({ ...sm, [target.id]: target.value }))
  }

  const openModalParticipants = (mission) => {
    setIsOpenModalParticipants(true)
    setSelectedMission({ ...mission })
    setMissionParticipants(mission.participants)
  }

  const openModalForms = (mission) => {
    setIsOpenModalForm(true)
    setSelectedMission({ ...mission })
  }

  const openModalAttachment = (mission) => {
    setIsOpenModalAttachments(true)
    setSelectedMission({ ...mission })
  }

  const openModalDetail = (mission) => {
    const contentBlock = htmlToDraft(mission.description)
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    )

    setIsOpenModalDetail(true)
    setSelectedMission({ ...mission })
    setEditorState(EditorState.createWithContent(contentState))
  }

  const showConfirmModal = (item) => {
    setSelectedMission({ ...item })
    setShowConfirm(true)
  }

  const handlerCategories = (e, { value }) => {
    setSelectedMission((sm) => ({ ...sm, missions_main_id: value }))
  }

  const updateMissionRowStatus = (item) => {
    if (
      item.participants === parseInt(item.has_finished, 2) &&
      item.participants > 0
    ) {
      message().error(
        'Você não pode mais alterar o status, esta missão já foi finalizada!'
      )
      return false
    }

    if (item.has_attachment && parseInt(item.attachment_count, 2) === 0) {
      message().error('Você precisa importar um arquivo para missão!')
      return false
    }

    if (item.has_forms && parseInt(item.forms_count, 2) === 0) {
      message().error('Você precisa escolher um formulário!')
      return false
    }

    const updatedMission = { ...item, status: !item.status }
    const updatedMissionIdx = missions.findIndex(
      (item) => item.uid === updatedMission.uid
    )
    const newMissionsList = [...missions]
    newMissionsList[updatedMissionIdx] = updatedMission
    setMissions(newMissionsList)

    dispatch(
      updateStatus({
        missionId: item.uid,
        status: !item.status,
      })
    )
    return message().success('Alterado com sucesso!')
  }

  useEffect(() => {
    if (debouncedFilters !== null) {
      getMissions()
    }
  }, [debouncedFilters, getMissions])

  useEffect(() => {
    if (searchPlacesResponse.success) {
      setAddress(searchPlacesResponse.data)
      searchPlacesResponse.success = false
    }
  }, [searchPlacesResponse])

  useEffect(() => {
    handlerUpdateResponse(missionsUpdate)
  }, [handlerUpdateResponse, missionsUpdate])

  useEffect(() => {
    if (selectedMission?.deleted === true) {
      submitMissionDetail()
    }
  }, [selectedMission, submitMissionDetail])

  const renderItem = (item, index) => (
    <MissionsCell
      item={item}
      key={`key-${index}`}
      openModalDetail={() => openModalDetail(item)}
      updateStatus={() => updateMissionRowStatus(item, index)}
      removeItem={() => showConfirmModal(item, index)}
      openModalForms={() => openModalForms(item)}
      openModalAttachments={() => openModalAttachment(item)}
      openModalParticipants={() => openModalParticipants(item)}
      openStatistics={() => history.push(`/missions/statistics/${item.uid}`)}
    />
  )

  return (
    <>
      {filters && (
        <MissionFilter
          filters={filters}
          isLoading={isLoadingMissions}
          onChangeFilters={onChangeFilters}
          clearFilters={clearFilters}
        />
      )}

      <TableComponent
        data={missions}
        columns={columns}
        renderItem={renderItem}
        isLoading={isLoadingMissions}
        count={count}
        totalCount={totalCount}
        activePage={activePage}
        offset={filters?.offset}
        handleColumnSort={handleSort}
        handlePaginationChange={onPageChange}
        emptyText={emptyText}
        siblingRange={1}
        boundaryRange={0}
        showEllipsis
        showFirstAndLastNav
        showPreviousAndNextNav
      />

      {selectedMission && isOpenModalForm && (
        <MissionForms
          selectedMissionUid={selectedMission.uid}
          isOpenModalForm={isOpenModalForm}
          onCloseModalForm={() => setIsOpenModalForm(false)}
        />
      )}

      {selectedMission && isOpenModalParticipants && (
        <MissionParticipants
          selectedMissionUid={selectedMission.uid}
          missionParticipants={missionParticipants}
          isOpenModalParticipants={isOpenModalParticipants}
          onCloseModalParticipants={() => setIsOpenModalParticipants(false)}
        />
      )}

      {selectedMission && isOpenModalAttachments && (
        <MissionAttachments
          selectedMissionUid={selectedMission.uid}
          customerId={selectedMission.customer_id}
          isOpenModalAttachments={isOpenModalAttachments}
          onCloseModalAttachments={() => setIsOpenModalAttachments(false)}
        />
      )}

      {selectedMission && isOpenModalDetail && (
        <MissionDetail
          selectedMission={selectedMission}
          isOpenModalDetail={isOpenModalDetail}
          close={() => setIsOpenModalDetail(false)}
          errors={errorsMissionDetail}
          handlerInput={handlerInput}
          address={address}
          changeAddress={changeAddress}
          handlerDate={handlerDate}
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          handlerCategories={handlerCategories}
          submitMissionDetail={submitMissionDetail}
        />
      )}

      <Confirm
        header="Alerta"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        content="Tem certeza deseja remover este item?"
        open={showConfirm}
        onConfirm={() => {
          setSelectedMission((sm) => ({ ...sm, deleted: true }))
        }}
        onCancel={() => setShowConfirm(false)}
      />

      <SemanticToastContainer position="bottom-right" />
    </>
  )
}
