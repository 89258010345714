import React from 'react'
import ReactMapGl from 'react-map-gl'
import { AutoSizer } from 'react-virtualized'

import MarkerIcon from '~/components/MapsItems/MarkerIcon'
import { MAPBOX_TOKEN } from '~/config/env'

export default function Map({ viewport, onChange, markers, children }) {
  return (
    <AutoSizer>
      {({ width, height }) => (
        <ReactMapGl
          {...viewport}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          onViewportChange={onChange}
          mapStyle="mapbox://styles/missionbrasil/cjkmphdta3c5r2rpezblnlfth"
          width={width}
          height={height}
        >
          {markers.map((item, index) => (
            <MarkerIcon {...item} index={index} />
          ))}
          {children}
        </ReactMapGl>
      )}
    </AutoSizer>
  )
}
