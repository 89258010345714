import React, { useState, useEffect, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import {
  List,
  Icon,
  Card,
  Table,
  Label,
  Button,
  Confirm,
  Divider,
  Progress,
} from 'semantic-ui-react'
import io from 'socket.io-client'

import Breadcrumbs from '~/components/Breadcrumbs'
import TableComponent from '~/components/Table'
import { API_BASE_URL as SOCKET } from '~/config/env'
import { baseURL } from '~/services/api'
import {
  update,
  createMissions,
  findImportsFiles,
} from '~/store/modules/imports/actions'
import { searchPlaces } from '~/store/modules/missions/actions'

import { STATUS_COLOR, STATUS_LABEL } from '../helpers/status'
import ModalAddress from './components/Modal'
import Popup from './components/Popup'

const ERROR = 'warning'
const SUCCESS = 'success'
const BREADCRUMBS_LIST = [
  {
    active: false,
    hasLink: true,
    link: '/imports',
    name: 'Importação',
  },
  {
    active: true,
    hasLink: false,
    name: 'Missões',
  },
]

function alertToast({ description, type }) {
  return toast(
    {
      type,
      description,
      title: 'Alerta',
      icon: type === SUCCESS ? 'check' : 'remove',
    },
    () => console.log('toast closed')
  )
}

function Imports(props) {
  const {
    match,
    update,
    history,
    searchPlaces,
    createMissions,
    addressResponse,
    importsFileUpdate,
    importsFileCreated,
    importsFilesMissions,
    findImportsFiles,
  } = props
  const id = match.params.importFileId
  const [list, setList] = useState([])
  const [open, setOpen] = useState(false)
  const [address, setAddress] = useState('')
  const [itemData, setItemData] = useState({})
  const [missions, setMissions] = useState([])
  const [confirm, setConfirm] = useState(false)
  const { current: socket } = useRef(io(SOCKET))
  const [loading, setLoading] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const [filters, setFilters] = useState({ limit: 100, offset: 0 })
  const [progress, setProgress] = useState({
    total: 0,
    currently: 0,
    start: false,
    hasFinished: false,
  })
  // const [items, setItems] = useState([]);
  // const [checkedAll, setCheckedAll] = useState(false);

  function onRefresh() {
    findImportsFiles({ ...filters, id })
    setLoading(true)
  }

  const updateProgress = useCallback(
    (item) => {
      if (item) {
        const { total, currently } = item
        setProgress({ ...progress, total, currently, start: true })
      }

      if (item.hasFinished) {
        setLoading(false)
        onRefresh()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [progress]
  )

  useEffect(() => {
    try {
      socket.open()
      socket.on(`integration-${id}-missions`, (getProgress) => {
        updateProgress(getProgress)
      })
    } catch (error) {
      socket.disconnect()
      alert(error)
    }
    return () => {
      socket.close()
    }
  }, [id, socket, updateProgress])

  useEffect(() => {
    findImportsFiles({ ...filters, id })
    setLoading(true)
  }, [id, filters, findImportsFiles])

  useEffect(() => {
    if (importsFilesMissions.success) {
      setMissions(
        importsFilesMissions.data.map((i) => ({
          ...i,
          checked: false,
        }))
      )
      setLoading(false)
      setLoadingButton(false)
      importsFilesMissions.success = false
    }

    if (addressResponse.success) {
      setList(addressResponse.data)
      addressResponse.success = false
    }

    if (importsFileUpdate.success) {
      alertToast({ type: SUCCESS, description: 'Item atualizado com sucesso!' })
      importsFileUpdate.success = false
    }

    if (importsFileCreated.success) {
      alertToast({
        type: SUCCESS,
        description: 'Iniciando a criação de missões...',
      })
      importsFileCreated.success = false
    } else if (importsFileCreated.error) {
      alertToast({ type: ERROR, description: importsFileCreated.message })
      setLoading(false)
      importsFileCreated.error = false
    }
  }, [
    id,
    filters,
    addressResponse,
    importsFileUpdate,
    importsFileCreated,
    importsFilesMissions,
  ])

  // function selectItem({ checked, item }) {
  //   const dataIndex = missions.findIndex(i => i === item);
  //   const mission = missions[dataIndex];
  //   mission.checked = checked;

  //   const newItems = checked
  //     ? [...items, missions[dataIndex]]
  //     : missions.filter(i => i.checked !== item.checked)

  //   setMissions(missions);
  //   setItems(newItems);
  // }

  // function selectedAll() {
  //   setCheckedAll(!checkedAll);
  //   setItems(!checkedAll ? missions.map(i => i) : []);
  //   setMissions(missions.map(i => ({ ...i, checked: !checkedAll })));
  // }

  function removeItem({ i, item }) {
    const newData = missions.find((m) => m === item)
    const addresses = newData.addressesFound.filter((r) => r !== i)
    newData.addressesFound = addresses

    if (addresses.length === 0) newData.status = 'NOT_FOUND'
    if (addresses.length === 1) newData.status = 'SUCCESS'
    if (addresses.length > 1) newData.status = 'MULTIPLES'

    update({ fileId: id, id: newData.uid, data: newData })
  }

  function openModal({ i, k, item, index }) {
    setItemData({
      item,
      index,
      addressItem: i,
      addressIndex: k,
    })
    Object.keys(i).length ? setAddress(i.data.formattedAddress) : setAddress('')
    return setOpen(true)
  }

  function handlerLimit() {
    setFilters({ limit: filters.limit + 100, offset: 0 })
    setLoadingButton(true)
    findImportsFiles({ ...filters, id, limit: filters.limit + 100 })
  }

  function handlerAddress(value) {
    setAddress(value)
    setTimeout(() => {
      return value.length > 2 && searchPlaces(value)
    }, 1000)
  }

  function changeAddress({ data }) {
    const newItem = missions.find((i) => itemData.item === i)

    if (newItem.addressesFound.length > 0) {
      const updateAddress = newItem.addressesFound.find(
        (r) => r === itemData.addressItem
      )
      updateAddress.data = data
      updateAddress.statusAddress = 'COMPLETED'
    } else {
      newItem.addressesFound = [
        ...newItem.addressesFound,
        {
          data,
          statusAddress: 'COMPLETED',
        },
      ]
    }

    newItem.status = 'CHECKED'

    update({ fileId: id, id: newItem.uid, data: newItem })
    setOpen(false)
  }

  function checkedItem({ item }) {
    const newData = missions.find((m) => m === item)
    newData.status = 'CHECKED'
    update({ fileId: id, id: newData.uid, data: newData })
  }

  function confirmItems() {
    const statusSuccess = missions.find(
      (i) =>
        i.status === 'MULTIPLES' ||
        i.status === 'NOT_FOUND' ||
        i.status === 'ERROR'
    )

    if (statusSuccess) {
      setConfirm(true)
      return false
    }

    return setConfirm(true)
  }

  function submit() {
    setLoading(true)
    setConfirm(false)
    return createMissions(id)
  }

  function submitNotFound() {
    window.open(`${baseURL}integration/importFile/${id}/not-found`, 'self')
  }

  function renderItem(item, index) {
    return (
      <Table.Row
        key={`k-${index}`}
        active={item.status === 'IMPORTED'}
        disabled={item.status === 'IMPORTED'}
      >
        {/* <Table.Cell>
          <Checkbox
            checked={item.checked}
            onClick={(e, { checked }) => selectItem({ checked, item })}
          />
        </Table.Cell> */}
        <Table.Cell>
          <Label color={STATUS_COLOR(item.status)}>
            {STATUS_LABEL(item.status)}
          </Label>
        </Table.Cell>
        <Table.Cell>{item.original.mission_name}</Table.Cell>
        <Table.Cell>
          <List>
            {item.addressesFound.length === 0 && (
              <Button onClick={() => openModal({ i: {}, k: 0, item, index })}>
                Adicionar Endereço
              </Button>
            )}

            {item.addressesFound.length > 0 &&
              item.addressesFound.map((i, k) => (
                <List.Item key={`i-${k}`}>
                  <List.Icon name="marker" />
                  <List.Content>
                    <List.Header as="a">
                      <span
                        style={{
                          color: i.statusAddress === 'INCOMPLETED' && 'red',
                        }}
                      >
                        <Popup
                          title={i.data.formattedAddress}
                          remove={() => removeItem({ i, item })}
                          open={() => openModal({ i, k, item, index })}
                          link={() =>
                            window.open(
                              `https://www.google.com/maps/search/?api=1&query=${i.data.formattedAddress}`
                            )
                          }
                        />
                      </span>
                    </List.Header>
                    <List.Description>{i.statusAddress}</List.Description>
                  </List.Content>
                </List.Item>
              ))}
          </List>
        </Table.Cell>
        <Table.Cell>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${item.original.address.join(
              '+'
            )}`}
            target="blank"
          >
            {item.original.address.join(', ')}
          </a>
        </Table.Cell>
        <Table.Cell>
          {item.status === 'SUCCESS' && (
            <Button
              compact
              content="Conferido"
              onClick={() => checkedItem({ item })}
            />
          )}
        </Table.Cell>
      </Table.Row>
    )
  }

  return (
    <>
      <Breadcrumbs history={history} items={BREADCRUMBS_LIST} />

      <Button
        icon
        positive
        loading={loading}
        disabled={loading}
        labelPosition="left"
        onClick={() => confirmItems()}
      >
        <Icon name="check" />
        Criar Missões
        {/* {items.length > 0 && `(${items.length})`} */}
      </Button>

      <Button icon labelPosition="left" onClick={() => submitNotFound()}>
        <Icon name="download" />
        Não Encontradas
      </Button>

      {!progress.hasFinished && loading && (
        <Card fluid>
          <Card.Content>
            <h4>
              {progress.start
                ? `Criando missões (${progress.currently} de ${progress.total})`
                : 'Iniciando importação...'}
            </h4>
            <div>
              <Progress
                percent={
                  (Number(progress.currently) / 100) * Number(progress.total)
                }
                indicating
              />
            </div>
          </Card.Content>
        </Card>
      )}

      <TableComponent
        data={missions}
        isLoading={loading}
        renderItem={renderItem}
        columns={[
          // <Checkbox checked={checkedAll} onClick={() => selectedAll()} />,
          'STATUS',
          'NOME DA MISSAO',
          'ENDEREÇO - CONVERTIDO',
          'ENDEREÇO - ORIGINAL',
          'AÇÕES',
        ]}
      />

      <Divider />

      {!progress.hasFinished && !loading && (
        <Button
          primary
          loading={loadingButton}
          disabled={loadingButton}
          onClick={() => handlerLimit()}
        >
          Carregar mais...
        </Button>
      )}

      <SemanticToastContainer position="top-center" />

      <ModalAddress
        open={open}
        list={list}
        item={itemData}
        address={address}
        close={() => setOpen(false)}
        changeAddress={changeAddress}
        handlerAddress={handlerAddress}
      />

      <Confirm
        open={confirm}
        header="Alerta"
        confirmButton="Criar"
        cancelButton="Cancelar"
        onConfirm={() => submit()}
        onCancel={() => setConfirm(false)}
        content="Tem certeza que deseja criar as missões? Lembrando que as missões que serão criadas devem estar com status igual a SUCESSO."
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  addressResponse: state.searchPlaces,
  importsFileUpdate: state.importsFileUpdate,
  importsFileCreated: state.importsFileCreated,
  importsFilesMissions: state.importsFilesMissions,
})

export default connect(mapStateToProps, {
  update,
  searchPlaces,
  createMissions,
  findImportsFiles,
})(Imports)
