import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { withRouter, useHistory, useLocation } from 'react-router-dom'
import { SemanticToastContainer } from 'react-semantic-toasts'

import queryString from 'query-string'
import { Confirm, Loader } from 'semantic-ui-react'

// import TableComponent from '~/components/Table'
import api from '~/services/api'
import {
  CUSTOMERS,
  CATEGORY_MISSIONS_MAIN,
  MISSIONS_USERS_EXPIRING,
  MISSIONS_USERS_EXPIRING_EXTEND,
  MISSIONS_USERS_EXPIRING_REMOVE,
} from '~/services/api/endpoints'
import { findSubcategories } from '~/store/modules/categories/actions'
import message from '~/utils/messages'

import Main from '../../Main'
import CardItem from './components/CardItem'
import Filter from './components/Filter'
import { CardGroup } from './styles'

function Expiring() {
  const history = useHistory()
  const location = useLocation()

  /* const user = useSelector((state) => state.user)
  const customers = useSelector((state) => state.customers)
  const categories = useSelector((state) => state.categories)
  const missionsExpiring = useSelector((state) => state.missionsExpiring)
  const missionsUsersExtend = useSelector((state) => state.missionsUsersExtend) 
  const missionsUsersRemove = useSelector((state) => state.missionsUsersRemove) */
  const subCategoriesAll = useSelector((state) => state.getSubCategories)

  const [item, setItem] = useState({})
  const [filters, setFilters] = useState({
    final: null,
    initial: null,
    category_id: null,
    customer_id: null,
    missions_main_id: null,
  })
  const [loading, setLoading] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [missions, setMissions] = useState([])
  const [customersList, setCustomers] = useState([])
  const [categoriesList, setCategories] = useState([])
  const [subCategories, setSubcategories] = useState([])
  const [expiration, setExpiration] = useState({
    id: null,
    new_expiration: '',
  })

  // paginação
  // hooks
  // render Card
  // { isFetching, data, success, progress, count, error, uniqueId }

  const getMissionsUsersExpiring = useCallback(async (query = '') => {
    const { data, status } = await api.get(MISSIONS_USERS_EXPIRING, {
      params: query,
    })

    if (status === 200) {
      setMissions(data.data)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    getMissionsUsersExpiring()
  }, [getMissionsUsersExpiring])

  const submitFilter = useCallback(() => {
    const query = { ...queryString.parse(location.search) }
    const search = queryString.stringify({ ...query })

    setLoading(true)
    getMissionsUsersExpiring(search)
  }, [location, getMissionsUsersExpiring])

  const getCategories = useCallback(async () => {
    const { data, status } = await api.get(CATEGORY_MISSIONS_MAIN)

    if (status === 200) {
      const newArr = data.data.map(handleSelect)
      setCategories(newArr)
    }
  }, [])

  useEffect(() => {
    getCategories()
  }, [getCategories])

  const getCustomers = useCallback(async (filter) => {
    const { data, status } = await api.get(CUSTOMERS, {
      params: filter,
    })

    if (status === 200) {
      const newArr = data.data.map(handleSelect)
      setCustomers(newArr)
    }
  }, [])

  useEffect(() => {
    const filter = {
      search: '',
      limit: 1000,
      offset: 0,
      order: '',
      direction: '',
    }
    getCustomers(filter)
  }, [getCustomers])

  useEffect(() => {
    if (subCategoriesAll.success) {
      const newArr = subCategoriesAll.data.map(handleSelect)
      setSubcategories(newArr)
    }
  }, [subCategoriesAll])

  function handleSelect(i, idx) {
    return { key: idx, value: i.uid, text: i.name || i.title }
  }

  function handleMissionsExpiring(missionItem) {
    setItem(missionItem)
    setConfirm(true)
  }

  async function submit() {
    const newArr = missions.filter(
      (i) => i.missions_users_id !== item.missions_users_id
    )
    setConfirm(false)

    try {
      await api.remove(MISSIONS_USERS_EXPIRING_REMOVE(item.missions_users_id))

      setMissions(newArr)
      message().success('Usuário removido com sucesso!')
    } catch (err) {
      message().error(err.response.data.data || 'Houve um erro no servidor')
    }
  }

  const handlerQuery = useCallback(
    (target, value) => {
      const queries = queryString.parse(location.search)
      const handlerQueries = { ...queries, [target]: value }
      const search = queryString.stringify(handlerQueries)
      history.replace({ search, pahtname: location.pathname })
    },
    [location.pathname, location.search, history]
  )

  const handlerChange = useCallback(
    (value, key) => {
      if (key === 'category_id') {
        findSubcategories(value)
      }

      if (key === 'initial' || key === 'final') {
        const newValue = new Date(value).getTime()
        setFilters((oldFilters) => ({ ...oldFilters, [key]: newValue }))
        handlerQuery(key, newValue)
        return false
      }

      handlerQuery(key, value)
      setFilters((oldFilters) => ({ ...oldFilters, [key]: value }))
    },
    [handlerQuery]
  )

  const handleExpiration = useCallback((value, muId) => {
    setExpiration({
      id: muId,
      new_expiration: new Date(value).getTime(),
    })
  }, [])

  const HandleExtendMissionsUsers = useCallback(async () => {
    const { status } = await api.put(
      MISSIONS_USERS_EXPIRING_EXTEND(expiration.id),
      {
        new_expiration: expiration.new_expiration,
      }
    )

    if (status === 200) {
      submitFilter({})
    }
  }, [expiration, submitFilter])

  function submitExpiration() {
    HandleExtendMissionsUsers()
  }

  return (
    <Main>
      <Filter
        {...filters}
        submit={submitFilter}
        customers={customersList}
        categories={categoriesList}
        handlerChange={handlerChange}
        subCategories={subCategories}
      />
      {loading ? (
        <div style={{ marginTop: 15 }}>
          <Loader active inline="centered" />
        </div>
      ) : (
        <CardGroup>
          {missions.map((mission, index) => (
            <CardItem
              key={`d-${mission.mission_id}-${index}`}
              mission={mission}
              {...expiration}
              onClick={handleMissionsExpiring}
              onClickUpdate={submitExpiration}
              handleExpiration={handleExpiration}
            />
          ))}
        </CardGroup>
      )}
      <Confirm
        open={confirm}
        header="Alerta"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onConfirm={() => submit()}
        onCancel={() => setConfirm(false)}
        content="Tem certeza deseja retirar este usuário da missão?"
      />
      <SemanticToastContainer position="bottom-right" />
    </Main>
  )
}

export default withRouter(Expiring)
