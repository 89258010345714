import React from 'react'
import { FlyToInterpolator } from 'react-map-gl'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import * as d3 from 'd3'
import moment from 'moment'
import locale from 'moment/locale/pt-br'
import { isNil, isEmpty } from 'ramda'
import { Grid, Loader, Confirm, Message, Dropdown } from 'semantic-ui-react'

import ImageLocation from '~/assets/logo-dark.png'
import Breadcrumbs from '~/components/Breadcrumbs'
import Evaluation from '~/components/Cards/Evaluation'
import RegisterServices from '~/components/Cards/RegisterServices'
import MarkerImage from '~/components/MapsItems/MarkerImage'
import PopupItem from '~/components/MapsItems/Popup'
import MissionsFeeds from '~/components/MissionsFeeds'
import FormsResponse from '~/components/Modal/FormsResponse'
import ReproveList from '~/components/Modal/ReproveList'
import { findDataByMissionId } from '~/store/modules/formsData/actions'
import { getInvoice } from '~/store/modules/invoices/actions'
import { userMissionsFeedsCount } from '~/store/modules/missions/actions'
import {
  findById,
  findFeeds,
  updateStatus,
} from '~/store/modules/registers/actions'
import { statusRegisterColor, statusRegisterLabel } from '~/utils/feedsActions'
import message from '~/utils/messages'

import Bonus from './components/Bonus'
import Invoice from './components/Invoice'
import Map from './components/Map'
import PercentualValue from './components/Percentual'

moment.updateLocale('pt-br', locale)

const positionDefault = {
  latitude: -8.269214,
  longitude: -55.441382,
}

class RegistersDetail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openPop: false,
      cost_value: null,
      verifyBonus: null,
      viewport: {
        zoom: 0,
        height: 600,
        ...positionDefault,
        width: window.innerWidth,
      },
      lat: positionDefault.latitude,
      lng: positionDefault.longitude,

      error: false,
      hasReproved: '',
      reproveError: '',
      hasOtherReproved: '',

      open: false,
      close: false,
      openConfirm: false,
      index: 0,
      feeds: [],
      invoices: [],
      formsItems: [],
      isLoading: false,
      loadingData: true,
      registerStatus: null,
      openFormsModal: false,
      openReprovedModal: false,
    }
  }

  componentDidMount() {
    this.props.findById(this.props.match.params.id)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.registerDetail !== this.props.registerDetail) {
      this.handlerRegister(nextProps.registerDetail)
    }

    if (nextProps.registersFeeds !== this.props.registersFeeds) {
      if (nextProps.registersFeeds.success) {
        this.setState({ feeds: nextProps.registersFeeds.data })
      }
    }

    if (nextProps.registersUpdate !== this.props.registersUpdate) {
      this.handlerUpdate(nextProps.registersUpdate)
    }

    if (nextProps.invoicesFetch !== this.props.invoicesFetch) {
      if (nextProps.invoicesFetch.success) {
        this.setState({
          invoices: nextProps.invoicesFetch.data,
        })
      }
    }

    if (nextProps.formsData !== this.props.formsData) {
      if (nextProps.formsData.success) {
        this.setState({
          formsItems: nextProps.formsData.data,
          isLoading: nextProps.formsData.isFetching,
        })
      }
    }
  }

  goToPoint = ({ lat, lng }) =>
    this.setState({
      viewport: {
        ...this.state.viewport,
        zoom: 14,
        latitude: lat,
        longitude: lng,
        transitionDuration: 5000,
        transitionEasing: d3.easeCubic,
        transitionInterpolator: new FlyToInterpolator(),
      },
    })

  handlerRegister = (props) => {
    if (props.success) {
      const { lat, lng, status, missions_users_id } = props.data

      if (props.data.has_products) {
        this.props.getInvoice(missions_users_id)
      }

      if (!isNil(lat) && !isNil(lng)) {
        this.setState((oldValues) => ({
          ...oldValues,
          viewport: {
            ...this.state.viewport,
            zoom: 14,
            latitude: lat,
            longitude: lng,
            transitionEasing: d3.easeCubic,
            transitionInterpolator: new FlyToInterpolator(),
          },
          lat,
          lng,
          registerStatus: status,
        }))
      } else {
        this.setState((oldValues) => ({
          ...oldValues,
          viewport: { ...this.state.viewport },
        }))
      }

      Promise.resolve()
        .then(this.props.findFeeds(missions_users_id))
        .then(this.props.userMissionsFeedsCount(missions_users_id))
        .then(
          this.setState((oldValues) => ({
            ...oldValues,
            loadingData: false,
          }))
        )

      props.success = false
    } else if (props.error) {
      props.error = false
    }
  }

  showToast = (description, type) => {
    toast(
      {
        description,
        title: 'Alerta',
        type: type ? 'success' : 'warning',
        icon: type ? 'check' : 'remove',
      },
      () => console.log('toast closed')
    )
  }

  handlerUpdate = (props) => {
    if (props.success) {
      this.props.findById(this.props.match.params.id)
      this.showToast('Registro atualizado com sucesso!', true)
    } else if (props.error) {
      this.setState({
        open: true,
        error: props.message,
      })
    }
  }

  handleChangeReproved = (e, value) =>
    this.setState({
      hasOtherReproved: '',
      hasReproved: value.label,
    })

  handlerChangeOthersReproved = (e, { value }) =>
    this.setState({
      hasReproved: '',
      hasOtherReproved: value,
    })

  confirmStatus = (statusRegister) => {
    const {
      uid,
      mission_id,
      customer_id,
      status,
      has_bonus,
      customer_value,
      has_value_percentage,
    } = this.props.registerDetail.data

    if (has_value_percentage && status === 3 && statusRegister === 0) {
      if (
        this.state.cost_value === 0 ||
        isNil(this.state.cost_value) ||
        isEmpty(this.state.cost_value)
      ) {
        this.showToast('Valor percentual deve ser maior que zero!')
        return false
      }
    }

    if (status === 3 && has_bonus && isNil(this.state.verifyBonus)) {
      this.showToast('Você precisa definir se o usuário realizou o bônus!')
      return false
    }

    if (
      status === 5 &&
      this.state.hasReproved.length === 0 &&
      this.state.hasOtherReproved.length === 0
    ) {
      this.showToast('Resposta de correção não pode estar vazia!')
      return false
    }

    this.setState({ openReprovedModal: false, isLoading: true })

    this.props.updateStatus({
      customer_id,
      customer_value,
      registerId: uid,
      missionId: mission_id,
      status: statusRegister,
      cost_value: this.state.cost_value,
      confirm_bonus: this.state.verifyBonus,
      has_reproved: this.state.hasReproved || this.state.hasOtherReproved,
    })
  }

  renderPopUp = () => (
    <PopupItem {...this.state} title={this.props.registerDetail.data.place} />
  )

  renderInitialLocation = () => (
    <MarkerImage {...this.state} path={ImageLocation} />
  )

  handleConfirm = () => {
    this.confirmStatus(this.state.registerStatus)
    this.setState({ openConfirm: false })
  }

  renderModal = () => (
    <ReproveList
      {...this.state}
      evaluationHandler={this.confirmStatus}
      handleChangeReproved={this.handleChangeReproved}
      close={() => this.setState({ openReprovedModal: false })}
      handlerChangeOthersReproved={this.handlerChangeOthersReproved}
    />
  )

  renderFormsModal = () => (
    <FormsResponse
      {...this.state}
      close={() => this.setState({ openFormsModal: false })}
    />
  )

  openForms = () => {
    this.setState({ openFormsModal: true })
    this.props.findDataByMissionId(
      this.props.registerDetail.data.missions_users_id
    )
  }

  downloadInvoice = (url) => {
    if (!url) {
      return message().error('Orçamento não foi gerado')
    }

    return window.open(url, '_blank')
  }

  handleInput = (key, value) => {
    this.setState({ [key]: value })
  }

  render() {
    const {
      uid,
      status,
      has_bonus,
      has_products,
      user_value_bonus,
      missions_users_id,
      has_value_percentage,
    } = this.props.registerDetail.data
    const breadcrumbsList = [
      {
        active: false,
        hasLink: true,
        link: null,
        name: 'Registros',
      },
      {
        active: true,
        hasLink: false,
        name: this.props.registerDetail.data.missionName,
      },
    ]
    const tagOptions = [
      {
        text: 'Aprovar',
        value: 'Aprovar',
        label: { color: 'green', empty: true, circular: true },
        onClick: () => this.setState({ registerStatus: 0, openConfirm: true }),
        status: 3,
      },
      {
        text: 'Correção',
        value: 'Correção',
        label: { color: 'blue', empty: true, circular: true },
        onClick: () => this.setState({ openReprovedModal: true }),
        status: 3,
      },
      {
        text: 'Reverter',
        value: 'Reverter',
        label: { color: 'yellow', empty: true, circular: true },
        onClick: () => this.setState({ registerStatus: 3, openConfirm: true }),
        status: 0,
      },
      {
        text: 'Ir para Análise',
        value: 'Ir para Análise',
        label: { color: 'orange', empty: true, circular: true },
        onClick: () => this.setState({ registerStatus: 3, openConfirm: true }),
        status: 4,
      },
    ]
    return (
      <>
        <Breadcrumbs
          hasButton
          items={breadcrumbsList}
          history={this.props.history}
        >
          <Dropdown item text="Avaliação" disabled={status === 2}>
            <Dropdown.Menu>
              {tagOptions.map(
                (option) =>
                  status === option.status && (
                    <Dropdown.Item key={option.value} {...option} />
                  )
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Breadcrumbs>
        <SemanticToastContainer position="top-right" />

        <Message style={{ backgroundColor: statusRegisterColor(status) }}>
          <Message.Header style={{ color: 'white' }}>
            {statusRegisterLabel(status)}
          </Message.Header>
        </Message>

        {this.props.registerDetail.isFetching ? (
          <Loader active inline="centered" />
        ) : (
          <div>
            <Grid>
              <Grid.Column width={12}>
                <Map
                  markers={this.state.feeds}
                  viewport={this.state.viewport}
                  onChange={(viewport) => this.setState({ viewport })}
                >
                  {this.renderPopUp()}
                  {this.renderInitialLocation()}
                </Map>
              </Grid.Column>
              <Grid.Column width={4}>
                {has_bonus && status === 3 && (
                  <Bonus
                    value={user_value_bonus}
                    verifyBonus={this.state.verifyBonus}
                    onClick={(check) => this.setState({ verifyBonus: check })}
                  />
                )}

                {status === 3 && has_value_percentage && (
                  <PercentualValue
                    {...this.state}
                    handleInput={this.handleInput}
                  />
                )}

                {has_products && this.state.invoices.length > 0 && (
                  <Invoice
                    data={this.state.invoices}
                    onClick={this.downloadInvoice}
                  />
                )}

                {!this.state.loadingData && (
                  <Evaluation
                    data={this.props.registerDetail.data}
                    evaluationHandler={this.evaluationHandler}
                    isLoading={this.props.registersUpdate.isFetching}
                    openReprovedModal={() =>
                      this.setState({ openReprovedModal: true })
                    }
                  />
                )}

                <RegisterServices
                  {...this.props.registerDetail.data}
                  openFormsModal={() => this.openForms()}
                  onClick={() =>
                    this.props.history.push(
                      `/register/${uid}/photos/${missions_users_id}`
                    )
                  }
                />

                {this.state.feeds.length > 0 && (
                  <MissionsFeeds
                    onClick={this.goToPoint}
                    items={this.state.feeds}
                  />
                )}
              </Grid.Column>
            </Grid>
            {this.renderModal()}
            {this.renderFormsModal()}
            <Confirm
              header="Alerta"
              cancelButton="Não"
              confirmButton="Sim"
              open={this.state.openConfirm}
              onConfirm={this.handleConfirm}
              content="Tem certeza que deseja alterar esse registro?"
              onCancel={() => this.setState({ openConfirm: false })}
            />
            <Confirm
              header="Alerta"
              open={this.state.open}
              cancelButton="Cancelar"
              content={this.state.error}
              onCancel={() => this.setState({ open: false })}
              onConfirm={() => this.setState({ open: false })}
            />
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  formsData: state.formsData,
  feedsCount: state.feedsCount,
  invoicesFetch: state.invoicesFetch,
  registerDetail: state.registerDetail,
  registersFeeds: state.registersFeeds,
  registersUpdate: state.registersUpdate,
})

export default connect(mapStateToProps, {
  findById,
  findFeeds,
  getInvoice,
  updateStatus,
  findDataByMissionId,
  userMissionsFeedsCount,
})(withRouter(RegistersDetail))
