import React, { Component } from 'react'
import update from 'react-addons-update'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import { saveAs } from 'file-saver'
import { Table, Header, Card } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import MetricsFilter from '~/components/MetricsFilter'
import { METRICS } from '~/services/api/endpoints'
import {
  subCategoriesAll,
  categories as findCategories,
} from '~/store/modules/categories/actions'
import { findAll as findCustomers } from '~/store/modules/customers/actions'
import { findMetrics } from '~/store/modules/metrics/actions'

import 'react-semantic-toasts/styles/react-semantic-alert.css'

const groups = [
  { key: 1, value: 'customers', text: 'Cliente', param: 'cliente' },
  { key: 2, value: 'categories', text: 'Categorias', param: 'categoria' },
  {
    key: 3,
    value: 'missions_main',
    text: 'Subcategorias',
    param: 'sub_catgoria',
  },
]

const types = [
  { key: 1, value: 'created', text: 'Criados' },
  { key: 2, value: 'started', text: 'Iniciados' },
  { key: 3, value: 'finished', text: 'Finalizados' },
  { key: 4, value: 'approved', text: 'Aprovados' },
  { key: 5, value: 'reproved', text: 'Reprovados' },
  { key: 6, value: 'expiring', text: 'Expirando' },
]

const ranges = [
  { key: 1, value: 'day', text: 'Dia' },
  { key: 2, value: 'week', text: 'Semana' },
  { key: 3, value: 'month', text: 'Mês' },
]

const periods = [
  { key: 1, value: 'interval', text: 'Intervalo' },
  { key: 2, value: 'period', text: 'Periodo' },
]

const resetFilter = {
  initial: '',
  final: '',
  group: '',
  range: '',
  period: 'interval',

  customerId: '',
  categoryId: '',
  missionsMainId: '',
  xls: false,
  analytical: false,

  labels: [],
  metrics: [],
  columns: [],
  customers: [],
  categories: [],
  conditions: [],
  typesGroups: [],
  typesMissions: [],
  subCategories: [],

  condcustomer: [],
  condcategory: [],
  condmissions_main: [],
}

class Metrics extends Component {
  state = {
    types,
    ranges,
    groups,
    periods,
    ...resetFilter,
  }

  componentDidMount() {
    this.onRefresh()
  }

  onRefresh = () => {
    return Promise.all([
      this.props.findCategories(),
      this.props.subCategoriesAll(),
      this.props.findCustomers({ search: '', limit: 1000, offset: 0 }),
    ])
  }

  resetFilterSubmit = () => {
    this.setState({ ...resetFilter })
    this.onRefresh()
  }

  addValues = (type, value) =>
    this.setState({
      [`cond${type}`]: value.map((item) => ({ type, uid: item })),
    })

  handlerChange = (key, value) => this.setState({ ...this.state, [key]: value })

  handlerArray = (key, value) => {
    const index = this.state[key].indexOf(value)

    index !== -1
      ? this.setState({
          [key]: update(this.state[key], { $splice: [[index, 1]] }),
        })
      : this.setState({ [key]: [...this.state[key], value] })
  }

  submitFilter = () => {
    const data = {}

    if (this.state.typesGroups.length === 0) {
      this.showToast('Você precisa escolher um grupo!')
      return false
    }

    if (this.state.typesMissions.length === 0) {
      this.showToast('Você precisa escolher um tipo!')
      return false
    }

    if (!this.state.initial) {
      this.showToast('A data inicial não pode estar vazia!')
      return false
    }
    if (!this.state.final) {
      this.showToast('A data final não pode estar vazia!')
      return false
    }
    if (this.state.initial > this.state.final) {
      this.showToast('A data inicial deve ser menor que final!')
      return false
    }

    Object.assign(data, {
      groups: {
        types: this.state.typesGroups,
        range: this.state.range,
      },
      types: this.state.typesMissions,
      initial: new Date(this.state.initial).getTime(),
      final: new Date(this.state.final).getTime(),
      conditions: [
        ...this.state.condcustomer,
        ...this.state.condcategory,
        ...this.state.condmissions_main,
      ],
      xls: this.state.xls,
      analytical: this.state.analytical,
    })

    if (this.state.xls) {
      this.importXls(data)
      return false
    }

    this.setState({ isLoading: true })

    this.props.findMetrics(data)
  }

  importXls = async (params) => {
    try {
      const res = await fetch(`${METRICS}?xls=${this.state.xls}`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      })
      const blob = await res.blob()
      saveAs(blob, `metricas-${new Date().getTime()}.xlsx`)
      this.setState({ isLoading: false })
    } catch (error) {
      this.showToast(error)
      return false
    }
  }

  showToast = (description, type) => {
    toast(
      {
        description,
        title: 'Alerta',
        type: type ? 'success' : 'warning',
        icon: type ? 'check' : 'remove',
      },
      () => console.log('toast closed')
    )
  }

  handlerMetrics = (props) => {
    if (props.success) {
      this.setState({ metrics: props.data, isLoading: false })
      props.success = false
    } else if (props.error) {
      this.setState({ isLoading: false })
      this.showToast(props.message, true)
      props.error = false
      return false
    }
  }

  handlerList = (props, key) => {
    if (props.success) {
      const newArray = props.data.map((item, index) => {
        const text = item.name || item.title
        return { key: index, text, value: item.uid }
      })

      this.setState({ [key]: newArray })
    }
  }

  componentWillReceiveProps({
    metrics,
    customers,
    categories,
    subCategoriesAllResponse,
  }) {
    if (customers !== this.props.customers) {
      this.handlerList(customers, 'customers')
    }

    if (categories !== this.props.categories) {
      this.handlerList(categories, 'categories')
    }

    if (subCategoriesAllResponse !== this.props.subCategoriesAllResponse) {
      this.handlerList(subCategoriesAllResponse, 'subCategories')
    }

    if (metrics !== this.props.metrics) {
      this.handlerMetrics(metrics)
    }
  }

  renderItem = (item, index) => (
    <Table.Row key={`index-${index}`}>
      <Table.Cell>
        {item.hasOwnProperty('cliente') ? item.cliente : ''}
      </Table.Cell>
      <Table.Cell>
        {item.hasOwnProperty('categoria') ? item.categoria : item.name}
      </Table.Cell>
      <Table.Cell>
        {item.hasOwnProperty('sub_categoria') ? item.sub_categoria : ''}
      </Table.Cell>
      <Table.Cell>{item.tipo}</Table.Cell>
      <Table.Cell>{item.periodo}</Table.Cell>
      <Table.Cell>{item.total}</Table.Cell>
      <Table.Cell>
        {item.total_customer_value || item.customer_value}
      </Table.Cell>
      <Table.Cell>{item.total_user_value || item.user_value}</Table.Cell>
    </Table.Row>
  )

  tableHeader = () => (
    <Table.Header>
      <Table.Row>
        <Table.Cell>Cliente</Table.Cell>
        <Table.Cell>Categoria</Table.Cell>
        <Table.Cell>Subcategoria</Table.Cell>
        <Table.Cell>Tipo</Table.Cell>
        <Table.Cell>Período</Table.Cell>
        <Table.Cell>Total</Table.Cell>
        <Table.Cell>Valor Cliente</Table.Cell>
        <Table.Cell>Valor Usuário</Table.Cell>
      </Table.Row>
    </Table.Header>
  )

  render() {
    return (
      <>
        <MetricsFilter {...this.state} {...this} />

        {this.state.metrics.length > 0 && (
          <div
            style={{
              flex: 1,
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <Header as="h4">
              Total de {this.state.metrics.length} resultados.
            </Header>
          </div>
        )}

        {this.state.metrics.length > 0 ? (
          this.state.metrics[0].hasOwnProperty('items') ? (
            this.state.metrics.map((item, index) => (
              <Card key={`k-${index}`} fluid>
                <Card.Content>
                  {item.hasOwnProperty('cliente') && (
                    <Card.Header>{item.cliente}</Card.Header>
                  )}
                  {item.hasOwnProperty('categoria') && (
                    <Card.Header>{item.sub_categoria}</Card.Header>
                  )}
                  {item.hasOwnProperty('sub_categoria') && (
                    <Card.Header>{item.sub_categoria}</Card.Header>
                  )}
                  <Table celled selectable>
                    {this.tableHeader()}
                    {item.items.map(this.renderItem)}
                  </Table>
                </Card.Content>
              </Card>
            ))
          ) : (
            <Table celled selectable>
              {this.tableHeader()}
              <Table.Body>{this.state.metrics.map(this.renderItem)}</Table.Body>
            </Table>
          )
        ) : (
          <Table>
            <Table.Row>
              <Table.Cell colSpan={6}>
                <EmptyList
                  icon="chart bar"
                  text="Nenhuma métrica executada..."
                />
              </Table.Cell>
            </Table.Row>
          </Table>
        )}
        <SemanticToastContainer position="top-right" />
      </>
    )
  }
}

const mapStateToProps = (store) => ({
  user: store.user,
  metrics: store.metrics,
  customers: store.customers,
  categories: store.categories,
  subCategoriesAllResponse: store.subCategoriesAll,
})

export default connect(mapStateToProps, {
  findMetrics,
  findCustomers,
  findCategories,
  subCategoriesAll,
})(withRouter(Metrics))
