import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { Editor } from 'react-draft-wysiwyg'
import { useSelector } from 'react-redux'

import moment from 'moment'
import {
  Modal,
  Form,
  List,
  Input,
  Select,
  Button,
  Message,
  Checkbox,
} from 'semantic-ui-react'

import '~/assets/css/react-datepicker.css'
import api from '~/services/api'
import { CUSTOMERS_ID_RESTRICTED } from '~/services/api/endpoints'

export default function MissionDetail({
  selectedMission,
  isOpenModalDetail,
  close,
  errors,
  handlerInput,
  address,
  changeAddress,
  handlerDate,
  editorState,
  onEditorStateChange,
  handlerCategories,
  submitMissionDetail,
}) {
  const [hasDistance, setHasDistance] = useState(true)

  const user = useSelector((store) => store.user)

  const [subCategoriesOptions, setSubCategoriesOptions] = useState([])

  const getOptionsArray = (arr) => {
    return arr.map((item, index) => ({
      key: index,
      value: item.uid || item.uf || item,
      text: item.name || item.title || item,
    }))
  }

  useEffect(() => {
    ;(async () => {
      try {
        const res = await api.get(
          CUSTOMERS_ID_RESTRICTED(selectedMission.customer_id)
        )
        setSubCategoriesOptions(getOptionsArray(res.data.data))
      } catch (error) {
        console.log(error)
      }
    })()
  }, [selectedMission.customer_id])

  return (
    <Modal size="small" open={isOpenModalDetail} onClose={close}>
      <Modal.Header content="Atualizar Missão" />
      <Modal.Content scrolling>
        {errors.length > 0 && (
          <Message
            key={errors}
            warning
            fluid
            header="Alerta!"
            style={{ padding: 10 }}
            list={errors}
          />
        )}
        <Form>
          <Form.Input
            id="name"
            type="text"
            onChange={handlerInput}
            value={selectedMission.name || ''}
            label="Nome da Missão (Obrigatório)"
            placeholder="Ex: Cópia de Processo"
          />
          <Form.Input
            type="text"
            id="identifier"
            onChange={handlerInput}
            value={selectedMission.identifier || ''}
            label="Identificador (Opcional)"
            placeholder="Ex: 123 João"
          />
          {user.profile.role_name === 'Admin' ||
          user.profile.role_name === 'Leader' ? (
            <Form.Field
              label="Categoria"
              control={Select}
              id="missionsMain"
              placeholder="Tipo"
              options={subCategoriesOptions}
              value={selectedMission.missions_main_id}
              onChange={handlerCategories}
            />
          ) : null}

          {!selectedMission.no_location && (
            <Form.Input
              type="text"
              id="place"
              value={selectedMission.place || null}
              onChange={handlerInput}
              label="Digite o endereço (Obrigatório)"
              placeholder="Ex: Florianópolis"
            />
          )}

          {!selectedMission.no_location && selectedMission.place.length > 0 && (
            <List selection verticalAlign="middle">
              {address.map((i, index) => {
                const place = i.formatted_address
                const { lat, lng } = i.geometry.location
                return (
                  <List.Item
                    key={`${place}${index}`}
                    onClick={() => changeAddress({ place, lat, lng })}
                  >
                    <List.Icon style={{ color: 'red' }} name="marker" />
                    <List.Content>
                      <List.Header as="a">{place}</List.Header>
                    </List.Content>
                  </List.Item>
                )
              })}
            </List>
          )}

          <Form.Field inline>
            <Checkbox onChange={() => setHasDistance(!hasDistance)} />
            <Input
              type="number"
              id="custom_distance"
              style={{ width: 300 }}
              onChange={handlerInput}
              value={selectedMission.custom_distance || null}
              placeholder="Restringir Distância (km). Ex: 1"
              disabled={hasDistance}
            />
          </Form.Field>
          <div className="field">
            <label>Data de Expiração</label>
            <div>
              <DatePicker
                id="expired_at"
                minDate={moment()}
                dateFormat="dd/MM/yyyy"
                onChange={handlerDate}
                value={moment(selectedMission.expired_at).format('DD/MM/YYYY')}
              />
            </div>
          </div>
          <div className="field">
            <label>Descrição</label>
            <div className="editor">
              <Editor
                style={{ borderWidth: 1, borderColor: 'gray' }}
                editorState={editorState}
                editorClassName="editorClassName"
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                onEditorStateChange={onEditorStateChange}
              />
            </div>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={close}>
          Cancelar
        </Button>
        <Button
          positive
          icon="checkmark"
          content="Atualizar"
          labelPosition="right"
          onClick={submitMissionDetail}
        />
      </Modal.Actions>
    </Modal>
  )
}
