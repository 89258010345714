import React, { useState, useEffect, useCallback } from 'react'

import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Image, Table } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'

import TableComponent from '~/components/Table'
import api from '~/services/api'
import { USER_DATA_REGISTERS } from '~/services/api/endpoints'
import { statusRegisterLabel, statusRegisterColor } from '~/utils/feedsActions'
import { formatPrice } from '~/utils/format'
import message from '~/utils/messages'

const columns = ['Status', 'Data', 'Missão', 'MU', 'Valor', 'Local']
const limitRegisters = 15

const emptyText = {
  icon: 'check circle outline',
  text: 'Nenhum registro até o momento...',
}

export default function RecentRegisters({ userId }) {
  const [userRegisters, setUserRegisters] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [activePage, setActivePage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [count, setCount] = useState(null)
  const [totalCount, setTotalCount] = useState(null)

  const handlePaginationChange = useCallback((_, params) => {
    const newOffset = params.activePage * limitRegisters - limitRegisters

    setActivePage(params.activePage)
    setOffset(newOffset)
  }, [])

  useEffect(() => {
    if (userId) {
      ;(async () => {
        const params = { limit: limitRegisters, offset }

        try {
          setIsLoading(true)

          const res = await api.get(`${USER_DATA_REGISTERS}${userId}`, {
            params,
          })
          setUserRegisters(res.data.data)

          const resCount = parseInt(res.data.count, 10)
          const value = resCount / limitRegisters
          setTotalCount(resCount)
          setCount(Math.ceil(value))

          setIsLoading(false)
        } catch (e) {
          console.log(e)
          setIsLoading(false)
          message().error(e.response.data.data || 'Houve um erro no servidor')
        }
      })()
    }
  }, [userId, offset])

  const renderItem = (item) => (
    <Table.Row key={uuidv4()}>
      <Table.Cell
        style={{
          color: statusRegisterColor(item.registerStatus),
          fontWeight: 'bold',
        }}
      >
        {statusRegisterLabel(item.registerStatus)}
      </Table.Cell>
      <Table.Cell>
        {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Image
          src={item.missionAvatar}
          size="mini"
          style={{ marginRight: '5px' }}
        />
        {item.missionName}
      </Table.Cell>
      <Table.Cell>{item.mu_key}</Table.Cell>
      <Table.Cell>{formatPrice(item.mission_value)}</Table.Cell>
      <Table.Cell>{item.place || '-'}</Table.Cell>
    </Table.Row>
  )

  return (
    <TableComponent
      data={userRegisters}
      renderItem={renderItem}
      count={count}
      totalCount={totalCount}
      activePage={activePage}
      offset={offset}
      columns={columns}
      handlePaginationChange={handlePaginationChange}
      isLoading={isLoading}
      siblingRange={1}
      boundaryRange={0}
      emptyText={emptyText}
      showEllipsis
      showFirstAndLastNav
      showPreviousAndNextNav
    />
  )
}

RecentRegisters.propTypes = {
  userId: PropTypes.string,
}
