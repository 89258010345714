import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import { convertToRaw, EditorState, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { isEmpty, head } from 'ramda'
import { Loader } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import CreateCategories from '~/components/CreateCategories'
import Main from '~/pages/Main'
import {
  findCategoryById,
  categoryUpdate,
} from '~/store/modules/categories/actions'

export default function Detail(props) {
  const [category, setCategory] = useState({
    uid: '',
    name: '',
    description: '',
    avatar: '',
    editorState: EditorState.createEmpty(),
  })
  const [breadcrumbTitle, setBreadcrumbTitle] = useState('')
  const [isFetching, setIsFetching] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const categoryByIdData = useSelector(store => store.categoryByIdData)
  const categoryUpdateData = useSelector(store => store.categoryUpdateData)

  const dispatch = useDispatch()

  const uploadRef = useRef()

  function showToast(description, type) {
    toast(
      {
        description,
        title: 'Alerta',
        type: type ? 'success' : 'warning',
        icon: type ? 'check' : 'remove',
      },
      () => console.log('toast closed')
    )
  }

  function update() {
    setIsLoading(true)

    const { uid, avatar, name, description, status } = category

    if (isEmpty(avatar)) {
      setIsLoading(false)
      showToast('Imagem não pode estar vazia!')
      return
    }

    if (isEmpty(name)) {
      setIsLoading(false)
      showToast('Título não pode estar vazio!')
      return
    }

    if (isEmpty(description)) {
      setIsLoading(false)
      showToast('Descrição não pode estar vazia!')
      return
    }

    dispatch(categoryUpdate({ uid, avatar, name, description, status }))
  }

  function onEditorStateChange(editorState) {
    setCategory({
      ...category,
      editorState,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    })
  }

  function handlerInput(e, { id, value }) {
    return setCategory({ ...category, [id]: value })
  }

  function uploadImage(e) {
    const file = head(e.target.files)
    const reader = new window.FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = event => {
      console.log(event)
      setCategory({
        ...category,
        avatar: {
          base64: event.target.result,
          size: file.size,
          name: file.name,
          type: file.type,
        },
      })
    }
  }

  useEffect(() => {
    setIsFetching(true)
    setIsLoading(true)
    dispatch(findCategoryById(props.match.params.categoryId))
  }, []); //eslint-disable-line

  useEffect(() => {
    if (categoryUpdateData.success) {
      setIsLoading(false)
      showToast('Categoria atualizada com sucesso!', true)
    } else if (categoryUpdateData.error) {
      showToast(categoryUpdateData.message)
      setIsLoading(false)
    }
  }, [categoryUpdateData]); //eslint-disable-line

  useEffect(() => {
    if (categoryByIdData.success) {
      setBreadcrumbTitle(categoryByIdData.data.name)

      const contentBlock = htmlToDraft(categoryByIdData.data.description)
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      setCategory({
        ...categoryByIdData.data,
        editorState: EditorState.createWithContent(contentState),
      })

      setIsFetching(false)
      setIsLoading(false)
    } else if (categoryByIdData.error) {
      showToast(categoryByIdData.message)
      setIsFetching(false)
      setIsLoading(false)
    }
  }, [categoryByIdData]); //eslint-disable-line

  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      link: '/categories',
      name: 'Categorias',
    },
    {
      active: true,
      hasLink: false,
      name: breadcrumbTitle || '',
    },
  ]

  if (isFetching) {
    return (
      <Main {...props}>
        <div>
          <Loader active inline="centered" />
        </div>
      </Main>
    )
  }

  return (
    <Main {...props}>
      <Breadcrumbs items={breadcrumbsList} history={props.history} />
      <SemanticToastContainer position="top-right" />
      <CreateCategories
        {...category}
        show
        create={() => update()}
        myRef={uploadRef}
        onClick={() => uploadRef.current.click()}
        onEditorStateChange={onEditorStateChange}
        handlerInput={handlerInput}
        uploadImage={uploadImage}
        isLoading={isLoading}
      />
    </Main>
  )
}
