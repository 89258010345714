const initialState = {
  data: [],
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const SEARCH_PLACES = 'SEARCH_PLACES'
const SEARCH_PLACES_OK = 'SEARCH_PLACES_OK'
const SEARCH_PLACES_FAIL = 'SEARCH_PLACES_FAIL'

export default function lastPlacesReducers(state = initialState, action) {
  const { data, message, type } = action

  switch (type) {
    case SEARCH_PLACES:
      return { ...state, isFetching: true }

    case SEARCH_PLACES_OK:
      return { isFetching: false, data, success: true, error: false }

    case SEARCH_PLACES_FAIL:
      return { isFetching: false, error: true, message, success: false }

    default:
      return state
  }
}
