import {
  USER_BADGE,
  USER_BADGE_CREATE,
  USER_BADGE_UPDATE,
  USER_BADGE_REMOVE,
} from '~/services/api/endpoints'
import { get, post, put, remove } from '~/utils/actionsRequests'

const findBadgeByUser = (id) =>
  get({
    // url: `${USER_BADGE}?userId=${id}`,
    url: USER_BADGE(id),
    typeFetch: 'USER_BADGE',
    typeError: 'USER_BADGE_FAIL',
    typeResponse: 'USER_BADGE_OK',
  })

const create = (data) =>
  post({
    data,
    url: USER_BADGE_CREATE,
    typeFetch: 'USER_BADGE_CREATE',
    typeError: 'USER_BADGE_CREATE_FAIL',
    typeResponse: 'USER_BADGE_CREATE_OK',
  })

const update = (data) =>
  put({
    data,
    url: USER_BADGE_UPDATE(data.badge_id),
    typeFetch: 'USER_BADGE_UPDATE',
    typeError: 'USER_BADGE_UPDATE_FAIL',
    typeResponse: 'USER_BADGE_UPDATE_OK',
  })

const removeById = (id) =>
  remove({
    url: USER_BADGE_REMOVE(id),
    typeFetch: 'USER_BADGE_REMOVE',
    typeError: 'USER_BADGE_REMOVE_FAIL',
    typeResponse: 'USER_BADGE_REMOVE_OK',
  })

export { create, update, removeById, findBadgeByUser }
