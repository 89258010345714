import React, { useState, useEffect } from 'react'

import t from 'prop-types'
import { Modal, Button, Form, Message } from 'semantic-ui-react'

export default function UserInfoEditModal({
  userProfile,
  open,
  close,
  errors,
  submit,
  isLoading,
}) {
  const [profile, setProfile] = useState({
    name: '',
    phone: '',
    email: '',
    newPass: '',
    confirmPass: '',
  })

  function handlerInput(e, { id, value }) {
    setProfile({ ...profile, [id]: value })
  }

  useEffect(() => {
    if (userProfile) {
      const { name, phone, email } = userProfile
      setProfile((p) => ({ ...p, name, phone, email }))
    }
  }, [userProfile])

  return (
    <Modal size="small" open={open} onClose={close}>
      <Modal.Header content="Info" />
      <Modal.Content>
        {errors.length > 0 && (
          <Message
            key={errors}
            warning
            fluid
            header="Alerta!"
            style={{ padding: 10 }}
            list={errors}
          />
        )}
        <Form>
          <Form.Input
            type="text"
            id="name"
            label="Nome"
            value={profile.name}
            onChange={handlerInput}
          />
          <Form.Input
            type="text"
            id="email"
            onChange={handlerInput}
            value={profile.email}
            label="Email"
            placeholder="Ex: ola@email.com"
          />

          <Form.Input
            type="text"
            id="phone"
            onChange={handlerInput}
            value={profile.phone}
            label="Celular"
            placeholder="Ex: +55 + Número"
          />

          <Form.Input
            id="newPass"
            type="password"
            onChange={handlerInput}
            value={profile.newPass}
            label="Senha"
            placeholder="Ex: Senha"
          />

          <Form.Input
            id="confirmPass"
            type="password"
            onChange={handlerInput}
            value={profile.confirmPass}
            label="Confirmar Senha"
            placeholder="Ex: Confirmar Senha"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={close}>
          Cancelar
        </Button>
        <Button
          positive
          onClick={() => submit(profile)}
          icon="checkmark"
          loading={isLoading}
          disabled={isLoading}
          labelPosition="right"
          content="Atualizar"
        />
      </Modal.Actions>
    </Modal>
  )
}

UserInfoEditModal.propTypes = {
  userProfile: t.objectOf(t.any),
  open: t.bool,
  close: t.func,
  errors: t.arrayOf(t.any),
  submit: t.func,
  isLoading: t.bool,
}
