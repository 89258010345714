import React from 'react'

import { Card, Input } from 'semantic-ui-react'

export default function PercentualValue({ cost_value, handleInput }) {
  return (
    <Card>
      <Card.Content>
        <Card.Header>Valor da Venda</Card.Header>
      </Card.Content>
      <Card.Content>
        <Input
          type="number"
          value={cost_value || null}
          placeholder="Digite o valor"
          onChange={(e, { value }) => handleInput('cost_value', value)}
        />
      </Card.Content>
    </Card>
  )
}
