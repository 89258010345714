import React from 'react'

import { Form, Modal, Button } from 'semantic-ui-react'

const ReproveList = ({
  close,
  openReprovedModal,
  hasOtherReproved,
  evaluationHandler,
  handlerChangeOthersReproved,
}) => (
  <Modal size="small" onClose={close} open={openReprovedModal}>
    <Modal.Header>
      Descreva os pontos em que o usuário deve corrigir na missão.
    </Modal.Header>
    <Modal.Content>
      <Form>
        <Form.Field>
          <Form.TextArea
            name="text"
            type="text"
            value={hasOtherReproved}
            onChange={handlerChangeOthersReproved}
            placeholder="Ex: Faltou enviar a foto de..."
          />
        </Form.Field>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button content="Cancelar" onClick={close} />
      <Button
        color="blue"
        content="Enviar para Correção"
        onClick={() => evaluationHandler(4)}
      />
    </Modal.Actions>
  </Modal>
)

export default ReproveList
