import { responseData } from '~/utils/reducer'

const FILES = 'FILES'
const FILES_POST = 'FILES_POST'
const FILES_REMOVE = 'FILES_REMOVE'

const files = responseData(FILES, 'array')
const filePost = responseData(FILES_POST, 'array')
const fileRemove = responseData(FILES_REMOVE, 'object')

export default {
  files,
  filePost,
  fileRemove,
}
