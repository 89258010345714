import React from 'react'

import { Card, Popup, Button, Image, Checkbox } from 'semantic-ui-react'

const LEFT = 90
const RIGHT = -90
const UPSIDE = 180

const PhotosItem = ({
  item,
  index,
  addItems,
  isChecked,
  removeById,
  isLoading,
  rotateImage,
  openLightbox,
}) => (
  <Card
    key={`item-${index}`}
    style={{
      margin: 8,
      width: '18%',
      display: 'inline-block',
    }}
  >
    <Image
      fluid
      onClick={() => openLightbox(index)}
      src={`${item.path}?time=${new Date().getTime()}`}
    />
    <Card.Content>
      <Card.Header>{`Ordem ${item.order}`}</Card.Header>
    </Card.Content>
    <Card.Content extra>
      <Checkbox
        fitted
        id={item.uid}
        onChange={addItems}
        checked={isChecked(item)}
        label={isChecked(item) && 'Selecionado'}
      />
      <Button.Group floated="right">
        <Popup
          trigger={
            <Button
              basic
              compact
              icon="trash"
              floated="right"
              onClick={() => removeById(item.uid)}
            />
          }
          inverted
          content="Remover"
        />
        <Popup
          trigger={
            <Button
              basic
              compact
              icon="arrow alternate circle left"
              floated="right"
              loading={isLoading}
              disabled={isLoading}
              onClick={() => rotateImage(item.uid, LEFT)}
            />
          }
          content="Rotação para esquerda"
          inverted
        />
        <Popup
          trigger={
            <Button
              basic
              compact
              floated="right"
              loading={isLoading}
              disabled={isLoading}
              icon="refresh"
              onClick={() => rotateImage(item.uid, UPSIDE)}
            />
          }
          content="Rotação 180˚"
          inverted
        />
        <Popup
          trigger={
            <Button
              basic
              compact
              icon="arrow alternate circle right"
              floated="right"
              loading={isLoading}
              disabled={isLoading}
              onClick={() => rotateImage(item.uid, RIGHT)}
            />
          }
          content="Rotação para direita"
          inverted
        />
      </Button.Group>
    </Card.Content>
  </Card>
)

export default PhotosItem
