import React from 'react'

import { format } from 'date-fns'
import t from 'prop-types'
import { length } from 'ramda'
import { Card, Feed, Icon, Button } from 'semantic-ui-react'

import { iconColor, feedIcon, feedLabel } from '~/utils/feedsActions'

const MissionsFeeds = ({ onClick, items }) => {
  return (
    <Card style={{ maxHeight: 285, overflow: 'auto' }}>
      <Card.Content>
        <Card.Header>{length(items)} Atividades</Card.Header>
      </Card.Content>
      <Card.Content>
        <Feed>
          {items?.map((item, index) => {
            const { lat, lng, action } = item
            const date = new Date(item.created_at)
            const isoDate = date.toISOString()
            const dateFormat = format(new Date(item.created_at), 'dd/MM/yyyy')
            const time = isoDate.substr(11, 8)
            return (
              <Feed.Event key={String(index)}>
                <Icon
                  circular
                  inverted
                  style={{ marginRight: 10 }}
                  color={iconColor(action)}
                  name={feedIcon(action)}
                />
                <Feed.Content>
                  <Feed.Date content={`${dateFormat} ${time}`} />
                  <Feed.Summary>
                    <Button as="a" onClick={() => onClick({ lat, lng })}>
                      {feedLabel(action)}
                    </Button>
                  </Feed.Summary>
                </Feed.Content>
              </Feed.Event>
            )
          })}
        </Feed>
      </Card.Content>
    </Card>
  )
}

MissionsFeeds.propTypes = {
  onClick: t.func,
  items: t.arrayOf(t.any),
}

export default MissionsFeeds
