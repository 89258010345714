import React from 'react'

import { Card, Button } from 'semantic-ui-react'

export default function Invoice({ data, onClick }) {
  const url = data[0].invoice_pdf_path
  return (
    <Card>
      <Card.Content>
        <Card.Header>Orçamento</Card.Header>
      </Card.Content>
      <Card.Content>
        <Button positive onClick={() => onClick(url)}>
          Baixar Orçamento
        </Button>
      </Card.Content>
    </Card>
  )
}
