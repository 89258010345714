import React, { useState, useEffect, useCallback, useRef } from 'react'

import { Card, Button, Grid, Header } from 'semantic-ui-react'

import EmptyList from '~/components/EmptyList'
import TableComponent from '~/components/Table'
import Main from '~/pages/Main'
import api from '~/services/api'
import { CUSTOMERS, IMPORT_PRODUCTS_PDV } from '~/services/api/endpoints'
import messages from '~/utils/messages'

import Customers from './components/Customers'
import Item from './components/Item'

function Imports(props) {
  const uploadRef = useRef('uploadRef')

  const [products, setProducts] = useState([])
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)
  const [customerId, setCustomerId] = useState(null)

  const findByCustomerId = useCallback(async (id) => {
    setLoading(true)
    const res = await api.get(`${IMPORT_PRODUCTS_PDV}/${id}`, {
      verifyStatus() {
        return true
      },
    })

    if (res.status === 200) {
      setLoading(false)
      setProducts(res.data)
    }
  }, [])

  const getCustomers = useCallback(async () => {
    setLoading(true)
    const res = await api.get(CUSTOMERS, {
      params: {
        limit: 100,
      },
      validateStatus() {
        return true
      },
    })

    if (res.status === 200) {
      setLoading(false)

      setCustomers(
        res.data?.data.map(({ uid, name }, key) => ({
          key,
          value: uid,
          text: name.toUpperCase(),
        }))
      )
    }
  }, [])

  function onChange(e, { value }) {
    setCustomerId(value)
    return findByCustomerId(value)
  }

  function uploadFile(e) {
    const fileInput = e.target.files
    submit(fileInput[0])
  }

  async function submit(fileInput) {
    if (!customerId) {
      return messages().error('Cliente esta vazio!')
    }

    setLoading(true)

    const newData = new FormData()
    newData.set('customerId', customerId)
    newData.append('file', fileInput)

    const res = await api.post(IMPORT_PRODUCTS_PDV, newData, {
      validateStatus() {
        return true
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    if (res.status === 500) {
      setLoading(false)
      return messages().error(res.data.message)
    }

    setLoading(false)
    return messages().success(
      'Dentro de instantes todos os seus produtos serão importados.'
    )
  }

  useEffect(() => {
    getCustomers()
    }, []) //eslint-disable-line

  return (
    <Main {...props}>
      <Header>Importar Produtos (PDV)</Header>
      <Card fluid>
        <Card.Content>
          <Grid columns={2}>
            <Grid.Column>
              <Customers
                loading={loading}
                onChange={onChange}
                values={customers}
              />
            </Grid.Column>
            <Grid.Column>
              <input
                type="file"
                ref={uploadRef}
                id="uploadImage"
                onChange={uploadFile}
                style={{ display: 'none' }}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <Button
                icon="upload"
                content="Upload"
                labelPosition="right"
                onClick={() => uploadRef.current.click()}
              />
            </Grid.Column>
          </Grid>
        </Card.Content>
      </Card>

      {customerId && (
        <TableComponent
          data={products}
          isLoading={loading}
          renderItem={(item) => <Item item={item} />}
          columns={['ID CATEGORIA PAI', 'ID CATEGORIA FILHO', 'DESCRIÇÃO']}
          emptyText={{ icon: 'box', text: 'Nenhum produto foi importado...' }}
        />
      )}

      {customerId === null && (
        <EmptyList text="Escolha um cliente" icon="users" />
      )}
    </Main>
  )
}

export default Imports
