import {
  BADGET_FORM_GET,
  BADGET_FORM_CREATE,
  BADGET_FORM_UPDATE,
  BADGET_FORM_CREATE_ANSWER,
  BADGET_FORM_REMOVE_ANSWER,
  BADGET_FORM_UPDATE_ANSWER,
  BADGET_FORM_REMOVE_QUESTION,
  BADGET_FORM_CHANGE_ORDER_ANSWER,
  BADGET_FORM_CHANGE_ORDER_QUESTIONS,
} from '~/services/api/endpoints'
import { get, post, put, remove } from '~/utils/actionsRequests'

const findById = (id) =>
  get({
    url: `${BADGET_FORM_GET}?badgeId=${id}&active=true`,
    typeFetch: 'BADGET_FORM_GET',
    typeError: 'BADGET_FORM_GET_FAIL',
    typeResponse: 'BADGET_FORM_GET_OK',
  })

const create = (data) =>
  post({
    data,
    url: BADGET_FORM_CREATE,
    typeFetch: 'BADGET_FORM_CREATE',
    typeError: 'BADGET_FORM_CREATE_FAIL',
    typeResponse: 'BADGET_FORM_CREATE_OK',
  })

const createAnswer = (data) =>
  post({
    data,
    url: BADGET_FORM_CREATE_ANSWER,
    typeFetch: 'BADGET_FORM_CREATE_ANSWER',
    typeError: 'BADGET_FORM_CREATE_ANSWER_FAIL',
    typeResponse: 'BADGET_FORM_CREATE_ANSWER_OK',
  })

const updateQuestion = (data, id) =>
  put({
    data,
    url: BADGET_FORM_UPDATE(id),
    typeFetch: 'BADGET_FORM_UPDATE',
    typeError: 'BADGET_FORM_UPDATE_FAIL',
    typeResponse: 'BADGET_FORM_UPDATE_OK',
  })

const updateAnswer = (data, id) =>
  put({
    data,
    url: BADGET_FORM_UPDATE_ANSWER(id),
    typeFetch: 'BADGET_FORM_UPDATE_ANSWER',
    typeError: 'BADGET_FORM_UPDATE_ANSWER_FAIL',
    typeResponse: 'BADGET_FORM_UPDATE_ANSWER_OK',
  })

const removeAnswer = (id) =>
  remove({
    url: BADGET_FORM_REMOVE_ANSWER(id),
    typeFetch: 'BADGET_FORM_REMOVE_ANSWER',
    typeError: 'BADGET_FORM_REMOVE_ANSWER_FAIL',
    typeResponse: 'BADGET_FORM_REMOVE_ANSWER_OK',
  })

const removeQuestion = (id) =>
  remove({
    url: BADGET_FORM_REMOVE_QUESTION(id),
    typeFetch: 'BADGET_FORM_REMOVE_QUESTION',
    typeError: 'BADGET_FORM_REMOVE_QUESTION_FAIL',
    typeResponse: 'BADGET_FORM_REMOVE_QUESTION_OK',
  })

const changeOrderQuestions = (data) =>
  put({
    data,
    url: BADGET_FORM_CHANGE_ORDER_QUESTIONS,
    typeFetch: 'BADGET_FORM_CHANGE_ORDER_QUESTIONS',
    typeError: 'BADGET_FORM_CHANGE_ORDER_QUESTIONS_FAIL',
    typeResponse: 'BADGET_FORM_CHANGE_ORDER_QUESTIONS_OK',
  })

const changeOrderAnswers = (data) =>
  put({
    data,
    url: BADGET_FORM_CHANGE_ORDER_ANSWER,
    typeFetch: 'BADGET_FORM_CHANGE_ORDER_ANSWER',
    typeError: 'BADGET_FORM_CHANGE_ORDER_ANSWER_FAIL',
    typeResponse: 'BADGET_FORM_CHANGE_ORDER_ANSWER_OK',
  })

export {
  create,
  findById,
  updateAnswer,
  createAnswer,
  removeAnswer,
  updateQuestion,
  removeQuestion,
  changeOrderAnswers,
  changeOrderQuestions,
}
