import React, { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { SemanticToastContainer } from 'react-semantic-toasts'

import { isEmpty } from 'ramda'
import { Button, Confirm } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import TableComponent from '~/components/Table'
import Main from '~/pages/Main'
import { findSubCategoriesById } from '~/store/modules/categories/actions'
import {
  missionsMainValues,
  missionsMainValuesUpdate,
  missionsMainValuesRemove,
  missionsMainValuesCreate,
} from '~/store/modules/missionsMainValues/actions'
import message from '~/utils/messages'

import CreateSubCategorieValue from './components/Create'
import Item from './components/Item'
import SubCategoriesValuesModal from './components/Modal'

function SubCategoriesValues(props) {
  const {
    match,
    history,
    subCategoriesById,
    missionsMainValues,
    findSubCategoriesById,
    fetchMissionsMainValues,
    createMissionsMainValues,
    updateMissionsMainValues,
    missionsMainValuesCreate,
    removeMissionsMainValues,
    missionsMainValuesUpdate,
    missionsMainValuesRemove,
  } = props
  const { categoryId, subcategoryId } = match.params
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [removeId, setRemoveId] = useState(null)
  const [subcategory, setSubcategory] = useState({})
  const [openConfirm, setOpenConfirm] = useState(false)
  const [missionsValues, setMissionsValues] = useState([])
  const [subCategorieValueId, setSubcategoryId] = useState(null)
  const [item, setItem] = useState({
    user_value: '',
    description: '',
    customer_value: '',
  })

  const findMissionsValues = useCallback(() => {
    missionsMainValues(subcategoryId)
  }, [subcategoryId, missionsMainValues])

  const getSubcategory = useCallback(() => {
    findSubCategoriesById(subcategoryId)
  }, [subcategoryId, findSubCategoriesById])

  const missionsValueResponseCallback = useCallback(() => {
    if (createMissionsMainValues.success) {
      findMissionsValues()
      setLoading(false)
      message().success('Item criado com sucesso!')
      createMissionsMainValues.success = false
    } else if (createMissionsMainValues.error) {
      message().error(createMissionsMainValues.message)
      createMissionsMainValues.error = false
    }
  }, [
    findMissionsValues,
    createMissionsMainValues.error,
    createMissionsMainValues.message,
    createMissionsMainValues.success,
  ])

  const missionsValueRemoveCallback = useCallback(() => {
    if (removeMissionsMainValues.success) {
      findMissionsValues()
      setLoading(false)
      message().success('Item removido com sucesso!')
      removeMissionsMainValues.success = false
    } else if (removeMissionsMainValues.error) {
      message().error(removeMissionsMainValues.message)
      removeMissionsMainValues.error = false
    }
  }, [
    findMissionsValues,
    removeMissionsMainValues.error,
    removeMissionsMainValues.message,
    removeMissionsMainValues.success,
  ])

  const missionsValueUpdateCallback = useCallback(() => {
    if (updateMissionsMainValues.success) {
      findMissionsValues()
      setLoading(false)
      message().success('Item atualizado com sucesso!')
      updateMissionsMainValues.success = false
    } else if (updateMissionsMainValues.error) {
      message().error(updateMissionsMainValues.message)
      updateMissionsMainValues.error = false
    }
  }, [
    findMissionsValues,
    updateMissionsMainValues.error,
    updateMissionsMainValues.message,
    updateMissionsMainValues.success,
  ])

  const getMissionsValuesCallback = useCallback(() => {
    if (fetchMissionsMainValues.success) {
      setMissionsValues(fetchMissionsMainValues.data)
      fetchMissionsMainValues.success = false
    } else if (fetchMissionsMainValues.error) {
      message().error(fetchMissionsMainValues.message)
      fetchMissionsMainValues.error = false
    }
  }, [
    fetchMissionsMainValues.data,
    fetchMissionsMainValues.error,
    fetchMissionsMainValues.message,
    fetchMissionsMainValues.success,
  ])

  const getSubcategoryData = useCallback(() => {
    if (subCategoriesById.success) {
      setSubcategory(subCategoriesById.data)
      subCategoriesById.success = false
    }
  }, [subCategoriesById])

  useEffect(() => {
    getSubcategory()
  }, [getSubcategory])

  useEffect(() => {
    findMissionsValues()
  }, [findMissionsValues])

  useEffect(() => {
    missionsValueResponseCallback()
  }, [missionsValueResponseCallback])

  useEffect(() => {
    missionsValueRemoveCallback()
  }, [missionsValueRemoveCallback])

  useEffect(() => {
    missionsValueUpdateCallback()
  }, [missionsValueUpdateCallback])

  useEffect(() => {
    getMissionsValuesCallback()
  }, [getMissionsValuesCallback])

  useEffect(() => {
    getSubcategoryData()
  }, [getSubcategoryData])

  function handleInput(key, value) {
    setItem({ ...item, [key]: value })
  }

  function removeItem() {
    setOpenConfirm(false)
    missionsMainValuesRemove(removeId)
  }

  function openRemoveItem(id) {
    setOpenConfirm(true)
    setRemoveId(id)
  }

  function openDetail(newObj) {
    setOpen(true)
    setSubcategoryId(newObj.uid)
    setItem({ ...item, ...newObj })
  }

  function submit({ like }) {
    if (isEmpty(item.description)) {
      message().error('Descrição não pode estar vazia!')
      return false
    }

    if (isEmpty(item.customer_value)) {
      message().error('Valor do cliente não pode estar vazio!')
      return false
    }

    if (isEmpty(item.user_value)) {
      message().error('Valor do usuário não pode estar vazio!')
      return false
    }

    if (Number(item.user_value) >= Number(item.customer_value)) {
      message().error('Valor do usuário não pode ser maior que o cliente.')
      return false
    }

    if (like === 'create' && !has_value_variable && missionsValues.length > 0) {
      message().error(
        'Porcentagem deve conter apenas uma configuração, remova as configurações atuais para criar uma nova.'
      )
      return false
    }

    setLoading(true)
    setOpen(false)

    like === 'create'
      ? missionsMainValuesCreate({ ...item, missions_main_id: subcategoryId })
      : missionsMainValuesUpdate({
          ...item,
          uid: subCategorieValueId,
          missions_main_id: subcategoryId,
        })
  }

  const { has_value_variable } = subcategory

  console.log(subcategory)
  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      link: '/categories',
      name: 'Categorias',
    },
    {
      active: false,
      hasLink: true,
      name: 'Sub-Categorias',
      link: `/categories/${categoryId}/sub-categories`,
    },
    {
      active: true,
      hasLink: false,
      name: has_value_variable ? 'Valor Variável' : 'Valor Percentual',
    },
  ]

  return (
    <Main {...props}>
      <Breadcrumbs items={breadcrumbsList} history={history} />

      <Confirm
        header="Alerta"
        open={openConfirm}
        onConfirm={removeItem}
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onCancel={() => setOpenConfirm((i) => !i)}
        content="Tem certeza deseja remover este item?"
      />

      <SemanticToastContainer position="bottom-right" />

      <Button
        size="small"
        style={{ marginTop: 10 }}
        color={show ? 'red' : 'blue'}
        onClick={() => setShow((i) => !i)}
        content={show ? 'Fechar' : 'Criar'}
      />

      <CreateSubCategorieValue
        {...item}
        show={show}
        valueType={has_value_variable}
        handlerInput={handleInput}
        create={() => submit({ like: 'create' })}
      />

      <TableComponent
        columns={[
          'ID',
          'Descrição',
          'Valor Usuário',
          'Valor Cliente',
          'Criado',
          'Atualizado',
          'Ações',
        ]}
        isLoading={loading}
        data={missionsValues}
        renderItem={(item, index) => (
          <Item
            item={item}
            index={index}
            key={`i-${index}`}
            openDetail={openDetail}
            openRemoveId={openRemoveItem}
          />
        )}
        emptyText={{
          icon: 'check circle outline',
          text: 'Nenhum valor variável...',
        }}
      />

      <SubCategoriesValuesModal
        {...item}
        openModal={open}
        handlerInput={handleInput}
        close={() => setOpen(false)}
        update={() => submit({ like: 'update' })}
      />
    </Main>
  )
}

const mapStateToProps = (store) => ({
  subCategoriesById: store.subCategoriesById,
  fetchMissionsMainValues: store.fetchMissionsMainValues,
  createMissionsMainValues: store.createMissionsMainValues,
  updateMissionsMainValues: store.updateMissionsMainValues,
  removeMissionsMainValues: store.removeMissionsMainValues,
})

const mapDispatchToState = {
  missionsMainValues,
  findSubCategoriesById,
  missionsMainValuesUpdate,
  missionsMainValuesRemove,
  missionsMainValuesCreate,
}

export default connect(mapStateToProps, mapDispatchToState)(SubCategoriesValues)
