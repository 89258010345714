import React from 'react'
import { Route as RouteRRD, Redirect } from 'react-router-dom'

import PropTypes from 'prop-types'

import AuthLayout from '~/pages/_layouts/auth'
import DefaultLayout from '~/pages/_layouts/default'
import { store } from '~/store'

export default function Route({ component: Component, isPrivate, ...params }) {
  console.log(params.path)

  if (params.path === '/404') {
    console.log(`0 - entrou aqui?? ${params.path}`)
    return (
      <RouteRRD
        {...params}
        render={(props) => {
          console.log(props)
          return <Component {...props} />
        }}
      />
    )
  }

  const { signed } = store.getState().auth

  console.log(signed, !isPrivate)

  if (!signed && isPrivate) {
    console.log(`1 - entrou aqui?? ${params.path}`)
    return <Redirect to={{ pathname: '/', state: { from: params.path } }} />
  }

  if (signed && !isPrivate) {
    console.log(`2 - entrou aqui?? ${params.path}`)
    return <Redirect to="/dashboard" />
  }

  console.log(signed)

  const Layout = signed ? DefaultLayout : AuthLayout

  return (
    <RouteRRD
      {...params}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

Route.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
}

Route.defaultProps = {
  isPrivate: false,
}
