import {
  FORMS_USERS_CREATE,
  FORMS_USERS_DELETE,
  FORMS_USERS_FETCH,
  FORMS_USERS_UPDATE,
  FORMS_USERS_FETCH_TYPE,
  FORMS_USERS_CREATE_TYPE,
} from '~/services/api/endpoints'
import { get, post, put, remove } from '~/utils/actionsRequests'

const fetchType = () =>
  get({
    url: FORMS_USERS_FETCH_TYPE,
    typeFetch: 'FORMS_USERS_FETCH_TYPE',
    typeError: 'FORMS_USERS_FETCH_TYPE_FAIL',
    typeResponse: 'FORMS_USERS_FETCH_TYPE_OK',
  })

const fetch = () =>
  get({
    url: `${FORMS_USERS_FETCH}`,
    typeFetch: 'FORMS_USERS_FETCH',
    typeError: 'FORMS_USERS_FETCH_FAIL',
    typeResponse: 'FORMS_USERS_FETCH_OK',
  })

const create = (data) =>
  post({
    data,
    url: FORMS_USERS_CREATE,
    typeFetch: 'FORMS_USERS_CREATE',
    typeError: 'FORMS_USERS_CREATE_FAIL',
    typeResponse: 'FORMS_USERS_CREATE_OK',
  })

const createType = (data) =>
  post({
    data,
    url: FORMS_USERS_CREATE_TYPE,
    typeFetch: 'FORMS_USERS_CREATE_TYPE',
    typeError: 'FORMS_USERS_CREATE_TYPE_FAIL',
    typeResponse: 'FORMS_USERS_CREATE_TYPE_OK',
  })

const update = (data, id) =>
  put({
    data,
    url: FORMS_USERS_UPDATE(id),
    typeFetch: 'FORMS_USERS_UPDATE',
    typeError: 'FORMS_USERS_UPDATE_FAIL',
    typeResponse: 'FORMS_USERS_UPDATE_OK',
  })

const removeById = (id) =>
  remove({
    url: FORMS_USERS_DELETE(id),
    typeFetch: 'FORMS_USERS_DELETE',
    typeError: 'FORMS_USERS_DELETE_FAIL',
    typeResponse: 'FORMS_USERS_DELETE_OK',
  })

export { fetch, create, update, fetchType, createType, removeById }
