import React, { useState, useEffect } from 'react'
import ReactMapGl, {
  Popup as PopupMap,
  Marker,
  FlyToInterpolator,
} from 'react-map-gl'
import { AutoSizer } from 'react-virtualized'

import * as d3 from 'd3'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { isNil } from 'ramda'
import { Grid, Icon, Image } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'

import ImageAvatar from '~/assets/avatar.png'
import api from '~/services/api'
import { USER_DATA_FEEDS } from '~/services/api/endpoints'
import { feedIcon, feedLabel, iconColor } from '~/utils/feedsActions'
import message from '~/utils/messages'

import UserFeeds from './Feeds'

const token =
  'pk.eyJ1IjoibWlzc2lvbmJyYXNpbCIsImEiOiJjajBqczEwZXMwMTgxMnFyNmZnZnR6ZGc4In0.SwxP9SQ88PFmT602u0Wtxg'
const positionDefault = {
  latitude: 0,
  longitude: 0,
}

export default function RecentActivities({ userDetailData }) {
  const [userFeeds, setUserFeeds] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [viewport, setViewport] = useState({
    ...positionDefault,
    zoom: 14,
    height: 600,
    width: window.innerWidth,
  })
  const [lat, setLat] = useState(positionDefault.latitude)
  const [lng, setLng] = useState(positionDefault.longitude)
  const [textCopy, setTextCopy] = useState('')
  const [limitFeeds, setLimitFeeds] = useState(15)

  function copy(item) {
    setTextCopy(item)
  }

  function onCopied() {
    message().success('Identificador copiado!')
  }

  function loadMoreFeeds() {
    setLimitFeeds((l) => l + 10)
  }

  function goToPoint(params) {
    setViewport({
      ...viewport,
      zoom: 14,
      latitude: params.lat,
      longitude: params.lng,
      transitionDuration: 5000,
      transitionEasing: d3.easeCubic,
      transitionInterpolator: new FlyToInterpolator(),
    })
  }

  useEffect(() => {
    if (userDetailData) {
      setViewport((v) => ({
        ...v,
        zoom: 14,
        transitionEasing: d3.easeCubic,
        transitionInterpolator: new FlyToInterpolator(),
        latitude: isNil(userDetailData.lat) ? lat : userDetailData.lat,
        longitude: isNil(userDetailData.lng) ? lng : userDetailData.lng,
      }))
      setLat(userDetailData.lat)
      setLng(userDetailData.lng)
    }
  }, [lat, lng, userDetailData])

  useEffect(() => {
    if (userDetailData) {
      ;(async () => {
        const params = { limit: limitFeeds }

        try {
          setIsLoading(true)
          const res = await api.get(`${USER_DATA_FEEDS}${userDetailData.uid}`, {
            params,
          })
          setUserFeeds(res.data.data)
          setIsLoading(false)
        } catch (e) {
          console.log(e)
          setIsLoading(false)
          message().error(e.response.data.data || 'Houve um erro no servidor')
        }
      })()
    }
  }, [limitFeeds, userDetailData])

  const renderInitialLocation = () => (
    <Marker offsetTop={0} offsetLeft={0} latitude={lat} longitude={lng}>
      <Image avatar size="mini" src={userDetailData.avatar || ImageAvatar} />
    </Marker>
  )

  const renderMarkers = () =>
    userFeeds?.map((item) => {
      return (
        <div key={uuidv4()}>
          <PopupMap
            anchor="top"
            tipSize={10}
            offsetTop={30}
            offsetLeft={15}
            closeButton={false}
            latitude={item.lat}
            longitude={item.lng}
          >
            <div style={{ fontSize: 13, textAlign: 'center' }}>
              <h5>{feedLabel(item.action)}</h5>
              <p>{format(new Date(item.created_at), 'dd/MM/yy HH:mm')}</p>
            </div>
          </PopupMap>
          <Marker
            key={uuidv4()}
            offsetTop={0}
            offsetLeft={0}
            latitude={item.lat}
            longitude={item.lng}
          >
            <Icon
              circular
              inverted
              style={{
                marginRight: 10,
                // zIndex: state[`index${item.uid}`],
              }}
              color={iconColor(item.action)}
              name={feedIcon(item.action)}
            />
          </Marker>
        </div>
      )
    })

  return (
    <Grid>
      <Grid.Column width={11}>
        {lat && lng ? (
          <AutoSizer>
            {({ width, height }) => (
              <ReactMapGl
                {...viewport}
                mapboxApiAccessToken={token}
                onViewportChange={(v) => setViewport(v)}
                mapStyle="mapbox://styles/missionbrasil/cjkmphdta3c5r2rpezblnlfth"
                width={width}
                height={height}
              >
                {renderInitialLocation()}
                {renderMarkers()}
              </ReactMapGl>
            )}
          </AutoSizer>
        ) : (
          <p>Endereço não disponível...</p>
        )}
      </Grid.Column>
      <Grid.Column width={5}>
        <UserFeeds
          feeds={userFeeds}
          copy={copy}
          textCopy={textCopy}
          loadMoreFeeds={loadMoreFeeds}
          onCopied={onCopied}
          goToPoint={goToPoint}
          isLoading={isLoading}
        />
      </Grid.Column>
    </Grid>
  )
}

RecentActivities.propTypes = {
  userDetailData: PropTypes.objectOf(PropTypes.any),
}
