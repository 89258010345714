import React from 'react'
import NumberFormat from 'react-number-format'

import { Card, Form, Grid, Button } from 'semantic-ui-react'

export default ({
  show,
  create,
  loading,
  handlerInput,
  valueType,

  user_value,
  description,
  customer_value,
}) => (
  <Card
    centered
    fluid
    style={{
      marginTop: 10,
      display: show ? 'block' : 'none',
    }}
  >
    <Card.Content>
      <Grid relaxed>
        <Grid.Column>
          <Form>
            <Form.Input
              type="text"
              value={description || ''}
              label="Descrição (Obrigatório)"
              placeholder={valueType ? 'Volume' : 'Ex: Orçamento'}
              onChange={(e, { value }) => handlerInput('description', value)}
            />

            <NumberFormat
              prefix="%"
              customInput={Form.Input}
              decimalSeparator="."
              label="Valor Cliente"
              thousandSeparator
              value={customer_value || ''}
              placeholder={valueType ? 'Ex: 10,50 (R$)' : 'Ex: 1 (%)'}
              onValueChange={({ value }) =>
                handlerInput('customer_value', value)
              }
            />

            <NumberFormat
              prefix="%"
              customInput={Form.Input}
              decimalSeparator="."
              label="Valor Usuário"
              thousandSeparator
              value={user_value || ''}
              placeholder={valueType ? 'Ex: 10,50 (R$)' : 'Ex: 1 (%)'}
              onValueChange={({ value }) => handlerInput('user_value', value)}
            />
          </Form>
        </Grid.Column>
      </Grid>
    </Card.Content>
    <Card.Content extra textAlign="right">
      <Button
        positive
        icon="check"
        content="Salvar"
        loading={loading}
        disabled={loading}
        labelPosition="left"
        onClick={() => create()}
      />
    </Card.Content>
  </Card>
)
