import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import { ExcelRenderer } from 'react-excel-renderer'
import { useSelector, useDispatch } from 'react-redux'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import { format } from 'date-fns'
import { Card, Icon, Button, Confirm, Table, Progress } from 'semantic-ui-react'
import io from 'socket.io-client'

import TableComponent from '~/components/Table'
import { API_BASE_URL as SOCKET } from '~/config/env'
import Main from '~/pages/Main'
import { findAll } from '~/store/modules/customers/actions'
import {
  create,
  removeImportFile,
  findImports,
} from '~/store/modules/imports/actions'

import Customers from './components/Customers'
import Files from './components/Files'

const ERROR = 'warning'
const SUCCESS = 'success'

function alertToast({ description, type }) {
  return toast(
    {
      type,
      description,
      title: 'Alerta',
      icon: type === SUCCESS ? 'check' : 'remove',
    },
    () => console.log('toast closed')
  )
}

function Imports(props) {
  const customers = useSelector((state) => state.customers)
  const importsFile = useSelector((state) => state.importsFile)
  const importCreated = useSelector((state) => state.importCreated)
  const importsFileRemoved = useSelector((state) => state.importsFileRemoved)

  const [rows, setRows] = useState([])
  const [name, setName] = useState(null)
  const [file, setFile] = useState(null)
  const [open, setOpen] = useState(false)
  const [fields, setFields] = useState([])
  const [imports, setImports] = useState([])
  const [loading, setLoading] = useState(false)
  const [importId, setImportId] = useState(null)
  const { current: socket } = useRef(io(SOCKET))
  const [customerId, setCustomerId] = useState(null)
  const [customersValues, setCustomers] = useState(null)
  const [loadingCreated, setLoadingCreated] = useState(false)
  const [progress, setProgress] = useState({
    total: 0,
    currently: 0,
    hasFinished: false,
  })

  const dispatch = useDispatch()

  const onRefresh = useCallback(() => {
    setLoading(true)
    dispatch(findImports({}))
  }, []); //eslint-disable-line

  const updateProgress = useCallback((item) => {
    if (item.hasFinished) {
      setLoadingCreated(false)
      setProgress({ ...progress, hasFinished: true })
      onRefresh()
    }

    setProgress({ ...item })
  }, []); //eslint-disable-line

  useEffect(() => {
    try {
      socket.open()
      socket.on('integration-redis', (getProgress) => {
        console.log(getProgress)
        return updateProgress(getProgress)
      })
    } catch (error) {
      socket.disconnect()
      alert(error)
      console.log('>>>>>>> socket error')
      console.log(error)
    }
    return () => {
      socket.close()
      console.log('>>>>>>> socket close <<<<<<<')
    }
  }, [customerId, socket, updateProgress])

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (
        file.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        alertToast({ type: ERROR, description: 'Arquivo inválido!' })
        return false
      }

      ExcelRenderer(file, (err, resp) => {
        if (err) {
          alertToast({ type: ERROR, description: err })
          return false
        }
        setName(file.name)
        setFile(file)
        setFields(
          resp.cols.map((i, index) => ({
            col: i.name,
            label: resp.rows[0][index],
          }))
        )
        setRows(resp.rows[0])
      })
    })
  }, [])

  useEffect(() => {
    dispatch(findAll({}))
    setLoading(true)
  }, []); //eslint-disable-line

  useEffect(() => {
    onRefresh()
  }, [onRefresh])

  useEffect(() => {
    if (customers.success) {
      setCustomers(
        customers.data.map(({ uid, name }, key) => ({
          key,
          value: uid,
          text: name.toUpperCase(),
        }))
      )
      setLoading(false)
      customers.success = false
    }

    if (importCreated.success) {
      alertToast({
        type: SUCCESS,
        description: 'Importação iniciada com sucesso!',
      })
      setFile(null)
      setCustomerId(null)
      importCreated.success = false
    } else if (importCreated.error) {
      alertToast({ type: ERROR, description: importCreated.message })
      setLoadingCreated(false)
      importCreated.error = false
    }

    if (importsFile.success) {
      setImports(importsFile.data)
      importsFile.success = false
    }

    if (importsFileRemoved.success) {
      alertToast({
        type: SUCCESS,
        description: 'Arquivo removido com sucesso!',
      })
      importsFileRemoved.success = false
    }
  }, [customers, importsFile, importCreated, importsFileRemoved])

  function onChange(e, { value }) {
    return setCustomerId(value)
  }

  function onChangeFields(e, { options, value }) {
    const newFields = fields.find((i) => i.label === rows[options[0].col])
    newFields.value = value
    setFields(fields)
  }

  function submitImport() {
    if (!customerId) {
      alertToast({ type: ERROR, description: 'Escolha um cliente!' })
      return false
    }

    if (fields.length === 0) {
      alertToast({ type: ERROR, description: 'Adicione um arquivo!' })
      return false
    }

    if (fields.length) {
      const hasValue = fields.filter((i) => i.value)

      if (hasValue.length !== fields.length) {
        alertToast({ type: ERROR, description: 'Adicione todas as colunas!' })
        return false
      }
    }

    const newData = new FormData()
    newData.set('name', name)
    newData.set(
      'format',
      JSON.stringify({
        row_start: 2,
        fields: fields.map((i) => ({ column: i.col, title: i.value })),
      })
    )
    newData.append('file', file)

    setLoadingCreated(true)
    dispatch(create(customerId, newData))
  }

  function removeFileById(id) {
    const newImports = imports.filter((i) => i.uid !== id)
    setImports(newImports)
    setOpen(false)
    dispatch(removeImportFile(id))
  }

  function confirmRemove(id) {
    setImportId(id)
    setOpen(true)
  }

  function renderItem(item, index) {
    return (
      <Table.Row key={`k-${index}`}>
        <Table.Cell>{item.uid}</Table.Cell>
        <Table.Cell>{item.name}</Table.Cell>
        <Table.Cell>
          {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
        </Table.Cell>
        <Table.Cell>
          <Button
            icon
            primary
            onClick={() => props.history.push(`imports/${item.uid}`)}
            labelPosition="left"
          >
            <Icon name="star" />
            Avaliar Missões
          </Button>
          <Button
            icon
            negative
            onClick={() => confirmRemove(item.uid)}
            labelPosition="left"
          >
            <Icon name="remove" />
            Remover
          </Button>
        </Table.Cell>
      </Table.Row>
    )
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Main {...props}>
      <Card fluid>
        <Card.Content>
          <Customers
            loading={loading}
            onChange={onChange}
            values={customersValues}
          />
          <Files
            rows={rows}
            onChange={onChangeFields}
            isDragActive={isDragActive}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
          />
        </Card.Content>
        <Card.Content extra>
          <Button
            positive
            loading={loadingCreated}
            disabled={loadingCreated}
            onClick={() => submitImport()}
          >
            Criar
          </Button>
        </Card.Content>
      </Card>

      {!progress.hasFinished && loadingCreated && (
        <Card fluid>
          <Card.Content>
            <h4>
              Importando arquivos...({progress.currently} de {progress.total})
            </h4>
            <div>
              <Progress
                percent={
                  (Number(progress.currently) / 100) * Number(progress.total)
                }
                indicating
              />
            </div>
          </Card.Content>
        </Card>
      )}
      <TableComponent
        data={imports}
        renderItem={renderItem}
        isLoading={loadingCreated}
        columns={['ID', 'NOME ARQUIVO', 'CRIADO EM', 'ACESSO']}
      />
      <SemanticToastContainer position="top-center" />
      <Confirm
        open={open}
        header="Alerta"
        confirmButton="Sim"
        cancelButton="Cancelar"
        onCancel={() => setOpen(false)}
        onConfirm={() => removeFileById(importId)}
        content="Tem certeza que deseja remover este arquivo?"
      />
    </Main>
  )
}

export default Imports
