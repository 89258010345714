import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { SemanticToastContainer } from 'react-semantic-toasts'

import { isNil } from 'ramda'
import { Card, Button, Divider, Confirm } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import Empty from '~/components/EmptyList'
import api from '~/services/api'
import { CUSTOM_STATUS_FETCH } from '~/services/api/endpoints'
import history from '~/services/history'
import {
  create,
  update,
  reorder,
  removeById,
} from '~/store/modules/customStatus/actions'
import message from '~/utils/messages'

import Create from './components/Create'
import Item from './components/Item'

const grid = 2

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? 'lightgreen' : '#f1f2f3',
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  width: '100%',
  padding: grid,
  background: isDraggingOver ? 'lightblue' : '#f1f2f3',
})

export default function CustomStatus() {
  const { categoryId, subcategoryId } = useParams()

  const [item, setItem] = useState({})
  const [items, setItems] = useState([])
  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    back: false,
    order: null,
    description: null,
    registerValue: null,
    missionsMainId: subcategoryId,
  })

  const customCreated = useSelector((store) => store.customCreated)
  const customUpdated = useSelector((store) => store.customUpdated)
  const customRemoved = useSelector((store) => store.customRemoved)
  const customReorded = useSelector((store) => store.customReorded)

  const dispatch = useDispatch()
  const breadcrumbsList = [
    {
      active: false,
      hasLink: true,
      name: 'Sub-categorias',
      link: `/categories/${categoryId}/sub-categories`,
    },
    {
      active: true,
      hasLink: false,
      name: 'Status Customizado',
    },
  ]

  useEffect(() => {
    ;(async () => {
      try {
        const res = await api.get(CUSTOM_STATUS_FETCH, {
          params: { missionsMainId: subcategoryId },
        })
        setItems(res.data.data)
        setLoading(false)
      } catch (error) {
        console.log(error)
        setLoading(false)
        message().error(error.response.data.data || 'Houve um erro no servidor')
      }
    })()
  }, [subcategoryId])

  useEffect(() => {
    if (customCreated.success) {
      setItems([...items, customCreated.data])
      setLoading(false)
      customCreated.success = false
    } else if (customCreated.error) {
      setLoading(false)
      message().error(customCreated.message)
      customCreated.error = false
    }

    if (customReorded.success) {
      message().success('Ordenação alterada com sucesso!')
      setLoading(false)
      customReorded.success = false
    } else if (customReorded.error) {
      setLoading(false)
      message().error(customReorded.message)
      customReorded.error = false
    }

    if (customRemoved.success) {
      const newItems = items.filter((i) => i.uid !== customRemoved.data.uid)
      message().success('Item removido com sucesso!')
      setLoading(false)
      setItems(newItems)
      customRemoved.success = false
    } else if (customRemoved.error) {
      setLoading(false)
      message().error(customRemoved.message)
      customRemoved.error = false
    }

    if (customUpdated.success) {
      message().success('Item atualizado com sucesso!')
      setLoading(false)
      customUpdated.success = false
    } else if (customUpdated.error) {
      setLoading(false)
      message().error(customUpdated.message)
      customUpdated.error = false
    }
  }, [customCreated, customRemoved, customReorded, customUpdated, items])

  function handleInput(value, key) {
    return setValues({ ...values, [key]: value })
  }

  function updateItem(item, value) {
    const index = items.findIndex((i) => i.uid === item.uid)
    items[index].description = value
    setItem({ ...item, description: value })
    setItems(items)
  }

  function confirmUpdate() {
    dispatch(update(item))
    setShowInput(false)
  }

  function submit() {
    if (!values.description) {
      message().error('Descrição não pode estar vazia!')
      return false
    }

    if (!Number(values.registerValue) && isNil(values.registerValue)) {
      message().error('Registro não pode estar vazio!')
      return false
    }

    setLoading(true)
    return dispatch(create({ ...values, order: items.length + 1 }))
  }

  function confirmItem(item) {
    setOpen(true)
    setItem(item)
  }

  function removeItem(item) {
    setOpen(false)
    setLoading(true)
    dispatch(removeById(item.uid))
  }

  function reorderList(list, startIndex, endIndex) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    const newItems = result.map((i, index) => ({ ...i, order: index + 1 }))
    dispatch(reorder({ items: newItems, missionsMainId: subcategoryId }))
    setItems(newItems)
    return newItems
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return
    }

    const newItems = reorderList(
      items,
      result.source.index,
      result.destination.index
    )

    setItems(newItems)
  }

  return (
    <>
      <Breadcrumbs items={breadcrumbsList} history={history} />

      <Button
        compact
        content="Criar Status"
        onClick={() => setShow((e) => !e)}
      />

      <Create
        {...values}
        show={show}
        submit={submit}
        loading={loading}
        handleInput={handleInput}
      />

      <Divider />

      <Card.Group>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((status, index) => (
                  <Draggable
                    index={index}
                    key={`c-${index}`}
                    draggableId={`c-${index}`}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <Item
                          index={index}
                          status={status}
                          showInput={showInput}
                          updateItem={updateItem}
                          confirmItem={confirmItem}
                          confirmUpdate={confirmUpdate}
                          show={() => setShowInput((e) => !e)}
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {items.length === 0 && (
          <Empty icon="ordered list" text="Nenhum status..." />
        )}
      </Card.Group>

      <Confirm
        open={open}
        header="Alerta"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        onCancel={() => setOpen(false)}
        onConfirm={() => removeItem(item)}
        content="Tem certeza que deseja remover esse item? Isso pode prejudicar o andamento de algumas missões."
      />
      <SemanticToastContainer position="top-right" />
    </>
  )
}
