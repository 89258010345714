import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'

import CreateCustomer from '~/components/CreateCustomer/index'

export default function Details(props) {
  const { customer, submitUpdateCustomer } = props
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(false)
  }, [customer])

  const updateCustomer = (customerForm) => {
    setIsLoading(true)
    submitUpdateCustomer(customerForm)
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} computer={8}>
          <CreateCustomer
            show
            isUpdating
            customerData={customer}
            isLoading={isLoading}
            onSubmit={updateCustomer}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

Details.propTypes = {
  customer: PropTypes.exact({
    uid: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    cnpj: PropTypes.string,
    phone: PropTypes.string,
    avatar: PropTypes.string,
    status: PropTypes.bool,
    responsible: PropTypes.string,
    billing_estimated: PropTypes.number,
    url_integration_import: PropTypes.string,
    url_integration_export: PropTypes.string,
    customer_config_id: PropTypes.string,
    customer_payment_type: PropTypes.number,
    expiring_day: PropTypes.number,
    limit: PropTypes.string,
    payment_type: PropTypes.number,
    contract_start: PropTypes.string,
    situation: PropTypes.string,
    total_spend: PropTypes.number,
  }),
  submitUpdateCustomer: PropTypes.func,
}
