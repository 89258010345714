import { responseData } from '~/utils/reducer'

const MISSIONS_MAIN_VALUE_CREATE = 'MISSIONS_MAIN_VALUE_CREATE'
const MISSIONS_MAIN_VALUE_UPDATE = 'MISSIONS_MAIN_VALUE_UPDATE'
const MISSIONS_MAIN_VALUE_REMOVE = 'MISSIONS_MAIN_VALUE_REMOVE'
const MISSIONS_MAIN_VALUE_FETCH = 'MISSIONS_MAIN_VALUE_FETCH'

const fetchMissionsMainValues = responseData(MISSIONS_MAIN_VALUE_FETCH, 'array')
const createMissionsMainValues = responseData(
  MISSIONS_MAIN_VALUE_CREATE,
  'object'
)
const updateMissionsMainValues = responseData(
  MISSIONS_MAIN_VALUE_UPDATE,
  'object'
)
const removeMissionsMainValues = responseData(
  MISSIONS_MAIN_VALUE_REMOVE,
  'object'
)

export default {
  createMissionsMainValues,
  updateMissionsMainValues,
  removeMissionsMainValues,
  fetchMissionsMainValues,
}
