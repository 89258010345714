import api from '~/services/api'
import {
  MISSIONS_FILES_GET,
  MISSIONS_FILES_POST,
  MISSIONS_FILES_DEL,
} from '~/services/api/endpoints'
import { get, remove } from '~/utils/actionsRequests'

const FILES_POST = 'FILES_POST'

const getFiles = (id) =>
  get({
    url: MISSIONS_FILES_GET(id),
    typeFetch: 'FILES',
    typeError: 'FILES_FAIL',
    typeResponse: 'FILES_OK',
  })

const removeFile = (id) =>
  remove({
    url: MISSIONS_FILES_DEL(id),
    typeFetch: 'FILES_REMOVE',
    typeError: 'FILES_REMOVE_FAIL',
    typeResponse: 'FILES_REMOVE_OK',
  })

const sendFiles = ({ missionsUsersId, data }) => {
  return async (dispatch) => {
    const url = MISSIONS_FILES_POST(missionsUsersId)
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        dispatch({ type: `${FILES_POST}`, progress: percentCompleted })
      },
    }

    try {
      const newData = new FormData()
      data.forEach((item) => newData.append('files', item))

      const res = await api.post(url, newData, config)

      if (res.status === 200 || res.status === 201) {
        dispatch({ type: `${FILES_POST}_OK`, data: res.data.data || res.data })
      }
    } catch (e) {
      console.log(e)
      const message = e || 'Houve um erro no servidor'
      dispatch({ type: `${FILES_POST}_FAIL`, message })
    }
  }
}

export { getFiles, sendFiles, removeFile }
