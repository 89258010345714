import React, { useEffect, useState, useCallback, memo } from 'react'

import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'

import TableComponent from '~/components/Table'
import api from '~/services/api'
import { USER_DATA_PAYMENTS } from '~/services/api/endpoints'
import { statusPaymentLabel } from '~/utils/feedsActions'
import { formatPrice } from '~/utils/format'
import message from '~/utils/messages'

const columns = ['Status', 'Data', 'Valor', 'Obs']
const limitPayments = 15

const emptyText = {
  icon: 'check circle outline',
  text: 'Nenhuma transação até o momento...',
}

function RecentPayments({ userId }) {
  const [userPayments, setUserPayments] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [activePage, setActivePage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [count, setCount] = useState(null)
  const [totalCount, setTotalCount] = useState(null)

  const handlePaginationChange = useCallback((_, params) => {
    const newOffset = params.activePage * limitPayments - limitPayments

    setActivePage(params.activePage)
    setOffset(newOffset)
  }, [])

  useEffect(() => {
    if (userId) {
      ;(async () => {
        const params = { limit: limitPayments, offset }

        try {
          setIsLoading(true)

          const res = await api.get(`${USER_DATA_PAYMENTS}${userId}`, {
            params,
          })
          setUserPayments(res.data.data)

          const resCount = parseInt(res.data.count, 10)
          const value = resCount / limitPayments
          setTotalCount(resCount)
          setCount(Math.ceil(value))

          setIsLoading(false)
        } catch (e) {
          console.log(e)
          setIsLoading(false)
          message().error(e.response.data.data || 'Houve um erro no servidor')
        }
      })()
    }
  }, [userId, offset])

  const renderItem = (item) => (
    <Table.Row
      key={uuidv4()}
      warning={item.payment_status === 0}
      positive={item.payment_status === 1}
      negative={item.payment_status === 2}
    >
      <Table.Cell
        style={{
          fontWeight: 'bold',
        }}
      >
        {statusPaymentLabel(item.payment_status)}
      </Table.Cell>
      <Table.Cell>
        {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>{formatPrice(item.value)}</Table.Cell>
      <Table.Cell>{item.obs || '-'}</Table.Cell>
    </Table.Row>
  )

  return (
    <TableComponent
      data={userPayments}
      renderItem={renderItem}
      count={count}
      totalCount={totalCount}
      activePage={activePage}
      offset={offset}
      columns={columns}
      handlePaginationChange={handlePaginationChange}
      isLoading={isLoading}
      siblingRange={1}
      boundaryRange={0}
      emptyText={emptyText}
      showEllipsis
      showFirstAndLastNav
      showPreviousAndNextNav
    />
  )
}

RecentPayments.propTypes = {
  userId: PropTypes.string,
}

export default memo(RecentPayments)
