import { Card } from 'semantic-ui-react'
import styled from 'styled-components'

export const Container = styled(Card)``

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
