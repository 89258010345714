import React from 'react'
import { Marker } from 'react-map-gl'

import { Image } from 'semantic-ui-react'

export default function MarkerImage({ lat, lng, path }) {
  return (
    <Marker offsetTop={0} offsetLeft={0} latitude={lat} longitude={lng}>
      <Image avatar size="mini" src={path} />
    </Marker>
  )
}
