import {
  FORMS_ITEMS_LIST,
  FORMS_ITEMS_UPDATE,
  FORMS_ITEMS_CREATE,
  FORMS_ITEMS_REMOVE,
  FORMS_ITEMS_UPDATE_ORDER,
} from '~/services/api/endpoints'
import { get, post, put, remove } from '~/utils/actionsRequests'

const findById = ({ id, customerId }) =>
  get({
    url: `${FORMS_ITEMS_LIST}${customerId}/${id}`,
    typeFetch: 'FORMS_ITEMS_LIST',
    typeError: 'FORMS_ITEMS_LIST_FAIL',
    typeResponse: 'FORMS_ITEMS_LIST_OK',
  })

const changeOrder = (data) =>
  put({
    data,
    url: `${FORMS_ITEMS_UPDATE_ORDER}`,
    typeFetch: 'FORMS_ITEMS_UPDATE_ORDER',
    typeError: 'FORMS_ITEMS_UPDATE_ORDER_FAIL',
    typeResponse: 'FORMS_ITEMS_UPDATE_ORDER_OK',
  })

const create = (data) =>
  post({
    data,
    url: `${FORMS_ITEMS_CREATE}`,
    typeFetch: 'FORMS_ITEMS_CREATE',
    typeError: 'FORMS_ITEMS_CREATE_FAIL',
    typeResponse: 'FORMS_ITEMS_CREATE_OK',
  })

const removeById = ({ id, customerId }) =>
  remove({
    url: `${FORMS_ITEMS_REMOVE}${id}/${customerId}`,
    typeFetch: 'FORMS_ITEMS_REMOVE',
    typeError: 'FORMS_ITEMS_REMOVE_FAIL',
    typeResponse: 'FORMS_ITEMS_REMOVE_OK',
  })

const update = (data) =>
  put({
    data,
    url: `${FORMS_ITEMS_UPDATE}`,
    typeFetch: 'FORMS_ITEMS_UPDATE',
    typeError: 'FORMS_ITEMS_UPDATE_FAIL',
    typeResponse: 'FORMS_ITEMS_UPDATE_OK',
  })

export { create, update, findById, removeById, changeOrder }
