import React, { useState, useEffect } from 'react'

import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import { v4 as uuidv4 } from 'uuid'

import TableComponent from '~/components/Table'
import api from '~/services/api'
import { MGM_FIND_GUESTS } from '~/services/api/endpoints'
import { formatPrice } from '~/utils/format'
import message from '~/utils/messages'

const columns = ['Cadastrou-se em', 'Nome', 'Email', 'Cashback Gerado']

const emptyText = {
  icon: 'check circle outline',
  text: 'Nenhum convidado até o momento...',
}

export default function UserGuests({ userId }) {
  const [userGuests, setUserGuests] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (userId) {
      ;(async () => {
        try {
          setIsLoading(true)
          const res = await api.get(MGM_FIND_GUESTS(userId))
          setUserGuests(res.data.data)
          setIsLoading(false)
        } catch (e) {
          console.log(e)
          setIsLoading(false)
          message().error(e.response.data.data || 'Houve um erro no servidor')
        }
      })()
    }
  }, [userId])

  const renderItem = (item) => (
    <Table.Row key={uuidv4()}>
      <Table.Cell>
        {format(new Date(item.joined_date), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <a href={`/user/${item.guest_id}`} target="blank">
          {item.guest_name}
        </a>
      </Table.Cell>
      <Table.Cell>{item.guest_email}</Table.Cell>
      <Table.Cell>{formatPrice(item.cashback)}</Table.Cell>
    </Table.Row>
  )

  return (
    <TableComponent
      data={userGuests}
      renderItem={renderItem}
      columns={columns}
      isLoading={isLoading}
      emptyText={emptyText}
    />
  )
}

UserGuests.propTypes = {
  userId: PropTypes.string,
}
