import { responseData } from '~/utils/reducer'

const USER_BADGE = 'USER_BADGE'
const USER_BADGE_CREATE = 'USER_BADGE_CREATE'
const USER_BADGE_UPDATE = 'USER_BADGE_UPDATE'
const USER_BADGE_REMOVE = 'USER_BADGE_REMOVE'

const usersBadges = responseData(USER_BADGE, 'array')
const usersBadgesCreate = responseData(USER_BADGE_CREATE, 'object')
const usersBadgesUpdate = responseData(USER_BADGE_UPDATE, 'object')
const usersBadgesRemove = responseData(USER_BADGE_REMOVE, 'object')

export default {
  usersBadges,
  usersBadgesCreate,
  usersBadgesUpdate,
  usersBadgesRemove,
}
