import { responseData } from '~/utils/reducer'

const PAYMENTS = 'PAYMENTS'
const PAYMENTS_FILE = 'PAYMENTS_FILE'
const PAYMENTS_USERS = 'PAYMENTS_USERS'
const PAYMENTS_UPDATE = 'PAYMENTS_UPDATE'

const payments = responseData(PAYMENTS, 'array')
const updatePayment = responseData(PAYMENTS_UPDATE, 'object')
const paymentUsers = responseData(PAYMENTS_USERS, 'object')
const uploadPaymentFile = responseData(PAYMENTS_FILE, 'object')

export default {
  payments,
  paymentUsers,
  updatePayment,
  uploadPaymentFile,
}
