import { cond, equals, always, assoc } from 'ramda'

const initialState = {
  message: '',
  error: false,
  success: false,
  isFetching: false,
}

const dataList = assoc('data', [], initialState)
const dataUpdate = assoc('data', {}, initialState)
const dataOrder = assoc('data', false, initialState)

const FORMS_LIST = 'FORMS_LIST'
const FORMS_LIST_OK = 'FORMS_LIST_OK'
const FORMS_LIST_FAIL = 'FORMS_LIST_FAIL'

const FORMS_UPDATE = 'FORMS_UPDATE'
const FORMS_UPDATE_OK = 'FORMS_UPDATE_OK'
const FORMS_UPDATE_FAIL = 'FORMS_UPDATE_FAIL'

const FORMS_CREATE = 'FORMS_CREATE'
const FORMS_CREATE_OK = 'FORMS_CREATE_OK'
const FORMS_CREATE_FAIL = 'FORMS_CREATE_FAIL'

const FORMS_DETAIL = 'FORMS_DETAIL'
const FORMS_DETAIL_OK = 'FORMS_DETAIL_OK'
const FORMS_DETAIL_FAIL = 'FORMS_DETAIL_FAIL'

const FORMS_REMOVE = 'FORMS_REMOVE'
const FORMS_REMOVE_OK = 'FORMS_REMOVE_OK'
const FORMS_REMOVE_FAIL = 'FORMS_REMOVE_FAIL'

const forms = (state = dataList, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_LIST), always({ ...state, isFetching: true })],
    [
      equals(FORMS_LIST_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_LIST_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

const formsUpdate = (state = dataUpdate, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_UPDATE), always({ ...state, isFetching: true })],
    [
      equals(FORMS_UPDATE_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_UPDATE_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

const findForms = (state = dataUpdate, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_DETAIL), always({ ...state, isFetching: true })],
    [
      equals(FORMS_DETAIL_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_DETAIL_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

const formsCreate = (state = dataOrder, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_CREATE), always({ ...state, isFetching: true })],
    [
      equals(FORMS_CREATE_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_CREATE_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

const formsRemove = (state = dataOrder, action) => {
  const { data, message, type } = action
  const fn = cond([
    [equals(FORMS_REMOVE), always({ ...state, isFetching: true })],
    [
      equals(FORMS_REMOVE_OK),
      always({ isFetching: false, data, success: true, error: false }),
    ],
    [
      equals(FORMS_REMOVE_FAIL),
      always({ isFetching: false, error: true, message, success: false }),
    ],
  ])
  return fn(type) || state
}

export default {
  forms,
  findForms,
  formsUpdate,
  formsCreate,
  formsRemove,
}
