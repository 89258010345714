import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'

import { Menu, Image } from 'semantic-ui-react'

import ImageAvatar from '~/assets/logo-dark.png'
import { APP_VERSION } from '~/config/env'
import { signOut } from '~/store/modules/auth/actions'

import items from './routesAuthenticated'

export default function SideBar() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const user = useSelector((store) => store.user)

  function handlerLogout() {
    dispatch(signOut())
    history.push('/')
  }

  useEffect(() => {}, [location])

  function renderItems(item, key) {
    const { path, icon, text, restrictions } = item
    const hasAccess = restrictions.find(
      (value) => value === user?.profile.role_name
    )
    if (!hasAccess) {
      return (
        <Menu.Item
          to={path}
          as={Link}
          name={path}
          icon={icon}
          content={<span>{text}</span>}
          key={`menu-side-bar-${key}`}
          active={location?.pathname === path}
        />
      )
    }

    return false
  }

  return (
    <Menu inverted vertical className="side-bar">
      <Menu.Item>
        <div>
          <Image avatar size="mini" src={ImageAvatar} verticalAlign="middle" />
          <span className="header-side-bar">
            Plataforma Admin ({APP_VERSION})
          </span>
        </div>
      </Menu.Item>
      {items.map(renderItems)}
      <Menu.Item
        name="Sair"
        content={<span>Sair</span>}
        icon="sign out"
        onClick={handlerLogout}
      />
    </Menu>
  )
}
