import React from 'react'
import { useSelector } from 'react-redux'
import { SortableElement } from 'react-sortable-hoc'

import { Menu, Button, Segment } from 'semantic-ui-react'

import EmptyList from './EmptyList'
import PhotosItem from './PhotosItem'

const SortablePhotoItem = SortableElement(PhotosItem)

const Photos = ({
  items,
  limit,
  myRef,
  onClick,
  newItems,
  isLoading,
  photosCount,
  loadingMore,
  onClickDrag,
  editPhoto,
  removeImages,
  uploadImages,
  isLoadingRemove,
  onClickSelection,
  addItems,
  isChecked,
  removeById,
  rotateImage,
  openLightbox,
}) => {
  const user = useSelector(store => store.user)
  return (
    <div>
      <Menu>
        <Menu.Menu position="left">
          {user.profile.role_name === 'Admin' && (
            <Menu.Item>
              <input
                multiple
                type="file"
                id="uploadAttachment"
                accept=".png, .jpg, .jpeg"
                style={{ display: 'none' }}
                ref={myRef}
                onChange={uploadImages}
              />
              <Button
                basic
                icon="plus"
                loading={isLoading}
                content="Adicionar"
                labelPosition="left"
                onClick={onClick}
              />
            </Menu.Item>
          )}
          <Menu.Item>
            {items.length > 0 && (
              <div>
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={onClickSelection}
                  color={newItems.length > 0 ? 'grey' : 'blue'}
                  content={
                    newItems.length > 0
                      ? 'Deselecionar tudo'
                      : 'Selecionar tudo'
                  }
                />
                {newItems.length > 0 && (
                  <Button
                    negative
                    onClick={() => removeImages()}
                    loading={isLoadingRemove}
                    disabled={isLoadingRemove}
                    content={`Remover ${newItems.length} ite${
                      newItems.length > 1 ? 'ns' : 'm'
                    }`}
                  />
                )}
              </div>
            )}
          </Menu.Item>
          <Menu.Item>
            <Button
              basic
              icon={editPhoto ? 'grab' : 'magic'}
              content={editPhoto ? 'Destravar' : 'Editar Foto'}
              labelPosition="left"
              onClick={onClickDrag}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <div
        style={{
          margin: 0,
          padding: 0,
          marginTop: -8,
          background: editPhoto && '#b9b9b9',
          border: editPhoto && '1px dashed grey',
        }}
      >
        {items.length === 0 && (
          <EmptyList text="Nenhuma Foto" icon="file image outline" />
        )}
        {items.map((item, index) => (
          <SortablePhotoItem
            item={item}
            index={index}
            sortIndex={index}
            addItems={addItems}
            key={`key-${index}`}
            isLoading={isLoading}
            isChecked={isChecked}
            removeById={removeById}
            rotateImage={rotateImage}
            openLightbox={openLightbox}
          />
        ))}
      </div>
      {limit < parseInt(photosCount.data.count, 0) && (
        <Segment padded>
          <Button fluid primary content="Carregar Mais" onClick={loadingMore} />
        </Segment>
      )}
    </div>
  )
}

export default Photos
