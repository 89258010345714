import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import { format } from 'date-fns'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import queryString from 'query-string'
import { head, length, isEmpty } from 'ramda'
import {
  Table,
  Popup,
  Loader,
  Button,
  Message,
  Confirm,
  Pagination,
  Header,
  Divider,
} from 'semantic-ui-react'

import CreateCategories from '~/components/CreateCategories'
import EmptyList from '~/components/EmptyList'
import Main from '~/pages/Main'
import {
  categories as findCategories,
  create as createAction,
  removeById,
} from '~/store/modules/categories/actions'

import '~/assets/css/react-draft-wysiwyg.css'

function Categories(props) {
  const [state, setState] = useState({
    paginationLimit: 10,
    paginationCount: 0,
    paginationOffset: 0,
    paginationActivePage: 1,
    paginationSiblingRange: 1,
    paginationBoundaryRange: 0,
    paginationShowEllipsis: true,
    paginationShowFirstAndLastNav: true,
    paginationShowPreviousAndNextNav: true,
    paginationSearch: 'offset=0&limit=10',

    categories: [],

    open: false,
    show: false,
    success: true,
    isLoading: false,

    errors: [],
    removeId: '',
    isFilterLoading: false,

    name: '',
    description: '',
    avatar: '',
    editorState: EditorState.createEmpty(),
  })

  const user = useSelector((store) => store.user)
  const categories = useSelector((store) => store.categories)
  const createCategory = useSelector((store) => store.createCategory)
  const removeCategory = useSelector((store) => store.removeCategory)

  const dispatch = useDispatch()

  const uploadRef = useRef()

  function handlerInput(e, { id, value }) {
    return setState({ ...state, [id]: value })
  }

  function onEditorStateChange(newEditorState) {
    setState({
      ...state,
      editorState: newEditorState,
      description: draftToHtml(
        convertToRaw(state.editorState.getCurrentContent())
      ),
    })
  }

  function uploadImage(e) {
    const file = head(e.target.files)
    const reader = new window.FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = (event) => {
      console.log(event)
      setState({
        ...state,
        avatar: {
          base64: event.target.result,
          size: file.size,
          name: file.name,
          type: file.type,
        },
      })
    }
  }

  function create() {
    setState({ ...state, errors: [], isLoading: true })

    const { avatar, name, description } = state

    if (isEmpty(avatar)) {
      setState({
        ...state,
        isLoading: false,
        errors: state.errors.concat('Imagem não pode estar vazia!'),
      })
      return
    }

    if (isEmpty(name)) {
      setState({
        ...state,
        isLoading: false,
        errors: state.errors.concat('Título não pode estar vazio!'),
      })
      return
    }

    if (isEmpty(description)) {
      setState({
        ...state,
        isLoading: false,
        errors: state.errors.concat('Descrição não pode estar vazia!'),
      })
      return
    }

    dispatch(createAction({ avatar, name, description }))
  }

  function handlerCancel() {
    setState({ ...state, open: false })
  }

  function removeItem() {
    dispatch(removeById(state.removeId))
    setState({ ...state, open: false })
  }

  function openRemoveId(removeId) {
    setTimeout(() => {
      setState({ ...state, removeId, open: true })
    }, 200)
  }

  function handlerQuery(target, value) {
    const queries = queryString.parse(props.location.search)
    const handlerQueries = { ...queries, [target]: value }
    const search = queryString.stringify(handlerQueries)
    props.history.replace({ search, pahtname: props.location.pathname })
  }

  function handlePaginationChange(e, { activePage }) {
    const newOffset = (activePage - 1) * state.paginationLimit
    handlerQuery('offset', Number(newOffset))
    const queries = queryString.parse(props.location.search)
    const search = queryString.stringify({
      ...queries,
      offset: newOffset,
      limit: state.paginationLimit,
    })
    setState({
      ...state,
      paginationActivePage: activePage,
      paginationOffset: Number(newOffset),
    })
    dispatch(findCategories(search))
  }

  function calculatePagination(props) {
    if (props.success) {
      const newCount = parseInt(props.count, 0)
      const value = newCount / state.paginationLimit

      setState({
        ...state,
        categories: props.data,
        paginationTotalCount: newCount,
        paginationCount: Math.ceil(value),
      })
    }
  }

  useEffect(() => {
    calculatePagination(categories)
  }, [categories]) //eslint-disable-line

  useEffect(() => {
    dispatch(findCategories(state.paginationSearch))
  }, []); //eslint-disable-line

  useEffect(() => {
    if (createCategory.success) {
      setTimeout(() => {
        const categoriesWithAdded = [
          { ...createCategory.data },
          ...state.categories,
        ]

        setState({
          ...state,
          name: '',
          avatar: '',
          description: '',
          isLoading: false,
          categories: categoriesWithAdded,
          paginationTotalCount: state.paginationTotalCount + 1,
        })

        createCategory.success = false

        toast(
          {
            title: 'Alerta',
            type: 'success',
            icon: 'check',
            description: 'Categoria criada com sucesso!',
          },
          () => console.log('toast closed')
        )
      }, 1000)
    } else if (createCategory.error) {
      setState({
        ...state,
        isLoading: false,
        errors: state.errors.concat(createCategory.message),
      })

      // dispatch(findCategories());
      createCategory.error = false
    }
  }, [createCategory]); //eslint-disable-line

  useEffect(() => {
    if (removeCategory.success) {
      const categoriesWithoutRemoved = state.categories.filter(
        (c) => c.uid !== state.removeId
      )
      setState({
        ...state,
        categories: categoriesWithoutRemoved,
        paginationTotalCount: state.paginationTotalCount - 1,
      })
      removeCategory.success = false

      setTimeout(() => {
        toast(
          {
            title: 'Alerta',
            type: 'success',
            icon: 'check',
            description: 'Item removido com sucesso!',
          },
          () => console.log('toast closed')
        )
      }, 200)
    } else if (removeCategory.error) {
      removeCategory.error = false
    }
  }, [removeCategory]) //eslint-disable-line

  const renderCells = (item, index) => (
    <Table.Row key={`index-${index}`}>
      <Table.HeaderCell
        style={{
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img alt="avatar" src={item.avatar} style={{ width: 50, height: 30 }} />
      </Table.HeaderCell>
      <Table.Cell>{item.id}</Table.Cell>
      <Table.Cell>{item.name}</Table.Cell>
      <Table.Cell>
        {format(new Date(item.created_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        {format(new Date(item.updated_at), 'dd/MM/yyyy HH:mm')}
      </Table.Cell>
      <Table.Cell>
        <Popup
          inverted
          trigger={
            <Button
              icon="search"
              onClick={() =>
                props.history.push(`categories/${item.uid}/sub-categories`, {
                  item,
                })
              }
            />
          }
          content="Ver sub-categorias"
        />
        <Popup
          inverted
          trigger={
            <Button
              icon="pencil"
              className="orange"
              inverted
              onClick={() =>
                props.history.push(`categories/${item.uid}`, {
                  item,
                })
              }
            />
          }
          content="Editar"
        />
        <Popup
          inverted
          trigger={
            <Button
              icon="trash"
              negative
              onClick={() => openRemoveId(item.uid)}
            />
          }
          content="Remover"
        />
      </Table.Cell>
    </Table.Row>
  )

  const renderComponent = () => (
    <>
      <Confirm
        header="Alerta"
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        open={state.open}
        onConfirm={removeItem}
        onCancel={handlerCancel}
        content="Tem certeza deseja remover este item?"
      />
      <SemanticToastContainer position="bottom-right" />
      {length(state.errors) > 0 && (
        <Message
          warning
          fluid="true"
          header="Alerta!"
          style={{ padding: 10 }}
          list={state.errors}
        />
      )}
      <Button
        size="small"
        color={state.show ? 'red' : 'blue'}
        onClick={() => setState({ ...state, show: !state.show })}
      >
        {state.show ? 'Fechar' : 'Criar'}
      </Button>

      {categories.isFetching && (
        <div>
          <Loader active inline="centered" />
        </div>
      )}

      {!categories.isFetching && !length(state.categories) && (
        <EmptyList icon="check circle outline" text="Nenhuma Categoria" />
      )}

      {!categories.isFetching && length(state.categories) > 0 && (
        <div className="container">
          <CreateCategories
            {...state}
            create={() => create()}
            myRef={uploadRef}
            onClick={() => uploadRef.current.click()}
            onEditorStateChange={onEditorStateChange}
            handlerInput={handlerInput}
            uploadImage={uploadImage}
          />
          <Divider inverted />
          {!!state.paginationCount && (
            <div
              style={{
                flex: 1,
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <Header as="h4">
                {`${state.categories.length + state.paginationOffset} de ${
                  state.paginationTotalCount
                } total`}
              </Header>
            </div>
          )}
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Criado Em</Table.HeaderCell>
                <Table.HeaderCell>Atualizado Em</Table.HeaderCell>
                <Table.HeaderCell>Ações</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {length(state.categories) > 0 &&
                state.categories.map(renderCells)}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <Pagination
                    totalPages={state.paginationCount}
                    activePage={state.paginationActivePage}
                    siblingRange={state.paginationSiblingRange}
                    boundaryRange={state.paginationBoundaryRange}
                    onPageChange={handlePaginationChange}
                    ellipsisItem={
                      state.paginationShowEllipsis ? undefined : null
                    }
                    firstItem={
                      state.paginationShowFirstAndLastNav ? undefined : null
                    }
                    lastItem={
                      state.paginationShowFirstAndLastNav ? undefined : null
                    }
                    prevItem={
                      state.paginationShowPreviousAndNextNav ? undefined : null
                    }
                    nextItem={
                      state.paginationShowPreviousAndNextNav ? undefined : null
                    }
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      )}
    </>
  )

  return (
    <Main {...props}>
      {user.profile.role_name === 'Basic' ? (
        <EmptyList icon="lock" text="Acesso restrito" />
      ) : (
        renderComponent()
      )}
    </Main>
  )
}

export default Categories
