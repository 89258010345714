import {
  CUSTOMERS,
  CUSTOMERS_CREATE,
  CUSTOMERS_UPDATE,
  CUSTOMERS_RESTRICT,
  CONFIG_PAYMENT_UPDATE,
  CUSTOMERS_PROCESS_PAYMENT,
} from '~/services/api/endpoints'
import { get, post, remove, put } from '~/utils/actionsRequests'

const findAll = ({ search, limit, offset, order, direction }) =>
  get({
    url: `${CUSTOMERS}?search=${search}&limit=${limit}&offset=${offset}&order=${order}&direction=${direction}`,
    typeFetch: 'CUSTOMERS_LIST',
    typeError: 'CUSTOMERS_LIST_FAIL',
    typeResponse: 'CUSTOMERS_LIST_OK',
  })

const findById = (id) =>
  get({
    url: `${CUSTOMERS}/${id}`,
    typeFetch: 'CUSTOMERS_DETAIL',
    typeError: 'CUSTOMERS_DETAIL_FAIL',
    typeResponse: 'CUSTOMERS_DETAIL_OK',
  })

const update = (data) =>
  put({
    data,
    url: CUSTOMERS_UPDATE,
    typeFetch: 'CUSTOMERS_UPDATE',
    typeError: 'CUSTOMERS_UPDATE_FAIL',
    typeResponse: 'CUSTOMERS_UPDATE_OK',
  })

const updatePayment = (data) =>
  put({
    data,
    url: CONFIG_PAYMENT_UPDATE(data.uid),
    typeFetch: 'CONFIG_PAYMENT_UPDATE',
    typeError: 'CONFIG_PAYMENT_UPDATE_FAIL',
    typeResponse: 'CONFIG_PAYMENT_UPDATE_OK',
  })

const findCategoriesById = (id) =>
  get({
    url: `${CUSTOMERS_RESTRICT}${id}/restricted-sub-categories`,
    typeFetch: 'CUSTOMERS_RESTRICT',
    typeError: 'CUSTOMERS_RESTRICT_FAIL',
    typeResponse: 'CUSTOMERS_RESTRICT_OK',
  })

const updateSubCategories = (data, id) =>
  put({
    data,
    url: `${CUSTOMERS_RESTRICT}${id}/restricted-sub-categories`,
    typeFetch: 'CUSTOMERS_RESTRICT_UPDATE',
    typeError: 'CUSTOMERS_RESTRICT_UPDATE_FAIL',
    typeResponse: 'CUSTOMERS_RESTRICT_UPDATE_OK',
  })

const removeById = (id) =>
  remove({
    url: `${CUSTOMERS}/${id}/remove`,
    typeFetch: 'CUSTOMERS_REMOVE',
    typeError: 'CUSTOMERS_REMOVE_FAIL',
    typeResponse: 'CUSTOMERS_REMOVE_OK',
  })

const create = (data) =>
  post({
    data,
    url: `${CUSTOMERS_CREATE}`,
    typeFetch: 'CUSTOMERS_CREATE',
    typeError: 'CUSTOMERS_CREATE_FAIL',
    typeResponse: 'CUSTOMERS_CREATE_OK',
  })

const proccessPayment = (data, request) =>
  post({
    data,
    url: `${CUSTOMERS_PROCESS_PAYMENT}?request=${request}`,
    typeFetch: 'CUSTOMERS_PROCESS_PAYMENT',
    typeError: 'CUSTOMERS_PROCESS_PAYMENT_FAIL',
    typeResponse: 'CUSTOMERS_PROCESS_PAYMENT_OK',
  })

export {
  create,
  update,
  findAll,
  findById,
  removeById,
  updatePayment,
  proccessPayment,
  findCategoriesById,
  updateSubCategories,
}
