import React from 'react'

import { Modal, Button, Icon, List, Image } from 'semantic-ui-react'

const urlExt = (url) => url.split('/').pop().split('.').pop()

function answerType(answer) {
  let ans
  if (typeof answer === 'string') {
    ans = String(answer)
  } else if (typeof Array.isArray(answer)) {
    ans = answer.forEach((item, index) => {
      if (item.hasOwnProperty('option')) {
        return (
          <div>
            <Icon name="check" />
            {item.option}
          </div>
        )
      }
      if (item.hasOwnProperty('path')) {
        const ext = urlExt(item.path)
        return ext === 'png' ? (
          <Image
            size="medium"
            src={item.path}
            key={`i-${index}`}
            style={{ marginRight: 5 }}
          />
        ) : (
          <a href={item.path} target="_blank" rel="noopener noreferrer">
            Abrir ({ext})
          </a>
        )
      }
    })
  }
  return ans
}

export default function FormsUsersData({ open, close, items }) {
  return (
    <Modal size="small" open={open} centered={false}>
      <Modal.Content>
        <h2>Dados do Consultor</h2>
        <List>
          {items.map((i, index) => (
            <List.Item key={`q-${index}`}>
              <List.Header>{i.question}</List.Header>
              {answerType(i.answer)}
            </List.Item>
          ))}
        </List>
      </Modal.Content>
      <Modal.Content>
        <Button color="grey" onClick={close} content="Cancelar" />
      </Modal.Content>
    </Modal>
  )
}
