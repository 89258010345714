import queryString from 'query-string'

import {
  USER_UPDATE,
  USERS_BANNED,
  USER_DATA_DETAIL,
  USER_NEARBY_BY_MISSION,
  USERS_IN_PROGRESS,
  DELIVERY_MESSAGES,
  SEARCH_LEADERS,
} from '~/services/api/endpoints'
import { get, put, post } from '~/utils/actionsRequests'

const searchLeaders = (value) =>
  get({
    url: `${SEARCH_LEADERS}?search=${value}`,
    typeFetch: 'SEARCH_LEADERS',
    typeError: 'SEARCH_LEADERS_FAIL',
    typeResponse: 'SEARCH_LEADERS_OK',
  })

const findByOpens = ({ lat, lng, limit }) =>
  get({
    url: `${USER_NEARBY_BY_MISSION}?${queryString({ lat, lng, limit })}`,
    typeFetch: 'USER_NEARBY_BY_MISSION',
    typeError: 'USER_NEARBY_BY_MISSION_FAIL',
    typeResponse: 'USER_NEARBY_BY_MISSION_OK',
  })

const findUsersInProgressById = (id) =>
  get({
    url: USERS_IN_PROGRESS(id),
    typeFetch: 'USERS_IN_PROGRESS',
    typeError: 'USERS_IN_PROGRESS_FAIL',
    typeResponse: 'USERS_IN_PROGRESS_OK',
  })

const findByIdUserDetail = (id) =>
  get({
    url: `${USER_DATA_DETAIL}${id}`,
    typeFetch: 'USER_DATA_DETAIL',
    typeError: 'USER_DATA_DETAIL_FAIL',
    typeResponse: 'USER_DATA_DETAIL_OK',
  })

const banned = (data) =>
  put({
    data,
    url: USERS_BANNED,
    typeFetch: 'USERS_BANNED',
    typeError: 'USERS_BANNED_FAIL',
    typeResponse: 'USERS_BANNED_OK',
  })

const deliveryNotification = (data) =>
  post({
    data,
    url: DELIVERY_MESSAGES,
    typeFetch: 'DELIVERY_MESSAGES',
    typeError: 'DELIVERY_MESSAGES_FAIL',
    typeResponse: 'DELIVERY_MESSAGES_OK',
  })

const update = (id, data) =>
  put({
    data,
    url: USER_UPDATE(id),
    typeFetch: 'USER_UPDATE',
    typeError: 'USER_UPDATE_FAIL',
    typeResponse: 'USER_UPDATE_OK',
  })

export {
  banned,
  update,
  findByOpens,
  searchLeaders,
  findByIdUserDetail,
  deliveryNotification,
  findUsersInProgressById,
}
