import React, { memo } from 'react'

import { Modal, Image, Segment, Table, Dimmer, Loader } from 'semantic-ui-react'

import ImageShort from '~/assets/short-paragraph.png'
import EmptyList from '~/components/EmptyList'

function CategoriesModal({
  close,
  categories,
  customerName,
  openCategoriesModal,
  isLoadingCategories,
}) {
  console.log(`categories => `, categories)

  return (
    <Modal size="small" open={openCategoriesModal} onClose={close}>
      <Modal.Header
        content={`${categories.length} Categorias - ${customerName}`}
      />
      <Modal.Content scrolling>
        {isLoadingCategories && (
          <Segment>
            <Dimmer active>
              <Loader />
            </Dimmer>

            <Image src={ImageShort} />
          </Segment>
        )}

        {!isLoadingCategories && categories.length > 0 && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID ATIVIDADE</Table.HeaderCell>
                <Table.HeaderCell>NOME</Table.HeaderCell>
                <Table.HeaderCell>VALOR CLIENTE</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {categories.map((item, index) => (
                <Table.Row key={`keys-${index}`}>
                  <Table.Cell>{item.activity_id}</Table.Cell>
                  <Table.Cell>{item.title}</Table.Cell>
                  <Table.Cell>{item.customer_value}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}

        {!isLoadingCategories && categories.length === 0 && (
          <EmptyList icon="tasks" text="Nenhuma categoria foi adicionada..." />
        )}
      </Modal.Content>
    </Modal>
  )
}

export default memo(CategoriesModal)
