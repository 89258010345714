import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import _ from 'lodash'
import { Search } from 'semantic-ui-react'

import Breadcrumbs from '~/components/Breadcrumbs'
import TableComponent from '~/components/Table'
import api from '~/services/api'
import { SEARCH_LEADERS } from '~/services/api/endpoints'
import messages from '~/utils/messages'

import Item from './components/Item'
import { HeaderSearch } from './styles'

function LeadersBySubcategory() {
  const { subcategoryId } = useParams()
  const { state } = useLocation()
  const { item } = state

  const [search, setSearch] = useState([])
  const [leaders, setLeaders] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingPress, setLoadingPress] = useState(false)

  const getLeaders = useCallback(async () => {
    const res = await api.get(`/user/consultants/${subcategoryId}`)

    if (res.status === 200) {
      return setLeaders(res.data.data)
    }
  }, [subcategoryId])

  const searchLeaders = useCallback(
    async (value) => {
      setLoading(true)
      const res = await api.get(
        `${SEARCH_LEADERS}?search=${value}&missionsMainId=${subcategoryId}`
      )

      if (res.status === 200) {
        setLoading(false)
        return setSearch(
          res.data.data.map((i) => ({
            uid: i.uid,
            title: i.name,
            image: i.avatar,
            description: 'Adicionar',
          }))
        )
      }
    },
    [subcategoryId]
  )

  const submit = useCallback(
    async (id) => {
      setLoading(true)
      const res = await api.post(`user/consultants/${subcategoryId}`, {
        leaderId: id,
      })

      if (res.status === 200) {
        setLoading(false)
        messages().success('Líder adicionado com sucesso!')
        return getLeaders()
      }
    },
    [getLeaders, subcategoryId]
  )

  const removeById = useCallback(
    async (id) => {
      setLoadingPress(true)
      const res = await api.delete(`user/consultants/${id}`)

      if (res.status === 200) {
        setLoadingPress(false)
        messages().success('Líder removido com sucesso!')
        return getLeaders()
      }
    },
    [getLeaders]
  )

  function onChangeSearch(e, { value }) {
    return searchLeaders(value)
  }

  function onClickLeader(leader) {
    return submit(leader.uid)
  }

  function onClickRemove(id) {
    return removeById(id)
  }

  function handlePagination() {}

  useEffect(() => {
    getLeaders()
  }, []) //eslint-disable-line

  return (
    <>
      <Breadcrumbs
        items={[
          { hasLink: true, name: 'Subcategorias' },
          { hasLink: false, name: item.title },
          { active: true, name: 'Líderes' },
        ]}
      />
      <HeaderSearch>
        <h4>Busque e Adicione os Líderes para esta Subcategoria</h4>
        <Search
          fluid
          results={search}
          loading={loading}
          style={{
            maxWidth: 300,
            justiContent: 'center',
            alignItems: 'flex-start',
          }}
          noResultsMessage="Nenhum líder encontrado..."
          onResultSelect={(e, { result }) => onClickLeader(result)}
          onSearchChange={_.debounce(onChangeSearch, 500, {
            leading: true,
          })}
        />
      </HeaderSearch>

      <TableComponent
        data={leaders}
        handlePaginationChange={handlePagination}
        renderItem={(leader, index) => (
          <Item
            key={leader.uid}
            item={leader}
            index={index}
            loading={loadingPress}
            onClickRemove={onClickRemove}
          />
        )}
        columns={['', 'Nome', 'Email', 'Fone', '']}
        emptyText={{ icon: 'check circle outline', text: 'Nenhum líder...' }}
      />
    </>
  )
}

export default LeadersBySubcategory
